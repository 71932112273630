import Spinner from "../spinner";
import styles from "./style.module.scss";

const Loading = ({ active, message, style }) => {
  return active ? <div className={styles.inlineLoader} style={style}>
    <div className="has-spinner active text-center backdrop">
      <div className={"spinner-panel"}>
        <Spinner />
        {message ? <div className={styles.message}>{message}</div> : undefined}
      </div>
    </div>
  </div> : <></>;
};
export default Loading;