import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import useMediaQuery from '@mui/material/useMediaQuery'
import ReadyForMeetingTicket from '../../pages/safetyMeetings/readyForMeeting'
import { styles } from './common'
import ReadyForMeeting from '../../pages/iep/readyForMeeting'

type ReadyForMettingModalProps = {
  open: boolean
  onClose: () => void
  selected: any
  onChange: () => void
  openTicketModal: () => void
  title: string
  type: 'ticket' | 'iep'
}

const ReadyForMettingModal: React.FC<ReadyForMettingModalProps> = ({ open, onClose, title, selected, onChange, openTicketModal, type }) => {
  const isMobileView = useMediaQuery('(max-width:700px)')

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={[styles.modal, isMobileView ? styles.mobile : styles.desktop]}>
        <Box sx={styles.header}>
          <Box>{title}</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </Box>
        <Box sx={styles.body}>
          {type === 'ticket' ? (
            <ReadyForMeetingTicket selected={selected} onChange={onChange} openTicketModal={openTicketModal} className={undefined} style={undefined} />
          ) : (
            <ReadyForMeeting selected={selected} onChange={onChange} className={undefined} style={undefined} />
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default ReadyForMettingModal
