import { Box, Button, IconButton } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import { styles } from "../../../pages/modal/common";
import { Fragment, useEffect, useState } from "react";
import Api from "../../../helpers/api";
import { Checkboxes } from "../../../helpers/checkbox";
import { DateInput } from "../../../helpers/dateInput";
import { DropdownInput } from "../../../helpers/dropdown";
import Form from "../../../helpers/form/form";
import { default as Label, default as PROLabel } from "../../../helpers/label/label";
import PRODate from "../../../helpers/proDate";
import { YesOrNo } from "../../../helpers/radioInput/radioInput";
import { SignatureInput } from "../../../helpers/signature";
import { TextAreaLabel } from "../../../helpers/textArea";
import { TextInput } from "../../../helpers/textInput";
import { FlightRestriction } from "../../../helpers/utils/flightRestriction";
import { History } from "@icons/history";
import ChangeLogModal from "../../../pages/modal/change-log";

export const Deferred = ({call, item, onClose, onSave}) => {
	const [temp, setTemp] = useState(item);
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false)
  
  useEffect(() => {
    setTemp(item)
  }, [item])

	const save = ()=>{
		let out = {
			deferred: temp.deferred,
			deferralAction: temp.deferralAction,
			deferredBy: temp.deferredBy
		}
		if (!get(out, "deferred.code")) {
			set(out, "deferred.code", "A");
		}
    Api.post(call || window.jsRoutes.controllers.SMS.saveDeferred(temp._id.$oid), out)
      .then(Api.flagSuccess)
      .then(() => {
        onSave();
      })
      .catch(Api.flagFail);
	}

	const onChange = (key, value)=>{
		setTemp(p=>{
			let state = cloneDeep(p);
			set(state, key, value);
			return state;
		})
	}

	let deferredUntil;
	try {
		deferredUntil = get(temp, "deferred.expire").format();
	} catch (err) {
		deferredUntil = "N/A";
	}

	return <Form bottom={<>
		{get(temp, "logs.deferred.user._id") ? <div className="text-center">Last edited
      by {get(temp, "logs.deferred.user.firstName")} {get(temp, "logs.deferred.user.lastName")} on {(get(temp, "logs.deferred.timestamp") || new PRODate()).format("MMM dd, yyyy")}
      <IconButton onClick={() => setOpenChangeLogModal(true)}><History/></IconButton>
    </div> : undefined}
      <Box sx={{ width: '100%', textAlign: 'end' }}>
        <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={onClose}>Cancel</Button>
        <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={save}>Save</Button>
      </Box>
  </>}>
    <ChangeLogModal open={openChangeLogModal} id={item?._id?.$oid} node="logs.deferred" onClosed={() => setOpenChangeLogModal(false)}/>
		<TextInput name="MEL ATA Code" value={get(temp, "deferred.description")}
				   onChange={v => onChange("deferred.description", v)}/>
		<YesOrNo name="(O) Procedure" value={get(temp, "deferred.oProcedure")}
				 onChange={v => onChange("deferred.oProcedure", v)}/>
		{get(temp, "deferred.oProcedure") ?
			<TextAreaLabel key={get(temp, "deferred.oProcess")} name={"(O) Process"}
						   value={get(temp, "deferred.oProcess")}
						   onChange={v => onChange("deferred.oProcess", v)}/>
		: undefined}
		<YesOrNo name="(M) Procedure" value={get(temp, "deferred.mProcedure")}
				 onChange={v => onChange("deferred.mProcedure", v)}/>
		{get(temp, "deferred.mProcedure") ?
			<TextAreaLabel key={get(temp, "deferred.mProcess")} name={"(M) Process"}
						   value={get(temp, "deferred.mProcess")}
						   onChange={v => onChange("deferred.mProcess", v)}/> : undefined}
		<TextAreaLabel key={get(temp, "deferred.process")} name={"Deferral Procedure"}
					   value={get(temp, "deferred.process")}
					   onChange={v => onChange("deferred.process", v)}/>
		<PROLabel name={null} value={"* Follow Placarding instructions per the approved MEL."}/>
		<DropdownInput name="Code" value={get(temp, "deferred.code")}
					   options={[
						   {id: "A", value: "A"},
						   {id: "B", value: "B"},
						   {id: "C", value: "C"},
						   {id: "D", value: "D"}
					   ]}
					   onChange={v => onChange("deferred.code", v)}/>
		<TextInput name="Deferred By" value={get(temp, "deferredBy.name") || ""}
				   onChange={v => onChange("deferredBy.name", v)}/>
		<TextInput name="Certificate #" value={get(temp, "deferredBy.certificate") || ""}
				   onChange={v => onChange("deferredBy.certificate", v)}/>
		<DateInput name="Date" value={get(temp, "deferredBy.date")}
				   onChange={v => onChange("deferredBy.date", v)}/>
		<SignatureInput grid={true}
						note={<div style={{marginTop: "var(--spacing)"}}>
							Note: Make sure all your data is saved BEFORE digitally signing the data.
						</div>}
						fields={["deferred.code","deferred.expire","deferredBy.name","deferredBy.certificate"]}
						object={item}
						request={window.jsRoutes.controllers.SMS.signatureRequest(temp._id.$oid, "deferred")}
						sign={window.jsRoutes.controllers.SMS.signatureSign(temp._id.$oid, "deferred")}
						item={get(temp, "signatures.deferred")} onSave={onSave}/>
		<Label name="Deferred Until" value={deferredUntil}/>
		{["B", "C"].includes(get(temp, "deferred.code")) ? <Fragment>
			<DateInput grid={true} name="Extension" value={get(temp, "deferred.extension")}
					   onChange={v => onChange("deferred.extension", v)}/>
			<TextInput grid={true} name="Extension Reason"
					   value={get(temp, "deferred.extensionReason") || ""}
					   onChange={v => onChange("deferred.extensionReason", v)}/>
			<SignatureInput grid={true}
							note={<div style={{marginTop: "var(--spacing)"}}>
								Note: Make sure all your data is saved BEFORE digitally signing the data.
							</div>}
							fields={["deferred.extension","deferred.extensionReason"]}
							object={temp}
							request={window.jsRoutes.controllers.SMS.signatureRequest(temp._id.$oid, "extension")}
							sign={window.jsRoutes.controllers.SMS.signatureSign(temp._id.$oid, "extension")}
							item={get(temp, "signatures.extension")}
							onSave={onSave}/>
		</Fragment> : undefined}
		<TextAreaLabel key={get(temp, "deferred.limitations")} name={"Limitations"}
					   value={get(temp, "deferred.limitations")}
					   onChange={v => onChange("deferred.limitations", v)}/>
		<Checkboxes pill={true} name="Restrictions" options={FlightRestriction.list()}
					values={get(temp, "deferred.restrictions")} onChange={val => {
						setTemp(p=>{
							let state = cloneDeep(p);
							let existing = get(state, "deferred.restrictions") || [];
							if (existing.includes(val)) {
								existing = existing.filter(i => i !== val);
							} else {
								existing.push(val);
							}
							set(state, "deferred.restrictions", existing);
							return state;
						})
		}} labelNode={"name"}/>
	</Form>;
}