import isEmpty from "lodash/isEmpty";
import {useDispatch, useSelector} from "react-redux";
import {selectMemo, setMemo} from "../../data/memoReducer";
import {Options, selectOption, setOption} from "../../data/optionsReducer";
import "./style.scss";

const Memo = () => {
	const dispatch = useDispatch();
	const memo = useSelector(selectMemo);
	const top = useSelector(selectOption(Options.Memo.Top));
	const left = useSelector(selectOption(Options.Memo.Left));

	function createMarkup() {
		return {__html: memo.text};
	}

	function onDrag(ev) {
		if (ev.clientX === 0 && ev.clientY === 0) return false;
		dispatch(setOption({option:Options.Memo.Top, value: ev.clientY}));
		dispatch(setOption({option:Options.Memo.Left, value: ev.clientX}));
	}

	return (isEmpty(memo.text)) ? false : (
		<div draggable={true} onDragStart={ev=>{ ev.dataTransfer.setDragImage(new Image(), 0 ,0) }} onDrag={onDrag} className="memo panel panel-warning" style={{top: top, left: left}}>
			<div className="panel-heading">Memo <button className="pull-right" onClick={() => {
				dispatch(setMemo(null));
			}}>&times;</button></div>
			<div className="panel-body" dangerouslySetInnerHTML={createMarkup()}/>
		</div>
	);
}
export default Memo;