import { ClickAwayListener } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import { noop } from "lodash";
import React, { CSSProperties, useRef, useState } from "react";
import { useNavigate } from "react-router";

const styles: { [x: string]: CSSProperties } = {
  menuLabel: {
    cursor: "pointer",
    fontWeight: 'var(--font-weight-normal)',
    textTransform: "capitalize",
    fontSize: 'var(--font-md)',
    height: "36px",
  },
  menu: {
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,.25)",
    backgroundColor: "transparent",
    zIndex: 1300,
    cursor: "pointer",
  },
  autoDropdown: {
    inset: "10px auto auto 0px !important",
  },
  menuItem: { whiteSpace: "normal", wordWrap: "break-word", backgroundColor: 'var(--white)', color: 'var(--trueBlack)', fontSize: 'calc(var(--font-md) - 2px)' }
};

type MenuButtonProp = {
  label: string;
  onSelect: (value: string) => void;
  value?: string;
  menuItems: { label: string; value: string }[];
  endIcon?: React.ReactNode;
  variant?: "text" | "outlined" | "contained";
  fullWidth?: boolean;
  createBtnWidth?: string;
};

const MenuButton: React.FC<MenuButtonProp> = ({
  label,
  value,
  menuItems,
  onSelect,
  endIcon,
  variant,
  fullWidth,
  createBtnWidth = "auto",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const hoverOnMenu = useRef<boolean>(false);

  const open = Boolean(anchorEl);
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (endIcon && anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const closeMenu = (value: string) => {
    setAnchorEl(null);
    hoverOnMenu.current = false;
    if (value) {
      setTimeout(() => {
        onSelect(value);
      }, 100);
    }
  };

  return (
    <Box sx={{ width: fullWidth ? "100%" : "auto" }}>
      <ClickAwayListener onClickAway={() => closeMenu("")}>
        <Button
          id={`btn-${label}`}
          sx={{
            ...styles.menuLabel,
            color: variant ? "#fff" : "var(--trueBlack)",
            fontSize: 14,
            fontWeight: endIcon ? 'bold' : 'initial',
            backgroundColor: endIcon ? "var(--primaryBlue)" : "",
            borderRadius: endIcon ? '5px' : 0,
            // borderBottom: '1px solid transparent',
            // '&:hover': {
            //   borderBottom: endIcon ? 'unset' : '1px solid var(--gray)'
            // }
          }}
          endIcon={endIcon}
          variant={variant}
          fullWidth={fullWidth}
          onMouseEnter={endIcon ? noop : openMenu}
          onMouseLeave={() => {
            setTimeout(() => {
              if (!hoverOnMenu.current) {
                closeMenu('')
              }
            }, 100);
          }}
          onClick={(e) => endIcon ? openMenu(e) : navigate(value || '')
          }
        >
          {label}
        </Button>
      </ClickAwayListener>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        transition
        sx={[
          styles.menu,
          !endIcon ? styles.autoDropdown : { width: createBtnWidth },
        ]}
        onMouseLeave={() => (!endIcon ? closeMenu("") : noop)}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Box sx={{ backgroundColor: "var(--white)" }}>
              {menuItems.map((item) => (
                <MenuItem
                  key={item.label}
                  sx={styles.menuItem}
                  onClick={(e) => {
                    e.stopPropagation()
                    closeMenu(item.value)
                  }}
                  onMouseOver={() => {
                    hoverOnMenu.current = true;
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default MenuButton;
