export const FlightRestriction = {
	parse: function (id) {
		switch (id) {
			case 2:
				return this.Day;
			case 3:
				return this.Flight;
			case 4:
				return this.Ice;
			case 5:
				return this.RVSM;
			case 6:
				return this.VMC;
			case 7:
				return this.Water;
			case 8:
				return this.Crew;
			case 9:
				return this.Other;
			default:
				return this.Altitude;
		}
	},
	list: function () {
		return [this.Altitude, this.Day, this.Flight, this.Ice, this.RVSM, this.VMC, this.Water, this.Crew, this.Other]
	},

	Altitude: {id: 1, name: "Altitude Restricted"},
	Day: {id: 2, name: "Only Daytime"},
	Flight: {id: 3, name: "Flight"},
	Ice: {id: 4, name: "No Icing Conditions"},
	RVSM: {id: 5, name: "No RVSM Conditions"},
	VMC: {id: 6, name: "VMC Only"},
	Water: {id: 7, name: "No Over Water"},
	Crew: {id: 8, name: "No Single Pilot Ops."},
	Other: {id: 9, name: "Other"}
};