import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import MuiToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, CSSProperties, useEffect, useState } from 'react';
import { styles } from './common';
import { cloneDeep, set, has, get, head, find, without, noop } from 'lodash';
import Api from '../../helpers/api';
import PRODate from '../../helpers/proDate';
import MenuItem from '@mui/material/MenuItem';
import { AttachmentsView } from '../../helpers/upload';
import AirportInput from '../../helpers/airportInput';
import SnackBar from '../../helpers/snackBar';

type EditWorkOrderModalProps = {
  open: boolean
  onCancel: () => void
  onOk: (result: any) => void
  onError: (err: Error) => void
  workOrder?: any
}

const localStyles: { [x: string]: CSSProperties | {} } = {
  toggleButton: {
    display: 'flex',
    justifyContent: 'start',
    '& > button': {
      borderRadius: '5px',
      textTransform: 'none',
      marginRight: '10px'
    }
  },
  customLabel: {
    minWidth: '150px !important',
  }
}

const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#337AB7',
  },
}));

type WorkOrder = {
  name: string
  aircraft: AircrafOpt
  airport: string
  start: PRODate | null
  proposedEnd: PRODate | null
  end: PRODate | null
  assigned: ProviderOpt
  billableHours: string
  findings: string
  completed: boolean
  files?: File[]
  _id?: {
    $oid: string
  }
}

type ProviderOpt = {
  airport: string
  code: string
  name: string
  _id: {
    $oid: string
  }
}

type AircrafOpt = {
  active: boolean
  airport: string
  paxSeats: number
  serial: string
  tailNumber: string
  type: {
    engines: number
    name: string
    _id: {
      $oid: string
    }
  }
  _id: {
    $oid: string
  }
}

export const initialWorkOrder: WorkOrder = {
  name: '',
  aircraft: {
    active: true,
    airport: '',
    paxSeats: 0,
    serial: '',
    tailNumber: '',
    type: {
      engines: 0,
      name: '',
      _id: {
        $oid: ''
      }
    },
    _id: {
      $oid: ''
    }
  },
  airport: '',
  start: null,
  proposedEnd: null,
  end: null,
  assigned: {
    airport: '',
    code: '',
    name: '',
    _id: {
      $oid: ''
    }
  },
  billableHours: '',
  findings: '',
  completed: true,
}

const EditWorkOrderModal: React.FC<EditWorkOrderModalProps> = ({ open, onCancel, onOk, onError, workOrder }) => {
  const isMobileView = useMediaQuery('(max-width:700px)');
  const [localWorkOrder, setLocalWorkOrder] = useState<WorkOrder>(initialWorkOrder)
  const [providers, setProviders] = useState<ProviderOpt[]>([]);
  const [aircrafts, setAircrafts] = useState<AircrafOpt[]>([]);

  const [airport, setAirport] = useState<string>('');
  const [airportName, setAirporName] = useState<string>('');
  const [error, setError] = useState<string>('')

  useEffect(() => {
    setLocalWorkOrder(workOrder || initialWorkOrder);
    const ops = [];
    ops.push(Api.get((window as any).jsRoutes.controllers.MaintenanceController.listAllProviders()).catch(Api.silentFail));
    ops.push(Api.get((window as any).jsRoutes.controllers.AircraftController.list()).catch(Api.silentFail));
    if (has(workOrder, '_id.$oid')) {
      ops.push(Api.get((window as any).jsRoutes.controllers.WorkOrders.get(workOrder._id.$oid)).then(result => {
        const tOps = [];
        tOps.push(new PRODate(result.workOrder.start).withZoneId(result.workOrder.airport).then(d => {
          result.workOrder.start = d;
        }));
        tOps.push(new PRODate(result.workOrder.proposedEnd).withZoneId(result.workOrder.airport).then(d => {
          result.workOrder.proposedEnd = d;
        }));
        if (PRODate.isDate(result.workOrder.end)) {
          tOps.push(new PRODate(result.workOrder.end).withZoneId(result.workOrder.airport).then(d => {
            result.workOrder.end = d;
          }));
        }
        return Promise.all(tOps).then(() => result);
      }).catch(Api.silentFail));
    }
    Promise.all(ops).then(results => {
      const providers = results[0]?.providers || [];
      const aircraftList = results[1]?.aircraft || [];
      const obj: any = Object.assign({ providers, aircraftList }, get(results, '2.workOrder'));
      if (!(has(obj, 'assigned._id.$oid') || has(workOrder, 'assigned._id.$oid')) && providers.length > 0) {
        obj.assigned = providers[0];
      }
      if (!(has(obj, 'aircraft._id') || has(workOrder, 'aircraft._id')) && (aircraftList).length > 0) {
        obj.aircraft = head(aircraftList);
      }
      setProviders(obj.providers);
      setAircrafts(obj.aircraftList);
    });
    return () => setLocalWorkOrder(initialWorkOrder);
  }, [workOrder]);

  const onChange = (field: string, value: any) => {
    const cloneData = cloneDeep(localWorkOrder);
    if (['proposedEnd', 'start'].includes(field)) {
      new PRODate(value).withZoneId(localWorkOrder.airport).then(time => {
        set(cloneData, field, time);
        setLocalWorkOrder(cloneData);
      });
    } else {
      if (field === 'completed' && value && localWorkOrder._id?.$oid) {
        set(cloneData, 'end', dayjs().valueOf());
      }
      set(cloneData, field, value);
      setLocalWorkOrder(cloneData);
    }
  }

  const localSave = () => {
    Api.post((window as any).jsRoutes.controllers.WorkOrders.save(), localWorkOrder).then(Api.flagSuccess).then((result) => {
      result.workOrder.start = new PRODate(result.workOrder.start);
      result.workOrder.proposedEnd = new PRODate(result.workOrder.proposedEnd);
      if (PRODate.isDate(get(result, "workOrder.end"))) {
        result.workOrder.end = new PRODate(result.workOrder.end);
      }
      onOk(result);
    }).catch(ev => {
      setError(ev.message)
    });
  }

  return (
    <Modal
      open={open}
      onClose={onCancel}
    >
      <Box sx={[styles.modal, isMobileView ? styles.mobile : styles.desktop]}>
        <SnackBar
          open={!!error}
          handleClose={() => setError('')}
          message={error}
          status='error'
        />
        <Box sx={styles.header}>
          <Box>{localWorkOrder._id ? 'Edit Work Order' : 'New Work Order'}</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onCancel} />
        </Box>
        <Box sx={styles.body}>
          <Box sx={styles.control}>
            <Typography sx={localStyles.customLabel}>Title </Typography>
            <FormControl variant='standard'>
              <TextField size='small' variant='standard' value={localWorkOrder.name} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('name', e.target.value)} />
            </FormControl>
          </Box>
          <Box sx={styles.control}>
            <Typography sx={localStyles.customLabel}>Aircraft </Typography>
            <FormControl variant='standard'>
              <Select
                value={localWorkOrder.aircraft.type._id.$oid}
                fullWidth
                onChange={(e: SelectChangeEvent) => {
                  const cloneWorkOrder = cloneDeep(localWorkOrder);
                  const selectedAirCraft = aircrafts.find(item => item.type._id.$oid === e.target.value);
                  if (selectedAirCraft) {
                    cloneWorkOrder.aircraft = selectedAirCraft;
                    setLocalWorkOrder(cloneWorkOrder);
                  }
                }}
              >
                {aircrafts.map((aircraft, idx) => (<MenuItem key={idx} value={aircraft.type._id.$oid}>{aircraft.tailNumber} - {aircraft.type.name}</MenuItem>))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.control}>
            <Typography sx={localStyles.customLabel}>Airport </Typography>
            <AirportInput icao={airport}
              onChange={(value: any) => {
              localWorkOrder.start?.withZoneId(value.icao).then(r => {
                onChange('start', r );
              });
              localWorkOrder.proposedEnd?.withZoneId(value.icao).then(r => {
                onChange('proposedEnd', r );
              });
              if (localWorkOrder.end) {
                localWorkOrder.end.withZoneId(value.icao).then(r => {
                  onChange('end', r );
                });
              }
              setAirport(value.icao);
              setAirporName(value.name);
            } } sideLabel={airportName} id={undefined} className={undefined} style={undefined} inputStyle={{ padding: '5px' }} />
            
          </Box>
          <Box sx={[styles.control, { width: isMobileView ? '100%' : '50%' }]}>
            <Typography sx={localStyles.customLabel}>Start </Typography>
            <FormControl variant='standard' >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  format='MM/dd/yyyy'
                  value={localWorkOrder.start?.valueOf() || null}
                  onChange={(value => onChange('start', value))}
                  slotProps={{
                    textField: { 
                      size: 'small',
                      variant: 'standard',
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={[styles.control, { width: isMobileView ? '100%' : '50%' }]}>
            <Typography sx={localStyles.customLabel}>Proposed End </Typography>
            <FormControl variant='standard' >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  format='MM/dd/yyyy'
                  value={localWorkOrder.proposedEnd?.valueOf() || null}
                  onChange={(value => onChange('proposedEnd', value))}
                  slotProps={{
                    textField: { 
                      size: 'small',
                      variant: 'standard',
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={[styles.control, { width: isMobileView ? '100%' : '50%' }]}>
            <Typography sx={localStyles.customLabel}>End </Typography>
            <FormControl variant='standard' >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  format='MM/dd/yyyy'
                  value={localWorkOrder.end?.valueOf() || null}
                  onChange={(value => onChange('end', value))}
                  slotProps={{
                    textField: { 
                      size: 'small',
                      variant: 'standard',
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={styles.control}>
            <Typography sx={localStyles.customLabel}>Assign To </Typography>
            <FormControl variant='standard' sx={{ mt: 1 }}>
              <Select
                value={localWorkOrder.assigned._id.$oid}
                fullWidth
                onChange={(e: SelectChangeEvent) => onChange('assigned._id.$oid', e.target.value)}
              >
                {providers.map((provider, idx) => (<MenuItem key={idx} value={provider._id.$oid}>{provider.name} - {provider.code}</MenuItem>))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.control}>
            <Typography sx={localStyles.customLabel}>Billable Hours </Typography>
            <FormControl variant='standard'>
              <TextField size='small' variant='standard' value={localWorkOrder.billableHours} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('billableHours', e.target.value)} />
            </FormControl>
          </Box>
          <Box sx={styles.control}>
            <Typography sx={localStyles.customLabel}>Description </Typography>
            <FormControl >
              <TextareaAutosize minRows={3}
                style={{ width: '100%', border: '1px solid rgb(133, 133, 133)', borderRadius: '5px', padding: '5px' }}
                value={localWorkOrder.findings} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange('findings', e.target.value)}
              />
            </FormControl>
          </Box>
          <Box sx={styles.control}>
            <Typography sx={localStyles.customLabel}>Completed </Typography>
            <FormControl variant='standard' sx={{ mt: 1 }}>
              <Box sx={localStyles.toggleButton}>
                <ToggleButton
                  sx={{ width: '150px' }}
                  value={true}
                  size='small'
                  selected={localWorkOrder.completed}
                  onChange={() => onChange('completed', !localWorkOrder.completed)}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  sx={{ width: '150px' }}
                  value={false}
                  size='small'
                  selected={!localWorkOrder.completed}
                  onChange={() => onChange('completed', !localWorkOrder.completed)}
                >
                  No
                </ToggleButton>
              </Box>
            </FormControl>
          </Box>
          <Box sx={styles.control}>
            {localWorkOrder._id &&
              <AttachmentsView className="full-width" items={localWorkOrder.files || []} onSave={(modal: any, item: any) => {
                Api.post((window as any).jsRoutes.controllers.WorkOrders.addFile(localWorkOrder._id?.$oid), item).then(Api.flagSuccess).then((result) => {
                  let cloneFiles = cloneDeep(localWorkOrder.files || []);
                  cloneFiles.push(result.file);
                  onChange('files', cloneFiles);
                  modal.close();
                }).catch(Api.flagFail);
              }} onDelete={(item: any) => {
                if (window.confirm("Are you sure you want to delete this file?")) {
                  Api.delete((window as any).jsRoutes.controllers.WorkOrders.removeFile(localWorkOrder._id?.$oid, item.id), {})
                    .then(Api.flagSuccess)
                    .then(() => {
                      const img = find(localWorkOrder.files, (i: any) => i.id === item.id);
                      const list = without(localWorkOrder.files, img);
                      onChange('files', list);
                    }).catch(Api.flagFail);
                }
              }} label={undefined} placeholder={undefined} allowEdit={undefined} meta={undefined} style={undefined} grid={undefined} newModal />}
          </Box>
          <Box sx={[styles.control, { justifyContent: 'end', mt: '20px' }]}>
            <Button variant='contained' sx={styles.action} onClick={onCancel}>Close</Button>
            <Button variant='contained' sx={styles.action} onClick={localSave}>Save</Button>
          </Box>
        </Box>
      </Box >
    </Modal >
  )
}

export default EditWorkOrderModal;