import { selectTicket } from "@data/ticketReducer.jsx";
import Api from "@helpers/api";
import { DateInput } from "@helpers/dateInput";
import { Dropdown, DropdownInput } from "@helpers/dropdown";
import Form from "@helpers/form/form";
import PRODate from "@helpers/proDate";
import { YesOrNo } from "@helpers/radioInput/radioInput";
import { TextAreaLabel } from "@helpers/textArea";
import { SMSTicketType } from "@helpers/utils/SMSTicketType";
import { usePopover } from "@hooks/usePopover";
import { QuestionCircle } from "@icons/circle/question";
import { History } from "@icons/history.jsx";
import { Shelf } from "@layout/shelf";
import { Box, Button, IconButton } from "@mui/material";
import includes from "lodash/includes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChangeLogModal from "../../../pages/modal/change-log";
import { styles } from "../../../pages/modal/common";

export const Review = ({id, safetyTeam, onSave, onClose}) => {
  const item = useSelector(selectTicket(id));
	const [popover, setPopover, clearPopover] = usePopover();
	const [errors, setErrors] = useState([]);

	const [review, setReview] = useState(item.review);
	const [reviewDate, setReviewDate] = useState(item.reviewDate);
	const [sdr, setSdr] = useState(item.sdr)
	const [sdrMis, setSdrMis] = useState(item.sdrMis);
	const [due, setDue] = useState(item.logs?.corrected?.due);
	const [user, setUser] = useState(item.logs?.corrected?.assigned?._id ?? safetyTeam?.[0]?._id);
	const [authority, setAuthority] = useState(item.logs?.corrected?.authority?._id);
	const [responsibility, setResponsibility] = useState(item.logs?.corrected?.responsibility?._id);
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false)

  useEffect(() => {
    setReview(item.review)
    setReviewDate(item.reviewDate)
    setSdr(item.sdr)
    setSdrMis(item.sdrMis)
    setDue(item.logs?.corrected?.due)
    setUser(item.logs?.corrected?.assigned?._id ?? safetyTeam?.[0]?._id)
    setAuthority(item.logs?.corrected?.authority?._id)
    setResponsibility(item.logs?.corrected?.responsibility?._id)
  }, [item, safetyTeam])

	const save = (btn)=>{
		let out = {
			review: review,
			sdr: sdr,
			sdrMis: sdrMis,
			reviewDate: reviewDate?.timestamp,
			due: due?.timestamp,
			user: user,
			authority: authority,
			responsibility: responsibility,
		};
		Api.post(window.jsRoutes.controllers.SMS.saveReview(item._id.$oid), out)
			.then(Api.flagSuccess)
			.then(() => {
				setErrors([]);
				onSave();
			}).catch(err => {
				Api.flagFail(err);
				setErrors(err.json.errors || []);
			})
			.then(() => {
				btn.deactivate();
			});
	}

	return <Form bottom={<>
		{item?.logs?.reviewed?.user?._id ? <div className="text-center">Last edited
			by {item?.logs?.reviewed?.user?.firstName} {item?.logs?.reviewed?.user?.lastName} on {(item?.logs?.reviewed?.timestamp || new PRODate()).format("MMM dd, yyyy")}
			<IconButton onClick={() => setOpenChangeLogModal(true)}><History/></IconButton>
		</div> : undefined}
    <Box sx={{ width: '100%', textAlign: 'end' }}>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={onClose}>Cancel</Button>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={save}>Save</Button>
    </Box>
	</>}>
    {popover}
    <ChangeLogModal open={openChangeLogModal} id={item?._id?.$oid} node="logs.reviewed" onClosed={() => setOpenChangeLogModal(false)}/>
		<TextAreaLabel key={review} name="Review" value={review || ""}
					   onChange={v => setReview(v)}
					   error={includes(errors, "obj.review")}/>
		{item.discrepancy && !item.deferred ?
			<YesOrNo name="SDR" value={sdr} onChange={v => setSdr(v)}
					 append={<button onClick={() => {
						 setPopover(<Shelf saveBtn={false} onClosed={clearPopover}>
							 <h4>§135.415&nbsp;&nbsp;&nbsp;Service difficulty reports.</h4>
							 <ol>
								 <li>Fires during flight and whether the related fire-warning system
									 functioned properly;
								 </li>
								 <li>Fires during flight not protected by related fire-warning system;</li>
								 <li>False fire-warning during flight;</li>
								 <li>An exhaust system that causes damage during flight to the engine,
									 adjacent structure, equipment, or components;
								 </li>
								 <li>An aircraft component that causes accumulation or circulation of smoke,
									 vapor, or toxic or noxious fumes in the crew compartment or passenger
									 cabin during flight;
								 </li>
								 <li>Engine shutdown during flight because of flameout;</li>
								 <li>Engine shutdown during flight when external damage to the engine or
									 aircraft structure occurs;
								 </li>
								 <li>Engine shutdown during flight due to foreign object ingestion or
									 icing;
								 </li>
								 <li>Shutdown of more than one engine during flight;</li>
								 <li>A propeller feathering system or ability of the system to control
									 overspeed during flight;
								 </li>
								 <li>A fuel or fuel-dumping system that affects fuel flow or causes
									 hazardous leakage during flight;
								 </li>
								 <li>An unwanted landing gear extension or retraction or opening or closing
									 of landing gear doors during flight;
								 </li>
								 <li>Brake system components that result in loss of brake actuating force
									 when the aircraft is in motion on the ground;
								 </li>
								 <li>Aircraft structure that requires major repair;</li>
								 <li>Cracks, permanent deformation, or corrosion of aircraft structures, if
									 more than the maximum acceptable to the manufacturer or the FAA;
								 </li>
								 <li>Aircraft components or systems that result in taking emergency actions
									 during flight (except action to shut-down an engine).
								 </li>
							 </ol>
						 </Shelf>);
					 }} className={"text-muted"}>
						 <QuestionCircle />
					 </button>}/> : undefined}
		{sdr ?
			<TextAreaLabel key={sdrMis} name="SDR MIS" value={sdrMis || ""}
						   onChange={v => setSdrMis(v)}
						   error={includes(errors, "obj.sdrMis")}/> : undefined}
		<DateInput name="Date" value={reviewDate}
				   onChange={v => setReviewDate(v)}
				   error={includes(errors, "obj.reviewDate")}/>
		<DropdownInput
			name={(item.ticketType === SMSTicketType.Change.id) ? "Accountability" : "Assigned To"}
			value={{_id: user}}
			options={safetyTeam}
			onChange={v=> setUser(v._id)}
			valueNode="_id.$oid"
			valueOnly={false}
			labelNode={Dropdown.Formatter.UserName}
			error={includes(errors, "obj.user")}/>
		{(item.ticketType === SMSTicketType.Change.id) ?
			<DropdownInput name="Authority" value={{_id: authority}}
						   options={safetyTeam}
						   onChange={v=> setAuthority(v._id)}
						   valueNode="_id.$oid"
						   valueOnly={false}
						   labelNode={Dropdown.Formatter.UserName}
						   error={includes(errors, "obj.user")}/> : undefined}
		{(item.ticketType === SMSTicketType.Change.id) ?
			<DropdownInput name="Responsibility"
						   value={{_id: responsibility}}
						   options={safetyTeam}
						   onChange={v=> setResponsibility(v._id)}
						   valueNode="_id.$oid"
						   valueOnly={false}
						   labelNode={Dropdown.Formatter.UserName}
						   error={includes(errors, "obj.user")}/> : undefined}
		<DateInput name="Due" value={due}
				   onChange={v=> setDue(v)}
				   error={includes(errors, "obj.due")}/>
	</Form>
}