import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import tail from "lodash/tail";
import SimpleInput from "./simpleInput";
import PROLabel from "./label/label";
import unset from "lodash/unset";
import head from "lodash/head";
import compact from "lodash/compact";
import { MinusCircle } from "../icons/circle/minus";
import { PlusCircle } from "../icons/circle/plus";

const PRORepeat = (props) => {
  const change = (index, value) => {
    let state = cloneDeep(props.values || []);
    set(state, index, value);
    props.onChange(compact(state));
  };

  const create = () => {
    let state = cloneDeep(props.values);
    (state || []).push("");
    props.onChange(state);
  };

  const remove = (index) => {
    let state = cloneDeep(props.values);
    unset(state, index);
    props.onChange(compact(state));
  };

  const fields = [
    <div
      key={0}
      style={{
        width: "100%",
        marginBottom: tail(props.values || []).length ? "15px" : 0,
      }}
    >
      <SimpleInput
        value={head(props.values) || ""}
        onChange={(v) => change(0, v)}
        append={
          (props.values || []).length <= 1 ? (
            <button
              className="text-primary"
              onClick={props.disabled ? undefined : create}
            >
              <PlusCircle />
            </button>
          ) : (
            <button
              className="text-danger"
              onClick={props.disabled ? undefined : () => remove(0)}
            >
              <MinusCircle />
            </button>
          )
        }
        disabled={props.disabled}
      />
    </div>,
  ].concat(
    tail(props.values || []).map((item, index, list) => {
      return (
        <div
          key={index + 1}
          style={{ flexDirection: "column", marginTop: index > 0 ? "15px" : 0 }}
        >
          <SimpleInput
            value={item}
            onChange={(v) => change(index + 1, v)}
            append={
              index + 1 === list.length ? (
                <button
                  className="text-primary"
                  onClick={props.disabled ? undefined : create}
                >
                  <PlusCircle />
                </button>
              ) : (
                <button
                  className="text-danger"
                  onClick={props.disabled ? undefined : () => remove(index + 1)}
                >
                  <MinusCircle />
                </button>
              )
            }
            disabled={props.disabled}
          />
        </div>
      );
    })
  );

  return <PROLabel name={props.name} value={fields} noBox/>;
};
export default PRORepeat;
