export const SMSTicketType = {
	parse: function (id) {
		switch (id) {
			case 1:
				return this.Maintenance;
			case 3:
				return this.Dispatch;
			case 4:
				return this.Change;
			case 5:
				return this.Advisory;
			default:
				return this.Safety;
		}
	},
	list: function () {
		return [this.Safety, this.Maintenance, this.Dispatch, this.Advisory, this.Change];
	},

	Safety: {id: 0, name: "Safety", bg: "background-darkYellow"},
	Maintenance: {id: 1, name: "Maintenance", bg: "background-orange"},
	Dispatch: {id: 3, name: "Dispatch", bg: "background-blue"},
	Change: {id: 4, name: "Change Management", bg: "background-darkGray"},
	Advisory: {id: 5, name: "Advisory", bg: "background-lightBlue"},
};