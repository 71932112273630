import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChangeLog from '../../pages/changeLog';
import { styles } from './common';
import { CSSProperties } from 'react';

type ChangeLogModalProps = {
  open: boolean
  onClosed: () => void
  id: string
  node: string
  showKey?: boolean
  mod?: any,
  newValueClass?: string
  byTimestamp?: boolean
  preprocess?: any
  title?: string
}

const localStyles: { [x: string]: CSSProperties | {} } = {
  customModal: {
    width: '70% !important'
  }
}

const ChangeLogModal: React.FC<ChangeLogModalProps> = ({ open, onClosed, id, node, showKey = false, title, ...props }) => {
  const isMobileView = useMediaQuery('(max-width:700px)');
  
  return (
    <Modal
      open={open}
      onClose={onClosed}
    >
      <Box sx={[styles.modal, isMobileView ? {} : localStyles.customModal, isMobileView ? styles.mobile : styles.desktop]}>
        <Box sx={styles.header}>
          <Box>{title || 'Change Log'}</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onClosed} />
        </Box>
        <Box sx={styles.body}>
          <ChangeLog id={id} node={node} showKey={showKey} onClosed={onClosed} {...props} />
          {/* <ChangeLog id={id} node={"deleted.erk"} showKey={false} onClosed={onClose} {...props} /> */}
        </Box>
      </Box>
    </Modal>
  )
}

export default ChangeLogModal;