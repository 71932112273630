import { selectTicket, setTicket as setRealTicket, updateTicket } from "@data/ticketReducer";
import Api from "@helpers/api";
import { DateInput } from "@helpers/dateInput";
import { Dropdown, DropdownInput } from "@helpers/dropdown";
import Form from "@helpers/form/form";
import PROLabel from "@helpers/label/label.jsx";
import { YesOrNo } from "@helpers/radioInput/radioInput";
import { SignatureInput } from "@helpers/signature.jsx";
import { TextAreaLabel } from "@helpers/textArea";
import { TextInput } from "@helpers/textInput";
import { SMSTicketType } from "@helpers/utils/SMSTicketType";
import { ChangeType } from "@helpers/utils/changeType";
import { usePopover } from "@hooks/usePopover";
import { PopoverFull } from "@layout/popover/index.jsx";
import { Box, Button } from "@mui/material";
import isPlainObject from "lodash/isPlainObject";
import { styles } from "../../pages/modal/common";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Create = ({onClosed, onSave, ticketType = SMSTicketType.Safety.id})=> {
	const dispatch = useDispatch();
	const [popover, setPopover] = usePopover();
	const [aircraftList, setAircraftList] = useState([]);
	const [phases, setPhases] = useState([]);
	const [types, setTypes] = useState([]);
	const [ataCodes, setAtaCodes] = useState([]);
	const [actions, setActions] = useState([]);
	const [errors, setErrors] = useState([]);
	const ticket = useSelector(selectTicket("new"));

	useEffect(() => {
		Api.get(window.jsRoutes.controllers.Api.getSMSPhases())
			.then(response => {
				setPhases(response.phases);
				setTicket("phase", response.phases[0].id);
			})
			.catch(Api.silentFail);
		Api.get(window.jsRoutes.controllers.Api.getSMSTypes())
			.then(response => {
				setTypes(response.types);
				setTicket("type", response.types[0].id);
			})
			.catch(Api.silentFail);
		Api.get(window.jsRoutes.controllers.Api.getSMSActions())
			.then(response => {
				setActions(response.actions);
				setTicket("action", response.actions[0].id);
			})
			.catch(Api.silentFail);
		Api.get(window.jsRoutes.controllers.Api.getSMSATACodes())
			.then(response => {
				setAtaCodes(response.ataCodes);
				setTicket("ataCode", response.ataCodes[0].id);
			})
			.catch(Api.silentFail);
		Api.get(window.jsRoutes.controllers.AircraftController.list())
			.then(result => {
				result.aircraft = result.aircraft || [];
				result.aircraft.unshift({"_id": {"$oid":null}, "tailNumber": "Not Applicable"});
				setAircraftList(result.aircraft);
			})
			.catch(Api.silentFail);
	}, []);

	const setTicket = useCallback((label, value)=> {
		dispatch(updateTicket({"key":"new", [label]: value}));
  }, [dispatch])
  
  useEffect(() => {
    setTicket('ticketType', ticketType)
  }, [setTicket, ticketType])

	const save = (modal)=> {
		Api.post(window.jsRoutes.controllers.SMS.create(),
			Object.assign({
					"ticketType": ticket.ticketType,
					"description": ticket.description,
					"reaction": ticket.reaction,
					"title": ticket.title,
					"discrepancy": ticket.discrepancy,
					"deferred": ticket.deferred,
					"certified": ticket.certified,
					"anonymous": ticket.anonymous,
					"asapEvent": ticket.asapEvent,
					"ataCode": ticket.ataCode,
				},
				ticket?.leg ? {
					"leg": ticket.leg,
					"flightTime": ticket.flightTime,
					"delayHours": ticket.delayHours,
					"phase": ticket.phase,
					"type": ticket.type,
					"action": ticket.action,
					"changeType": ticket.changeType
				} : {
					"aircraft": ticket.aircraft,
					"eventDate": ticket.eventDate?.timestamp,
				},
				ticket.ticketType === SMSTicketType.Safety.id ? {"regulatoryEvent": ticket.regulatoryEvent} : {},
				ticket.deferred ? {
					"deferredDescription": ticket.deferred.description,
					"deferredCode": ticket.deferred.code,
					"deferredProcess": ticket.deferred.process,
					"deferredOProcedure": ticket.deferred.oProcedure,
					"deferredOProcess": ticket.deferred.oProcess,
					"deferredMProcedure": ticket.deferred.mProcedure,
					"deferredMProcess": ticket.deferred.mProcess,
					"deferredByName": ticket.deferredBy.name,
					"deferredByCert": ticket.deferredBy.certificate,
					"deferredByDate": ticket.deferredBy.date,
				} : {},
			)
		)
			.then(Api.flagSuccess)
			.then(response => {
        setErrors([]);
        onSave();
				const newTicket = response.ticket;
				if (isPlainObject(newTicket.deferred)) {
					setPopover(
						<PopoverFull title={"Deferral Signature"} saveBtn={false} onClosed={()=>{ modal.close() }}>
							<Form style={{minHeight:300}}>
								<SignatureInput grid={true}
																fields={["deferred.code","deferred.expire","deferredBy.name","deferredBy.certificate"]}
																object={newTicket}
																request={window.jsRoutes.controllers.SMS.signatureRequest(newTicket._id.$oid, "deferred")}
																sign={window.jsRoutes.controllers.SMS.signatureSign(newTicket._id.$oid, "deferred")}
																onSave={()=>{
																	modal.close();
																}} />
							</Form>
						</PopoverFull>
					);
				} else {
					dispatch(setRealTicket({"key":"new"}));
				}
			})
			.catch(e => {
				setErrors(e.json.errors ?? []);
				Api.flagFail(e);
			});
	}

	return (
		<Box>
			{popover}
			<Form style={{minHeight: 400}}>
				<DropdownInput name="Type of Event" value={ticket.ticketType || SMSTicketType.Safety.id} options={SMSTicketType.list()}
								 labelNode="name" onChange={(v)=> setTicket("ticketType", v)}/>
				<TextInput name="Title" value={ticket.title} onChange={(v)=> setTicket("title", v)}/>
				<TextAreaLabel key={ticket.description} name="Description" value={ticket.description}
								 onChange={(v)=> setTicket("description", v)} error={errors.includes("obj.description")}/>

				{ticket.ticketType === SMSTicketType.Change.id ?
					<DropdownInput name="Type of Change" value={ticket.changeType} options={ChangeType.list()}
									 labelNode="name" onChange={(v)=> setTicket("changeType", v)}/>
					:
					<TextAreaLabel key={ticket.reaction} name={ticket.ticketType === SMSTicketType.Maintenance.id ? "Corrective Action" : "Reaction"} value={ticket.reaction} onChange={(v)=> setTicket("reaction", v)}/>
				}

				{ticket.ticketType === SMSTicketType.Safety.id ?
					<TextInput name="Regulatory Event" value={ticket.regulatoryEvent}
								 onChange={(v)=> setTicket("regulatoryEvent", v)}/> : undefined}

				<YesOrNo name="ASAP Event" value={ticket.asapEvent} onChange={v => setTicket("asapEvent", v)} />

				{ticket.leg ? (
					<>
						<TextInput name="Time into Flight" value={ticket.flightTime}
											 onChange={(v)=> setTicket("flightTime", v)}
											 append={<span className="input-group-addon">Tenths</span>} process={TextInput.Tenth}/>
						<TextInput name="Delay Hours" value={ticket.delayHours}
											 onChange={(v)=> setTicket("delayHours", v)}
											 append={<span className="input-group-addon" id="basic-addon2">Tenths</span>}
											 process={TextInput.Tenth}/>
						<DropdownInput name="Flight Phase" value={ticket.phase} options={phases}
													 onChange={(v)=> setTicket("phase", v)}/>
						<DropdownInput name="Type" value={ticket.type} options={types}
													 onChange={(v)=> setTicket("type", v)}/>
						<DropdownInput name="Action" value={ticket.action} options={actions}
													 onChange={(v)=> setTicket("action", v)}/>
					</>
				) : (
					<>
						{ticket.ticketType !== SMSTicketType.Safety.id ?
							<DropdownInput name="Tail Number" value={ticket.aircraft} allowNull={true}
														 options={aircraftList} valueNode="_id.$oid" labelNode={Dropdown.Formatter.Aircraft}
														 valueOnly={false} onChange={v => setTicket("aircraft", v)}
														 error={errors.includes("obj.aircraft")}/>
						: false}
						<DateInput name="Date" value={ticket.eventDate} error={errors.includes("obj.eventDate")} onChange={(v)=> setTicket("eventDate", v)} />
					</>
				)}
				<DropdownInput name="ATA Code" value={ticket.ataCode} options={ataCodes} onChange={(v)=> setTicket("ataCode", v)}/>

				{ticket.ticketType === SMSTicketType.Maintenance.id ?
					<YesOrNo name="Deferred" value={ticket.isDeferred} onChange={(v)=> setTicket("isDeferred", v)}/>
					:
					<YesOrNo name="Submit Anonymously" value={ticket.anonymous} onChange={(v)=> setTicket("anonymous", v)}/>
				}

				{ticket.isDeferred ? <>
					<TextInput name="MEL ATA Code" value={ticket.deferred?.description}
										 onChange={v => {
											 let state = {...ticket.deferred};
											 state.description = v;
											 dispatch(updateTicket({"key":"new", deferred: state}));
										 }}/>
					<YesOrNo name="(O) Procedure" value={ticket.deferred?.oProcedure}
									 onChange={v => {
										 let state = {...ticket.deferred};
										 state.oProcedure = v;
										 dispatch(updateTicket({"key":"new", deferred: state}));
									 }}/>
					{ticket.deferred?.oProcedure ?
						<TextAreaLabel key={ticket.deferred?.oProcess} name={"(O) Process"}
													 value={ticket.deferred?.oProcess}
													 onChange={v => {
														 let state = {...ticket.deferred};
														 state.oProcess = v;
														 dispatch(updateTicket({"key":"new", deferred: state}));
													 }}/>
						: undefined}
					<YesOrNo name="(M) Procedure" value={ticket.deferred?.mProcedure}
									 onChange={v => {
										 let state = {...ticket.deferred};
										 state.mProcedure = v;
										 dispatch(updateTicket({"key":"new", deferred: state}));
									 }}/>
					{ticket.deferred?.mProcedure ?
						<TextAreaLabel key={ticket.deferred?.mProcess} name={"(M) Process"}
													 value={ticket.deferred?.mProcess}
													 onChange={v => {
														 let state = {...ticket.deferred};
														 state.mProcess = v;
														 dispatch(updateTicket({"key":"new", deferred: state}));
													 }}/> : undefined}
					<TextAreaLabel key={ticket.deferred?.process} name={"Deferral Procedure"}
												 value={ticket.deferred?.process}
												 onChange={v => {
													 let state = {...ticket.deferred};
													 state.process = v;
													 dispatch(updateTicket({"key":"new", deferred: state}));
												 }}/>
					<PROLabel name={null} value={"* Follow Placarding instructions per the approved MEL."}/>
					<DropdownInput name="Code" value={ticket.deferred?.code}
												 options={[
													 {id: "A", value: "A"},
													 {id: "B", value: "B"},
													 {id: "C", value: "C"},
													 {id: "D", value: "D"}
												 ]}
												 onChange={v => {
													 let state = {...ticket.deferred};
													 state.code = v;
													 dispatch(updateTicket({"key":"new", deferred: state}));
												 }}/>
					<TextInput name="Deferred By" value={ticket.deferredBy?.name || ""}
										 onChange={v => {
											 let state = {...ticket.deferredBy};
											 state.name = v;
											 dispatch(updateTicket({"key":"new", deferredBy: state}));
										 }}/>
					<TextInput name="Certificate #" value={ticket.deferredBy?.certificate || ""}
										 onChange={v => {
											 let state = {...ticket.deferredBy};
											 state.certificate = v;
											 dispatch(updateTicket({"key":"new", deferredBy: state}));
										 }}/>
					<DateInput name="Date" value={ticket.deferredBy?.date}
										 onChange={v => {
											 let state = {...ticket.deferredBy};
											 state.date = v;
											 dispatch(updateTicket({"key":"new", deferredBy: state}));
										 }}/>
				</> : false}
      </Form>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
          <Box>
            
          </Box>
          <Box>
            <Button variant='contained' sx={styles.action} onClick={onClosed}>Close</Button>
            <Button variant='contained' sx={styles.action} onClick={save}>Save</Button>
          </Box>
        </Box>
		</Box>
	);
}

export default Create