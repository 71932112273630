/* eslint-disable react/display-name */
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {createPortal} from "react-dom";
import last from "lodash/last";
import head from "lodash/head";
import isFunction from "lodash/isFunction";
import delay from "lodash/delay";
import isBoolean from "lodash/isBoolean";
import ButtonSave from "@helpers/buttonSave/buttonSave";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "./style.module.scss";
import {Flex} from "@helpers/flex";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import {Button} from "@mui/material";

/**
 * Helper for creating a Popover.
 *
 * param additional DOM any additional content below the dialog box.
 * param large Bool user to pick a small or large size box
 *
 **/
export const BaseShelf = forwardRef(({onClosed, autoFocus: _autoFocus, additional, className, large, style, children}, ref) => {
	const [target, setTarget] = useState();
	const modal = useRef();
	const lastElement = useRef();
	const autoFocus = isBoolean(_autoFocus) ? _autoFocus : true;

	const focusOut = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.target === lastElement.current && modal.current) {
			let first = head(modal.current.querySelectorAll('input,a,select,button,textarea'));
			if (first) {
				first.focus();
			}
		}
	}

	useEffect(() => {
		if (!ref.current) return;

		const keyboardCheck = (ev) => {
			const key = ev.key;
			if (key === "Escape") {
				if (ref.current) {
					ref.current.close();
				}
			}
		}

		document.addEventListener('keyup', keyboardCheck, false);
		return () => {
			document.removeEventListener('keyup', keyboardCheck, false);
		}
	}, [ref]);

	useEffect(() => {
		if (!modal.current) return;
		lastElement.current = last(modal.current.querySelectorAll('input,a,select,button,textarea'));
		const first = modal.current.querySelector('input,textarea');
		if (autoFocus && first) {
			first.focus()
		}
	}, [autoFocus, modal]);

	useImperativeHandle(ref,
		() => ({
			dom: ()=>{
				return modal.current;
			},
			close: () => {
				onClosed?.(modal.current);
			}
		}),
		[onClosed, modal]
	)

	useEffect(()=>{
		if (!target) {
			let holder = document.getElementById("modalHolder");
			if (holder.childElementCount > 1) {
				setTarget(holder.lastElementChild.getElementsByClassName("shelfHolder")[0]);
			} else {
				setTarget(document.getElementById("shelfHolder"));
			}
		}
	}, [target])

	return target ? createPortal(<div ref={modal} className={styles.proShelf + " " + (className || "") + (large ? " pro-shelf-large" : "")} onBlur={focusOut} style={style}>
		<div className="pro-shelf-content">
			{children}
		</div>
		{additional}
	</div>, target) : null;
})

export const Shelf = forwardRef((props, ref) => {
	const btnSave = useRef();
	const modal = useRef();
	const fallback = useRef();
	ref = ref || fallback;

	const save = () => {
		document.activeElement.blur();
		if (props.save) {
			delay(props.save, 300, ref.current);
		} else {
			modal.current.close();
		}
	}

	const onClosed = () => {
		if (isFunction(props.onClosed)) {
			props.onClosed(ref.current);
		}
	}

	useImperativeHandle(ref, () => ({
		dom: ()=> {
			return modal.current?.dom?.();
		},
		close: () => {
			modal.current.close();
		},
		scrollToTop: ()=>{
			document.getElementById("shelfHolder").scrollTo(0,0);
		},
		deactivate: () => {
			if (btnSave.current) {
				btnSave.current.deactivate();
			}
		},
		enable: () => {
			if (btnSave.current) {
				btnSave.current.enable();
			}
		},
		disable: () => {
			if (btnSave.current) {
				btnSave.current.disable();
			}
		}
	}), []);

	return <BaseShelf onClosed={onClosed} ref={modal} large={props.large} autoFocus={props.autoFocus}
					  className={props.className} style={props.style}>
		{props.header === false ? null :
			<div className="pro-shelf-header">
				{props.prepend}
				<h4 className="pro-shelf-title text-center">{props.title}</h4>
				<Flex className={"stack-right"}>
					<button tabIndex={-1} type="button" className="pro-shelf-close" onClick={() => {
						modal.current.close()
					}}>
						<FontAwesomeIcon icon={faTimes} />
					</button>
					{props.append}
				</Flex>
			</div>}
		<div className="notification-container" />
		<div className="pro-shelf-body">
			{props.children}
			{props.additional ?
				<div className="pro-shelf-additional">
					{props.additional}
				</div>
				: undefined}
		</div>
		{props.footer === false ? null :
			<div className="pro-shelf-footer">
				{props.saveBtn === false ? null : <ButtonSave tabIndex={0} ref={btnSave} onClick={save} label={props.saveLabel} {...props.saveProps} />}
				<Button tabIndex={0} className="background-darkGray" onClick={()=>{ modal.current.close() }}>Close</Button>
				{props.footer}
			</div>
		}
	</BaseShelf>
})