import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./main";
import {Provider} from "react-redux";
import store from "@data//store";
// import reportWebVitals from "./reportWebVitals.js"
import {Amplify} from "@aws-amplify/core";
import {AWSIoTProvider, PubSub} from "@aws-amplify/pubsub/lib";
import awsExports from "./aws-exports";
import {jsRoutes} from "./jsRoutes";
import {Authenticator} from "@aws-amplify/ui-react";
import './variables.css';
import './index.scss';

Amplify.configure(awsExports);
Amplify.addPluggable(new AWSIoTProvider({
	aws_pubsub_region: "us-west-2",
	aws_pubsub_endpoint: "wss://a2349ln95u0wde-ats.iot.us-west-2.amazonaws.com/mqtt"
}));
Amplify.register(PubSub);

window.jsRoutes = jsRoutes();

try {
	ReactDOM.createRoot(document.getElementById('root')).render(
		<React.StrictMode>
			<Authenticator.Provider>
				<Provider store={store}>
					<Main />
				</Provider>
			</Authenticator.Provider>
		</React.StrictMode>
	);
} catch (e) {
	console.error("Major UI error.", e);
}


// reportWebVitals(console.log);