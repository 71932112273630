import {Children, useEffect, useRef, useState} from "react";
import get from "lodash/get";
import last from "lodash/last";
import head from "lodash/head";
import tail from "lodash/tail";
import extend from "lodash/extend";
import {Try} from "@helpers/utils/try";
import styles from "./style.module.scss";
import Loading from "@helpers/loading/loading";
import {Typography} from "@mui/material";

const BasicTable = ({template, headers, style, maxHeight, scroll, container, offset, children, cells, widths=["auto"], noDataLabel="No data is available.", className, isLoading}) => {
	const table = useRef();
	const uuid = "table-"+Math.floor(Math.random() * 100)+"-"+Date.now();
	const [top, setTop] = useState(0);
	let rowContent = extend({}, {"gridTemplateColumns": (template || "1fr repeat(" + ((headers || []).length - 1) + ", auto)")}, style);
	if (maxHeight) {
		rowContent.maxHeight = maxHeight;
	} else {
		if (!(scroll === false || container)) {
			const adjust = Try(() => table.current.getBoundingClientRect()) || {height:0, top:0};
			rowContent.maxHeight = `calc(100vh - ${adjust.height === 0 ? 100 : (adjust.top || 69) + 31}px - var(--spacing) - var(--spacing) - var(--spacing) - var(--spacing))`
		}
	}
	if (scroll === false || container) {
		rowContent.maxHeight = "unset";
		rowContent.height = "fit-content";
	}
	if ((headers || []).length === 1) {
		rowContent.gridTemplateColumns = "1fr"
	}
	if (rowContent.maxHeight) {
		rowContent.overflow = "auto"
	}

	useEffect(()=>{
		if (!container) return;

		const updateTop = ()=>{
			const scroll = container.scrollTop;
			if (table.current) {
				const _offset = table.current.offsetTop;
				const n = Math.max(0, scroll - (offset ?? 0) - _offset);
				setTop(n);
			}
			call = requestAnimationFrame(updateTop);
		}

		let call = requestAnimationFrame(updateTop);
		return ()=>{
			cancelAnimationFrame(call);
		}
	}, [container, offset])

	let content = children ? Children.toArray(children)?.filter?.(Boolean) : cells?.filter?.(Boolean).map?.((r, ind) =>
		<div key={"row-head-" + ind} className="row">
			<div className="cell first-cell" style={{width: widths[0]}}>
				{head(r)}
			</div>
			{tail(r).map((h, i) =>
				<div className="cell" key={"cell-" + i}
					 style={{width: get(widths, (i + 1)) || last(widths)}}>
					{h}
				</div>
			)}
		</div>
	);

	return <div ref={table} id={uuid} className={styles.tableBasic + " " + (className || "")} style={rowContent}>
		<style dangerouslySetInnerHTML={{__html: top > 0 ? `#${uuid} > .row > .header-cell { transform: translate(0, ${top}px); }` : ""}} />
		<div className="row">
			{(headers || []).map((h, i) =>
				<div className="cell header-cell" key={"header-" + i} style={{width: get(widths, (i + 1)) || last(widths)}}>
					{h}
				</div>
			)}
		</div>
		<Loading active={isLoading} />
		{content}
		{!content || content?.length === 0 ? <Typography variant={"overline"} className={"text-center full-width"}>{noDataLabel}</Typography> : undefined}
	</div>
};
export default BasicTable;