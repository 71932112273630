import React, {Children, forwardRef} from 'react';
import styles from "./style.module.scss";

const Columns = ({className, responsive, gridGap, template, children, style, metric="1fr", ...props}, ref) => {
	let baseStyle = {};
	if (responsive) {
		className += " liquid";
	}
	let count = 0;
	Children.forEach(children, i => {
		if (i) {
			if (i.type && i.type.toString() === "Symbol(react.fragment)") {
				count += React.Children.count(i.props.children);
			} else {
				count += 1;
			}
		}
	})
	baseStyle.gridGap = (gridGap || gridGap === 0) ? gridGap : "var(--spacing)";
	baseStyle.gridTemplateColumns = (template) ? template : `repeat(${count}, ${metric})`;
	return <div {...props} ref={ref} className={[styles.columns, (className || "")].join(" ")} style={Object.assign({}, baseStyle, style)}>
		{children}
	</div>
};
export default forwardRef(Columns);