import Label from "../label/label";
import {SwitchField} from "@aws-amplify/ui-react";
import styles from "../label/style.module.scss";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

export const PRORadio = props => {
	const options = props.options || [];
	return <ToggleButtonGroup color={"primary"} className={props.className} style={props.style} exclusive value={props.selected} size={"small"}>
		{options.map((item) =>
			<ToggleButton key={item[props.valueNode ?? "value"]} value={item[props.valueNode ?? "value"]} onClick={() => {
				let value = item[props.valueNode ?? "value"];
				if (value !== props.selected) {
					props.onChange(value);
				}
			}}>
				{item[props.labelNode ?? "label"]}
			</ToggleButton>
		)}
	</ToggleButtonGroup>
}
PRORadio.yesNo = [{"label": "Yes", "value": true}, {"label": "No", "value": false}];

export const RadioInput = function (props) {
	let input = <PRORadio options={props.options} selected={props.value} onChange={props.onChange} style={{...{width: "100%"}, ...props.style}} rounded={props.rounded} small={props.small} labelNode={props.labelNode} valueNode={props.valueNode} />;
	return <Label {...{...props, ...{"style": {...props.style, ...props.labelStyle}}}} value={(props.append) ? <div className={styles.inputGroup}>{input}{props.append}</div> : input} />;
};

export const YesOrNo = function (props) {
	return <RadioInput {...props} options={PRORadio.yesNo}/>
};

export const SimpleYesOrNo = function ({name, className, value, onChange, style}) {
	return <label className={"pro-btn btn-default " + className} style={{...style, ...{gridTemplateColumns:"1fr auto",display:"grid",gridGap:"var(--spacing)"}}}>
		{name}
		<SwitchField size={"small"} className={"centered"} label={name} isLabelHidden={true} style={{lineHeight:0, color:"inherit"}} isChecked={value} onChange={()=> onChange((!value) || false)} />
	</label>
};