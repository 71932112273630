import "../app-layout.scss";
import '../stylesheets/pro/pro.scss';

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import AppBar from './appBar';
import Memo from "./memo";

export const MoreOptionsContext = React.createContext({});

const MainLayout = () => {
  const location = useLocation();
  const [, setAtTop] = useState(true);
  const [atBottom, setAtBottom] = useState(true);
  // const menu = useSelector(selectMenu, (a, b) => (JSON.stringify(a.title) === JSON.stringify(b.title)) && (JSON.stringify(a.subtitle) === JSON.stringify(b.subtitle)) && (isEqual(a.breadcrumb, b.breadcrumb)));

  useEffect(() => {
    function onScroll() {
      const dom = document.getElementById("mainHolder");
      if (dom) {
        const topTest = dom.scrollTop;
        const bottomTest = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        setAtTop(topTest === 0);
        setAtBottom(bottomTest <= 0);
      }
      call = requestAnimationFrame(onScroll);
    }
    let call = requestAnimationFrame(onScroll);
    return () => {
      cancelAnimationFrame(call);
    }
  }, []);

  return <div id="page" className={location.pathname.startsWith("/popout/") ? "no-header" : ""}>
    <div id="shelfHolder" />
    <div id="priorityHolder" />
    <div id="utilsHolder" />
    <div id="notification-container" />

    {/* <Stripe title={menu.title} subtitle={menu.subtitle} breadcrumb={menu.breadcrumb} /> */}
    <AppBar />

    {/* <MainNav /> */}

    <div id="headerHolder" />

    <Memo />

    <div id="mainHolder">
      <Outlet />
    </div>

    <div id="popoverHolder" />

    <div id="footerHolder" />
  </div>
}
export default MainLayout;