import {Part} from "./part";

export const Purpose = {
	NA: {id: -1, name: "Unknown Purpose", background: "background-unknown", text: "text-unknown"},
	Positioning: {id: 4, name: "Positioning", background: "background-positioning", text: "text-positioning"},
	Maintenance: {id: 5, name: "Maintenance", background: "background-maintenance", text: "text-maintenance"},
	Training: {id: 6, name: "Training", background: "background-training", text: "text-training"},
	Charter: {id: 7, name: "Charter", background: "background-charter", text: "text-charter"},
	Owner: {id: 8, name: "Owner", background: "background-owner", text: "text-owner"},
	Company: {id: 9, name: "Company", background: "background-owner", text: "text-owner"},
	Medical: {id: 10, name: "Medical", background: "background-medical", text: "text-medical"},
	OwnerLease: {id: 11, name: "Owner Lease", background: "background-owner", text: "text-owner"},
	quote: function (options) {
		let out = [this.Charter, this.Owner, this.OwnerLease, this.Company];
		if (options?.medical) {
			out.push(this.Medical);
		}
		return out;
	},
	dispatch: function (options, part) {
		switch (part) {
			case Part.Part91:
				return [this.Positioning, this.Owner, this.OwnerLease, this.Company, this.Training, this.Maintenance];
			case Part.Part125:
				return [this.Positioning, this.Owner, this.OwnerLease, this.Company, this.Training, this.Maintenance];
			default: {
				let out = [this.Charter, this.Positioning, this.Owner, this.OwnerLease, this.Company, this.Training, this.Maintenance];
				if (options?.medical) {
					out.push(this.Medical);
				}
				return out;
			}
		}
	},
	parse: function (id) {
		try {
			if (id instanceof Purpose) return id;
		} catch {}
		switch (id) {
			case 4:
				return this.Positioning;
			case 5:
				return this.Maintenance;
			case 6:
				return this.Training;
			case 7:
				return this.Charter;
			case 8:
				return this.Owner;
			case 9:
				return this.Company;
			case 10:
				return this.Medical;
			case 11:
				return this.OwnerLease;
			default:
				return this.NA;
		}
	},
	get: function(leg) {
		const pax = Math.max((leg.passengers || []).length, (Number.isInteger(leg.passengerCount) ? leg.pass : null) || 0);
		let purpose = this.parse(leg.dispatch.purpose);
		const isPositioning = leg.isPositioning;
		if (pax <= 0 && ![this.Training, this.Maintenance].includes(purpose)) {
			purpose = this.Positioning;
		}
		if (isPositioning && isPositioning === true) {
			purpose = this.Positioning;
		}
		return purpose;
	}
};