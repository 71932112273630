import AirportInput from "@helpers/airportInput";
import Api from "@helpers/api";
import { DateTimeInput } from "@helpers/dateInput";
import { Dropdown, DropdownInput } from "@helpers/dropdown";
import Form from "@helpers/form/form";
import PROLabel from "@helpers/label/label";
import PRODate from "@helpers/proDate";
import { YesOrNo } from "@helpers/radioInput/radioInput";
import { TextAreaLabel } from "@helpers/textArea";
import { TextInput } from "@helpers/textInput";
import { AttachmentsView } from "@helpers/upload";
import Promises from "@helpers/utils/promises";
import { usePopover } from "@hooks/usePopover";
import { Confirm } from "@layout/confirmModal";
import { Box, Button } from "@mui/material";
import { styles } from "../../pages/modal/common";
import { useEffect, useState } from "react";
import { SelectedEvents } from "../safetyMeetings/selectedEvents";

export const WorkOrderEdit = ({id, aircraft:_aircraft, start:_start, onDelete, onSave, onClosed})=> {
	const [popover, setPopover, clearPopover] = usePopover();
	const [aircraftList, setAircraftList] = useState([]);
	const [maintenance, setMaintenance] = useState([]);
	const [providers, setProviders] = useState([]);
	const [smsEvents, setSmsEvents] = useState([]);
	const [name, setName] = useState(null);
	const [assigned, setAssigned] = useState(null);
	const [aircraft, setAircraft] = useState(_aircraft ?? null);
	const [start, setStart] = useState(_start ?? PRODate.now());
	const [proposedEnd, setProposedEnd] = useState((_start ?? PRODate.now()).plus(24*60));
	const [end, setEnd] = useState(null);
	const [billableHours, setBillableHours] = useState(null);
	const [findings, setFindings] = useState(null);
	const [completed, setCompleted] = useState(false);
	const [airport, setAirport] = useState(null);
	const [airportName, setAirportName] = useState(null);
	const [files, setFiles] = useState([]);
	const [errors, setErrors] = useState([]);

	const save = ()=>{
		const payload = {
			_id: id,
			maintenance: maintenance,
			name: name,
			start: start,
			proposedEnd: proposedEnd,
			end: end,
			aircraft: aircraft,
			assigned: assigned,
			findings: findings,
			billableHours: billableHours,
			airport: airport,
			smsEvents: smsEvents?.map?.(i => i._id),
			completed: completed,
			files: files,
		};
		Api.post(window.jsRoutes.controllers.WorkOrders.save(), payload)
			.then(Api.flagSuccess)
			.then((result) => {
				result.workOrder.start = new PRODate(result.workOrder.start);
				result.workOrder.proposedEnd = new PRODate(result.workOrder.proposedEnd);
				if (PRODate.isDate(result.workOrder?.end)) {
					result.workOrder.end = new PRODate(result.workOrder.end);
				}
				onSave?.(result.workOrder);
			})
			.catch(ev => {
				console.error("WorkOrderEdit save error", ev);
				setErrors(ev?.json?.errors || []);
				Api.flagFail(ev);
			})
	}

	const del = ()=>{
		setPopover(<Confirm title={"Delete Work Order"} message={"Are you sure you want to delete this Work Order?"} onClosed={clearPopover} onConfirm={() =>{
			Api.delete(window.jsRoutes.controllers.WorkOrders.delete(id.$oid))
				.then(Api.flagSuccess)
				.then(() => {
					onDelete?.();
				})
				.catch(Api.flagFail)
			}}/>);
	}

	useEffect(() => {
		Api.get(window.jsRoutes.controllers.MaintenanceController.listAllProviders())
			.then(result=> {
				setProviders(result.providers ?? []);
				setAssigned(p => {
					if (!p?._id?.$oid) {
						return result.providers?.[0] ?? {};
					} else {
						return p;
					}
				})
			})
			.catch(Api.silentFail)
	}, []);

	useEffect(()=>{
		Api.get(window.jsRoutes.controllers.AircraftController.list())
			.then(result=> {
				setAircraftList(result.aircraft ?? []);
				setAircraft(p=> {
					if (!p?._id) {
						return result.aircraft?.[0] ?? {};
					} else {
						return p;
					}
				})
			})
			.catch(Api.silentFail)
	}, [])

	useEffect(() => {
		if (id?.$oid) {
			Api.get(window.jsRoutes.controllers.WorkOrders.get(id.$oid))
				.then(result => {
					let tOps = new Promises();
					tOps.push(new PRODate(result.workOrder.start).withZoneId(result.workOrder.airport).then(d => {
						result.workOrder.start = d;
					}));
					tOps.push(new PRODate(result.workOrder.proposedEnd).withZoneId(result.workOrder.airport).then(d => {
						result.workOrder.proposedEnd = d;
					}));
					if (PRODate.isDate(result.workOrder.end)) {
						tOps.push(new PRODate(result.workOrder.end).withZoneId(result.workOrder.airport).then(d => {
							result.workOrder.end = d;
						}));
					}
					return Promise.all(tOps)
						.then(()=>{
							setName(result.workOrder.name);
							setStart(result.workOrder.start);
							setProposedEnd(result.workOrder.proposedEnd);
							setEnd(result.workOrder.end);
							setAircraft(result.workOrder.aircraft);
							setAssigned(result.workOrder.assigned);
							setFindings(result.workOrder.findings);
							setBillableHours(result.workOrder.billableHours);
							setAirport(result.workOrder.airport);
							setSmsEvents(result.workOrder.smsEvents);
							setCompleted(result.workOrder.completed);
							setMaintenance(result.workOrder.maintenance);
							setFiles(result.workOrder.files);
						})
				})
				.catch(Api.silentFail);
		}
	}, [id?.$oid]);

	return <Box>
		{popover}
		<Form>
			<TextInput name="Name" value={name} onChange={setName} error={(errors || []).includes("obj.name")} />
			<DropdownInput name="Aircraft" value={aircraft} options={aircraftList}
						   valueNode="_id.$oid" labelNode={Dropdown.Formatter.Aircraft} valueOnly={false}
						   onChange={setAircraft}/>
			<PROLabel name="Airport" value={<AirportInput key={airport} icao={airport} sideLabel={airportName}
														  onChange={value => {
															  start.withZoneId(value.icao ?? value.iata ?? value.faa).then(setStart);
															  proposedEnd.withZoneId(value.icao ?? value.iata ?? value.faa).then(setProposedEnd);
															  if (end) {
																  end.withZoneId(value.icao ?? value.iata ?? value.faa).then(setEnd);
															  }
															  setAirport(value.icao ?? value.iata ?? value.faa);
															  setAirportName(value.name);
														  }} />}
					  error={(errors || []).includes("obj.aircraft")} />
			<DateTimeInput name="Start" value={start} onChange={setStart} error={(errors || []).includes("obj.start")}/>
			<DateTimeInput name="Proposed End" value={proposedEnd} onChange={setProposedEnd}
						   error={(errors || []).includes("obj.proposedEnd")}/>
			<DateTimeInput name="End" value={end} onChange={v => {
				if (!(end instanceof PRODate)) {
					v.withZoneId(airport).then(setEnd);
				} else {
					setEnd(v);
				}
			}}/>
			<DropdownInput name="Assigned To" value={assigned} options={providers}
						   valueNode="_id.$oid" labelNode={function () {
				return `${this.name} - ${this.code}`
			}} valueOnly={false} onChange={setAssigned} error={(errors || []).includes("obj.assigned")}/>
			<TextInput name="Billable Hours" value={billableHours} onChange={setBillableHours} process={TextInput.Tenth}/>
			<TextAreaLabel key={findings} name="Description" value={findings} onChange={setFindings} />

			<SelectedEvents onChange={setSmsEvents} selected={smsEvents ?? []} />

			{/* TODO: Add a list of Aircraft Task Lists like SMS Events does */}

			<YesOrNo name="Completed" value={completed} onChange={setCompleted} error={(errors || []).includes("obj.completed")}/>
			{id?.$oid ?
				<AttachmentsView className="full-width" items={files} onSave={(modal, item) => {
					Api.post(window.jsRoutes.controllers.WorkOrders.addFile(id.$oid), item)
						.then(Api.flagSuccess)
						.then((result) => {
							setFiles(p => [...p, result.file]);
							onSave?.();
						})
						.catch(Api.flagFail)
				}} onDelete={(item) => {
					setPopover(<Confirm title={"Delete File"} message={"Are you sure you want to delete this file?"} onClosed={clearPopover}
										onConfirm={() =>{
											Api.delete(window.jsRoutes.controllers.WorkOrders.removeFile(id.$oid, item.id), {})
												.then(Api.flagSuccess)
												.then(() => {
													setFiles(p => p.filter(f => f.id !== item.id));
													onSave?.();
												})
												.catch(Api.flagFail)
										}} />);
				}} newModal/> : undefined}
    </Form>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
      <Box>
        {id?.$oid && <Button variant='contained' sx={[styles.action, styles.error]} onClick={del}>Delete</Button>}
      </Box>
      <Box>
        <Button variant='contained' sx={styles.action} onClick={onClosed}>Close</Button>
        <Button variant='contained' sx={styles.action} onClick={save}>Save</Button>
      </Box>
    </Box>
	</Box>
}
export default WorkOrderEdit;