import {createSelector, createSlice} from "@reduxjs/toolkit";

const initialState = [];

export const ticketSlice = createSlice({
	name: "ticket",
	initialState,
	reducers: {
		setTicket: (state, action) => ({...state, [action.payload.key]: action.payload}),
		updateTicket: (state, action) => ({...state, [action.payload.key]: {...state[action.payload.key], ...action.payload}}),
	}
})

export const {setTicket, updateTicket} = ticketSlice.actions;

export const selectTicket = (key) => createSelector(
	[state => state.ticket[key]],
	ticket => ticket || {}
);

export default ticketSlice.reducer;