/* eslint-disable react/display-name */
import {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {createPortal} from "react-dom";
import min from "lodash/min";
import max from "lodash/max";
import isFunction from "lodash/isFunction";
import Columns from "../columns";
import {TextField} from "@aws-amplify/ui-react";
import {Times} from "../../icons/circle/times";
import styles from "./style.module.scss";
import Spinner from "../spinner";

const Autocomplete = forwardRef(({id, style, inputStyle, selected, className, onInputChange, onChange, onBlur, disabled, sideLabel, options, node, placeholder, append, canClear, isLoading}, ref) => {
	const dom = useRef();
	const timeout = useRef();
	const dropdown = useRef();
	const listDom = useRef();
	const [index, setIndex] = useState(0);
	const [iter, setIter] = useState(0);
	const [showDropdown, setShowDropdown] = useState(false);
	const [value, setValue] = useState(selected);

	useMemo(()=>{
		setValue(selected);
		return selected
	}, [selected])

	const _onInputChange = (ev)=>{
		if (isFunction(onInputChange)) {
			onInputChange(ev);
		} else {
			console.warn("No onInputChange handler.")
		}
		setValue(ev)
	};

	const _onChange = (ev)=>{
		if (isFunction(onChange)) {
			onChange(ev);
		} else {
			console.warn("No onChange handler.")
		}
	};

	const changed = (item, showDropdown) => {
		_onChange(item);
		setShowDropdown(showDropdown);
	}

	const onArrows = (ev) => {
		if (ev.keyCode !== 40 && ev.keyCode !== 38 && ev.keyCode !== 13) {
			return
		}
		if (ev.keyCode === 40) {
			setIndex(min([index + 1, (options || []).length - 1]));
		} else if (ev.keyCode === 38) {
			setIndex(max([index - 1, 0]));
		} else if (ev.keyCode === 13) {
			if ((options || [])[index]) {
				changed((options || [])[index], true);
			}
		}
	}

	useImperativeHandle(ref, () => ({
		focus: () => {
			dom.current.focus();
		},
		hasFocus: ()=>{
			return dom.current.hasFocus();
		}
	}))

	const rect = dom.current ? dom.current.getBoundingClientRect() : null;

	return <div className={className} style={Object.assign({position: "relative"}, style)}>
    <TextField key={iter}
      variation="quiet"
      ref={dom}
      label={id}
      id={id}
      className={styles.textField}
      placeholder={placeholder}
      style={inputStyle}
      value={value}
      isDisabled={disabled}
      autoComplete={"off"}
      labelHidden={true}
      onChange={v => _onInputChange(v.target.value) }
      onKeyDown={onArrows}
      onFocus={()=>{
        clearTimeout(timeout.current);
        setShowDropdown(true);
      }}
      onBlur={(v)=>{
        timeout.current = setTimeout(()=>{
          setShowDropdown(false);
        }, 500);
        if (onBlur) onBlur(v.target.value);
      }}
      innerEndComponent={canClear || sideLabel || isLoading ? <Columns className={"center"} style={{height:"100%", marginRight:"var(--spacing)"}}>
        {isLoading ? <Spinner /> : false}
      {canClear ? <button className={"btn-danger"} style={{padding:"0 calc(var(--spacing) / 2)"}} onClick={()=>{
        changed(null, false);
        setIter(p=> p+1);
      }}>
        <Times style={{color:"var(--lightGray)"}} />
      </button> : null}
      {sideLabel ?
        <div className={"text-small"} style={{display:"grid",alignItems:"center",justifyItems:"center", height:"100%", marginRight:"var(--spacing)"}}>{sideLabel}</div>
      : null}
      </Columns> : null}
      outerEndComponent={append}
		/>

		<div ref={listDom} />

		{showDropdown && listDom.current ? <AutocompleteList
			portal={listDom.current}
			ref={dropdown}
			options={options || []}
			changed={changed}
			node={node || "label"}
			index={index}
			style={{width: rect.width}}
		/> : undefined}
	</div>
})
export default Autocomplete;

const AutocompleteList = forwardRef(({portal, style, options, node, index, changed}, ref) => {
	const [top, setTop] = useState(0);

	useEffect(()=>{
		let domParent = portal;
		while(domParent && domParent.scrollTop === 0) {
			domParent = domParent.parentNode;
		}
		setTop(domParent.scrollTop);

		const step = ()=>{
			setTop(domParent.scrollTop);
			let _domParent = portal;
			while(_domParent && _domParent.scrollTop === 0) {
				_domParent = _domParent.parentNode;
			}
			domParent = _domParent;
			ani = window.requestAnimationFrame(step);
		}

		let ani = window.requestAnimationFrame(step);

		return ()=>{
			window.cancelAnimationFrame(ani);
		}
	}, [portal])

	return createPortal(
		<div ref={ref} className={styles["autocomplete-list"]} style={Object.assign({width: 200, transform:`translate(0, -${top}px)`}, style)}>
			<Columns className="autocomplete-list-items" template={"1fr"}>
        {(options || []).map((item, ind) => {
					const label = isFunction(node) ? node.call(item) : item[node];
					return (
						<button key={ind+"-"+label} className={"pro-btn text-left" + ((ind === index && ind > 0) ? " active" : "")}
								onClick={() => {
									changed(item, false)
								}}>{label}</button>)
				})}
			</Columns>
		</div>
		,
		portal
	)
})