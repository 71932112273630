import {useState, Children, cloneElement, Fragment} from "react";
import {Button, Menu, MenuList} from "@mui/material";
import uniqueId from "lodash/uniqueId";

export const MoreOptions = ({children, ignoreClicks=false, label, containerClass, containerStyle, buttonStyle, buttonParams}) => {
	const [dom, setDom] = useState(null);
	const open = Boolean(dom);

	const handleClick = (ev)=>{
		setDom(ev.currentTarget);
	}

	const handleClose = ()=>{
		setDom(null);
	}

	const _interceptInteraction = (child) => {
		if (!ignoreClicks && child?.props?.onClick) {
			return cloneElement(child, {
				onClick: (ev)=>{
					handleClose();
					setTimeout(()=> child?.props?.onClick?.(ev), 0);
				}
			});
		}
		if (!ignoreClicks && child?.props?.onChange) {
			return cloneElement(child, {
				onChange: (ev)=>{
					handleClose();
					setTimeout(()=> child?.props?.onChange?.(ev), 0);
				}
			});
		}
		return child;
	}

	const _recursiveLook = (child)=> {
		let out = _interceptInteraction(child);
		out?.props?.children?.forEach?.(c => {
			_recursiveLook(c);
		})
		return out;
	}

	const _children = Children.toArray(children).map(child => {
		if (child?.type?.render?.name.includes("MenuItem")) {
			return [_recursiveLook(child)];
		} else if (Fragment === child.type) {
			return child?.props?.children?.map?.(c => _recursiveLook(c))
		} else if (child?.type?.["$$typeof"]?.toString?.().includes?.("forward_ref")) {
			return [child];
		} else {
			return [child];
		}
	});

	const uuid = uniqueId("more-btn-");
	const uuid2 = uniqueId("more-menu-");

	return <div className={containerClass} style={containerStyle}>
		<Button {...buttonParams} id={uuid}
				style={buttonStyle}
				aria-controls={open ? uuid2 : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}>
			{label}
		</Button>
		<Menu
			id={uuid2}
			anchorEl={dom}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': uuid,
			}}
		>
			<MenuList dense>
				{_children}
			</MenuList>
		</Menu>
	</div>
}
export default MoreOptions;