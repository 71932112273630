import { selectTicket } from "@data/ticketReducer.jsx";
import Api from "@helpers/api";
import { DateInput } from "@helpers/dateInput";
import Form from "@helpers/form/form";
import { TextAreaLabel } from "@helpers/textArea";
import { History } from "@icons/history.jsx";
import { Box, Button, IconButton } from "@mui/material";
import includes from "lodash/includes";
import ChangeLogModal from "../../../pages/modal/change-log";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styles } from "../../../pages/modal/common";

export const FollowUp = ({id, onSave, onClose}) => {
  const item = useSelector(selectTicket(id));
	const [errors, setErrors] = useState([]);

	const [resolution, setResolution] = useState(item.resolution);
  const [resolutionDate, setResolutionDate] = useState(item.resolutionDate);
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false)

  useEffect(() => {
    setResolution(item.resolution)
    setResolutionDate(item.resolutionDate)
  }, [item])

	const save = (btn)=> {
		let out = {
			resolution: resolution,
			resolutionDate: resolutionDate
		};
		Api.post(window.jsRoutes.controllers.SMS.saveResolution(item._id.$oid), out)
			.then(Api.flagSuccess)
			.then(() => {
				setErrors([]);
				onSave();
			})
			.catch(e => {
				Api.flagFail(e);
				setErrors(e.json.errors || []);
			})
			.then(() => {
				btn.deactivate();
			});
	}

	return <Form bottom={<>
		{item?.logs?.followedUp?.user?._id ? <div className="text-center">Last edited
			by {item?.logs?.followedUp?.user?.firstName} {item?.logs?.followedUp?.user?.lastName} on {item?.logs?.followedUp?.timestamp?.format?.("MMM dd, yyyy")}
			<IconButton onClick={() => setOpenChangeLogModal(true)}><History/></IconButton>
		</div> : undefined}
    <Box sx={{ width: '100%', textAlign: 'end' }}>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={onClose}>Cancel</Button>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={save}>Save</Button>
    </Box>
	</>}>
    <ChangeLogModal open={openChangeLogModal} id={item._id.$oid} node="logs.followedUp" onClosed={() => setOpenChangeLogModal(false)}/>
		<TextAreaLabel key={resolution} name="Follow-Up"
					   value={resolution || ""}
					   onChange={v => setResolution(v)}
					   error={includes(errors, "obj.resolution")}/>
		<DateInput name="Follow-Up Date" value={resolutionDate}
				   onChange={v => setResolutionDate(v)}
				   error={includes(errors, "obj.resolutionDate")}/>
	</Form>
}