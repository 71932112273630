/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "cea11e57cb2a4073863f92e386759e45",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "cea11e57cb2a4073863f92e386759e45",
            "region": "us-west-2"
        }
    },
    "Notifications": {
        "InAppMessaging": {
            "AWSPinpoint": {
                "appId": "cea11e57cb2a4073863f92e386759e45",
                "region": "us-west-2"
            }
        }
    },
    "aws_cognito_identity_pool_id": "us-west-2:d50be070-53f9-4cb7-96c5-93d6fe0c25f5",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_TagaQDKHW",
    "aws_user_pools_web_client_id": "58otlio1noqd2rmnq13sg7v5r4",
    "oauth": {
        "domain": "lf.auth.us-west-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://beta-ops.levelflight.com/oauth,https://dev-ops-alt.levelflight.com/oauth,https://dev.levelflight.com:8443/oauth,https://dev.levelflight.com:9443/oauth,https://ops.levelflight.com/oauth,https://pilot.levelflight.com/oauth,https://prototype.levelflight.com/oauth,https://quickquote.levelflight.com/oauth,https://staging-ops.levelflight.com/oauth",
        "redirectSignOut": "https://beta-ops.levelflight.com/deauth,https://dev-ops-alt.levelflight.com/deauth,https://dev.levelflight.com:8443/deauth,https://dev.levelflight.com:9443/deauth,https://ops.levelflight.com/deauth,https://pilot.levelflight.com/deauth,https://prototype.levelflight.com/deauth,https://quickquote.levelflight.com/deauth,https://staging-ops.levelflight.com/deauth",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "us-west-2",
            "maps": {
                "items": {
                    "levelflightDark-production": {
                        "style": "VectorEsriDarkGrayCanvas"
                    },
                    "levelflightLight-production": {
                        "style": "VectorEsriLightGrayCanvas"
                    }
                },
                "default": "levelflightLight-production"
            }
        }
    }
};


export default awsmobile;
