import {Fragment, useEffect, useState} from "react";
import get from "lodash/get";
import includes from "lodash/includes";
import isFunction from "lodash/isFunction";
import {Section} from "../section";
import Columns from "../columns";
import difference from "lodash/difference";
import styles from "./style.module.scss";
import cx from 'classnames'

export const Checkbox = function ({ name, onChange, className, value, unvalue, checked, pill, disabled }) {
  const [defaultDisabled, setDefaultDisabled] = useState(disabled)

  useEffect(() => {
    if (!disabled) {
      setDefaultDisabled(false);
    }
  }, [disabled])

	return pill ?
		<div className={styles.checkbox + " pill " + (className)}>
			<label className={cx({["btn btn-primary" + (checked ? " active" : "")]: true, [styles.disabled]: defaultDisabled})}>
				<input disabled={defaultDisabled} type="checkbox" value={value} checked={checked} onChange={(node) => {
					onChange(node.target.checked ? value : unvalue)
				}}/> {name}
			</label>
		</div>
		:
		<div className={styles.checkbox + " " + (className)}>
			<label className={cx({[styles.disabled]: defaultDisabled})}>
				<input disabled={defaultDisabled} type="checkbox" value={value} checked={checked} onChange={(node) => {
					onChange(node.target.checked ? value : unvalue)
				}}/> {name}
			</label>
		</div>;
};

export const Checkboxes = function ({name, onChange, options, values, valueNode, labelNode, pill, error, style}) {
	const optVals = options.map(option => isFunction(valueNode) ? valueNode.bind(option)() : get(option, valueNode));
	const leftovers = difference(values, optVals).map(v => Object.assign({}, {[labelNode]: "Unknown", [valueNode]: v}));
	const _options = [...options, ...leftovers];

	return (pill ?
			<Fragment>
				{name ? <Section name={name} className={"full text-center"} error={error}/> : undefined}
				<Columns style={{gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", ...style}} className={"full"}>
					{_options.map(function (option) {
						let label = isFunction(labelNode) ? labelNode.bind(option)() : get(option, labelNode);
						let value = isFunction(valueNode) ? valueNode.bind(option)() : get(option, valueNode);
						let active = includes(values, value);
						return <Checkbox key={value} pill={true} name={label} value={value} unvalue={value}
									  checked={active} onChange={onChange}/>
					})}
				</Columns>
			</Fragment>
			:
			<Columns template={"1fr"}>
				{name ? <h4>{name}</h4> : undefined}
				{_options.map(function (option) {
					let label = isFunction(labelNode) ? labelNode.bind(option)() : get(option, labelNode);
					let value = isFunction(valueNode) ? valueNode.bind(option)() : get(option, valueNode);
					let active = includes(values, value);
					return <Checkbox key={value} name={label} value={value} unvalue={value} checked={active}
									 onChange={onChange} style={{marginRight: 15}}/>
				})}
			</Columns>
	);
};
Checkboxes.defaultProps = {
	labelNode: "value",
	valueNode: "id",
};