import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import PRODate from './proDate';
import { minutesToHHMM } from "./utils/formatters";

const ZuluClock = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date())
    }, 200);
    return () => {
      clearInterval(interval);
    }
  }, []);

  const mins = date.getUTCHours() * 60 + date.getUTCMinutes();
  const proDate = new PRODate(date)

  return <Typography sx={{ fontSize: 'calc(var(--font-md) - 1px)', width: 'max-content' }}>{proDate.monthLabel} { proDate.day }&nbsp;{minutesToHHMM(mins)}&nbsp;Z</Typography>
}
export default ZuluClock;