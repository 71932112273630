import isString from "lodash/isString";
import has from "lodash/has";

export const passengerCount = function (props) {
	return (props.isPositioning ? 0 : (props.passengers || []).length > 0 ? props.passengers.length : Number.isInteger(props.passengerCount) ? props.passengerCount : null) || 0
};

export const minutesToHHMM = function (input) {
	let sign = (input < 0) ? "-" : "";
	input = Math.abs(input);
	let hours = Math.floor(input / 60);
	let mins = Math.round(input % 60);
	if (mins === 60) {
		mins = 0;
		hours += 1;
	}
	if (mins < 10) {
		mins = "0" + mins;
	}
	return sign + hours + ":" + mins;
};

export const tenthToHHMM = function (input) {
	let sign = (input < 0) ? "-" : "";
	input = Math.abs(input * 60);
	let hours = Math.floor(input / 60);
	let mins = Math.round(input % 60);
	if (mins === 60) {
		mins = 0;
		hours += 1;
	}
	if (mins < 10) {
		mins = "0" + mins;
	}
	return sign + hours + ":" + mins;
};

export const minutesToTenths = function (input) {
	let hours = parseFloat(input) / 60.0;
	return toTenths(hours);
};

export const toTenths = function (input) {
	return Math.floor(parseFloat(input) * 10 + 0.015) / 10;
};

export const getDate = function (objectId) {
	if (!objectId) return new Date();
	if (has(objectId, "$oid")) {
		objectId = objectId.$oid;
	}
	return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

export const humanize = function (string) {
	if (!isString(string)) return undefined;
	return string.replace(/([A-Z])/g, ' $1').replace(/^./, function (c) {
		return c.toUpperCase()
	});
};

export const replaceNaN = function(test, sub) {
	if (isNaN(test)) {
		return sub;
	}
	return test;
}