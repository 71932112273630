import { Alert, Snackbar } from "@mui/material";

interface SnackBarProps {
  open: boolean
  handleClose: Function
  status:  'success' | 'warning' | 'error' | 'info'
  message: string
}

const SnackBar: React.FC<SnackBarProps> = (props) => {
  const { open, handleClose, status, message } = props;
  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={() => handleClose()} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert onClose={() => handleClose()} severity={status || 'success'} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackBar
