import {PubSub} from "@aws-amplify/pubsub/lib";

export default class IoTSocket {
	static subscribe(topic, onData, onError, onClose) {
		return new IoTSocket(topic, onData, onError, onClose);
	}

	constructor(topic, onData, onError, onClose) {
		this.topic = topic;
		this.sub = PubSub.subscribe(topic).subscribe({
			next: onData || (data => console.info("Message Received", data.value)),
			error: onError || (err => {
				console.error("Error::"+topic, err)
			}),
			complete: onClose || (() => console.info("Closed::"+topic)),
		});
	}

	unsubscribe() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}
}