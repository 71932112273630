import notify from "./notify";
import has from "lodash/has";
import clone from "lodash/clone";
import extend from "lodash/extend";
import get from "lodash/get";
import {Auth} from "@aws-amplify/auth";
import {Try} from "./utils/try";

const Api = {
	silentFail: function () { },
	flagFail: function (response) {
		let message = get(response, "json.message");
		if (message) {
			notify.danger("Error", response.json.message);
		}
	},
	flagSuccess: function (json) {
		notify.success("Success", json.message);
		return json;
	},
	changeUrl: function (url) {
		if (typeof url === "string") {
			url = {
				url: clone(url)
			};
		}
		if (url.url.includes("/login")) {
			Api.reload();
			return
		}
		if (url.url.includes("/dashboard")) {
			window.location.href = "/dashboard";
			return
		}
		Auth.currentSession()
			.catch(()=>{})
			.then(()=>{
				window.location.href = url.url;
			})
	},
	reload: function () {
		window.location.reload();
	},
	load: function(url, payload, signal, modal){
		return this._call(null, url, payload, signal, modal);
	},
	get: function (url, signal, modal) {
		return this._call("GET", url, null, signal, modal);
	},
	post: function (url, payload, signal, modal) {
		return this._call("POST", url, payload, signal, modal)
	},
	put: function (url, signal, modal) {
		return this._call("PUT", url, null, signal, modal);
	},
	stream: function (url, payload, signal, modal) {
		return this._streamed("POST", url, payload, signal, modal)
	},
	delete: function (url, payload, signal, modal) {
		return this._call("DELETE", url, payload, signal, modal)
	},
	_basicCall: function(method, url, payload, signal) {
		if (typeof url === "string") {
			const temp = clone(url);
			url = {
				absoluteURL: function () {
					return temp
				}
			};
		}
		const options = extend(url, {
			method: method ?? url.method ?? "GET",
			headers: {
				"Accept": "application/json",
				...(payload ? {"Content-Type": "application/json"} : {})
			},
		}, payload ? {
			body: JSON.stringify(payload)
		} : null, signal);

		return Auth.currentSession()
			.catch(()=>{ })
			.then(async (auth) => {
				const path = options.absoluteURL(true);
				const request = (path instanceof Request) ? path : new Request(path);
				if (request.url.includes("levelflight.com/")) {
					options.headers["Authorization"] = "Bearer " + auth?.getIdToken?.()?.getJwtToken?.();
				}
				if (path.includes("/logout")) {
					options.headers["X-Refresh-Token"] = "Bearer " + auth?.getRefreshToken?.()?.getToken?.()
				}
				return fetch(request, options)
			})
	},
	_call: function (method, url, payload, signal, modal) {
		return this._basicCall(method, url, payload, signal)
			.then(async response => {
				const contentType = response.headers.get("content-type");
				if (contentType?.includes?.("text/html")) {
					const html = await response.blob();
					throw new ApiHtml(html, "The response was html.");
				}
				try {
					return await response.json()
				} catch (e) {
					throw new ApiError(null, "The response is not JSON.")
				}
			} )
			.then(json => {
				if (has(json, "refresh")) this.reload();
				if (has(json, "redirect")) this.changeUrl(json.redirect);
				if (json.success) {
					return json;
				} else {
					throw new ApiError(json, has(json, "message") ? json.message : "A communications error occurred.");
				}
			})
			.then(json => {
				modal?.deactivate?.();
				return json
			})
			.catch(ev=> {
				modal?.deactivate?.();
				throw ev;
			})
	},
	_streamed: function (method, url, payload, signal, modal) {
		if (typeof url === "string") {
			const temp = clone(url);
			url = {
				absoluteURL: function () {
					return temp
				}
			};
		}
		const options = extend(url, {
			method: method,
			headers: {"Content-Type": "application/json", "Accept": "application/json"}
		}, payload ? {body: JSON.stringify(payload)} : null, signal);

		return Auth.currentSession()
			.then(async (auth) => {
				const path = options.absoluteURL(true)
				const request = (path instanceof Request) ? path : new Request(path);
				options.headers = {...options.headers, "Authorization": "Bearer "+auth.getIdToken().getJwtToken()}
				return fetch(request, options)
					.then(response => response.text())
					.then(response => JSON.parse("["+response.slice(0,-1)+"]"))
					.then(json => {
						try {
							modal.deactivate()
						} catch (e) {
							// do nothing
						}
						return json
					})
			});
	},
	_blob: function (method, url, payload, signal, modal) {
		if (typeof url === "string") {
			const temp = clone(url);
			url = {
				absoluteURL: function () {
					return temp
				}
			};
		}
		const options = extend(url, {
			method: method,
			headers: {
				"Content-Type": "application/json"
			}
		}, payload ? {body: JSON.stringify(payload)} : null, signal);

		return Auth.currentSession()
			.catch(()=>{ })
			.then(async (auth) => {
				const path = options.absoluteURL(true)
				const request = (path instanceof Request) ? path : new Request(path);

				if (request.url.includes("levelflight.com")) {
					options.headers = {...options.headers, "Authorization": "Bearer "+auth.getIdToken().getJwtToken(), "X-Refresh-Token": "Bearer "+auth.getRefreshToken().getToken()}
				}

				return fetch(request, options)
					.then(async response => {
						const blob = await response.blob();
						const fileName = response.headers.get("content-disposition")?.split("; ")?.[1]?.split("filename=")?.[1] ?? "LevelFlight File";
						return new File([blob], fileName, { type: blob.type });
					})
					.then(out => {
						try {
							modal.deactivate()
						} catch (e) {
							// do nothing
						}
						return out
					})
			});
	},
	popout: function (url, name) {
		if (typeof url === "string") {
			const temp = clone(url);
			url = {
				url: temp,
				absoluteURL: function () {
					return temp
				}
			};
		}
		let test = window.open("/popout"+url.url, name);
		if (test == null) {
			alert("Sorry, but the browser blocked a window from opening.");
		}
	},
	directDownload: function(data, name){
		let test = document.createElement("a");
		test.href = data;
		test.download = name;
		test.click();
	},
	redirect: function(url, target){
		return this._call("GET", url, null, null, null)
			.then(result => {
				if (result.url) {
					let test = document.createElement("a");
					test.href = result.url;
					test.target = target;
					test.click();
				}
			})
	},
	download: function (url, name) {
		return this._basicCall(null, url)
			.then(async response => {
				let blob = await response.blob();
				let status = response.status;
				if (status >= 200 && status < 400) {
					const disposition = Try(() => response.headers.get("content-disposition").split("filename=")) || [];
					if (disposition[1] && disposition[1] !== "") {
						name = disposition[1];
					}
					let test = document.createElement("a");
					test.href = URL.createObjectURL(blob);
					test.download = (name ?? "LevelFlight").replaceAll("\"", "");
					test.click();
					return;
				} else if (response.redirected) {
					let body = await blob.text();
					if (body.includes("Only one auth mechanism allowed;")) {
						throw new ApiError({code:13, url:response.url, name: name});
					}
				}
				throw new Error("Invalid status.")
			})
			.catch(e => {
				console.error(e);
				if (e instanceof ApiError) {
					if (e.json.code === 13) {
						return Api.download(e.json.url, e.json.name)
					}
				}
				alert("This file could not be downloaded.")
				throw e;
			})
	},
	downloadPost: function (url, payload, name) {
		if (typeof url === "string") {
			const temp = clone(url);
			url = {
				absoluteURL: function () {
					return temp
				}
			};
		}
		return new Promise((s, f)=>{
			Auth.currentSession()
				.then((auth)=>{
					let req = new XMLHttpRequest();
					req.open("POST", url.absoluteURL(true), true);
					req.setRequestHeader("Authorization", "Bearer "+auth.getIdToken().getJwtToken());
					req.setRequestHeader("Content-Type", "application/json");
					req.responseType = "blob";
					req.addEventListener("load", (a)=>{
						const disposition = Try(()=> req.getResponseHeader("Content-Disposition").split("filename=")) || [];
						if (disposition[1] && disposition[1] !== "") {
							name = disposition[1];
						}
						try {
							let test = document.createElement("a");
							test.href = URL.createObjectURL(a.target.response);
							test.download = name.replaceAll("\"", "");
							test.click();
							s();
						} catch (e) {
							f(e);
						}
					});
					req.addEventListener("error", (a)=>{ f(a) });
					req.addEventListener("progress", (a)=>{ console.info("progress", parseInt((a.loaded / a.total) * 100)) });
					req.send(JSON.stringify(payload));
				});
		})
	}
};

export default Api;

export class ApiError extends Error {
	constructor(json = {}, ...params) {
		super(...params);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ApiError)
		}

		this.name = "ApiError";
		this.json = json;
	}
}

export class ApiHtml extends Error {
	constructor(html, ...params) {
		super(...params);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ApiHtml)
		}

		this.name = "ApiHtml";
		this.html = html;
	}
}