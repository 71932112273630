export const ChangeType = {
	parse: function (id) {
		switch (id) {
			case 6:
				return this["6"];
			case 5:
				return this["5"];
			case 3:
				return this["3"];
			case 1:
				return this["1"];
			case 2:
				return this["2"];
			case 4:
				return this["4"];
			case 7:
				return this["7"];

			default:
				return {id: 0, name: "-"};
		}
	},
	list: function () {
		return [this["5"], this["1"], this["6"], this["2"], this["4"], this["3"], this["7"]];
	},

	1: {id: 1, name: "Management"},
	6: {id: 6, name: "Expand Authorizations"},
	4: {id: 4, name: "Location Change"},
	3: {id: 3, name: "Add / Remove Aircraft"},
	5: {id: 5, name: "Expand Authorized Areas"},
	2: {id: 2, name: "Personnel"},
	7: {id: 7, name: "Exemption"},
};