import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

export const appBarAction = createSlice({
  name: "appBarAction",
  initialState: {
    createdSMS: false
  },
  reducers: {
    onCreateNewSMS: (state, action) => action.payload
  }
})

export const { onCreateNewSMS } = appBarAction.actions;

export const onCreateNewSMSState = (state: { appBarAction: { createdSMS: boolean } }) => state.appBarAction

export default appBarAction.reducer;