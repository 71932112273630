import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";
import extend from "lodash/extend";
import find from "lodash/find";
import get from "lodash/get";
import has from "lodash/has";
import head from "lodash/head";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";
import merge from "lodash/merge";
import pick from "lodash/pick";
import set from "lodash/set";
import tail from "lodash/tail";
import unset from "lodash/unset";
import without from "lodash/without";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectOperation } from "../../data/operationReducer";
import PROAddress from "../../helpers/address";
import Api from "../../helpers/api";
import ButtonSave from "../../helpers/buttonSave/buttonSave";
import Columns from "../../helpers/columns";
import { DateInput } from "../../helpers/dateInput";
import { DropdownInput } from "../../helpers/dropdown";
import { Flex } from "../../helpers/flex";
import Form from "../../helpers/form/form";
import PROLabel from "../../helpers/label/label";
import Loading from "../../helpers/loading/loading";
import { Panel } from "../../helpers/panel";
import PRODate from "../../helpers/proDate";
import { RadioInput, YesOrNo } from "../../helpers/radioInput/radioInput";
import PRORepeat from "../../helpers/repeat";
import SimpleInput from "../../helpers/simpleInput";
import { TextAreaLabel } from "../../helpers/textArea";
import { TextInput } from "../../helpers/textInput";
import { AttachmentsView } from "../../helpers/upload";
import { Country } from "../../helpers/utils/country";
import { DocumentCode } from "../../helpers/utils/documentCode";
import { usePopover } from "../../hooks/usePopover";
import { MinusCircle } from "../../icons/circle/minus";
import { PlusCircle } from "../../icons/circle/plus";
import { SearchIcon } from "../../icons/search";
import { PopoverFull } from "../../layout/popover";
import BasicTable from "../../layout/table/table";
import CompanySelector from "../company/selector";

const CustomerEdit = ({ label = "Customer", terms, customerId, company, contact, locked, onSave, onChange, onClose }) => {
  const modal = useRef();
  const operation = useSelector(selectOperation);
  const [popover, setPopover, clearPopover] = usePopover();
  const [isLoading, setIsLoading] = useState();
  const [decision, setDecision] = useState();
  const [errors, setErrors] = useState([]);

  let parts = (terms || "").split(" ") || [];
  let first, last;
  if ((terms || "").includes(",")) {
    first = tail(parts).join(" ").replace(",", "").trim() || "";
    last = head(parts).replace(",", "").trim() || "";
  } else {
    first = head(parts).trim() || "";
    last = tail(parts).join(" ").trim() || "";
  }
  const [customer, setCustomer] = useState({
    "firstName": first,
    "middleName": "",
    "lastName": last,
    "email": "",
    "citizenship": Country.US.short,
    "passportCountry": Country.US.short,
    "phones": [""],
    "company": [company],
    "contact": isBoolean(contact) ? contact : true,
    "locked": isBoolean(locked) ? locked : false
  });

  const save = () => {
    let data = extend({ "companyId": get(company, "_id.$oid") }, customer);
    data.birthday = get(data, "birthday.timestamp");
    data.passportExpiry = get(data, "passportExpiry.timestamp");
    Api.post(window.jsRoutes.controllers.Customers.edit(), data)
      .then(Api.flagSuccess)
      .then((result) => {
        setErrors([]);
        if (has(result, "decision")) {
          setDecision(result.decision);
        } else {
          onSave?.(result.customer);
        }
      })
      .catch(response => {
        setErrors(response.json.errors || []);
      })
  }

  const notifiyChange = () => {
    onChange?.(customer);
  }

  const change = (label, item) => {
    let state = cloneDeep(customer);
    set(state, label, item);
    setCustomer(state);
    notifiyChange();
  }

  const decisionResolve = (label) => {
    let state = cloneDeep(customer);
    set(state, label, true);
    setCustomer(state);
    notifiyChange();
    save(modal.current);
  }

  const removeCompany = (index) => {
    let state = cloneDeep(customer);
    unset(state, "company." + index);
    set(state, "company", compact(state.company));
    setCustomer(state)
    notifiyChange();
  }

  const addCompany = (item) => {
    let state = cloneDeep(customer);
    if (!isArray(state.company)) {
      state.company = []
    }
    state.company.push(item);
    setCustomer(state)
    notifiyChange();
  }

  useEffect(() => {
    let call = new AbortController();
    if (customerId) {
      setIsLoading(true);
      Api.get(window.jsRoutes.controllers.Customers.get(customerId), call)
        .then(result => {
          if (result?.customer?.birthday) {
            result.customer.birthday = new PRODate(result.customer.birthday);
          }
          (result.customer.documents || []).forEach(function (doc) {
            doc.expiry = new PRODate(doc.expiry);
          });
          console.info(result.customer?.birthday);
          setCustomer(p => {
            let state = cloneDeep(p);
            merge(state, result.customer);
            return state
          });
          setIsLoading(false);
        })
        .catch(Api.silentFail);
    }
    return () => {
      call.abort();
    }
  }, [customerId])

  let countryCheck = Country.parse(get(customer, "address.country") || "") !== Country.US;
  let citizenshipCountry = Country.parse(get(customer, "citizenship") || "") || Country.US;

  let decisionBlock = (decision) ? (
    <div key="decision">
      <h3 className="bg-warning" style={{ padding: "0 6px" }}>A {label} already exists with this name. How to
        you want to proceed?</h3>
      <div className="list-group">
        <button className="list-group-item" onClick={v => decisionResolve("update", v)}>
          <h4 className="list-group-item-heading">Update the existing {label}</h4>
          <p className="list-group-item-text">Add this company to the list of companies they are associated
            with.</p>
        </button>
        <button className="list-group-item" onClick={v => decisionResolve("overwrite", v)}>
          <h4 className="list-group-item-heading">Overwrite the existing {label}</h4>
          <p className="list-group-item-text">Change this {label} to be only associated with this
            company.</p>
        </button>
        <button className="list-group-item" onClick={v => decisionResolve("new", v)}>
          <h4 className="list-group-item-heading">Create a new {label}</h4>
          <p className="list-group-item-text">Leave the existing {label} alone and create a new one.</p>
        </button>
        <button className="list-group-item">
          <h4 className="list-group-item-heading">Cancel</h4>
        </button>
      </div>
    </div>
  ) : false;

  return <Box>
    {popover}
    <Form>
      <Loading active={isLoading} />
      <TextInput name="First Name" value={customer.firstName} onChange={v => change("firstName", v)}
        error={includes(errors, "obj.firstName")} />
      <TextInput name="Middle Name" value={customer.middleName} onChange={v => change("middleName", v)}
        error={includes(errors, "obj.middleName")} />
      <TextInput name="Last Name" value={customer.lastName} onChange={v => change("lastName", v)}
        error={includes(errors, "obj.lastName")} />
      <DateInput key={"birthday"} name="Birthday" value={customer.birthday} onChange={v => change("birthday", v)} format="MM/DD/yyyy" />
      <RadioInput name="Gender" value={customer.gender}
        options={[{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }, {value: null, label:"NMNF"}]}
        onChange={v => change("gender", v)} />
      <TextInput name="Weight" value={customer.weight} onChange={v => change("weight", v)}
        process={TextInput.Whole} />
      <DropdownInput name="Citizenship" value={citizenshipCountry.short}
        onChange={v => change("citizenship", v)} options={Country.byCountryName()}
        labelNode="name" valueNode="short" />
      <TextInput name="Email" value={customer.email} onChange={v => change("email", v)} />
      <PRORepeat key={(get(customer, "phones") || []).join("-")} name="Phones" values={customer.phones}
        onChange={v => change("phones", v)} />
      <YesOrNo name="Contact" value={customer.contact} onChange={v => change("contact", v)} />
      <YesOrNo name={"Lock this " + label + " to me"} value={customer.locked}
        onChange={v => change("locked", v)} />
      {(customer.documents || []).map((doc, index) => {
        let country = Country.parse(doc.country || "") || Country.US;
        return <Fragment key={doc._id.$oid}>
          <hr className="full" />
          <DropdownInput name="Document Type" value={doc.type}
            onChange={v => change("documents." + index + ".type", v)}
            options={DocumentCode.list()} labelNode="name" valueNode="id"
            append={<button className="control-content btn-danger" onClick={() => {
              let state = cloneDeep(customer);
              unset(state, "documents." + index);
              set(state, "documents", compact(state.documents));
              setCustomer(state);
            }}><MinusCircle /></button>} />
          <TextInput name="Number" value={doc.number}
            onChange={v => change("documents." + index + ".number", v)} />
          <DateInput name="Expiration" value={doc.expiry}
            onChange={v => change("documents." + index + ".expiry", v)} format="MM/DD/yyyy" />
          {doc.type === DocumentCode.License.id ? null :
            <DropdownInput key="country" name="Country" value={country.short}
              onChange={v => change("documents." + index + ".country", v)}
              options={Country.byCountryName()} labelNode="name" valueNode="short" />}
          {doc.type === DocumentCode.License.id ? <PROAddress key={index + "addr"} address={doc.address}
            onChange={v => change("documents." + index + ".address", v)}
            topLine={false}
            bottomLine={false} /> : undefined}
        </Fragment>
      })}
      <ButtonSave className="control-content btn-primary btn-block"
        label={<span><PlusCircle /> Travel Document</span>} onClick={(btn) => {
          btn.deactivate();
          Api.get(window.jsRoutes.controllers.Application.generateId()).then(result => {
            change("documents." + (get(customer, "documents") || []).length, {
              _id: result._id,
              country: Country.US.short,
              type: DocumentCode.list()[0].id
            })
          });
        }} />
      <PROAddress key={"addr"} address={customer.address} onChange={v => change("address", v)} />
      {countryCheck ? <PROAddress key={"usAddr"} topLine={false} title="Travel Address (For Non-US residents)"
        address={customer.travelAddress}
        onChange={v => change("travelAddress", v)} /> : undefined}

      {customerId ?
        <AttachmentsView showAsPopover={true} items={get(customer, "images") || []} onSave={(modal, item) => {
          Api.post(window.jsRoutes.controllers.Customers.addImage(get(customer, "_id.$oid")), item).then(Api.flagSuccess).then(result => {
            let state = cloneDeep(customer);
            (state.images || []).push(result.image);
            setCustomer(state);
            modal.close();
            notifiyChange();
          }).catch(Api.flagFail)
        }} onDelete={(item) => {
          if (window.confirm("Are you sure you want to delete this image?")) {
            Api.delete(window.jsRoutes.controllers.Customers.removeImage(get(customer, "_id.$oid"), item.id))
              .then(Api.flagSuccess)
              .then(() => {
                let img = find(customer.images, function (i) {
                  return i.id === item.id
                });
                let list = without(customer.images, img);
                let state = cloneDeep(customer);
                state.images = list;
                setCustomer(state);
                notifiyChange();
              }).catch(Api.flagFail);
          }
        }} /> : undefined}
      <hr className={"full"} />
      <CompanySelector title="Add Company" onChange={addCompany} />
      <PROLabel name="Companies" value={<Columns template={"1fr auto"}>
        {(get(customer, "company") || []).map((item, index) => {
          if (get(item, "_id.$oid")) {
            return (
              <div key={"company_" + get(item, "_id.$oid")} className={"row"}>
                <div>{item.name}</div>
                <button className="btn-danger" onClick={() => removeCompany(index)}>
                  <MinusCircle />
                </button>
              </div>
            );
          } else {
            removeCompany(index);
            return false;
          }
        })}
      </Columns>} />
      <hr className={"full"} />
      <TextAreaLabel key={customer.notes} name="Notes" value={get(customer, "notes") || ""}
        onChange={v => change("notes", v)} />
      {get(operation, "tools.quickbooks") ? <Fragment>
        <hr className={"full"} />
        <PROLabel name={"QuickBooks"} value={get(customer, "quickbooks.FullName")} />
        <button className={"full pro-btn"} onClick={() => {
          setPopover(<QuickBooksCustomer
            query={get(customer, "quickbooks.FullName") || get(customer, "firstName")}
            onSave={v => change("quickbooks", v)} onClosed={clearPopover} />)
        }}>Map to QuickBooks Desktop
        </button>
      </Fragment> : undefined}
    </Form>
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant="contained" sx={{ width: '120px' }} onClick={save}>Save</Button>
      <Button sx={{ width: '120px' }} onClick={onClose}>Cancel</Button>
    </Box>
  </Box>
}
export default CustomerEdit;

export const QuickBooksCustomer = ({ query, onSave, onClosed }) => {
  const [customers, setCustomers] = useState([]);
  const [term, setTerm] = useState(query || "");
  const [sub, setSub] = useState();
  const [selected, setSelected] = useState();
  const [lastUpdate, setLastUpdate] = useState();
  const [isWaiting, setIsWaiting] = useState(false);

  const search = () => {
    if (term.trim() === "") return
    Api.post(window.jsRoutes.controllers.IntuitController.customerQuery(), { "term": term }).then(result => {
      const id = { $oid: result.id };
      setLastUpdate(new PRODate(result.lastUpdate));
      setIsWaiting(true);
      setSub(id);
      // dataManager.addRoom(id, qbSocket); //TODO: Move to IoT like AccountingCodesEdit
    }).catch(Api.silentFail);
  }

  const qbSocket = (data) => {
    setIsWaiting(false);
    setCustomers(data.customers || []);
  }

  useEffect(() => {
    return () => {
      if (get(sub, "$oid")) {
        // dataManager.removeRoom(sub, qbSocket)
      }
    }
  }, [sub]);

  return <PopoverFull title={"QuickBooks Customer Mapping"} onClosed={onClosed} saveLabel={"Select"} save={() => {
    onSave(pick(selected, ["ListID", "FullName"]));
    onClosed();
  }}>
    <Flex style={{ marginBottom: "var(--spacing)" }}>
      <SimpleInput value={term} onChange={value => {
        setTerm(value)
      }} />
      <button className="text-primary" onClick={search} style={{ flexGrow: 0 }}><SearchIcon />
      </button>
    </Flex>
    {isWaiting ? <QuickBooksQueue lastUpdate={lastUpdate} /> : <BasicTable template={"1fr"} headers={["Full Name"]}>
      {customers.map(customer =>
        <div key={customer.ListID}
          className={"row" + (get(selected, "ListID") === customer.ListID ? " active" : "")}>
          <div className="cell" onClick={() => {
            setSelected(customer)
          }}>{customer.FullName}</div>
        </div>
      )}
    </BasicTable>}
  </PopoverFull>
}

export const QuickBooksQueue = ({ lastUpdate, className }) => {
  return <Panel title={"Awaiting QuickBooks next update"} className={className}>
    <p>Estimated wait time is <TimeUntil time={lastUpdate.plus(2.75)} /></p>
    <small>Last update {lastUpdate.format()}</small>
  </Panel>
}

export const TimeUntil = ({ time }) => {
  const [now, setNow] = useState(PRODate.now());

  useEffect(() => {
    let timer = setTimeout(() => {
      setNow(PRODate.now())
    }, 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [now]);

  return <span>{parseInt((time.timestamp - now.timestamp) / 1000)} seconds</span>
}