import {useEffect, useState} from "react";
import debounce from "lodash/debounce";

export const ReactiveSize = {
	small: {id: 0, max: 600},
	regular: {id: 1}
}

export function useReactive() {
	const [size, setSize] = useState(ReactiveSize.regular);

	useEffect(()=>{
		const checkWidth = () => {
			const width = window.innerWidth;
			if (width < ReactiveSize.small.max) {
				setSize(ReactiveSize.small);
			} else {
				setSize(ReactiveSize.regular);
			}
		}

		const debounced = debounce(checkWidth, 300);

		window.addEventListener("resize", debounced, false);

		checkWidth();

		return ()=>{
			window.removeEventListener("resize", debounced, false);
		}
	}, [])

	return size;
}
