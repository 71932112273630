import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cloneDeep, without } from 'lodash';
import { CSSProperties, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../data/userReducer';
import Api from '../../helpers/api';
import { Checkbox } from '../../helpers/checkbox';
import Form from '../../helpers/form/form';
import { UserType } from '../../helpers/utils/userType';
import Role_Descriptions from '../../pages/user/roles/roles';
import { isValidEmail, styles } from './common';

const localStyles: { [x: string]: CSSProperties | {} } = {
  toggleButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '10px',
    width: '150px !important',
    minWidth: '150px',
    '& > button': {
      borderRadius: '10px',
      textTransform: 'none'
    }
  },
  customLabel: {
    display: 'flex',
    minWidth: '150px !important',
    justifyContent: 'end !important',
    paddingRight: '10px'
  },
  customModal: {
    minWidth: '70%'
  }
}

type AddUserModalProps = {
  open: boolean
  onCancel: () => void
  onOk: () => void
}

type User = {
  firstName: string
  lastName: string
  email: string
}

const AddUserModal: React.FC<AddUserModalProps> = ({ open, onCancel, onOk }) => {
  const isMobileView = useMediaQuery('(max-width:700px)');
  const [newUser, setNewUser] = useState<User>({ email: '', firstName: '', lastName: '' });
  const loggedInUser = useSelector(selectUser);
  const [userTypes, setUserTypes] = useState([]);
  const [enableSave, setEnableSave] = useState<boolean>(false)
  const [validateEmailMsg, setValidateEmailMsg] = useState<string>('');

  const onChange = (field: keyof User, value: any) => {
    newUser[field] = value;
    setNewUser({...newUser});
  }

  const onAddNewUser = () => {
    Api.post((window as any).jsRoutes.controllers.Users.createUser(), {
			firstName: newUser.firstName,
			lastName: newUser.lastName,
			email: newUser.email,
			userTypes: userTypes,
		})
      .then(onOk)
			.catch(e => {
        console.error(e);
        onCancel();
			})
  }

  useEffect(() => {
    let msg = '';
    if (newUser.email.length) {
      msg = isValidEmail(newUser.email) ? '' : 'Please enter a valid email';
    }
    setEnableSave(!!newUser.firstName && !!newUser.lastName && !!newUser.email && !!userTypes.length && !msg);
    setValidateEmailMsg(msg);
  }, [newUser, userTypes])

  return (
    <Modal
      open={open}
      onClose={onCancel}
    >
      <Box sx={[styles.modal, isMobileView ? styles.mobile : styles.desktop, localStyles.customModal]}>

        <Box sx={styles.header}>
          <Box>Add New User</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onCancel} />
        </Box>
        <Box sx={styles.body}>
          <Box sx={[styles.control, {alignItems: 'center'}]}>
            <Typography sx={localStyles.customLabel}>Email </Typography>
            <FormControl variant='standard' >
              <TextField error={!!validateEmailMsg} helperText={validateEmailMsg} size='small' variant="standard" onChange={(e) => onChange('email', e.target.value)} />
            </FormControl>
          </Box>
          <Box sx={styles.control}>
            <Typography sx={localStyles.customLabel}>First Name </Typography>
            <FormControl variant='standard' >
              <TextField size='small' variant="standard" onChange={(e) => onChange('firstName', e.target.value)} />
            </FormControl>
          </Box>
          <Box sx={styles.control}>
            <Typography sx={localStyles.customLabel}>Last Name </Typography>
            <FormControl variant='standard' >
              <TextField size='small' variant="standard" onChange={(e) => onChange('lastName', e.target.value)} />
            </FormControl>
          </Box>
          <Form className={undefined} style={undefined} bottom={undefined}>
          {UserType.list().map((type: any, idx: number) => {
            if (!loggedInUser.userTypes.includes(UserType.SuperAdmin) && [UserType.SuperAdmin, UserType.FAA, UserType.SAFA, UserType.Auditor, UserType.TCE].includes(type)) return;
            return <Fragment key={idx}>
              <hr className="full-width"/>
              <Checkbox className={styles.controlLabel} pill={true} name={type.name} value={type.id}
                unvalue={type.id} checked={userTypes.includes(type.id)}
                onChange={(value: any) => {
                  setUserTypes(old => {
                    let state: any = cloneDeep(old) || [];
                    if (state.includes(value)) {
                      state = without(state, value);
                    } else {
                      state.push(value);
                    }
                    return state;
                  })
                }}/>
              <div className="control-content"><p>{(Role_Descriptions.descriptions() as any)[type.id] || ""}</p></div>
            </Fragment>;
          })}
            </Form>
          <Box sx={[styles.control, { justifyContent: 'end' }]}>
            <Button variant='contained' sx={styles.action} onClick={onCancel}>Close</Button>
            <Button variant='contained' sx={styles.action} disabled={!enableSave} onClick={onAddNewUser}>Save</Button>
          </Box>
        </Box>
      </Box>
    </Modal >
  )
}

export default AddUserModal;