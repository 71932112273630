import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import CompanyRolladex from '../../pages/company/rolladex';
import { styles } from './common';

type CompanySelectorModalProps = {
  open: boolean
  onClose: () => void
  listSelect: (item: any) => void
}

const CompanySelectorModal: React.FC<CompanySelectorModalProps> = ({ open, onClose, listSelect }) => {
  const isMobileView = useMediaQuery('(max-width:700px)');

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={[styles.modal, isMobileView ? styles.mobile : styles.desktop]}>
        <Box sx={styles.header}>
          <Box>Select a Company</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </Box>
        <Box sx={styles.body}>
          <CompanyRolladex onSelect={listSelect} onClosed={onClose} />
        </Box>
      </Box>
    </Modal>
  )
}

export default CompanySelectorModal;