import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import Spinner from "../spinner";
import Columns from "../columns";

const ButtonSave = forwardRef(({onClick, tabIndex, style, className, type, label}, ref) => {
	const btnSave = useRef();
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const fallback = useRef();
	ref = ref || fallback;

	useImperativeHandle(ref, () => ({
		activate: () => {
			setIsLoading(true);
		},
		deactivate: () => {
			setIsLoading(false);
		},
		enable: () => {
			if (btnSave.current) {
				setIsDisabled(false);
			}
		},
		disable: () => {
			if (btnSave.current) {
				setIsDisabled(true);
			}
		}
	}), [])

	const _onClick = (ev) => {
		ev.preventDefault();
		if (!isLoading && !isDisabled) {
			setIsLoading(true);
			onClick(ref.current);
		}
	}

	return <button ref={btnSave} tabIndex={tabIndex || 0} style={style} type={type || "button"} className={"pro-btn has-spinner " + (className || " btn-success") + (isDisabled ? " disabled" : "")} onClick={_onClick}>
		<Columns className={"center"} metric={"auto"} style={{gridAutoColumns:"1fr"}}>
			{isLoading ? <Spinner active={isLoading} className={"right"} /> : false}
			{React.isValidElement(label) ? React.cloneElement(label, isLoading ? {style:{justifySelf:"start"}} : {}) : <span className={isLoading ? "text-left" : null} style={isLoading ? {justifySelf:"start"} : {}}>{label || "Save"}</span>}
		</Columns>
	</button>;
})
export default ButtonSave;