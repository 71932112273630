class Database {
	constructor(name, version, upgrade) {
		this.name = name;
		this.version = version;
		return new Promise((resolve, reject)=>{
			let req = indexedDB.open(this.name, this.version);
			req.onsuccess = (ev)=>{
				this.db = ev.target?.result;
				resolve(this);
			}
			req.onerror = (ev)=>{
				console.error("db error", ev);
				reject(ev.target?.errorCode);
			}
			req.onupgradeneeded = (ev)=>{
				console.log("db upgrade needed", ev);
				this.db = ev.target?.result;
				upgrade(this.db);
			}
		})
	}

	#getObjectStore(name, mode) {
		const trans = this.db.transaction([name], mode)
		return trans.objectStore(name);
	}

	async clear(name) {
		return new Promise((resolve, reject)=>{
			const store = this.#getObjectStore(name, 'readwrite');
			const req = store.clear();
			req.onsuccess = () => {
				resolve(true);
			}
			req.onerror = ev => {
				console.error("error on clear", ev);
				reject(ev.target?.errorCode);
			}
		})
	}

	async get(name, key) {
		return new Promise((resolve, reject)=>{
			const store = this.#getObjectStore(name, 'readonly');
			const req = store.get(key);
			req.onsuccess = ev => {
				resolve(ev.target?.result);
			}
			req.onerror = ev => {
				console.error("error on get", ev);
				reject(ev.target?.errorCode);
			}
		})
	}

	async set(name, obj) {
		return this.add(name, obj).catch(err=> {
			if (err.message?.includes("Key already exists")) {
				return this.put(name, obj);
			} else {
				throw err;
			}
		})
	}

	async add(name, obj) {
		return new Promise((resolve, reject)=>{
			const store = this.#getObjectStore(name, 'readwrite');
			const req = store.add(obj);
			req.onsuccess = ev => {
				resolve(ev.target?.result);
			}
			req.onerror = ev => {
				reject(ev.target?.error);
			}
		})
	}

	async put(name, obj) {
		return new Promise((resolve, reject)=>{
			const store = this.#getObjectStore(name, 'readwrite');
			const req = store.put(obj);
			req.onsuccess = ev => {
				resolve(ev.target?.result);
			}
			req.onerror = ev => {
				reject(ev.target?.error);
			}
		})
	}

	async list(name) {
		return new Promise((resolve, reject)=>{
			const store = this.#getObjectStore(name, 'readonly');
			const req = store.getAll()
			req.onsuccess = ev => {
				resolve(ev.target?.result);
			}
			req.onerror = ev => {
				reject(ev.target?.error);
			}
		})
	}
}
export default Database;