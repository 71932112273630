import React, { Fragment } from 'react';
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import includes from "lodash/includes";
import set from "lodash/set";
import filter from "lodash/filter";
import Api from "../../../helpers/api";
import { connect } from "react-redux";
import Form from "../../../helpers/form/form";
import { SaveInput } from "../../../helpers/saveInput";
import { userMapDispatchToProps, userMapStateToProps } from "../../../data/user/maps";
import { Checkbox } from "../../../helpers/checkbox";
import Loading from "../../../helpers/loading/loading";
import "./style.scss";
import { UserType } from "../../../helpers/utils/userType";

class _Roles extends React.Component {
  static descriptions() {
    let descriptions = {};

    descriptions[UserType.Accounting.id] = "Permission Upgrade. Ability to create Expense Codes, access financial analytics, download finance CSV files.";
    descriptions[UserType.AircraftiPad.id] = "Training Center Evaluator and/or just an iPad. Used exclusively to view Manuals.";
    descriptions[UserType.AircraftOwner.id] = "This role has 2 uses. Primarily, this role is used to fill out the owner registration information to file APIS. Each account (keyed to email) can own multiple airplanes. The account is keyed to an email address and it does not necessarily need to be a live email. The second use, is for an actual live aircraft owner/office/assistant/family etc. This login type will allow the user to see a limited view of their aircraft’s schedule. The account will be a “member of” the tail numbers in the fleet, and be able to see where their planes are going, and if they have preferred crew(s) attached to their plane(s), they can see limited view of what their pilot(s) are doing. [Training, Vacation, HDO]. MX Work orders are also displayed in red.";
    descriptions[UserType.Auditor.id] = "This is an ephemeral account that can be moved from account to account for the purposes of 3rd party audits (ejm, isbao, etc). Manuals and duty time view only. Deactivated when audit is over by Admin or LF.";
    descriptions[UserType.Broker.id] = "This role only allows the user to create and book “off fleet” quotes.";
    descriptions[UserType.CheckAirman.id] = "Permission Upgrade. Stores, tracks, checks airman documentation.";
    descriptions[UserType.FAA.id] = "Ability to view documentation and duty times when activated by primary admin and/or LF administration.";
    descriptions[UserType.FlightAttendant.id] = "Cabin Attendants. Receive tripsheets and are assignable to trips.";
    descriptions[UserType.GroundInstructor.id] = "Role attribute, and manual access.";
    descriptions[UserType.LineService.id] = "Ability to create Safety tickets only.";
    descriptions[UserType.Mechanic.id] = "MX viewer of documents. Able to be assigned MX related tasks.";
    descriptions[UserType.MXControl.id] = "Ability to create MX Work orders. Receives notification when MX tickets are generated.";
    descriptions[UserType.NoAccess.id] = "No LF access. Used to limit access without deleting.";
    descriptions[UserType.Pilot.id] = "Main account for pilots. Record keeping, ability to be assigned to trips, dispatches, submit expenses, OOOI, Duty times. Full reporting.";
    descriptions[UserType.PrimaryAdmin.id] = "Ability to create users, release trips with warning, add planes, edit all core elements of LF, except for  Permission Upgrade items.  Permissions Upgrades are user roles “on top of” base roles.";
    descriptions[UserType.OpsControl.id] = "Administrative Function for Operational Control Managers without access to financial data or expenses.";
    descriptions[UserType.SAFA.id] = "Ability to view documentation and duty times when activated by primary admin and/or LF administration.";
    descriptions[UserType.SafetyCommittee.id] = "Tasks get assigned to these people.";
    descriptions[UserType.SafetyOfficer.id] = "Ability to create, administer SMS tickets (root cause, severity), and assign follow up tasks to members of Safety committee. Receives notification when SMS tickets are generated.";
    descriptions[UserType.Sales.id] = "Permission Upgrade. This role includes the user to be on the Avinode automatic distribution email.";
    descriptions[UserType.Scheduler.id] = "Ability to create quotes and schedule (book) quotes (but not Release flight).";
    descriptions[UserType.ScheduleRelease.id] = "Permission Upgrade. This role allows the user to RELEASE Flights.";
    descriptions[UserType.Screener.id] = "Reserved for Screeners.";
    descriptions[UserType.SuperAdmin.id] = "The one role to rule them all.";
    descriptions[UserType.TCE.id] = "Training Center Evaluator and/or just an iPad. Used only to view Manuals.";
    descriptions[UserType.TSAUploader.id] = "Permission Upgrade – Ability to upload TSA No Fly CSV files (Operations > More > TSA). This role implies that the user has a login to HSIN and has access to the 12-5 lists.";
    descriptions[UserType.RecordsManager.id] = "An add-on role that allows for Document and Record management.";
    descriptions[UserType.GeneralCalendar.id] = "An add-on role that allows the calendar to show all flights.";
    return descriptions;
  }

  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.state = { descriptions: _Roles.descriptions() };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ isLoading: true });
    Api.get(window.jsRoutes.controllers.Users.getRoles(this.props.id)).then(result => {
      this.props.setUser(result.user);
      this.setState({ isLoading: false });
    }).catch(err => {
      this.setState(err.json);
    });
  }

  change(node, value) {
    let state = cloneDeep(this.props.user);
    set(state, node, value);
    this.props.setUser(state);
  }

  save(btn) {
    let toSave = cloneDeep(this.props.user);
    toSave.userTypes = toSave.userTypes.map(i => i.id);
    Api.post(window.jsRoutes.controllers.Users.saveRoles(this.props.id), toSave).then(Api.flagSuccess).then(() => {
      this.loadData();
    }).catch((response) => {
      this.setState(response.json);
    }).then(() => {
      btn.deactivate();
    });
  }

  render() {
    return this.state.success === false ? <div>{this.state.message}</div> :
      <div className="container-fluid mobile-safe">
        <Loading active={this.state.isLoading} />
        <h2 className="text-center">Roles</h2>
        <Form className="roles">
          {UserType.list().map(type => {
            let checked = includes(get(this.props, "user.userTypes"), type);
            return <Fragment key={type.id}>
              <hr className="full-width" />
              <Checkbox pill={true} name={type.name} value={true} unvalue={false} checked={checked}
                onChange={value => {
                  let state = cloneDeep(this.props.user);
                  if (value) {
                    state.userTypes.push(type);
                  } else {
                    state.userTypes = filter(state.userTypes, i => i.id !== type.id);
                  }
                  this.props.setUser(state);
                }} />
              <div className="control-content"><p>{this.state.descriptions[type.id] || ""}</p></div>
            </Fragment>;
          })}
          <SaveInput onClick={this.save.bind(this)} />
        </Form>
      </div>;
  }
}

const Roles = connect(userMapStateToProps, userMapDispatchToProps)(_Roles);
export default Roles;