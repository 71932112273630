export function jsRoutes() {
	let _root = {};
	let root = import.meta.env.VITE_REST_SERVER;
	let _nS = function (c, f, b) {
		let e = c.split(f || "."), g = b || _root, d, a;
		for (d = 0, a = e.length; d < a; d++) {
			g = g[e[d]] = g[e[d]] || {}
		}
		return g
	}
	let _qS = function (items) {
		let qs = '';
		for (let i = 0; i < items.length; i++) {
			if (items[i]) qs += (qs ? '&' : '') + items[i]
		}
		return qs ? ('?' + qs) : ''
	}
	let _wA = function (r) {
		return {
			...r, ...{
				absoluteURL: function () {
					return 'https://' + root + r.url
				}
			}
		}
	};
	_root['root'] = root;
	_nS('controllers.Api');
	_root['controllers']['Api']['autocompleteAirports'] =
		function (query0) {
			return _wA({
				method: "GET", url: "/api/autocomplete/airport/" + encodeURIComponent((function (k, v) {
					return v
				})("query", query0))
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['fitForDuty'] =
		function () {
			return _wA({method: "POST", url: "/api/fitForDuty"})
		}
	;
	_root['controllers']['Api']['dutyCheck'] =
		function () {
			return _wA({method: "POST", url: "/api/dutyCheck"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getTools'] =
		function () {
			return _wA({method: "GET", url: "/api/operation/tools"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['dutyOut'] =
		function () {
			return _wA({method: "POST", url: "/api/dutyOut"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getFlightAttendants'] =
		function () {
			return _wA({method: "GET", url: "/api/attendants/list"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getFBOs'] =
		function (icao0) {
			return _wA({
				method: "GET", url: "/api/airport/fbo/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0))
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getServices'] =
		function (icao0) {
			return _wA({
				method: "GET", url: "/api/airport/services/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0))
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getAirportInformation'] =
		function (icao0) {
			return _wA({
				method: "GET", url: "/api/airport/info/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0))
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getSMSPhases'] =
		function () {
			return _wA({method: "GET", url: "/api/sms/phases"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getSMSPhases'] =
		function () {
			return _wA({method: "GET", url: "/api/sms/phases"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getSMSTypes'] =
		function () {
			return _wA({method: "GET", url: "/api/sms/types"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getSMSActions'] =
		function () {
			return _wA({method: "GET", url: "/api/sms/actions"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getSMSATACodes'] =
		function () {
			return _wA({method: "GET", url: "/api/sms/ata_items"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getSeverities'] =
		function () {
			return _wA({method: "GET", url: "/api/sms/severities"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getLikelihoods'] =
		function () {
			return _wA({method: "GET", url: "/api/sms/likelihoods"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getRiskMatrix'] =
		function () {
			return _wA({method: "GET", url: "/api/sms/matrix"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getPurposes'] =
		function () {
			return _wA({method: "GET", url: "/api/leg/purposes"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getSafetyTeam'] =
		function () {
			return _wA({method: "GET", url: "/api/safetyTeam/list"})
		}
	;
	_root['controllers']['Api']['getAllUsers'] =
		function () {
			return _wA({method: "GET", url: "/api/users/list"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getMechanics'] =
		function () {
			return _wA({method: "GET", url: "/api/mechanics/list"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getAdmins'] =
		function () {
			return _wA({method: "GET", url: "/api/admins/list"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['recheckUserDocuments'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/document/recheck"
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['recheckAircraftDocuments'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/erk/recheck"
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['recheckAllDocuments'] =
		function () {
			return _wA({method: "GET", url: "/api/recheck"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getPilots'] =
		function () {
			return _wA({method: "GET", url: "/api/pilots/list"})
		}
	;
	_root['controllers']['Api']['getCheckAirmen'] =
		function () {
			return _wA({method: "GET", url: "/api/checkAirmen/list"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getPilotsForAircraft'] =
		function (craft0) {
			return _wA({
				method: "GET", url: "/api/pilots/" + encodeURIComponent((function (k, v) {
					return v
				})("craft", craft0)) + "/list"
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['generatePresignedUrl'] =
		function () {
			return _wA({method: "POST", url: "/api/upload/presigned"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getChangeLog'] =
		function () {
			return _wA({method: "POST", url: "/api/changeLog"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getChangeLogByTimestamp'] =
		function () {
			return _wA({method: "POST", url: "/api/changeLog/timestamp"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getReceipts'] =
		function () {
			return _wA({method: "POST", url: "/api/receipts"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['updateSubscription'] =
		function () {
			return _wA({method: "POST", url: "/api/user/subscription"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['markManualAsRead'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/manuals/doc/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/read"
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getDelayReasons'] =
		function () {
			return _wA({method: "GET", url: "/api/dispatch/delays"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['editNote'] =
		function () {
			return _wA({method: "POST", url: "/api/note/edit"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getOperationDocuments'] =
		function () {
			return _wA({method: "GET", url: "/api/erk/documents"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['deleteNote'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/note/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/delete"
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getFuel'] =
		function (icao0) {
			return _wA({
				method: "GET", url: "/api/airport/fuel/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0))
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getFlightsForUserBetween'] =
		function () {
			return _wA({method: "POST", url: "/api/user/flights"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['getSalesAndDispatch'] =
		function () {
			return _wA({method: "GET", url: "/api/salesAndDispatch/list"})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['listUserEmails'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/list/" + (function (k, v) {
					return v
				})("id", id0)
			})
		}
	;
	_nS('controllers.Api');
	_root['controllers']['Api']['requestPasswordReset'] =
		function () {
			return _wA({method: "POST", url: "/api/user/passwordReset"})
		}
	;
	_nS('controllers.Application');
	_root['controllers']['Application']['index'] =
		function () {
			return _wA({method: "GET", url: "/"})
		}
	;
	_root['controllers']['Application']['generateId'] =
		function () {
			return _wA({method: "GET", url: "/api/generate/id"})
		}
	;
	_nS('controllers.Application');
	_root['controllers']['Application']['processLogout'] =
		function () {
			return _wA({method: "GET", url: "/logout"})
		}
	;
	_nS('controllers.Application');
	_root['controllers']['Application']['login'] =
		function () {
			return _wA({method: "GET", url: "/login"})
		}
	;
	_nS('controllers.Utils');
	_root['controllers']['Utils']['weather'] =
		function (icao0) {
			return _wA({
				method: "GET", url: "/utils/weather/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0))
			})
		}
	;
	_nS('controllers.Utils');
	_root['controllers']['Utils']['tsaUploadNoFly'] =
		function () {
			return _wA({method: "POST", url: "/api/tsa/upload/no_fly"})
		}
	;
	_root['controllers']['Utils']['checkDHSResponse'] =
		function (legId) {
			return _wA({
				method: "GET", url: "/api/tsa/dhs/check/" + encodeURIComponent((function (k, v) {
					return v
				})("legId", legId))
			})
		}
	_nS('controllers.Utils');
	_root['controllers']['Utils']['tsaUploadSelectee'] =
		function () {
			return _wA({method: "POST", url: "/api/tsa/upload/selectee"})
		}
	;
	_nS('controllers.Utils');
	_root['controllers']['Utils']['tsaUploadCleared'] =
		function () {
			return _wA({method: "POST", url: "/api/tsa/upload/cleared"})
		}
	;
	_nS('controllers.Utils');
	_root['controllers']['Utils']['flightPlan'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/flightplan"
			})
		}
	;
	_nS('controllers.Utils');
	_root['controllers']['Utils']['worldFuel'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/upload/worldFuel"})
		}
	;
	_root['controllers']['Utils']['avFuel'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/upload/avFuel"})
		}
	;
	_root['controllers']['Utils']['signatureFuel'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/upload/signatureFuel"})
		}
	;
	_root['controllers']['Utils']['everestFuel'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/upload/everestFuel"})
		}
	;
	_root['controllers']['Utils']['mercuryFuel'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/upload/mercuryFuel"})
		}
	;
	_root['controllers']['Utils']['aegFuel'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/upload/aegFuel"})
		}
	;
	_nS('controllers.Utils');
	_root['controllers']['Utils']['eapis'] =
		function (id0, part1) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/eapis/" + encodeURIComponent((function (k, v) {
					return v
				})("part", part1))
			})
		}
	;
	_nS('controllers.Utils');
	_root['controllers']['Utils']['caricomeAPIS'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/eapis/caricom"
			})
		}
	;
	_nS('controllers.Utils');
	_root['controllers']['Utils']['mexicoAPIS'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/eapis/mexico"
			})
		}
	;
	_root['controllers']['Utils']['eSecureFlight'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/eSecureFlight"
			})
		}
	;
	_nS('controllers.Dashboard');
	_root['controllers']['Dashboard']['pilotTrips'] =
		function () {
			return _wA({method: "POST", url: "/api/dashboard/pilot/trips"})
		}
	;
	_nS('controllers.Dashboard');
	_root['controllers']['Dashboard']['attendantTrips'] =
		function () {
			return _wA({method: "POST", url: "/api/dashboard/attendant/trips"})
		}
	;
	_nS('controllers.Dashboard');
	_root['controllers']['Dashboard']['ownerCalendar'] =
		function () {
			return _wA({method: "POST", url: "/api/dashboard/owner/trips"})
		}
	;
	_nS('controllers.Dashboard');
	_root['controllers']['Dashboard']['smsByCode'] =
		function () {
			return _wA({method: "GET", url: "/api/dashboard/sms/ataCodes"})
		}
	;
	_nS('controllers.Dashboard');
	_root['controllers']['Dashboard']['pilotExpirableDocuments'] =
		function (part) {
			return _wA({method: "GET", url: "/api/dashboard/pilotExpirableDocuments"+(part ? "?part="+part : "")})
		}
	;
	_root['controllers']['Dashboard']['attendantExpirableDocuments'] =
		function () {
			return _wA({method: "GET", url: "/api/dashboard/attendantExpirableDocuments"})
		}
	;
	_nS('controllers.Dashboard');
	_root['controllers']['Dashboard']['aircraftExpirableDocuments'] =
		function () {
			return _wA({method: "GET", url: "/api/dashboard/aircraftExpirableDocuments"})
		}
	;
	_nS('controllers.Dashboard');
	_root['controllers']['Dashboard']['adminWidget'] =
		function () {
			return _wA({method: "GET", url: "/api/dashboard/breakdown"})
		}
	;
	_nS('controllers.Dashboard');
	_root['controllers']['Dashboard']['pilot'] =
		function () {
			return _wA({method: "GET", url: "/api/dashboard/pilot"})
		}
	;
	_root['controllers']['Dashboard']['fleetPosition'] =
		function () {
			return _wA({method: "GET", url: "/api/dashboard/fleetPosition"})
		}
	;
	_nS('controllers.Expenses');
	_root['controllers']['Expenses']['list'] =
		function (page0) {
			if (page0 === 1) {
				return _wA({method: "GET", url: "/api/expenses/list"})
			}
			return _wA({
				method: "GET", url: "/api/expenses/list/" + encodeURIComponent((function (k, v) {
					return v
				})("page", page0))
			})
		}
	;
	_nS('controllers.Expenses');
	_root['controllers']['Expenses']['filter'] =
		function () {
			return _wA({method: "POST", url: "/api/expenses/filter"})
		}
	;
	_root['controllers']['Expenses']['download'] =
		function () {
			return _wA({method: "POST", url: "/api/expenses/download"})
		}
	;
	_nS('controllers.Expenses');
	_root['controllers']['Expenses']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/expenses/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Expenses');
	_root['controllers']['Expenses']['edit'] =
		function () {
			return _wA({method: "POST", url: "/api/expenses/edit"})
		}
	;
	_nS('controllers.Expenses');
	_root['controllers']['Expenses']['addImage'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/expenses/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/image/add"
			})
		}
	;
	_nS('controllers.Expenses');
	_root['controllers']['Expenses']['removeImage'] =
		function (id0, image1) {
			return _wA({
				method: "DELETE", url: "/api/expenses/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/image/remove/" + encodeURIComponent((function (k, v) {
					return v
				})("image", image1))
			})
		}
	;
	_nS('controllers.Expenses');
	_root['controllers']['Expenses']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/expenses/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Expenses');
	_root['controllers']['Expenses']['dispatch'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/expenses"
			})
		}
	;
	_nS('controllers.Expenses');
	_root['controllers']['Expenses']['recent'] =
		function () {
			return _wA({method: "GET", url: "/api/expenses/recent"})
		}
	;
	_nS('controllers.AccountingCodes');
	_root['controllers']['AccountingCodes']['all'] =
		function () {
			return _wA({method: "GET", url: "/api/accounting/all"})
		}
	;
	_nS('controllers.AccountingCodes');
	_root['controllers']['AccountingCodes']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/accounting/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/delete"
			})
		}
	;
	_nS('controllers.AccountingCodes');
	_root['controllers']['AccountingCodes']['edit'] =
		function () {
			return _wA({method: "POST", url: "/api/accounting/edit"})
		}
	;
	_nS('controllers.AccountingCodes');
	_root['controllers']['AccountingCodes']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/accounting/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.PaymentMethods');
	_root['controllers']['PaymentMethods']['all'] =
		function () {
			return _wA({method: "GET", url: "/api/paymentMethods/all"})
		}
	;
	_nS('controllers.PaymentMethods');
	_root['controllers']['PaymentMethods']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/paymentMethods/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.PaymentMethods');
	_root['controllers']['PaymentMethods']['edit'] =
		function () {
			return _wA({method: "POST", url: "/api/paymentMethods/edit"})
		}
	;
	_nS('controllers.PaymentMethods');
	_root['controllers']['PaymentMethods']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/paymentMethods/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Documents');
	_root['controllers']['Documents']['index'] =
		function (bucket0) {
			if (bucket0 === "") {
				return _wA({method: "GET", url: "/api/manuals"})
			}
			return _wA({
				method: "GET", url: "/api/manuals/" + encodeURIComponent((function (k, v) {
					return v
				})("name", bucket0))
			})
		}
	;
	_nS('controllers.Documents');
	_root['controllers']['Documents']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/manuals/doc/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Documents');
	_root['controllers']['Documents']['edit'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/manuals/doc/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Documents');
	_root['controllers']['Documents']['remove'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/manuals/doc/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Components');
	_root['controllers']['Components']['list'] =
		function (active0) {
			return _wA({
				method: "GET", url: "/api/components/all/" + encodeURIComponent((function (k, v) {
					return !!v
				})("active", active0))
			})
		}
	;
	_nS('controllers.Components');
	_root['controllers']['Components']['setAPUTime'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/components/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/setTime"
			})
		}
	;
	_root['controllers']['Components']['save'] =
		function () {
			return _wA({method: "POST", url: "/api/components/save"})
		}
	;
	_nS('controllers.Components');
	_root['controllers']['Components']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/components/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Components');
	_root['controllers']['Components']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/components/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['list'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/list"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['flightLog'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/flightLog"
			})
		}
	;
	_root['controllers']['Dispatches']['createNewVersion'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/version/create"
			})
		}
	;
	_root['controllers']['Dispatches']['markAsActiveVersion'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/version/activate"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['saveTSA'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/tsa"
			})
		}
	;
	_root['controllers']['Dispatches']['activateBookedHistoryItem'] =
		function (id0, index1) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/history/activate/" + encodeURIComponent((function (k, v) {
					return v
				})("index", index1))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['acceptTSA'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/tsa/accept"
			})
		}
	;
	_root['controllers']['Dispatches']['acceptTSAExternal'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/tsa/acceptExternal"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['addNewLeg'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/leg/add"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['removeLeg'] =
		function (id0, leg1) {
			return _wA({
				method: "DELETE", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("leg", leg1))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['createDispatch'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/create"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['releaseDispatch'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/release"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['releaseLeg'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/release"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['getFlightTimeAndDistance'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/flightTimeAndDistance"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['riskAudit'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/riskAudit"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['saveRiskAudit'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/riskAudit"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['itinerary'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/itinerary"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['legItinerary'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/itinerary"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['savePreflightUser'] =
		function (id0, kind1) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/user/" + encodeURIComponent((function (k, v) {
					return v
				})("kind", kind1))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['removePreflightUser'] =
		function (leg1, kind2) {
			return _wA({
				method: "DELETE", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("leg", leg1)) + "/user/" + encodeURIComponent((function (k, v) {
					return v
				})("kind", kind2))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['unreleaseLeg'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/unrelease"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['loadManifest'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/loadManifest"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['saveLoadManifest'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/loadManifest"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['saveFBO'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/leg/fbo"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['saveServices'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/leg/services"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['saveServiceNote'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/leg/services/note"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['savePrereleaseLegItem'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/leg/prerelease/item"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['savePilotItem'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/leg/pilot/item"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['saveDispatchItem'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/leg/trip/item"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['removeDispatch'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['markAsComplete'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/mark/complete"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['unmarkComplete'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/unmark/complete"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['changeAircraft'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/changeAircraft"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['duplicate'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/duplicate"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['split'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/split"})
		}
	;
	_root['controllers']['Dispatches']['modify'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/modify"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['link'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/link"})
		}
	;
	_root['controllers']['Dispatches']['unlink'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/unlink"
			})
		}
	;
	_root['controllers']['Dispatches']['related'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/related"})
		}
	;
	_root['controllers']['Dispatches']['unrelate'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/related"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['merge'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/merge"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['setComponents'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/leg/setComponents"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['genDec'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/reports/gendec/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['canpass'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/reports/canpass/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['internationalManifest'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/reports/internationalManifest/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['search'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/search"})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['reviewInternationalManifest'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/review/internationalManifest"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['reviewTripSheet'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/review/tripSheet"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['reviewItinerary'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/review/itinerary"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['reviewCYA'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/review/cya"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['cya'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/reports/cya/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['emailTripSheet'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/tripSheet/email"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['emailItinerary'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/itinerary/email"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['emailCYA'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/cya/email"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['reviewTripSheetLeg'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/review/tripSheet"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['reviewItineraryLeg'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/review/itineraryLeg"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['emailTripSheetLeg'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/tripSheet/email"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['emailItineraryLeg'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/itineraryLeg/email"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['markLegAsReleased'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/release"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['addPostFlightImage'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/postFlight/image/add"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['removePostFlightImage'] =
		function (id0, image1) {
			return _wA({
				method: "DELETE", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/postFlight/image/remove/" + encodeURIComponent((function (k, v) {
					return v
				})("image", image1))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['releaseCheck'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/check"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['sendAutomatedReminders'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/automatedReminder"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['recalculateFlightTimeAndDistance'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/recalculateFlightTimeAndDistance"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['addFile'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/files/add"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['removeFile'] =
		function (id0, file1) {
			return _wA({
				method: "DELETE", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/files/remove/" + encodeURIComponent((function (k, v) {
					return v
				})("file", file1))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['addDispatchFile'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/files/add"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['removeDispatchFile'] =
		function (id0, file1) {
			return _wA({
				method: "DELETE", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/files/remove/" + encodeURIComponent((function (k, v) {
					return v
				})("file", file1))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['addComment'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/comment"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['removeComment'] =
		function (id0, comment1) {
			return _wA({
				method: "DELETE", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/comment/" + encodeURIComponent((function (k, v) {
					return v
				})("comment", comment1))
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['notifyCrew'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/notifyCrew"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['addLoadManifestFile'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/loadManifest/file/add"
			})
		}
	;
	_nS('controllers.Dispatches');
	_root['controllers']['Dispatches']['removeLoadManifestFile'] =
		function (id0, image1) {
			return _wA({
				method: "DELETE", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/loadManifest/file/remove/" + encodeURIComponent((function (k, v) {
					return v
				})("image", image1))
			})
		}
	;
	_root['controllers']['Dispatches']['signatureRequest'] =
		function (id0, section1) {
			return _wA({
				method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/signature/" + encodeURIComponent((function (k, v) {
					return v
				})("section", section1)) + "/request"
			})
		}
	;
	_root['controllers']['Dispatches']['signatureSign'] =
		function (id0, section1) {
			return _wA({
				method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/signature/" + encodeURIComponent((function (k, v) {
					return v
				})("section", section1)) + "/sign"
			})
		}
	;

	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['create'] =
		function () {
			return _wA({method: "POST", url: "/api/quotes/create"})
		}
	;
	_root['controllers']['Quotes']['quickQuote'] =
		function () {
			return _wA({method: "POST", url: "/api/quotes/quickQuote"})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['report'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/report/generate"
			})
		}
	;
	_root['controllers']['Quotes']['generateQuote'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/report/refresh"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['showReport'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/report"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['reviewQuote'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/review/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/quote"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['emailQuote'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/email"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['invoice'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/invoice/generate"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['emailInvoice'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/invoice/email"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['reviewInvoice'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/review/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/invoice"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['approvalRequest'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/approvalRequest/generate"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['reviewApprovalRequest'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/approvalRequest/email"
			})
		}
	;
	_root['controllers']['Quotes']['emailApprovalRequest'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/approvalRequest/send"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['showApprovalRequest'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/approvalRequest"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['moveToDispatch'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/book"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['moveToCancelled'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/cancel"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['duplicate'] =
		function () {
			return _wA({method: "POST", url: "/api/quotes/duplicate"})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['calculatePrice'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/price"
			})
		}
	;
	_root['controllers']['Quotes']['calculateTemporaryPrice'] =
		function () {
			return _wA({
				method: "POST", url: "/api/quotes/price"
			})
		}
	;
	_nS('controllers.Quotes');
	_root['controllers']['Quotes']['accept'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/accept"
			})
		}
	;
	_root['controllers']['Quotes']['clientAccept'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/quotes/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/client/accept"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['create'] =
		function () {
			return _wA({method: "POST", url: "/api/aircraft/create"})
		}
	;
	_root['controllers']['AircraftController']['getPreferredFBO'] =
		function (id0, icao1) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/preferredFBO/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao1))
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['addImage'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/image/add"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['removeImage'] =
		function (id0, image1) {
			return _wA({
				method: "DELETE", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/image/remove/" + encodeURIComponent((function (k, v) {
					return v
				})("image", image1))
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['listRates'] =
		function () {
			return _wA({
				method: "GET", url: "/api/operation/rates"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['getBasicInfo'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/basicInfo"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['saveBasicInfo'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/basicInfo"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['list'] =
		function () {
			return _wA({method: "GET", url: "/api/aircraft/list"})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['all'] =
		function () {
			return _wA({method: "GET", url: "/api/aircraft/all"})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['attachComponents'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/components"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['listAircraftRates'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/ratesAndFees/list"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['getRate'] =
		function (craft0, id1) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("craft", craft0)) + "/ratesAndFees/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1))
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['saveRate'] =
		function () {
			return _wA({
				method: "POST", url: "/api/aircraft/ratesAndFees/save"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['deleteRate'] =
		function (id1) {
			return _wA({
				method: "DELETE", url: "/api/aircraft/ratesAndFees/delete/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1))
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['baseFees'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/baseFees"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['components'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/components"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['mechanicalInfo'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/aircraft/mechanical/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['sort'] =
		function () {
			return _wA({method: "POST", url: "/api/aircraft/sort"})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['erk'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/aircraft/erk/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['erkEdit'] =
		function (id0, doc1) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/erk/" + encodeURIComponent((function (k, v) {
					return v
				})("doc", doc1))
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['erkSave'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/erk/save"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['erkDelete'] =
		function (id0, doc1) {
			return _wA({
				method: "DELETE", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/erk/" + encodeURIComponent((function (k, v) {
					return v
				})("doc", doc1)) + "/delete"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['deactivate'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/deactivate"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['reactivate'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/reactivate"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['ical'] =
		function (op0, id1) {
			return _wA({
				method: "GET", url: "/operation/" + encodeURIComponent((function (k, v) {
					return v
				})("op", op0)) + "/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1)) + "/calendar"
			})
		}
	;
	_root['controllers']['AircraftController']['saveiCal'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/ical"
			})
		}
	;
	_root['controllers']['AircraftController']['deleteiCal'] =
		function (id0, id1) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("craft", id0)) + "/ical/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1))
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['setDefaultRate'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/ratesAndFees/default"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['getTimeAndLandings'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/timeAndLandings"
			})
		}
	;
	_root['controllers']['AircraftController']['getiCalList'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/ical"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['saveTimeAndLandings'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/timeAndLandings"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['savePreferredCrew'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/preferredCrew"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['getFees'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/fees"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['saveFee'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/fees"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['deleteFee'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/fees"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['getMechanicalInfo'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/mechanicalInfo"
			})
		}
	;
	_nS('controllers.AircraftController');
	_root['controllers']['AircraftController']['saveMechanicalInfo'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/mechanicalInfo"
			})
		}
	;
	_nS('controllers.MaintenanceController');
	_root['controllers']['MaintenanceController']['maintenanceForAircraft'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/maintenance"
			})
		}
	;
	_nS('controllers.MaintenanceController');
	_root['controllers']['MaintenanceController']['saveMilestones'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/milestones"
			})
		}
	;
	_nS('controllers.MaintenanceController');
	_root['controllers']['MaintenanceController']['save'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/maintenance/save"
			})
		}
	;
	_nS('controllers.MaintenanceController');
	_root['controllers']['MaintenanceController']['delete'] =
		function (id0, id1) {
			return _wA({
				method: "DELETE", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/maintenance/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1))
			})
		}
	;
	_nS('controllers.MaintenanceController');
	_root['controllers']['MaintenanceController']['get'] =
		function (craft0, id1) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("craft", craft0)) + "/maintenance/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1))
			})
		}
	;
	_nS('controllers.MaintenanceController');
	_root['controllers']['MaintenanceController']['listProviders'] =
		function (page0, active1) {
			return _wA({
				method: "GET", url: "/api/maintenanceProviders/list/" + encodeURIComponent((function (k, v) {
					return v
				})("page", page0)) + "/" + encodeURIComponent((function (k, v) {
					return !!v
				})("active", active1))
			})
		}
	;
	_nS('controllers.MaintenanceController');
	_root['controllers']['MaintenanceController']['editProvider'] =
		function () {
			return _wA({method: "POST", url: "/api/maintenanceProviders/edit"})
		}
	;
	_nS('controllers.MaintenanceController');
	_root['controllers']['MaintenanceController']['getProvider'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/maintenanceProviders/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.MaintenanceController');
	_root['controllers']['MaintenanceController']['listAllProviders'] =
		function () {
			return _wA({method: "GET", url: "/api/maintenanceProviders/list/all"})
		}
	;
	_root['controllers']['MaintenanceController']['addFile'] =
		function (id0, prepend1) {
			return _wA({
				method: "POST", url: "/api/maintenanceProviders/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/file/" + encodeURIComponent((function (k, v) {
					return v
				})("prepend", prepend1))
			})
		}
	;
	_root['controllers']['MaintenanceController']['removeFile'] =
		function (id0, prepend1) {
			return _wA({
				method: "DELETE", url: "/api/maintenanceProviders/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/file/" + encodeURIComponent((function (k, v) {
					return v
				})("prepend", prepend1))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['riskAssessment'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/reports/riskAssessment/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['flightLog'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/flightLog/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['flightAndDuty'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/flightAndDuty/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['pilotActivity'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/pilotActivity/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['pilotDutyYear'] =
		function (id0, start1) {
			return _wA({
				method: "GET", url: "/reports/pilotDutyYear/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['aircraftActivity'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/aircraftActivity/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['pilotLandings'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/pilotLandings/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['pilotInfoSheet'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/reports/pilotInfoSheet/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['sifl'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/sifl/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['smsATA'] =
		function (start0, end1) {
			return _wA({
				method: "GET", url: "/reports/sms/ata/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end1))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['smsNonATA'] =
		function (start0, end1) {
			return _wA({
				method: "GET", url: "/reports/sms/nonATA/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end1))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['perDiem'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/perDiem/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['pilotFlightGant'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/pilotGant/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['review'] =
		function (path0, id1, start2, end3) {
			return _wA({
				method: "GET", url: "/reports/review/" + encodeURIComponent((function (k, v) {
					return v
				})("path", path0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start2)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end3))
			})
		}
	;
	_root['controllers']['Reports']['ownerReport'] =
		function (id1, start2, end3) {
			return _wA({
				method: "GET", url: "/ars/owner/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start2)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end3))
			})
		}
	;
	_root['controllers']['Reports']['detailedActivityReport'] =
		function (id1, start2, end3) {
			return _wA({
				method: "GET", url: "/ars/detailed_activity/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start2)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end3))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['spis'] =
		function (start0, end1) {
			return _wA({
				method: "GET", url: "/reports/spis/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end1))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['maintenanceReview'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/reports/mxReview/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['owner'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/owner/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['passenger'] =
		function (id0, start1, end2) {
			return _wA({
				method: "GET", url: "/reports/passenger/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['passengers'] =
		function (start1, end2) {
			return _wA({
				method: "GET", url: "/reports/passengers/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end2))
			})
		}
	;
	_root['controllers']['Reports']['expensesCSV'] =
		function (start0, end1) {
			return _wA({
				method: "GET", url: "/reports/expenses/csv/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end1))
			})
		}
	;
	_root['controllers']['Reports']['fuelUsageCSV'] =
		function (start0, end1) {
			return _wA({
				method: "GET", url: "/reports/fuel/csv/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end1))
			})
		}
    ;
  	_root['controllers']['Reports']['aircraftUsageCSV'] =
		function (start0, end1) {
			return _wA({
				method: "GET", url: "/reports/aircraft/csv/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end1))
			})
		}
	;
	_root['controllers']['Reports']['agingCSV'] =
		function () {
			return _wA({
				method: "POST", url: "/reports/aging/csv"
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['scheduledTripsCSV'] =
		function (start0) {
			return _wA({
				method: "GET", url: "/reports/scheduledTrips/csv/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['portsideCSV'] =
		function (start0) {
			return _wA({
				method: "GET", url: "/reports/portside/csv/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0))
			})
		}
	;
	_root['controllers']['Reports']['ownerSpecialReportCSV'] =
		function (id0, start1) {
			return _wA({
				method: "GET", url: "/reports/ownerSpecialReport/csv/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start1))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['greatPlainsExpensesCSV'] =
		function (start0, end1) {
			return _wA({
				method: "GET", url: "/reports/greatPlainsExpenses/csv/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end1))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['tripFinancialsCSV'] =
		function (start0, end1) {
			return _wA({
				method: "GET", url: "/reports/tripFinancials/csv/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end1))
			})
		}
	;
	_nS('controllers.Reports');
	_root['controllers']['Reports']['greatPlainsTripFinancialsCSV'] =
		function (start0, end1) {
			return _wA({
				method: "GET",
				url: "/reports/greatPlainsTripFinancials/csv/" + encodeURIComponent((function (k, v) {
					return v
				})("start", start0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("end", end1))
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['list'] =
		function (status, page, bucket) {
			return _wA({
				method: "GET",
				url: `/api/sms/${status}/${page}/${bucket}`
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['search'] =
		function () {
			return _wA({method: "POST", url: "/api/sms/search"})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['create'] =
		function () {
			return _wA({method: "POST", url: "/api/sms/event/create"})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_root['controllers']['SMS']['setWorkOrder'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/workOrder"
			})
		}
	;
	_root['controllers']['SMS']['getMXVendor'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/vendor"
			})
		}
	;
	_root['controllers']['SMS']['generateExternalId'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/vendor/generate"
			})
		}
	;
	_root['controllers']['SMS']['vendorLinkDeferral'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/mx-vendor/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/deferral"
			})
		}
	;
	_root['controllers']['SMS']['vendorLinkCorrective'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/mx-vendor/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/corrective"
			})
		}
	;
	_root['controllers']['SMS']['vendorLinkRII'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/mx-vendor/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/rii"
			})
		}
	;
	_root['controllers']['SMS']['removeExternalId'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/vendor/remove"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['review'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/ticket/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['saveOpening'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/opening"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['saveProcessing'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/processing"
			})
		}
	;
	_root['controllers']['SMS']['saveDeferred'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/deferred"
			})
		}
	;
	_root['controllers']['SMS']['saveRII'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/rii"
			})
		}
	;
	_root['controllers']['SMS']['saveMXVendorDeferral'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/vendor/deferral"
			})
		}
	;
	_root['controllers']['SMS']['saveMXVendorCorrective'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/vendor/corrective"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['saveAnalysis'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/analysis"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['saveReview'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/review"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['saveControl'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/control"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['saveResolution'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/resolution"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['changeStatus'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/changeStatus"
			})
		}
	;
	_root['controllers']['SMS']['promote'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/promote"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['demote'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/demote"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/sms/event/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['uploadImage'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/attachment"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['removeImage'] =
		function (id0, image1) {
			return _wA({
				method: "DELETE", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/remove/" + encodeURIComponent((function (k, v) {
					return v
				})("image", image1))
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['signatureRequest'] =
		function (id0, section1) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/signature/" + encodeURIComponent((function (k, v) {
					return v
				})("section", section1)) + "/request"
			})
		}
	;
	_nS('controllers.SMS');
	_root['controllers']['SMS']['signatureSign'] =
		function (id0, section1) {
			return _wA({
				method: "POST", url: "/api/sms/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/signature/" + encodeURIComponent((function (k, v) {
					return v
				})("section", section1)) + "/sign"
			})
		}
	;
	_nS('controllers.SafetyMeetings');
	_root['controllers']['SafetyMeetings']['list'] =
		function (page0) {
			return _wA({
				method: "GET", url: "/api/safetyMeeting/" + (function (k, v) {
					return v
				})("page", page0)
			})
		}
	;
	_nS('controllers.SafetyMeetings');
	_root['controllers']['SafetyMeetings']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/safetyMeeting/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.SafetyMeetings');
	_root['controllers']['SafetyMeetings']['save'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/safetyMeeting/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/edit"
			})
		}
	;
	_root['controllers']['SafetyMeetings']['create'] =
		function () {
			return _wA({
				method: "PUT", url: "/api/safetyMeeting"
			})
		}
	;
	_nS('controllers.SafetyMeetings');
	_root['controllers']['SafetyMeetings']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/safetyMeeting/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.SafetyMeetings');
	_root['controllers']['SafetyMeetings']['readyForMeeting'] =
		function () {
			return _wA({method: "GET", url: "/api/safetyMeeting/events"})
		}
	;
	_nS('controllers.SafetyMeetings');
	_root['controllers']['SafetyMeetings']['uploadAttachment'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/safetyMeeting/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/attachment"
			})
		}
	;
	_nS('controllers.SafetyMeetings');
	_root['controllers']['SafetyMeetings']['removeAttachment'] =
		function (id0, image1) {
			return _wA({
				method: "DELETE", url: "/api/safetyMeeting/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/remove/" + encodeURIComponent((function (k, v) {
					return v
				})("image", image1))
			})
		}
	;
	_nS('controllers.Customers');
	_root['controllers']['Customers']['index'] =
		function (letter0, page1) {
			if (letter0 === "A" && page1 === 1) {
				return _wA({method: "GET", url: "/api/customer/index"})
			}
			return _wA({
				method: "GET", url: "/api/customer/index/" + encodeURIComponent((function (k, v) {
					return v
				})("letter", letter0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("page", page1))
			})
		}
	;
	_nS('controllers.Customers');
	_root['controllers']['Customers']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/customer/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Customers');
	_root['controllers']['Customers']['edit'] =
		function () {
			return _wA({method: "POST", url: "/api/customer/edit"})
		}
	;
	_nS('controllers.Customers');
	_root['controllers']['Customers']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/customer/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Customers');
	_root['controllers']['Customers']['search'] =
		function () {
			return _wA({method: "POST", url: "/api/customer/search"})
		}
	;
	_root['controllers']['Customers']['merge'] =
		function () {
			return _wA({method: "POST", url: "/api/customer/merge"})
		}
	;
	_nS('controllers.Customers');
	_root['controllers']['Customers']['addImage'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/customer/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/image"
			})
		}
	;
	_nS('controllers.Customers');
	_root['controllers']['Customers']['removeImage'] =
		function (id0, image1) {
			return _wA({
				method: "DELETE", url: "/api/customer/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/image/" + encodeURIComponent((function (k, v) {
					return v
				})("image", image1))
			})
		}
	;
	_root['controllers']['Customers']['paxCSV'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/paxCSV"
			})
		}
	;
	_nS('controllers.Companies');
	_root['controllers']['Companies']['index'] =
		function (letter0, page1) {
			return _wA({
				method: "GET", url: "/api/company/index/" + encodeURIComponent((function (k, v) {
					return v
				})("letter", letter0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("page", page1))
			})
		}
	;
	_nS('controllers.Companies');
	_root['controllers']['Companies']['quickCreate'] =
		function () {
			return _wA({method: "POST", url: "/api/company/quick"})
		}
	;
	_nS('controllers.Companies');
	_root['controllers']['Companies']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/company/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Companies');
	_root['controllers']['Companies']['edit'] =
		function () {
			return _wA({method: "POST", url: "/api/company/edit"})
		}
	;
	_nS('controllers.Companies');
	_root['controllers']['Companies']['search'] =
		function () {
			return _wA({method: "POST", url: "/api/company/search"})
		}
	;
	_root['controllers']['Companies']['merge'] =
		function () {
			return _wA({method: "POST", url: "/api/company/merge"})
		}
	;
	_nS('controllers.Companies');
	_root['controllers']['Companies']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/company/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['index'] =
		function (id0, page1, active2) {
			if (id0 === 3 && page1 === 1 && active2 === true) {
				return _wA({method: "GET", url: "/api/admin"})
			}
			if (active2 === true) {
				return _wA({
					method: "GET", url: "/api/admin/" + encodeURIComponent((function (k, v) {
						return v
					})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
						return v
					})("page", page1))
				})
			}
			return _wA({
				method: "GET", url: "/api/admin/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("page", page1)) + "/" + encodeURIComponent((function (k, v) {
					return !!v
				})("active", active2))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['createUser'] =
		function () {
			return _wA({method: "GET", url: "/api/user/create"})
		}
	;
	_root['controllers']['Users']['changeEmail'] =
		function () {
			return _wA({method: "GET", url: "/api/user/changeEmail"})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['listUserRoles'] =
		function () {
			return _wA({method: "GET", url: "/api/user/roles/list"})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['search'] =
		function () {
			return _wA({method: "POST", url: "/api/user/search"})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['activate'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/activate"
			})
		}
	;
	_root['controllers']['Users']['exitPacket'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/exitPacket"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['deactivate'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/deactivate"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/delete"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getRecords'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/documents"
			})
		}
	;
	_root['controllers']['Users']['getPRIA'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/pria"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getBasicInfo'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/basicInfo"
			})
		}
	;
	_root['controllers']['Users']['getPrivileges'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/privileges"
			})
		}
	;
	_root['controllers']['Users']['savePrivileges'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/privileges"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getCertificate'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/certificate"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['saveCertificate'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/certificate"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['saveBasicInfo'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/basicInfo"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['manageDocuments'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/manage/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/documents"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['manageDuties'] =
		function (id0, page1) {
			if (page1 === 1) {
				return _wA({
					method: "GET", url: "/manage/" + encodeURIComponent((function (k, v) {
						return v
					})("id", id0)) + "/duties"
				})
			}
			return _wA({
				method: "GET", url: "/manage/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duties/" + encodeURIComponent((function (k, v) {
					return v
				})("page", page1))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['manageCertificate'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/manage/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/certificate"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['manageUserTypes'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/manage/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/userTypes"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['manageComments'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/manage/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/comments"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getDutyTimes'] =
		function (id0, page1) {
			if (page1 === 1) {
				return _wA({
					method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
						return v
					})("id", id0)) + "/duties"
				})
			}
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duties/" + encodeURIComponent((function (k, v) {
					return v
				})("page", page1))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getDutyTime'] =
		function (id0, log1) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duty/" + encodeURIComponent((function (k, v) {
					return v
				})("log", log1))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['saveDutyTime'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duty"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['deleteDutyTime'] =
		function (id0, log1) {
			return _wA({
				method: "DELETE", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duty/" + encodeURIComponent((function (k, v) {
					return v
				})("log", log1))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['erkDelete'] =
		function (id0, doc1) {
			return _wA({
				method: "DELETE", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/document/" + encodeURIComponent((function (k, v) {
					return v
				})("doc", doc1)) + "/delete"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['erkEdit'] =
		function (id0, doc1) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/document/" + encodeURIComponent((function (k, v) {
					return v
				})("doc", doc1))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['erkNew'] =
		function (kind0) {
			return _wA({
				method: "GET", url: "/api/erk/kind/" + encodeURIComponent((function (k, v) {
					return v
				})("kind", kind0))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['erkSave'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/document/save"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getEmailSettings'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/emailSettings"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['saveEmailSettings'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/emailSettings"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['validateEmail'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/verify"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getRatings'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/ratings"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['saveRatings'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/ratings"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getPilot'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/pilot"
			})
		}
	;
	_root['controllers']['Users']['getPilotNote'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/pilotNote"
			})
		}
	;
	_root['controllers']['Users']['savePilotNote'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/pilotNote"
			})
		}
	;
	_root['controllers']['Users']['getPilotCheckAirman'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/checkAirman"
			})
		}
	;
	_root['controllers']['Users']['savePilotCheckAirman'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/checkAirman"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['savePilot'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/pilot"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getAttendant'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/attendant"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['saveAttendant'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/attendant"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getCheckRides'] =
		function (id0, page1) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/checkRides/" + encodeURIComponent((function (k, v) {
					return v
				})("page", page1))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getCheckAirmanDocuments'] =
		function () {
			return _wA({method: "GET", url: "/api/erk/checkAirman/docs"})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['ical'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/calendar"
			})
		}
	;
	_root['controllers']['Users']['getiCalList'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/ical"
			})
		}
	;
	_root['controllers']['Users']['saveiCal'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/ical"
			})
		}
	;
	_root['controllers']['Users']['deleteiCal'] =
		function (id0, id1) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("craft", id0)) + "/ical/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id1))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getAircraftOwnerInfo'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/aircraftOwner"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['saveAircraftOwnerInfo'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/aircraftOwner"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['uploadImage'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/profile"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['removeImage'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/profile"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['changeOperation'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/operations/change/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getComments'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/comments"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['saveComments'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/comments"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['getRoles'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/roles"
			})
		}
	;
	_nS('controllers.Users');
	_root['controllers']['Users']['saveRoles'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/roles"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['create'] =
		function () {
			return _wA({method: "GET", url: "/operation/create"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['basicInfo'] =
		function () {
			return _wA({method: "POST", url: "/operation/basicInfo"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getBasicInfo'] =
		function () {
			return _wA({method: "GET", url: "/api/operation/basic"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['saveBasicInfo'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/basic"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getGeneralInfo'] =
		function () {
			return _wA({method: "GET", url: "/api/operation/general"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['saveGeneralInfo'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/general"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getFlightSettings'] =
		function () {
			return _wA({method: "GET", url: "/api/operation/flightSettings"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['saveFlightSettings'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/flightSettings"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['uiSettings'] =
		function () {
			return _wA({method: "POST", url: "/operation/ui"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['flightLogSettings'] =
		function () {
			return _wA({method: "POST", url: "/operation/flightLogs"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['quoteSettings'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/quote"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getUISettings'] =
		function () {
			return _wA({method: "GET", url: "/api/operation/uiSettings"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['saveUISettings'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/uiSettings"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getSPIs'] =
		function (active0) {
			return _wA({
				method: "GET", url: "/api/operation/spis" + _qS([(function (k, v) {
					return k + '=' + (!!v)
				})("active", active0)])
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getSPI'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/operation/spi/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['editSPI'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/spi"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['removeSPI'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/operation/spi/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getDispatchCategories'] =
		function (active0) {
			return _wA({
				method: "GET", url: "/api/dispatch/categories/" + encodeURIComponent((function (k, v) {
					return v ? "true" : "false"
				})("active", active0))
			})
		}
	;
	_root['controllers']['Operations']['getDispatchCategory'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/category/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_root['controllers']['Operations']['editDispatchCategory'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/category"})
		}
	;
	_root['controllers']['Operations']['removeDispatchCategory'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/dispatch/category/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getTCs'] =
		function (kind0) {
			if (kind0 === 0) {
				return _wA({method: "GET", url: "/api/operation/tcs"})
			}
			return _wA({
				method: "GET", url: "/api/operation/tcs/" + (function (k, v) {
					return v
				})("kind", kind0)
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getTC'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/operation/tcs/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['editTC'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/tcs"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['removeTC'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/operation/tcs/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['searchOffFleet'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/offFleet"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['editErk'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/erk/edit"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['deleteErk'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/operation/erk/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['uploadImage'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/operation/image/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/add"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['erk'] =
		function () {
			return _wA({method: "GET", url: "/operation/erk"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['saveAirportFee'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/airports/fees/edit"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['deleteAirportFee'] =
		function () {
			return _wA({method: "DELETE", url: "/api/operation/airports/fees"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getFBOFees'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/airports/fbo/fees"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['saveFBOFee'] =
		function () {
			return _wA({method: "POST", url: "/api/operation/airports/fbo/fees/edit"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['deleteFBOFee'] =
		function () {
			return _wA({method: "DELETE", url: "/api/operation/airports/fbo/fees"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getPreferredFBO'] =
		function (icao0) {
			return _wA({
				method: "GET", url: "/api/operation/airports/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0)) + "/preferredFBO"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['setPreferredFBO'] =
		function (icao0) {
			return _wA({
				method: "POST", url: "/api/operation/airports/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0)) + "/preferredFBO"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getCustomServices'] =
		function (icao0) {
			return _wA({
				method: "GET", url: "/api/operation/airports/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0)) + "/custom"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['saveCustomAirportData'] =
		function (icao0) {
			return _wA({
				method: "POST", url: "/api/operation/airports/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0)) + "/custom"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['removeCustomAirportData'] =
		function (icao0) {
			return _wA({
				method: "DELETE", url: "/api/operation/airports/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0)) + "/custom"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getAircraft'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/aircraft/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['editOffFleetAircraft'] =
		function () {
			return _wA({
				method: "POST", url: "/api/operation/offFleet/edit"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getAirportNote'] =
		function (icao0) {
			return _wA({
				method: "GET", url: "/api/operation/airports/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0)) + "/note"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['saveAirportNote'] =
		function (icao0) {
			return _wA({
				method: "POST", url: "/api/operation/airports/" + encodeURIComponent((function (k, v) {
					return v
				})("icao", icao0)) + "/note"
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['getErk'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/operation/erk/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['listAll'] =
		function () {
			return _wA({method: "GET", url: "/api/operations/list"})
		}
	;
	_nS('controllers.Operations');
	_root['controllers']['Operations']['removeImage'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/operation/image/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/remove"
			})
		}
	;
	_nS('controllers.AvinodeController');
	_root['controllers']['AvinodeController']['submitQuote'] =
		function () {
			return _wA({method: "POST", url: "/api/avinode/submit"})
		}
	;
	_nS('controllers.IEPs');
	_root['controllers']['IEPs']['list'] =
		function () {
			return _wA({method: "POST", url: "/api/iep/list"})
		}
	;
	_nS('controllers.IEPs');
	_root['controllers']['IEPs']['save'] =
		function () {
			return _wA({method: "POST", url: "/api/iep/save"})
		}
	;
	_nS('controllers.IEPs');
	_root['controllers']['IEPs']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/iep/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.WorkOrders');
	_root['controllers']['WorkOrders']['list'] =
		function () {
			return _wA({method: "POST", url: "/api/workOrder/all"})
		}
	;
	_root['controllers']['WorkOrders']['save'] =
		function () {
			return _wA({method: "POST", url: "/api/workOrder/save"})
		}
	;
	_root['controllers']['WorkOrders']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/workOrder/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_root['controllers']['WorkOrders']['search'] =
		function () {
			return _wA({method: "POST", url: "/api/workOrder/search"})
		}
	;
	_root['controllers']['WorkOrders']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/workOrder/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_root['controllers']['WorkOrders']['addFile'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/workOrder/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/file"
			})
		}
	;
	_root['controllers']['WorkOrders']['removeFile'] =
		function (id0, file1) {
			return _wA({
				method: "DELETE", url: "/api/workOrder/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/file/" + encodeURIComponent((function (k, v) {
					return v
				})("file", file1))
			})
		}
	;
	_root['controllers']['WorkOrders']['getForOperation'] =
		function () {
			return _wA({method: "POST", url: "/api/workOrder/ranged"})
		}
	;


	_nS('controllers.CAMPController');
  _root['controllers']['CAMPController']['getPreferences'] =
    function () {
      return _wA({ method: "GET", url: "/api/camp/preferences" })
    }
    ;
  _nS('controllers.CAMPController');
  _root['controllers']['CAMPController']['savePreferences'] =
    function () {
      return _wA({ method: "POST", url: "/api/camp/preferences" })
    }
    ;
  _nS('controllers.CAMPController');
  _root['controllers']['CAMPController']['syncAircraft'] =
    function () {
      return _wA({ method: "GET", url: "/api/camp/sync" })
    }
    ;
  _nS('controllers.CAMPController');
  _root['controllers']['CAMPController']['updateHoursAndCycles'] =
    function () {
      return _wA({ method: "GET", url: "/api/camp/update" })
    }
    ;
  _root['controllers']['CAMPController']['updateAircraftHoursAndCycles'] =
    function (id0) {
      return _wA({
        method: "GET",
        url: "/api/camp/update/" + encodeURIComponent((function (k, v) {
          return v
        })("id", id0))
      })
    }
    ;
  _root['controllers']['CAMPController']['retrieveAircraftDueList'] =
    function (id0) {
      return _wA({
        method: "GET",
        url: "/api/camp/dueList/" + encodeURIComponent((function (k, v) {
          return v
        })("id", id0))
      })
    }
    ;
  _nS('controllers.CAMPController');
  _root['controllers']['CAMPController']['retrieveDueLists'] =
    function () {
      return _wA({ method: "GET", url: "/api/camp/dueList" })
    }
    ;
  _root['controllers']['CAMPController']['retrieveTimes'] =
    function () {
      return _wA({ method: "GET", url: "/api/camp/times" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['auth'] =
    function () {
      return _wA({ method: "GET", url: "/intuit/auth" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['disconnect'] =
    function () {
      return _wA({ method: "GET", url: "/api/intuit/disconnect" })
    }
    ;
  _root['controllers']['IntuitController']['disconnectQWC'] =
    function () {
      return _wA({ method: "GET", url: "/api/intuit/disconnectQWC" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['getItems'] =
    function () {
      return _wA({ method: "GET", url: "/api/intuit/items" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['getPaymentMethods'] =
    function () {
      return _wA({ method: "GET", url: "/api/intuit/paymentMethods" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['getCustomers'] =
    function () {
      return _wA({ method: "POST", url: "/api/intuit/customers" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['get'] =
    function () {
      return _wA({ method: "GET", url: "/api/intuit/operation" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['edit'] =
    function () {
      return _wA({ method: "POST", url: "/api/intuit/operation" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['editQB'] =
    function () {
      return _wA({ method: "POST", url: "/api/intuit/operation/quickbooks" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['sendInvoice'] =
    function (id0) {
      return _wA({
        method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
          return v
        })("id", id0)) + "/invoice"
      })
    }
    ;
  _root['controllers']['IntuitController']['getInvoice'] =
    function () {
      return _wA({
        method: "POST", url: "/api/intuit/invoices/search"
      })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['sendDesktopInvoice'] =
    function (id0) {
      return _wA({
        method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
          return v
        })("id", id0)) + "/invoice/quickbooks"
      })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['customerQuery'] =
    function () {
      return _wA({ method: "POST", url: "/api/intuit/customers/query" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['itemsQuery'] =
    function () {
      return _wA({ method: "POST", url: "/api/intuit/items/query" })
    }
    ;
  _nS('controllers.IntuitController');
  _root['controllers']['IntuitController']['paymentMethodsQuery'] =
    function () {
      return _wA({ method: "POST", url: "/api/intuit/paymentMethods/query" })
    }
    ;
  _nS('controllers.FlightBridgeController');
  _root['controllers']['FlightBridgeController']['iframeUrl'] =
    function () {
      return _wA({ method: "POST", url: "/api/flightBridge/iframeUrl" })
    }
    ;
  _nS('controllers.FlightBridgeController');
  _root['controllers']['FlightBridgeController']['getServicesForTrip'] =
    function (id0) {
      return _wA({
        method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
          return v
        })("id", id0)) + "/flightBridge/services"
      })
    }
    ;


	_nS('controllers.FuelerLinxController');
	_root['controllers']['FuelerLinxController']['services'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/fuelerLinx/services"
			})
		}
	;
	_root['controllers']['FuelerLinxController']['externalLink'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/fuelerLinx"
			})
		}
	;
	_root['controllers']['FuelerLinxController']['auth'] =
		function () {
			return _wA({method: "GET", url: "/fuelerLinx/auth"})
		}
	;
	_root['controllers']['FuelerLinxController']['sendTrip'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/fuelerlinx/sendTrip"
			})
		}
	;


	_nS('controllers.MicrosoftController');
	_root['controllers']['MicrosoftController']['auth'] =
	  function () {
		return _wA({ method: "GET", url: "/microsoft/auth" })
	  }
	  ;
  
	_nS('controllers.ATPController');
	_root['controllers']['ATPController']['auth'] =
	  function () {
		return _wA({ method: "GET", url: "/atp/auth" })
	  }
	  ;
	_root['controllers']['ATPController']['getPreferences'] =
	  function () {
		return _wA({ method: "GET", url: "/api/atp/preferences" })
	  }
	  ;
	_root['controllers']['ATPController']['savePreferences'] =
	  function () {
		return _wA({ method: "POST", url: "/api/atp/preferences" })
	  }
	  ;
	_root['controllers']['ATPController']['syncAircraft'] =
	  function () {
		return _wA({ method: "GET", url: "/api/atp/sync" })
	  }
	  ;
	_root['controllers']['ATPController']['updateHoursAndCycles'] =
	  function () {
		return _wA({ method: "GET", url: "/api/atp/update" })
	  }
	  ;
  
	_root['controllers']['ATPController']['retrieveDueLists'] =
	  function () {
		return _wA({ method: "GET", url: "/api/atp/dueList" })
	  }
	  ;
	_root['controllers']['ATPController']['retrieveTimes'] =
	  function () {
		return _wA({
		  method: "G_root['controllers']['ATPController']['updateAircraftHoursAndCycles'] =\n" +
			"\t\tfunction (id0) {\n" +
			"\t\t\treturn _wA({\n" +
			"\t\t\t\tmethod: \"GET\",\n" +
			"\t\t\t\turl: \"/api/atp/update/\" + encodeURIComponent((function (k, v) {\n" +
			"\t\t\t\t\treturn v\n" +
			"\t\t\t\t})(\"id\", id0))\n" +
			"\t\t\t})\n" +
			"\t\t}\n" +
			"\t;ET", url: "/api/atp/times"
		})
	  }
	  ;

	_nS('controllers.Payments');
	_root['controllers']['Payments']['list'] =
		function (page0) {
			return _wA({
				method: "GET", url: "/api/payment/list/" + encodeURIComponent((function (k, v) {
					return v
				})("page", page0))
			})
		}
	;
	_nS('controllers.Payments');
	_root['controllers']['Payments']['delete'] =
		function (id0) {
			return _wA({
				method: "DELETE", url: "/api/dispatch/payment/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Payments');
	_root['controllers']['Payments']['get'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/payment/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Payments');
	_root['controllers']['Payments']['dispatch'] =
		function (id0) {
			return _wA({
				method: "GET", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/payment/all"
			})
		}
	;
	_nS('controllers.Payments');
	_root['controllers']['Payments']['addDocument'] =
		function (id0) {
			return _wA({
				method: "POST", url: "/api/dispatch/payment/document/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0))
			})
		}
	;
	_nS('controllers.Payments');
	_root['controllers']['Payments']['removeDocument'] =
		function (id0, image1) {
			return _wA({
				method: "DELETE", url: "/api/dispatch/payment/document/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/" + encodeURIComponent((function (k, v) {
					return v
				})("image", image1))
			})
		}
	;
	_nS('controllers.Payments');
	_root['controllers']['Payments']['edit'] =
		function () {
			return _wA({method: "POST", url: "/api/dispatch/payment/edit"})
		}
	;
	_nS('controllers.Assets');
	_root['controllers']['Assets']['at'] =
		function (file0) {
			return _wA({
				method: "GET", url: "/assets/" + (function (k, v) {
					return v
				})("file", file0)
			})
		}
	;

	_nS('controllers.RiskAuditController');
	_root['controllers']['RiskAuditController']['list'] = function () {
		return _wA({
			method: "GET", url: "/api/riskAudit/list"
		})
	}
	_root['controllers']['RiskAuditController']['save'] = function () {
		return _wA({
			method: "POST", url: "/api/riskAudit"
		})
	}
	_root['controllers']['RiskAuditController']['get'] = function (id) {
		return _wA({
			method: "GET", url: `/api/riskAudit/${id}`
		})
	}
	_root['controllers']['RiskAuditController']['delete'] = function (id) {
		return _wA({
			method: "DELETE", url: `/api/riskAudit/${id}`
		})
	}

	_nS('controllers.WidgetsController');
	_root['controllers']['WidgetsController']['timezone'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/timezone`
		})
	}
	_root['controllers']['WidgetsController']['recentFlights'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/recentFlights`
		})
	}
	_root['controllers']['WidgetsController']['departingSoon'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/departingSoon`
		})
	}
	_root['controllers']['WidgetsController']['onDuty'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/onDuty`
		})
	}
	_root['controllers']['WidgetsController']['pendingFlights'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/pendingFlights`
		})
	}
	_root['controllers']['WidgetsController']['tenths'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/tenths`
		})
	}
	_root['controllers']['WidgetsController']['upcomingInternational'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/upcomingInternational`
		})
	}
	_root['controllers']['WidgetsController']['requestToBook'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/requestToBook`
		})
	}
	_root['controllers']['WidgetsController']['campIntegration'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/campIntegration`
		})
	}
	_root['controllers']['WidgetsController']['atpIntegration'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/atpIntegration`
		})
	}
	_root['controllers']['WidgetsController']['tripConflict'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/tripConflict`
		})
	}
	_root['controllers']['WidgetsController']['aircraftCalendar'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/aircraftCalendar`
		})
	}
	_root['controllers']['WidgetsController']['pilotCalendar'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/pilotCalendar`
		})
	}
	_root['controllers']['WidgetsController']['crewAvailable'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/crewAvailable`
		})
	}
	_root['controllers']['WidgetsController']['offFleetCalendar'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/offFleetCalendar`
		})
	}
	_root['controllers']['WidgetsController']['aircraftStatus'] = function () {
		return _wA({
			method: "POST", url: `/api/widgets/aircraftStatus`
		})
	}
	_root['controllers']['WidgetsController']['assignedItems'] = function () {
		return _wA({
			method: "GET", url: `/api/widgets/assignedItems`
		})
	}

	_nS('controllers.AnalyticsController');
	_root['controllers']['AnalyticsController']['fuelUsage'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/fuelUsage`
		})
	}
	_root['controllers']['AnalyticsController']['aircraftUsage'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/aircraftUsage`
		})
	}
	_root['controllers']['AnalyticsController']['aging'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/aging`
		})
	}
	_root['controllers']['AnalyticsController']['tripsFinancial'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/tripsFinancial`
		})
	}
	_root['controllers']['AnalyticsController']['quotesCount'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/quotesCount`
		})
	}
	_root['controllers']['AnalyticsController']['expenses'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/expenses`
		})
	}
	_root['controllers']['AnalyticsController']['pilotDuty'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/pilotDuty`
		})
	}
	_root['controllers']['AnalyticsController']['tickets'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/tickets`
		})
	}
	_root['controllers']['AnalyticsController']['dutyTimes'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/dutyTimes`
		})
	}
	_root['controllers']['AnalyticsController']['cancelled'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/cancelled`
		})
	}
	_root['controllers']['AnalyticsController']['scheduledLegs'] = function () {
		return _wA({
			method: "POST", url: `/api/analytics/scheduledLegs`
		})
  }

_nS('controllers.ForeFlightController');
_root['controllers']['ForeFlightController']['getPreferences'] = function () {
  return _wA({ method: "GET", url: "/api/foreflight/preferences" })
}

_root['controllers']['ForeFlightController']['savePreferences'] = function () {
  return _wA({ method: "POST", url: "/api/foreflight/preferences" })
}
  _nS('controllers.Library')
  _root['controllers']['Library']['certification'] = function () {
    return _wA({ method: 'GET', url: '/api/manuals/certification' })
  }
  _nS('controllers.Library')
  _root['controllers']['Library']['documents'] = function () {
    return _wA({ method: 'GET', url: '/api/manuals/bulletins' })
  }
  _nS('controllers.Library')
  _root['controllers']['Library']['operation'] = function () {
    return _wA({ method: 'GET', url: '/api/manuals/operation' })
  }
  _nS('controllers.Library')
  _root['controllers']['Library']['mrd'] = function () {
    return _wA({ method: 'GET', url: '/api/manuals/mrd' })
  }
  _nS('controllers.Library')
  _root['controllers']['Library']['manuals'] = function (id0) {
    return _wA({
      method: 'GET',
      url:
        '/api/manuals/' +
        encodeURIComponent(
          (function (k, v) {
            return v
          })('id', id0)
        ),
    })
  }
  _nS('controllers.Library')
  _root['controllers']['Library']['mels'] = function () {
    return _wA({ method: 'GET', url: '/api/manuals/mel' })
  }

	return _root;
}