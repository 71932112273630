import React, {Fragment} from "react";
import Api from "../../../helpers/api";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import {Panel} from "../../../helpers/panel";
import {Currency} from "../../../helpers/formatters";
import Loading from "../../../helpers/loading/loading";
import "./style.scss";
import {InfoIcon} from "../../../icons/circle/info";

export default class Fuel extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {fuel: {}};
	}

	componentDidMount() {
		this.loadData();
	}

	componentWillUnmount() {
		if (this.call) this.call.abort();
	}

	loadData() {
		if (this.call) this.call.abort();
		this.call = new AbortController();
		this.setState({isLoading: true});
		Api.get(window.jsRoutes.controllers.Api.getFuel(this.props.id), this.call).then(result => {
			this.call = null;
			this.setState({fuel: result.fuel || {}});
		}).catch(Api.silentFail).then(()=>{
			this.setState({isLoading: false});
		})
	}

	render() {
		return <Panel title={this.props.id + " Fuel"} style={{position: "relative", minHeight: 100}}>
			<Loading active={this.state.isLoading}/>
			<div className="fuel-grid">
				{map(this.state.fuel || {}, (fbos, vendor) => {
					return <Fragment key={vendor}>
						<h6 className="vendor">{vendor.toUpperCase()}</h6>
						{map(fbos || {}, (prices, fbo) => {
							return <div key={fbo} className="passthru">
								<hr className="full"/>
								<div className="fbo-name">{fbo}</div>
								<div className="quantity">Qty.</div>
								<div className="price no-border">Price</div>
								{map(sortBy(prices || [], 'quantity'), i => {
									return <Fragment key={i.quantity + "-" + i.price}>
										<div className="quantity mono">{i.quantity}</div>
										<div className="price mono">{Currency(i.price)}</div>
										{(i.notes) ? <div className="info">
											<button onClick={() => {
												alert(i.notes)
											}} className="text-muted"><InfoIcon/></button>
										</div> : null}
									</Fragment>
								})}
							</div>;
						})}
					</Fragment>;
				})}
			</div>
		</Panel>
	}
}
