import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styles } from './common';

type AttachmentViewModalProps = {
  open: boolean
  onClose: () => void
  onSave: () => void
  children: React.ReactElement
  file?: string
}

const AttachmentViewModal: React.FC<AttachmentViewModalProps> = ({ open, onClose, onSave, children, file }) => {
  const isMobileView = useMediaQuery('(max-width:700px)');

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={[styles.modal, isMobileView ? styles.mobile : styles.desktop]}>
        <Box sx={styles.header}>
          <Box>Add Attachment</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </Box>
        <Box sx={styles.body}>
          {children}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}>
            <Button variant='contained' sx={styles.action} onClick={onClose}>Close</Button>
            <Button variant='contained' disabled={!file} sx={styles.action} onClick={onSave}>Save</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AttachmentViewModal;