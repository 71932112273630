import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import ZuluClock from '../../helpers/clock';
import React, { CSSProperties } from 'react';
import { CreateItems } from './constants';
import MenuButton from './menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { selectUser } from '../../data/userReducer';
import { useNavigate } from 'react-router';

type SettingProps = {
  anchorEl: SVGSVGElement | null;
  onSelect: (value: string) => void;
  onClose: () => void
  onOpenAirportClicked: () => void
  onMyAccountClicked: () => void
  onTripQuoteClicked: () => void
  onOpenLog: () => void
  onLogout: () => void
  onChangeNewOperation: () => void
  createNewOperation: () => void
}

const style: { [x: string]: CSSProperties | {} } = {
  iconStyle: {
    minWidth: '20px',
    marginRight: '10px'
  }
}
const Settings: React.FC<SettingProps> = ({ anchorEl, onSelect, onClose, onOpenAirportClicked, onMyAccountClicked, onTripQuoteClicked, onOpenLog, onLogout, onChangeNewOperation, createNewOperation }) => {
  const open = Boolean(anchorEl);
  const user = useSelector(selectUser);

  const onSelectValue = (value: string) => {
    onClose();
    setTimeout(() => {
      onSelect(value);
    }, 50);
  }

  return <Menu
    PaperProps={{
      style: {
        width: 250,
      },
    }}
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    TransitionComponent={Fade}
  >
    <MenuItem disabled>
      <ZuluClock />
    </MenuItem>
    <MenuItem>
      <MenuButton createBtnWidth='210px' label='Create' fullWidth endIcon={<KeyboardArrowDownIcon />} menuItems={CreateItems.menuItems} onSelect={onSelectValue} variant='contained' />
    </MenuItem>
    <MenuItem sx={{ opacity: 0.6 }} onClick={onOpenAirportClicked}>
      <FontAwesomeIcon icon={faPlane} style={style.iconStyle} />
      Airport Finder
    </MenuItem>
    <MenuItem sx={{ opacity: 0.6 }} onClick={onMyAccountClicked}>
      <FontAwesomeIcon icon={faUserAlt} style={style.iconStyle} />
      My Account
    </MenuItem>
    <MenuItem sx={{ opacity: 0.6 }} onClick={onChangeNewOperation}>
      <FontAwesomeIcon icon={faUserAlt} style={style.iconStyle} />
      Change Operation
    </MenuItem>
    <MenuItem sx={{ opacity: 0.6 }} onClick={createNewOperation}>
      <FontAwesomeIcon icon={faUserAlt} style={style.iconStyle} />
      Create New Operation
    </MenuItem>
    <MenuItem sx={{ opacity: 0.6 }} onClick={onOpenLog}>
      <FontAwesomeIcon icon={faListCheck} style={style.iconStyle} />
      Trip/Quote Logs of Deletes
    </MenuItem>
    <MenuItem sx={{ opacity: 0.6 }} onClick={onLogout}>
      <FontAwesomeIcon icon={faSignOutAlt} style={style.iconStyle} />
      Logout {user.fullName}
    </MenuItem>
  </Menu>
}

export default Settings;
