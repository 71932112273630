import {Fragment, useState} from "react";
import {Shelf} from "../layout/shelf";
import {TextInput} from "./textInput";
import Api from "./api";
import Form from "./form/form";
import PROLabel from "./label/label";
import PRODate from "./proDate";
import {RadioInput} from "./radioInput/radioInput";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Flex} from "./flex";
import {useSelector} from "react-redux";
import {selectUser} from "../data/userReducer";
import {UserType} from "./utils/userType";
import {usePopover} from "../hooks/usePopover";
import {PopoverFull} from "../layout/popover";
import Spinner from "./spinner";
import Well from "../layout/well";
import isEmpty from "lodash/isEmpty";
import {Envelope} from "../icons/envelope";
import {faCommentAltLines} from "@fortawesome/pro-light-svg-icons/faCommentAltLines";
import {PhoneIcon} from "../icons/phone";
import get from "lodash/get";
import isNumber from "lodash/isNumber";

export const SignatureButton = ({active=true, request, sign, note, onSave, className, buttonText})=>{
	const [popover, setPopover, clearPopover] = usePopover();

	const signature = ()=>{
		if (!active) return;
		setPopover(
			<SignatureRequest requestUrl={request} signUrl={sign} note={note} onClosed={clearPopover} onSave={onSave}/>
		)
	}

	return <Fragment>
		{popover}
		<button className={"pro-btn " + (className || "") + (active ? "" : " disabled")} disabled={!active} onClick={signature}>
			{buttonText || "Digitally Sign"}
		</button>
	</Fragment>
}

export const SignatureView = ({item}) => {
	return item ?
		<Flex className={"center stack-left"}>
			<div>Signed by:</div>
			<div className="bold">{item.name}</div>
			<div>from</div>
			<div className="bold">{item.id}</div>
			<div>on</div>
			<div>{new PRODate(item.date).format()}</div>
			<div className={"spacer"}/>
			<div><img alt={"LF Digital Signature Verification"} src={item.stamp} width="88" height="88"/>
			</div>
		</Flex>
		: false
}

export const SignatureInput = ({grid, fields, object, ...props}) => {
	const user = useSelector(selectUser);
	const notReady = (fields ?? []).map(field => {
		const test = get(object, field);
		return isEmpty(test) && !isNumber(test)
	}).reduce((a,b)=> a || b, false)

	return <PROLabel grid={grid} name={UserType.FAA.isExactly(user.userTypes) ?
		"Digital Signature"
			:
		<SignatureButton active={!notReady} {...props} />
	} value={notReady ?
		<div>You must save your data before signing.</div>
		:
		<SignatureView {...props} />
	}/>
}

export const SignatureRequest = ({requestUrl, signUrl, id, name, note, onSave, onClosed})=>{
	const [popover, setPopover, clearPopover] = usePopover();
	const [errors, setErrors] = useState([]);
	const [request, setRequest] = useState({
		status: 1,
		method: 1,
		id: id,
		name: name
	});

	const change = (key, value)=>{
		setRequest(p => {
			let state = cloneDeep(p);
			set(state, key, value);
			if (key === "method") {
				if (value === 3) {
					set(state, "phoneType", 1);
				} else if (value === 4) {
					set(state, "phoneType", 2);
				} else {
					set(state, "phoneType", null);
				}
			}
			return state;
		});
	}

	const sendRequest = (modal)=>{
		let state = cloneDeep(request);
		set(state, "id", state.id.toString());
		if (state.method !== 1) set(state, "password", null);
		setPopover(<SignatureProcessingFlow message={"We are preparing your unique code."} />);

		Api.post(requestUrl, state).then(Api.flagSuccess).then(r => {
			setErrors([]);
			if (r.completed) {
				onSave();
				modal.close();
			} else {
				change("status", 2);
			}
		}).catch(e => {
			console.error("request error", e);
			setErrors(e.json.errors || [e.json.message]);
		}).then(() => {
			clearPopover();
			modal.deactivate();
		})
	}

	const sendSign = (modal)=>{
		setPopover(<SignatureProcessingFlow message={"We are securing your transaction."} />);

		Api.post(signUrl, {
			id: request.id.toString(),
			name: request.name,
			code: request.code
		}).then(Api.flagSuccess).then(() => {
			setErrors([]);
			modal.close();
			onSave();
		}).catch(e => {
			console.error("sign error", e);
			setErrors(e.json.errors || [e.json.message]);
		}).then(() => {
			clearPopover();
			modal.deactivate();
		})
	}

	const action = request.status === 2 ? sendSign : sendRequest;
	const label = request.status === 2 ? "Verify" : "Sign";

	let fields;
	switch (request.method) {
		case 2:
			fields = [
				<TextInput key={"name"} name="Name" value={request.name} onChange={v => change("name", v)} autoComplete={"name"}/>,
				<TextInput key={"email"} name="Email" value={request.id} onChange={v => change("id", v)} autoComplete={"email"}/>
			];
			break;
		case 3:
			fields = [
				<TextInput key={"name"} name="Name" value={request.name} onChange={v => change("name", v)} autoComplete={"name"}/>,
				<TextInput key={"phone"} name="Phone" value={request.id} prepend={"+"} onChange={v => change("id", v)} process={TextInput.Whole} autoComplete={"phone"}  inputMode={"numeric"} pattern={"[0-9]*"}/>,
				<PROLabel key={"note"} value={"For US phone numbers remember to start with 1"}/>
			];
			break;
		case 4:
			fields = [
				<TextInput key={"name"} name="Name" value={request.name} onChange={v => change("name", v)} autoComplete={"name"}/>,
				<TextInput key={"phone"} name="Phone" value={request.id} prepend={"+"} onChange={v => change("id", v)} process={TextInput.Whole} autoComplete={"phone"}  inputMode={"numeric"} pattern={"[0-9]*"}/>,
				<PROLabel key={"note"} value={"For US phone numbers remember to start with 1"}/>
			];
			break;
		default:
			fields = [
				<TextInput key={"email"} name="Email" value={request.id} onChange={v => change("id", v)} autoComplete={"email"}/>,
				<TextInput key={"password"} type={"password"} autoComplete={"current-password"} name="Password" value={request.password} onChange={v => change("password", v)}/>,
			];
	}

	return <Shelf title="Digital Signature" saveLabel={label} save={action} onClosed={onClosed}
				  footer={<Fragment>
					  <div className="stretch"/>
					  <button tabIndex={0} type="button" className="pro-btn btn-cancel" onClick={() => {
						  change("status", 2);
					  }}>Already have a code?
					  </button>
				  </Fragment>}>
		{popover}
		<Form>
			<RadioInput name="Contact Method" value={request.method}
						options={[{"label": "LF User", "value": 1}, {
							"label": <div><Envelope /> Email</div>, "value": 2
						}, {
							"label": <div><FontAwesomeIcon icon={faCommentAltLines}/> SMS</div>,
							"value": 3
						}, {"label": <div><PhoneIcon /> Phone</div>, "value": 4}]}
						onChange={v => change("method", v)}/>
			{fields}
			{request.status === 2 ?
				<TextInput name="Code" value={request.code} onChange={v => change("code", v)} autoComplete={"one-time-code"} inputMode={"numeric"} pattern={"[0-9]*"}/>
			: undefined}
		</Form>
		{note ? <Well style={{marginTop:"var(--spacing)"}}>{note}</Well> : false}
		<div className={"text-danger"} style={{padding:"var(--spacing) 0 0",textAlign:"right"}}>{isEmpty(errors) ? false : errors.map((e,i) => <div key={i+e}>{e}</div>)}</div>
	</Shelf>
}

const SignatureProcessingFlow = ({message})=> {
	return <PopoverFull header={false} footer={false}>
		<div className={"text-center"}>
			<Spinner />
			<div>{message}</div>
		</div>
	</PopoverFull>
}