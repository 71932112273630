import {SimpleTooltip} from "../../helpers/tooltip";
import has from "lodash/has";
import {Exclamation} from "../../icons/circle/exclamation";

const QuoteUtils = {
	InputAlert: function (props) {
		return (has(props, "class")) ?
			<span className="input-group-addon">
                <SimpleTooltip tooltip={props.message}>
                    <Exclamation className={"text-" + props.class}/>
                </SimpleTooltip>
            </span> : false;
	},
	Alert: function (props) {
		return (has(props, "class")) ?
			<SimpleTooltip tooltip={props.message}>
				<Exclamation className={"text-" + props.class}/>
			</SimpleTooltip>
			: false;
  },
  isEmail: function (email) {
    return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(email)
  }
};

export default QuoteUtils;