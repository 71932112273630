import { faAsterisk, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import cloneDeep from "lodash/cloneDeep";
import React, { ChangeEvent, useEffect, useState } from "react";
import PROAddress from "../../helpers/address";
import Api from "../../helpers/api";
import SnackBar from "../../helpers/snackBar";
import { TabPanel, isValidEmail, styles } from "./common";
import { Asterisk } from "../../icons/new-asterisk";

type CompanyModalProps = {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  editCompanyId?: string;
};

const TABS = ["Basic Info", "Address", "Notes", "Finance"];

const initFormData = {
  name: "",
  email: "",
  phones: [],
  address: {},
  notes: "",
  wholesale: null,
};

const CompanyModal: React.FC<CompanyModalProps> = ({
  open,
  onCancel,
  onOk,
  editCompanyId
}) => {
  const [value, setValue] = useState<number>(0);
  // const isSmallerMobileView = useMediaQuery("(max-width:370px)");
  const isMobileView = useMediaQuery("(max-width:500px)");
  const isTabView = useMediaQuery("(max-width:700px)");
  const [formData, setFormData] = useState<any>(initFormData);
  const [isButtonDisabled, setButtonDisabled] = useState<any>(true);
  const [query] = useState("");
  // const [isQuerying, setIsQuerying] = useState(false);
  // const [customers, setCustomers] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [validateEmailMsg, setValidateEmailMsg] = useState<string>('');

  const initializeData = () => {
    setValue(0);
    let initFormDataCloned = cloneDeep(initFormData);
    setFormData(initFormDataCloned);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const saveCompany = () => {
    const fn = editCompanyId ? (window as any).jsRoutes.controllers.Companies.edit() : (window as any).jsRoutes.controllers.Companies.quickCreate()
    return Api.post(fn, { ...formData })
      .then(r => {
        // setIsQuerying(false);
        setFormData(r.company);
        return true;
      })
      .catch(() => {
        Api.silentFail();
        return false;
      });
  };

  const handleSave = () => {
    saveCompany().then(() => {
      onOk();
      setSnackBarOpen(true);
    });
    setButtonDisabled(true);
  };

  const handleFormDataChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const loadCompanyData = () => {
    let call = new AbortController();
    Api.get((window as any).jsRoutes.controllers.Companies.get(editCompanyId), call)
      .then(r => {
        const newFormData = { ...formData, ...r.company }
        setFormData(newFormData);
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }

  useEffect(() => {
    initializeData();
    if (!open) {
      setSnackBarOpen(false);
    }
  }, [open]);

  useEffect(() => {
    let msg = '';
    if (formData.email.length) {
      msg = isValidEmail(formData.email) ? '' : 'Please a enter valid email';
    }
    setButtonDisabled(!formData?.name || !formData?.email || !formData?.phones?.length || msg);
    setValidateEmailMsg(msg);
  }, [formData])

  useEffect(() => {
    let call = new AbortController();
    if (query.length > 1) {
      // setIsQuerying(true);
      Api.post((window as any).jsRoutes.controllers.IntuitController.getCustomers(), { query: query }, call)
        .then(r => {
          // setIsQuerying(false);
          // setCustomers(r.customers);
        })
        .catch(Api.silentFail);
    }
    return () => {
      call.abort()
    }
  }, [query]);

  useEffect(() => {
    if (!editCompanyId) {
      return;
    }
    loadCompanyData();
  }, [editCompanyId])

  const handleCloseSnackBar = () => setSnackBarOpen(false);

  return (
    <Modal open={open} onClose={onCancel}>
      <Box sx={[styles.modal, isTabView ? styles.mobile : styles.desktop]}>
        <SnackBar
          open={snackBarOpen}
          handleClose={handleCloseSnackBar}
          message='Save Company Success!'
          status='success'
        />
        <Box sx={styles.header}>
          <Box>{editCompanyId ? 'Edit Company' : 'Add Company'}</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onCancel} />
        </Box>
        <Box sx={styles.body}>
          <Tabs value={value} onChange={handleTabChange} scrollButtons={"auto"} variant="scrollable">
            {TABS.map((tab) => (
              <Tab key={tab} label={tab} id={`tab-${tab}`} sx={styles.tab} />
            ))}
          </Tabs>

          <TabPanel value={value} index={0} sx={{ pt: "1rem" }}>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>Name <Asterisk /> </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  name="name"
                  onChange={(e) =>
                    handleFormDataChange(e.target.name, e.target.value)
                  }
                  value={formData?.name}
                />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>Email <Asterisk /></Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  name="email"
                  placeholder='johndoe@example.com'
                  onChange={(e) =>
                    handleFormDataChange(e.target.name, e.target.value)
                  }
                  value={formData?.email}
                  error={!!validateEmailMsg}
                  helperText={validateEmailMsg}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0
                    }
                  }}
                />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, alignItems: "center", gap: isMobileView ? '4px' : '0px' }}>
              <Typography>Phone <Asterisk /></Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    handleFormDataChange('phones', [e.target.value])
                  }
                  value={formData?.phones?.length ? formData?.phones[0] : ''}
                />
              </FormControl>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} sx={{ pt: "1rem" }}>
            <PROAddress
              topLine={false}
              bottomLine={false}
              key={"Address"}
              address={formData?.address}
              onChange={(v: any) => handleFormDataChange("address", v)}
              hideLabel={true}
            />
          </TabPanel>
          <TabPanel value={value} index={2} sx={{ pt: "1rem" }}>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>Notes </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextareaAutosize
                  minRows={3}
                  name="notes"
                  style={{
                    width: "100%",
                    border: "1px solid rgb(133, 133, 133)",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                  onChange={(e) =>
                    handleFormDataChange(e.target.name, e.target.value)
                  }
                  value={formData?.notes}
                />
              </FormControl>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={3} sx={{ pt: "1rem" }}>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography sx={{ width: "150px !important" }}>
                FET Exempt{" "}
              </Typography>
              <RadioGroup
                row
                name={"wholesale"}
                onChange={(e) =>
                  handleFormDataChange(
                    e.target.name,
                    e.target.value === "true" ? true : false
                  )
                }
                value={formData?.wholesale}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio defaultChecked />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </Box>

            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>QuickBooks Desktop </Typography>
              <TextField
                value={formData?.intuit?.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleFormDataChange("intuit", {name: e.target.value});
                }}
              />
            </Box>
          </TabPanel>

          <Box sx={[styles.actionButtons, { mt: "2rem", justifyContent: 'end', flexDirection: 'row', alignItems: 'center' }]}>
            <Button
              variant="contained"
              sx={styles.action}
              onClick={onCancel}
            >
              Close
            </Button>

            <Button
              disabled={isButtonDisabled}
              variant="contained"
              sx={styles.action}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CompanyModal;
