import {useEffect, useState} from 'react';
import {Auth} from "@aws-amplify/auth";
import {DeleteMessageCommand, ReceiveMessageCommand, SQSClient} from "@aws-sdk/client-sqs";
import MoreOptions from "../../helpers/moreOptions";
import Api from "../../helpers/api";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import PRODate from "../../helpers/proDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {selectUser} from "../../data/userReducer";
import {faBells} from "@fortawesome/pro-solid-svg-icons/faBells";
import "./style.scss";
import {MenuItem} from "@mui/material";

const Notifications = () => {
	const [notifications, setNotifications] = useState([]);
	const [client, setClient] = useState();
	const [itr, setItr] = useState(0);
	const [refresh, setRefresh] = useState(0);
	const user = useSelector(selectUser);
	const url = `https://sqs.us-west-2.amazonaws.com/245211809793/${get(user, "_id.$oid")}`;

	const remove = (item) => {
		client.send(new DeleteMessageCommand({
			QueueUrl: url,
			ReceiptHandle: item.receipt,
		})).then(() => {
			setNotifications(list =>
				list.filter(i => i.id !== item.id)
			)
		})
	}

	useEffect(() => {
		setClient(new SQSClient({region: "us-west-2", credentials: Auth.currentCredentials()}));
	}, [refresh]);

	useEffect(() => {
		if (!client) return;
		const params = {
			QueueUrl: url,
			WaitTimeSeconds: 20,
			MaxNumberOfMessages: 1,
		}
		client.send(new ReceiveMessageCommand(params))
			.then(result => {
				const notifications = (result.Messages || []).map(p => {
					try {
						let data = JSON.parse(p.Body);
						data.id = p.MessageId;
						data.receipt = p.ReceiptHandle;
						data.timestamp = new PRODate(data.timestamp);
						return data;
					} catch (e) {
						return null;
					}
				});
				setItr(i => i + 1);
				if (notifications.length > 0) {
					setNotifications(not => uniqBy(not.concat(notifications), "id"));
				}
			})
			.catch(e => {
				console.error("Notifications::SQSError:", e);
				if (e.message.includes("expired")) {
					setRefresh(p => p + 1);
				}
			});
	}, [client, itr, url]);

	const newNotifications = filter(notifications, function (item) {
		return !item.read
	}).length;
	const badge = (newNotifications > 0) ? newNotifications : "";
	const sorted = sortBy(notifications, function (a, b) {
		return (get(a, "timestamp") || 0 > get(b, "timestamp") || 0) ? -1 : (a.timestamp === b.timestamp) ? 0 : 1;
	});
	return <MoreOptions key="notificationsList" containerClass={"row-one"} buttonStyle={{ minWidth: 'unset', padding: 'unset' }} label={<span className="fa-layers fa-fw no-print">
        <FontAwesomeIcon icon={faBells} />
        <span className="fa-layers-counter">{badge}</span>
    </span>} ignoreClicks={true}>
		{(notifications.length > 0) ? sorted.map((item) =>
				<NotificationsItem key={item.id} item={item} remove={()=> remove(item)}/>
			) :
			<MenuItem>You have no notifications.</MenuItem>
		}
	</MoreOptions>
}
export default Notifications;

const NotificationsItem = props => {
	const onClick = props.item.url ? () => {
		Api.changeUrl(props.item.url);
	} : null;

	return <MenuItem className="notification-bar-item">
		<button onClick={props.remove} type="button" className="close" aria-label="Close"><span
			aria-hidden="true">&times;</span></button>
		<div className="title" dangerouslySetInnerHTML={{__html: props.item.title}} onClick={onClick}/>
		<div className="message" dangerouslySetInnerHTML={{__html: props.item.message}} onClick={onClick}/>
		<div className="time">{props.item.timestamp.fromNow()}</div>
	</MenuItem>;
}