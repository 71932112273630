import {useRef, useState} from "react";
import Label from "../label/label";
import {TextAreaField} from "@aws-amplify/ui-react";
import { noop } from "lodash";

export const TextAreaLabel = ({value, name, rows, onChange, inputStyle, readOnly, onClick = noop, ...props}) => {
  return <Label {...props} name={name} value={<TextArea key={value} rows={rows} name={name} value={value} onChange={onChange} style={inputStyle} readOnly={readOnly}/>} onClick={onClick} />;
}

const TextArea = ({name, value, onChange, style, rows, className, readOnly}) => {
	const input = useRef();
	const [internalState, setInternalState] = useState(value);

	const change = (ev) => {
		setInternalState(ev.target.value);
	}

	const focus = () => {
		window.onbeforeunload = (ev)=>{
			ev.preventDefault();
			const message = "Are you sure you want to leave this page before saving?";
			ev.returnValue = message;
			return message;
		};
	}

	const blur = () => {
		window.onbeforeunload = null;
		if (internalState !== value) {
			onChange(internalState);
		}
	}

	return <TextAreaField ref={input} labelHidden={true} readOnly={readOnly} className={className} rows={rows} name={name} value={internalState} onChange={change} style={style} onFocus={focus} onBlur={blur} />;
}
export default TextArea;