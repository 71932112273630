import {PRODatePicker, PRODateTimePicker} from "./calendar";
import PROLabel from "./label/label";
import Columns from "./columns";

export const DateInput = ({value, append, onChange, debug, ...props})=>{
	let input = <PRODatePicker selected={value} onChange={onChange} debug={debug}/>;
	return <PROLabel {...props} value={(append) ?
		<Columns template={"1fr auto"}>
			{input}
			{append}
		</Columns> : input} />;
};

export const DateTimeInput = ({value, onChange, allowLocal, allowZulu, debug, ...props})=> {
  return <PROLabel {...props} style={{ alignItems: 'flex-start' }} value={<PRODateTimePicker selected={value} onChange={onChange} allowLocal={allowLocal} allowZulu={allowZulu} debug={debug} />} />;
};