import get from "lodash/get";
import includes from "lodash/includes";
import union from "lodash/union";
import uniqBy from "lodash/uniqBy";
import { useEffect, useState } from "react";
import Api from "../../helpers/api";
import PRODate from "../../helpers/proDate";
import { Pencil } from "../../icons/pencil";
import BasicTable from "../../layout/table/table";

const ReadyForMeeting = ({ onChange, className, style, ...props }) => {
  const [selected, setSelected] = useState(props.selected);
  const [events, setEvents] = useState(selected || []);

  const toggleEvent = (value) => {
    let index = selected.findIndex((item) => get(item, "_id.$oid") === get(value, "_id.$oid"));
    let state = [...selected];
    if (index >= 0) {
      state.splice(index, 1);
    } else {
      state.push(value);
    }
    onChange(state);
    setSelected(state);
  }

  useEffect(() => {
    const call = new AbortController();
    Api.get(window.jsRoutes.controllers.SafetyMeetings.readyForMeeting(), call)
      .then(response => {
        setEvents(events => uniqBy(union(events, response.events), (item) => item._id.$oid));
      })
      .catch(Api.silentFail);
    return () => {
      call.abort();
    }
  }, []);

  let selectedIds = selected.map((item) => get(item, "_id.$oid"));

  return <BasicTable className={className} style={style} template={"1fr auto auto"}
    headers={["Description", "Date", ""]}>
    {events.map(event =>
      <SMSEvent key={event._id.$oid} {...event} selected={includes(selectedIds, event._id.$oid)}
        onClick={() => toggleEvent(event)} openTicketModal={props.openTicketModal}/>
    )}
  </BasicTable>
}
export default ReadyForMeeting;

export const SMSEvent = props => {

  const view = (ev) => {
    ev.stopPropagation();
    if (window.confirm("Are you sure you want to move to this event?")) {
      props.openTicketModal(props._id.$oid);
    }
  }

  const truncate = (str = '', count) => {
    if (str.length < count) {
      return str;
    }
    let sub = `${str}`.substring(0, count);
    return sub.substring(0, sub.lastIndexOf(" ")) + "...";
  }

  return <div className={(props.selected ? "active " : "") + "row passthru"} onClick={props.onClick}>
    <div className="cell text-overflow">{truncate(props.description, 150)}</div>
    <div className="cell mono no-wrap">{new PRODate(props.eventDate).format("MMM DD, yyyy")}</div>
    <div className={"cell"}>
      <button onClick={view}>
        <Pencil />
      </button>
    </div>
  </div>
}