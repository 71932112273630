import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import AirportInput from '../../helpers/airportInput';
import PROLabel from '../../helpers/label/label';
import { AirportInformation } from '../airportInformation';
import { useState } from 'react';
import { styles } from './common';
import { isEmpty } from 'lodash';

type AirportBrowserModalProps = {
  open: boolean
  onCancel: () => void
  onOk: () => void
}

const AirportBrowserModal: React.FC<AirportBrowserModalProps> = ({ open, onCancel, onOk }) => {
  const [icao, setIcao] = useState();
  const isMobileView = useMediaQuery('(max-width:700px)');

  return (
    <Modal
      open={open}
      onClose={onCancel}
    >
      <Box sx={[styles.modal, isMobileView ? styles.mobile : styles.desktop, { minHeight: '400px' }]}>
        <Box sx={styles.header}>
          <Box>Airport Browser</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onCancel} />
        </Box>
        <Box sx={styles.body}>
          <PROLabel name="Airport" value={<AirportInput key={icao} icao={icao} onChange={(airport: any) =>
            setIcao(airport.icao)
          } />} style={undefined} error={undefined} addendum={undefined} className={undefined} grid={undefined} groupClass={undefined} labelClass={undefined} />
          {isEmpty(icao) ? undefined : <AirportInformation icao={icao} />}
        </Box>
      </Box>
    </Modal>
  )
}

export default AirportBrowserModal;