import styles from "./style.module.scss";
import { renderToStaticMarkup } from "react-dom/server";

const notify = {
  success: function (title, message) {
    this.show(title, message, 'success');
  },
  danger: function (title, message) {
    this.show(title, message, 'danger');
  },
  warning: function (title, message) {
    this.show(title, message, 'warning');
  },
  info: function (title, message) {
    this.show(title, message, 'info');
  },
  show: function (title, message, clazz) {
    clearTimeout(this.timer);
    if (message instanceof Error) {
      message = message.message;
    }
    if (message.toLowerCase().includes("fetch")) {
      return
    }
    if (message.toLowerCase().includes("aborted")) {
      return
    }
    this.timer = setTimeout(this.hide, 5000);
    let list = [...document.getElementById("shelfHolder").getElementsByClassName("notification-container"), ...document.getElementById("modalHolder").getElementsByClassName("notification-container")];
    let modalContainer = (list.length > 0) ? list[list.length - 1] : null;
    let container = (modalContainer) ? modalContainer : document.getElementsByClassName("notification-container")[0];
    if (container) {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    }
    const item = <div className={styles.notificationItem + " " + clazz}>
      <strong>{title}</strong>
      <span dangerouslySetInnerHTML={{ __html: message }} />
    </div>
    if (container) {
      container.innerHTML = renderToStaticMarkup(item);
    }
    // createPortal(item, container);
  },
  hide: function () {
    clearTimeout(this.timer);
    let items = document.getElementsByClassName(styles.notificationItem);
    for (let i = 0; i < items.length; i++) {
      if (items[i]) {
        items[i].classList.add("hide");
      }
    }
  },
  timer: null
};

export default notify;