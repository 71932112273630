/* eslint-disable react/display-name */
import {Box, Button, Checkbox, FormControlLabel, Modal, Stack, Typography} from "@mui/material";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {createPortal} from "react-dom";

export const ConfirmType = {
	Checkbox : 0,
	Plain : 1,
	Alert : 2
}

export const Confirm = forwardRef(({title, message, label="Delete", color="error", onConfirm, onClosed, type=ConfirmType.Checkbox}, ref)=>{
	const selfRef = useRef();
	const modal = useRef();
	const [target, setTarget] = useState(document.getElementById("modalHolder"));
	const [disabled, setDisabled] = useState(type === ConfirmType.Checkbox);
	const _ref = ref || selfRef;

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	useEffect(()=>{
		if (!target) {
			setTarget(document.getElementById("modalHolder"));
		}
	}, [target])

	useImperativeHandle(_ref, () => ({
		close: () => {
			onClosed?.();
		}
	}), [onClosed])

	return createPortal(<Modal ref={modal} open={true} onClose={()=>{ _ref.current.close() }}>
		<Box sx={style}>
			<Typography variant="h6" component="h2">{title}</Typography>
			<Typography sx={{ mt: 2 }}>{message}</Typography>
			{type===ConfirmType.Checkbox ? <FormControlLabel control={<Checkbox onChange={(v)=> {
				setDisabled(!v.target.checked);
			}} />} label={label} /> : <br/>}
			<Stack direction="row-reverse" spacing={2}>
				{label === false ? false : <Button variant={disabled ? "outlined" : "contained"} color={color} disabled={disabled} onClick={type === ConfirmType.Alert ? ()=> _ref.current.close() : disabled ? null : ()=> onConfirm?.(_ref.current)}>{label}</Button>}
				{type === ConfirmType.Alert ? null : <Button variant="outlined" onClick={()=> _ref.current.close()}>Cancel</Button>}
			</Stack>
		</Box>
	</Modal>, target)
})