import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";
import extend from "lodash/extend";
import set from "lodash/set";
import uniqueId from "lodash/uniqueId";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { usePopover } from "../../hooks/usePopover";
import { CheckCircle } from "../../icons/circle/check";
import { Exclamation } from "../../icons/circle/exclamation";
import { PlusCircle } from "../../icons/circle/plus";
import { Times } from "../../icons/circle/times";
import { Pencil } from "../../icons/pencil";
import { SpinnerIcon } from "../../icons/spinner";
import { PopoverFull } from "../../layout/popover";
import { Shelf } from "../../layout/shelf";
import AttachmentViewModal from "../../pages/modal/attachment";
import { GeneralRoutes } from "../../routes/general";
import Api from "../api";
import { Flex } from "../flex";
import Form from "../form/form";
import { TextAreaLabel } from "../textArea";
import { TextInput } from "../textInput";
import "./style.scss";

// eslint-disable-next-line react/display-name
export const PROUpload = forwardRef(({ style, meta = {}, onComplete = () => {
  console.info("No onComplete handler specified for upload.")
}, onProgress = () => {
  console.info("No onProgress handler specified for upload.")
} }, ref) => {
  const fetchPresignedUrl = (file, submit) => {
    const _meta = extend(meta, {
      filename: file.name,
    });
    Api.post(window.jsRoutes.controllers.Api.generatePresignedUrl(), _meta)
      .then(response => {
        let xhr = new XMLHttpRequest();
        xhr.open("PUT", response.url);
        for (const k in meta) {
          if (_meta[k]) {
            // eslint-disable-next-line
            xhr.setRequestHeader("x-amz-meta-" + k, _meta[k].replace(/[^\x00-\x7F]/g, ""));
          }
        }
        submit(xhr, response.id);
      })
      .catch(Api.flagFail);
  }

  return <SimpleUpload ref={ref} onFileSelected={fetchPresignedUrl} onProgress={onProgress}
    onComplete={onComplete} style={style} />
})

// eslint-disable-next-line react/display-name
export const SimpleUpload = forwardRef(({ grid, style, onFileSelected, onProgress, onComplete }, ref) => {
  const [viewState, setViewState] = useState(SimpleUpload.ViewState.None);
  const file = useRef();
  const [classes, setClasses] = useState();
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("Click or drag a file here.");
  const fallback = useRef();
  ref = ref || fallback;

  const fileSelected = (e) => {
    file.current = e.target.files[0];
    onFileSelected(file.current, submit);
  }

  const fileDropped = (e) => {
    e.preventDefault();
    file.current = e.dataTransfer.files[0];
    onFileSelected(file.current, submit);
    setClasses(null);
  }

  const submit = (xhr, id) => {
    if (xhr.upload) {
      xhr.upload.onload = () => {
        setViewState(SimpleUpload.ViewState.Loading);
        setMessage("File uploaded. Now to process it...");
      };
      xhr.upload.onprogress = e => {
        setProgress(e.loaded / e.total);
        setViewState(SimpleUpload.ViewState.Loading);
        onProgress(e.loaded / e.total);
      };
      xhr.upload.onerror = function (a) {
        setViewState(SimpleUpload.ViewState.Error);
        setMessage("An upload error occurred");
        console.error(a);
      };
    } else {
      xhr.onload = () => {
        setViewState(SimpleUpload.ViewState.Loading);
        setMessage("File uploaded. Now to process it...");
      };
      xhr.onprogress = e => {
        setProgress(e.loaded / e.total);
        setViewState(SimpleUpload.ViewState.Loading);
        onProgress(e.loaded / e.total);
      };
      xhr.onerror = function (a) {
        setViewState(SimpleUpload.ViewState.Error);
        setMessage("An upload error occurred");
        console.error(a);
      };
    }
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        setViewState(SimpleUpload.ViewState.Success);
        onComplete(id, xhr.responseText);
        setMessage("Your upload is done!");
      }
    };
    xhr.send(file.current);
  }

  useImperativeHandle(ref, () => ({
    setViewState: (state, message) => {
      setViewState(state);
      setMessage(message);
    },
  }), [])

  const percent = (progress * 100).toFixed(0);

  let showProgress = (percent > 0) ? (percent >= 100) ? "" : percent : false;
  let messageClass = "";
  switch (viewState) {
    case SimpleUpload.ViewState.Loading:
      showProgress = <SpinnerIcon className="text-warning" spin />;
      break;
    case SimpleUpload.ViewState.Success:
      messageClass = "text-success"
      showProgress = <CheckCircle className="text-success" />;
      break;
    case SimpleUpload.ViewState.Error:
      messageClass = "text-danger";
      showProgress = <Exclamation className="text-danger" />;
      break;
    default:
      break;
  }

  const circle = 22 * 2 * Math.PI;
  const unique = uniqueId("file_");

  return <div className={(grid ? "full" : "full-width") + " uploader"}
    style={Object.assign({ width: "100%", marginTop: "var(--spacing)", marginBottom: "var(--spacing)" }, style)}>
    <div className={"drop-area " + (classes || "")}
      onDragEnter={() => {
        setClasses("highlight")
      }}
      onDragOver={(ev) => {
        ev.preventDefault();
        setClasses("highlight")
      }}
      onDragLeave={() => {
        setClasses(null)
      }}
      onDrop={fileDropped}>
      <input id={unique} name="file" type="file" onChange={fileSelected} />
      <label htmlFor={unique} className={messageClass}>{message}</label>
      <label htmlFor={unique}>
        {progress > 0 ? <div className="progress-circle">
          <svg className="progress-circle-bag" height="50" width="50">
            <circle cx="25" cy="25" r="20" stroke="#555" strokeWidth="1" fill="none" />
          </svg>
          <svg className="progress-circle-over" height="50" width="50">
            <circle cx="25" cy="25" r="22" stroke="#5cb85c" strokeWidth="5" fill="none"
              strokeDasharray={1000} strokeDashoffset={1000 - (circle * progress)} />
          </svg>
          <div className="progress-circle-label">{showProgress}</div>
        </div> : undefined}
      </label>
    </div>
  </div>
});
SimpleUpload.ViewState = {
  None: 0,
  Loading: 1,
  Error: 2,
  Success: 3,
};

export const AttachmentsView = ({ items, label, placeholder, allowEdit, meta, className, style, grid, onSave, onDelete, newModal }) => {
  const [popover, setPopover, clearPopover] = usePopover();
  const [overrides, setOverrides] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const showModal = () => {
    if (newModal) {
      setOpenModal(true);
    } else {
      setPopover(<PROUploadModal meta={meta} onSave={onSave} onClosed={clearPopover} showAsPopover={false}/>)
    }
  }

  const onEdit = (item) => {
    setPopover(<EditNote key={item.id} item={item} onClosed={clearPopover} onSave={onSave} />)
  }

  return <div className={(className || "border") + (grid ? " full" : "")} style={style}>
    {newModal ? <PROUploadModal meta={meta} onSave={(modal, obj) => {
      onSave(modal, obj);
      setOpenModal(false);
    }} onClosed={() => setOpenModal(false)}
      open={openModal}
      newModal={newModal}
    /> : popover }
    {(items || []).length === 0 ? <p className={"text-center"}>{placeholder}</p> : undefined}
    <Flex className="stack-left" style={{ marginTop: "var(--spacing)" }}>
      {compact(items || []).map((item) => {
        return <div key={item.id} className="attachments-item">
          <button className="remove" onClick={v => onDelete(item, v)}>
            <Times className="badgify" />
          </button>
          {item.file ?
            <div className={"background-gray"}>We will upload on save.</div>
            :
            <a href={GeneralRoutes.image(item.id).absoluteURL(true)} target="_blank" rel="noopener noreferrer">
              <img alt={"Uploaded"}
                src={(overrides[item.id] || GeneralRoutes.thumb(item.id).absoluteURL(true))}
                className="img-responsive" onError={() => {
                  let state = cloneDeep(overrides);
                  set(state, item.id, GeneralRoutes.thumb("pdf_thumbnail").absoluteURL(true));
                  setOverrides(state);
                }} />
            </a>
          }
          <p className="text-center">
            {item.note}
            {allowEdit ?
              <button onClick={() => onEdit(item)} style={{ marginLeft: "var(--spacing)" }}>
                <Pencil className="badgify" />
              </button> : undefined}
          </p>
        </div>
      })}
    </Flex>
    <button className="pro-btn btn-block" onClick={showModal}><PlusCircle />&nbsp;{label || "Add Attachments"}</button>
  </div>
}

const PROUploadModal = ({ meta, onSave, onClosed, showAsPopover, open, newModal = false }) => {
  const modal = useRef();
  const [note, setNote] = useState("");
  const [file, setFile] = useState();

  const save = () => {
    onSave(modal.current, { note, file });
  }

  const uploadComplete = (id) => {
    modal.current?.enable();
    setFile(id);
  }

  const uploadProgress = () => {
    modal.current?.disable();
  }

  useEffect(() => {
    if (modal.current && !file) {
      modal.current.disable();
    }
  }, [file]);

  return newModal ?
    <AttachmentViewModal open={open} onSave={save} onClose={onClosed} file={file}>
      <PROUpload meta={meta} onComplete={uploadComplete} onProgress={uploadProgress} />
      <TextAreaLabel key={note} name="Note" value={note} onChange={obj => setNote(obj)} />
    </AttachmentViewModal>
    :
      showAsPopover ?
        <PopoverFull ref={modal} title="Add Attachment" onClosed={onClosed} save={save}>
          <PROUpload meta={meta} onComplete={uploadComplete} onProgress={uploadProgress} />
          <TextAreaLabel key={note} name="Note" value={note} onChange={(obj) => {
            setNote(obj)
          }} />
        </PopoverFull>
        :
        <Shelf ref={modal} title="Add Attachment" onClosed={onClosed} save={save}>
          <PROUpload meta={meta} onComplete={uploadComplete} onProgress={uploadProgress} />
          <TextAreaLabel key={note} name="Note" value={note} onChange={(obj) => {
            setNote(obj)
          }} />
        </Shelf>
}

export const PROUploadDisplay = ({id, note, noteStyle, style, className, remove, small, onError=ev => {
	const self = ev.target;
	self.src = "https://api.levelflight.com/api/image/pdf_thumbnail/thumb";
	self.onError = null;
}}) => {
	return <div className={className} style={{...style, position: "relative"}}>
		<button className="text-muted" style={{position: "absolute", right: 0, top: -10}} onClick={remove}>
			<Times />
		</button>
		<a href={GeneralRoutes.image(id).absoluteURL(true)} target="_blank"
		   rel="noopener noreferrer">
			<img alt={"Thumbnail"}
				 src={GeneralRoutes.thumb(id).absoluteURL(true)}
         style={{maxWidth: '100px'}}
				 className={small ? "thumbnail-small" : "thumbnail"} onError={onError}/>
		</a>
		<p className={"text-center"} style={noteStyle}>{note}</p>
	</div>
};

const EditNote = ({ item, onSave, onClosed }) => {
  const [_item, setItem] = useState(item);

  return <Shelf title={"Edit File Note"} save={modal => {
    onSave(modal, { file: _item.id, note: _item.note });
  }} onClosed={onClosed}>
    <Form>
      <TextInput name={"Note"} value={_item.note} onChange={note => {
        const newItem = cloneDeep(_item);
        set(newItem, "note", note);
        setItem(newItem)
      }} />
    </Form>
  </Shelf>
}
