import get from "lodash/get";
import find from "lodash/find";
import isFunction from "lodash/isFunction";
import isNumber from "lodash/isNumber";
import PROLabel from "../label/label";
import Columns from "../columns";
import compact from "lodash/compact";
import isObject from "lodash/isObject";
import isNil from "lodash/isNil";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export const Dropdown = ({options=[], name, valueNode="_id.$oid", valueOnly, onChange=function () {
	console.error("No onChange handler.");
}, allowNull, value, labelNode="name", className, style, autoWidth, sx, groupClass}) => {
	const change = (ev) => {
		let value = ev.target.value;
		let item = find(options, (item) => {
			const a = isFunction(valueNode) ? valueNode.apply(item) : get(item, valueNode);
			const b = isNumber(a) ? parseInt(value) : value;
			return a === b;
		});
		if (item) {
			if (valueOnly) {
				item = isFunction(valueNode) ? valueNode.apply(item) : get(item, valueNode)
			}
			onChange(item);
		} else {
			if (allowNull) {
				onChange(null);
			}
		}
	}

	let selected = (valueOnly) ? value : isFunction(valueNode) ? valueNode.apply(value) : get(value, valueNode);

	return <FormControl {...(autoWidth ?? sx ? {} : {fullWidth:true})} sx={sx} size={"small"} className={groupClass} style={{margin:0}}>
		<InputLabel>{name}</InputLabel>
		<Select autoWidth={autoWidth} className={className} style={style} label={name} value={isNil(selected) ? value || "" : selected} onChange={change}>
			{options.map(item => {
				let value = isFunction(valueNode) ? valueNode.apply(item) : get(item, valueNode);
				let label = isFunction(labelNode) ? labelNode.apply(item) : get(item, labelNode);
				return <MenuItem key={isObject(value) ? JSON.stringify(value) : value} value={value}>{label}</MenuItem>
			})}
		</Select>
	</FormControl>
}

Dropdown.Formatter = {
	UserName: function () {
		const last = (this.lastName || "").trim();
		const first = (this.firstName || "").trim();
		const middle = (this.middleName || "").trim();
		let _f = `${first} ${middle}`.trim();
		return compact([last.length > 0 ? last : null, _f.length > 0 ? _f : null]).join(", ")
	},
	Aircraft: function () {
		return `${this.tailNumber || "Unknown"} - ${this.type?.name || ""}`
	},
	Component: function () {
		return `${this.manufacturer || ""} - ${this.model || ""} - ${this.serial || ""}`
	},
	Expense: function () {
		return `${this.code || ""} - ${this.name || ""}`;
	}
};

/**
 * options: List[{id:any, value:String}]
 */
export const DropdownInput = (props) => {
	let input = <Dropdown key={props?.options?.length} options={props.options} value={props.value} onChange={props.onChange}
						  valueNode={props.valueNode} labelNode={props.labelNode} valueOnly={props.valueOnly}
						  allowNull={props.allowNull} />;
	return <PROLabel {...props} value={
		(props.append) ?
			<Columns template={"1fr auto"}>{input}{props.append}</Columns>
			:
			input
	} />
};
DropdownInput.defaultProps = {valueNode: "id", labelNode: "value", valueOnly: true};
