import {selectViewingUser, setUser} from "../viewingUserReducer";
import {selectUser} from "../userReducer";
import {selectOperation} from "../operationReducer";
import {selectOption, toggle} from "../optionsReducer";
import {selectLeg, selectTrip} from "../tripReducer";

export const userMapDispatchToProps = (dispatch, _) => {
	return {
		setUser: (user) => {
			dispatch(setUser(user));
		}
	}
};

export const optionsMapDispatchToProps = (dispatch, _) => {
	return {
		toggle: (id) => {
			dispatch(toggle(id));
		}
	}
};

export const userMapStateToProps = state => {
	return {
		loggedInUser: selectUser(state),
		user: selectViewingUser(state)
	}
};

export const loggedInUserMapStateToProps = state => {
	return {
		loggedInUser: selectUser(state)
	}
};

export const legMapStateToProps = (state, {tripId, legId}) => {
	return {
		activeTrip: selectTrip(tripId)(state),
		activeLeg: selectLeg(tripId, legId)(state)
	}
};

export const operationMapStateToProps = state => {
	return {
		operation: selectOperation(state)
	}
};

export const optionsMapStateToProps = (id, state) => {
	return {
    useNewFeatures: selectOption(id)(state),
    showCalendar: selectOption(id)(state),
    showM2M: selectOption(id)(state)
	}
};