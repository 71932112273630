/* eslint-disable react/display-name */
import React, {Fragment} from "react";
import SimpleInput from "./simpleInput";
import PROLabel from "./label/label";
import { Box, useMediaQuery } from "@mui/material";

export const TextInput = React.forwardRef((props, ref) => {
  const isMobileView = useMediaQuery("(max-width:500px)");

	let input = <SimpleInput key={props.value} ref={ref} type={props.type} name={props.name} value={props.value}
							 onChange={props.onChange} preprocess={props.process.In} postprocess={props.process.Out}
							 prepend={props.prepend} append={props.append} onCharacter={props.onCharacter}
							 onEnter={props.onEnter} autoComplete={props.autoComplete} disabled={props.disabled}
							 style={props.style} inputStyle={props.inputStyle} />;

  const Wrapped = isMobileView ? Box : Fragment;
	return <Wrapped><PROLabel grid={props.grid} error={props.error} name={props.label || props.name} hideLabel={props.hideLabel} value={<Fragment>
		{input}
		{props.errors ? <span className="errors">{props.errors}</span> : false}
	</Fragment>} style={props.style} required={props.required}/></Wrapped>
});

TextInput.Normal = {
	In: function (value) {
		return value;
	},
	Out: function (value) {
		return value;
	}
};

TextInput.Currency = {
	/**
	 * @return {string}
	 */
	In: function (value) {
		try {
			let base = value.toString().replace(",", "");
			let d = Math.min(100, Math.pow(10, (base.split(".")[1] || "").length));
			let v = Number.parseInt(base.replace(".", ""));
			let out = (v / d).toFixed(2);
			return Number.isNaN(out) ? undefined : out;
		} catch (e) {
			return undefined;
		}
	},
	/**
	 * @return {number}
	 */
	Out: function (value) {
		try {
			const out = Number.parseFloat(value);
			return Number.isNaN(out) ? undefined : out;
		} catch (e) {
			return undefined;
		}
	}
};

TextInput.Tenth = {
	/**
	 * @return {string}
	 */
	In: function (value) {
		try {
			let d = Math.min(10, Math.pow(10, (value.toString().split(".")[1] || "").length));
			let v = Number.parseInt(value.toString().replace(".", ""));
			let out = (v / d).toFixed(1);
			return Number.isNaN(out) ? undefined : out;
		} catch (e) {
			return undefined;
		}
	},
	/**
	 * @return {number}
	 */
	Out: function (value) {
		try {
			const out = Number.parseFloat(value);
			return Number.isNaN(out) ? undefined : out;
		} catch (e) {
			return undefined;
		}
	}
};

TextInput.Whole = {
	/**
	 * @return {number}
	 */
	In: function (value) {
		try {
			const out = Number.parseInt(value);
			return Number.isNaN(out) ? undefined : out;
		} catch (e) {
			return undefined;
		}
	},
	/**
	 * @return {number}
	 */
	Out: function (value) {
		try {
			const out = Number.parseInt(value);
			return Number.isNaN(out) ? undefined : out;
		} catch (e) {
			return undefined;
		}
	}
};

TextInput.defaultProps = {process: TextInput.Normal, type: "text"};
