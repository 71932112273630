/* eslint-disable react/display-name */
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {createPortal} from "react-dom";
import last from "lodash/last";
import head from "lodash/head";
import isFunction from "lodash/isFunction";
import delay from "lodash/delay";
import isBoolean from "lodash/isBoolean";
import ButtonSave from "../../helpers/buttonSave/buttonSave";
import {Try} from "../../helpers/utils/try";
import "./style.scss";

export const Popover = forwardRef(({onClosed, additional, large, autoFocus, children, customStyles}, ref) => {
	const selfRef = useRef();
	const modal = useRef();
	const [target, setTarget] = useState(document.getElementById("modalHolder"));
	const lastElement = useRef();
	const _autoFocus = isBoolean(autoFocus) ? autoFocus : true;
	const _ref = ref || selfRef;

	const focusOut = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.target === lastElement.current && modal.current) {
			let first = head(modal.current.querySelectorAll('input,a,select,button,textarea'));
			if (first) {
				first.focus();
			}
		}
	}

	useEffect(()=>{
		if (!target) {
			setTarget(document.getElementById("modalHolder"));
		}
	}, [target])

	useEffect(() => {
		if (!_ref.current) return;

		const scrollBackTo = Try(()=> document.getElementById("mainHolder").scrollTop) || 0;

		const keyboardCheck = (ev) => {
			const key = ev.key;
			if (key === "Escape") {
				if (_ref.current) {
					_ref.current.close();
				}
			}
		}

		document.addEventListener('keyup', keyboardCheck, false);
		const page = document.getElementById("modalHolder");
		if (page) {
			let next = page.nextSibling;
			if (next) {
				next.classList.add("blur");
			}
		}

		return () => {
			document.removeEventListener('keyup', keyboardCheck, false);
			const target = document.getElementById("modalHolder");
			if (target) {
				let next = target.nextSibling;
				if (next && target.children.length < 1) {
					next.classList.remove("blur");
				}
			}
			setTimeout(() => {
				if (target) {
					target.scrollTo({top: scrollBackTo, behavior: "smooth"})
				}
			}, 100);
		}
	}, [_ref]);

	useEffect(() => {
		if (!modal.current) return;
		lastElement.current = last(modal.current.querySelectorAll('input,a,select,button,textarea'));
		const first = modal.current.querySelector('input,textarea');
		if (_autoFocus && first) {
			first.focus()
		}
	}, [_autoFocus, modal]);

	useImperativeHandle(_ref, () => ({
		close: () => {
			if (isFunction(onClosed)) {
				onClosed(modal.current);
			}
		}
	}), [onClosed, modal])

	return target ? createPortal(<dialog ref={modal} className={"pro-modal fade-in"} style={customStyles} onBlur={focusOut}>
		<div className={large ? "pro-modal-dialog pro-modal-large" : "pro-modal-dialog"}>
			<div className="pro-modal-content">
				{children}
			</div>
		</div>
		{additional}
	</dialog>, target) : null;
})

export const PopoverFull = forwardRef(({title, save, onClosed, additional, header, footer, saveBtn, saveLabel, saveClass, large, autoFocus, children, customStyles}, ref) => {
	const btnSave = useRef();
	const modal = useRef();
	const body = useRef();

	const fallback = useRef();
	ref = ref || fallback;

	const _save = () => {
		if (save) {
			delay(save, 300, ref.current);
		} else {
			modal.current.close();
		}
	}

	const _onClosed = () => {
		if (isFunction(onClosed)) {
			onClosed(ref.current);
		}
	}

	useImperativeHandle(ref, () => ({
		close: () => {
			modal.current.close();
		},
		deactivate: () => {
			if (btnSave.current) {
				btnSave.current.deactivate();
			}
		},
		enable: () => {
			if (btnSave.current) {
				btnSave.current.enable();
			}
		},
		disable: () => {
			if (btnSave.current) {
				btnSave.current.disable();
			}
		},
		body: ()=>{
			return body.current;
		}
	}), []);

  return <Popover onClosed={_onClosed} ref={modal} large={large} autoFocus={autoFocus} customStyles={customStyles}>
		{header === false ? null :
			<div className="pro-modal-header">
				<button tabIndex={-1} type="button" className="pro-modal-close" onClick={() => {
					modal.current.close()
				}}><span>&times;</span></button>
				<h4 className="pro-modal-title text-center">{title}</h4>
			</div>}
		<div className="pro-modal-top-design">
			<div className="notification-container"/>
		</div>
		<div ref={body} className="pro-modal-body">
			{children}
			{additional ?
				<div className="pro-modal-additional">
					{additional}
				</div>
				: undefined}
		</div>
		<div id={"shelfHolder"} className={"shelfHolder"} />
		<div className="pro-modal-bottom-design"/>
		{footer === false ? null :
			<div className="pro-modal-footer">
				{saveBtn === false ? null :
					<ButtonSave tabIndex={0} ref={btnSave} onClick={_save} label={saveLabel} className={saveClass} />}
				<button tabIndex={0} type="button" className="pro-btn btn-cancel" onClick={() => {
					modal.current.close()
				}}>Close</button>
				{footer}
			</div>}
	</Popover>
})