import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOperation } from '../../data/operationReducer';
import Api from '../../helpers/api';
import { DropdownInput } from '../../helpers/dropdown';
import { styles } from './common';

type ChangeOpsModalProps = {
  open: boolean
  onClose: () => void
}

const ChangeOpsModal: React.FC<ChangeOpsModalProps> = ({ open, onClose }) => {
  const isMobileView = useMediaQuery('(max-width:700px)');
  const operation = useSelector(selectOperation);
  const [operations, setOperations] = useState([]);

  const onChange = (op: any) => {
    Api.get((window as any).jsRoutes.controllers.Users.changeOperation(op))
      .then(async () => {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const session = await Auth.currentSession();
        user.refreshSession(session.getRefreshToken(), () => {
          Api.reload()
        });
      })
      .catch(Api.silentFail);
  }

  useEffect(() => {
    const call = new AbortController();
    Api.get((window as any).jsRoutes.controllers.Operations.listAll(), call).then(data => {
      setOperations(data.operations);
    }).catch(Api.silentFail);
    return () => {
      call.abort()
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={[styles.modal, isMobileView ? styles.mobile : styles.desktop]}>
        <Box sx={styles.header}>
          <Box>Change Operation</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </Box>
        <Box sx={styles.body}>
          <DropdownInput name={"Operation"} value={operation?._id?.$oid} options={operations} valueNode={"_id.$oid"} labelNode={"name"} onChange={onChange} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}>
          <Button variant='contained' sx={styles.action} onClick={onClose}>Close</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ChangeOpsModal;