import {PopoverFull} from "../../layout/popover";
import {useEffect, useState} from "react";
import Api from "../../helpers/api";
import Loading from "../../helpers/loading/loading";

const CompanyNote = ({_id, onClosed})=>{
	const [isLoading, setIsLoading] = useState(false);
	const [company, setCompany] = useState({});

	useEffect(() => {
		let call = new AbortController();
		setIsLoading(true);
		Api.get(window.jsRoutes.controllers.Companies.get(_id.$oid), call)
			.then(r => {
				setCompany(r.company);
			})
			.catch(Api.silentFail)
			.then(()=>{
				setIsLoading(false);
			})
		return () => {
			call.abort()
		}
	}, [_id])

	return <PopoverFull title={"Company Notes"} onClosed={onClosed} saveBtn={false}>
		<Loading active={isLoading} />
		<pre>
			{company.notes}
		</pre>
	</PopoverFull>
}

export default CompanyNote;