import { Analytics } from "@aws-amplify/analytics";
import { Auth } from "@aws-amplify/auth";
import { Hub } from '@aws-amplify/core';
import { CONNECTION_STATE_CHANGE, ConnectionState } from "@aws-amplify/pubsub/lib";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { withInAppMessaging } from "@aws-amplify/ui-react-notifications";
import { faWifiSlash } from "@fortawesome/pro-light-svg-icons/faWifiSlash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { GeneralRoutes } from "@routes/general";
import { Notifications } from "aws-amplify";
import { AwsRum } from "aws-rum-web";
import flatten from "lodash/flatten";
import isArray from "lodash/isArray";
import pick from "lodash/pick";
import { lazy, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { toggleDashboardState } from "./data/dashboardStateReducer";
import { setOperation, setTools } from "./data/operationReducer";
import { setRights, setUser } from "./data/userReducer";
import Api from "./helpers/api";
import Database from "./helpers/database";
import DataStore from "./helpers/dataStore";
import Loading from "./helpers/loading/loading";
import { SimpleTooltip } from "./helpers/tooltip";
import { Part } from "./helpers/utils/part";
import { ReactiveSize } from "./hooks/useReactive";
import MainLayout from "./layout/main";
import { ErrorBoundary } from "./pages/errorBoundary";
import { PRORedirect } from "./redirect";

const { InAppMessaging } = Notifications;
const Login = lazy(() => import("./pages/login"));
const NotFound = lazy(() => import("./notFound"));
const Flights = lazy(() => import("./pages/flights"));
const Financials = lazy(() => import("./pages/financials"));
const Compliance = lazy(() => import("./pages/compliance"));
const Safety = lazy(() => import("./pages/safety"));
const Fleet = lazy(() => import("./pages/fleet"));
const MxVendorDeferral = lazy(()=> import("./pages/mxVendor/deferral") );
const MxVendorCorrective = lazy(()=> import("./pages/mxVendor/corrective") );
const MxVendorRII = lazy(()=> import("./pages/mxVendor/rii") );
const ClientAccept = lazy(() => import("./pages/clientAccept"));
const ReviewARA = lazy(() => import("./pages/reviewARA"));
const CalendarIndex = lazy(() => import("./pages/calendars"));
const DispatchTrip = lazy(() => import("./pages/dispatches/trip/index"));
const Operations = lazy(() => import("./pages/operations"));
const CRM = lazy(() => import("./pages/crm"));
const Popout = lazy(() => import("./pages/popout"));
const Dashboard = lazy(() => import("./pages/dashboard/index"));
const NewDashboard = lazy(() => import("./pages/new-dashboard"));
const Operation = lazy(() => import("./pages/operation/index"));
const User = lazy(() => import("./pages/user"));
const Admin = lazy(() => import("./pages/user/list"));

const Main = () => {
  const currentToken = useRef();
  const dispatch = useDispatch();
  const { user, signOut, route } = useAuthenticator(c => [c.user, c.signOut, c.route]);
  const dashboardState = useSelector(toggleDashboardState);
  const [DashboardComponent, setDashboardComponent] = useState(NewDashboard);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const theme = useMemo(()=> createTheme({
		palette: {
			mode: prefersDarkMode ? 'dark' : "light",
		},
		typography: {
			fontFamily: "var(--sans)"
		},
		components: {
			MuiFormControl: {
				styleOverrides: {
					root: {
						margin: "var(--spacing) 0"
					}
				}
			},
			MuiModal: {
				styleOverrides: {
					root: {
            zIndex: 10000,
					}
				}
			}
		}
  }), [prefersDarkMode]);
  
  useEffect(() => {
    const myMessageReceivedHandler = (message) => {
      console.info("message received", message);
    };
    const listener = InAppMessaging.onMessageReceived(myMessageReceivedHandler);

    InAppMessaging.syncMessages();

    return () => {
      listener.remove();
    }
  }, [])

  useEffect(() => {
    (new Database("levelflight", 1, db => {
      db.createObjectStore("timezones", { keyPath: ["timestamp", "timezone"] });
    }))
      .then(db => {
        window.db = db;
      })
  }, [])

  useEffect(() => {
    Hub.listen('pubsub', (data) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        switch (payload.data.connectionState) {
          case ConnectionState.Disconnected:
            if (!window.location.pathname.includes("/popout")) {
              let modal = <div>
                <SimpleTooltip tooltip={"The real-time updates to LevelFlight have been disconnected.\r\nThis might affect data updating.\r\nA refresh will reconnect this."}>
                  <FontAwesomeIcon icon={faWifiSlash} />
                </SimpleTooltip>
              </div>;
              window.dispatchEvent(new CustomEvent("socketUIEvent", { detail: modal }));
            }
            break;
          case ConnectionState.Connected:
            window.dispatchEvent(new CustomEvent("socketUIEvent", null));
            break;
          default:
          // Do Nothing
        }
      }
    });
  }, []);

  useEffect(() => {
    const before = () => {
      switch (document.visibilityState) {
        case "hidden":
          window.document.activeElement.blur();
          break;
        default:
        // Do nothing...
      }
    }

    const resize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      document.documentElement.style.setProperty('--cellWidth', window.innerWidth > ReactiveSize.small.max ? "120px" : "100px");
    }

    window.addEventListener("visibilitychange", before, true);
    window.addEventListener("resize", resize, true);

    resize();

    return () => {
      window.removeEventListener("visibilitychange", before, true);
      window.removeEventListener("resize", resize, true);
    }
  }, []);

  useEffect(() => {
    setDashboardComponent(dashboardState.isLegacy ? Dashboard : NewDashboard);
  }, [dashboardState]);

  useEffect(() => {
    switch (route) {
      case "authenticated": {
        let idToken = user.signInUserSession.idToken;
        window.sessionStorage.setItem("token", idToken.jwtToken);
        if (idToken.jwtToken === currentToken.current) return;
        currentToken.current = idToken.jwtToken;

        let _user = {
          _id: { "$oid": idToken.payload.preferred_username },
          fullName: `${idToken.payload.given_name || ""} ${idToken.payload.family_name || ""}`,
          userTypes: (idToken.payload["cognito:roles"] || []),
          operations: (idToken.payload["custom:operations"] || "").split(",")
        }
        dispatch(setUser(_user));
        let attr = Object.fromEntries(Object.entries(pick(idToken.payload, ['cognito:groups', 'custom:operations', 'email', 'family_name', 'given_name', 'preferred_username', 'website', 'part'])).map(v => [v[0].replace('cognito:', ''), isArray(v[1]) ? flatten(v[1]) : [v[1]]]));
        let p = {
          address: idToken.payload.email,
          optOut: 'NONE',
          userId: idToken.payload.sub,
          userAttributes: attr
        }
        Analytics.updateEndpoint({ ...p, ...{ channelType: 'EMAIL' } }).catch(() => { });
        InAppMessaging.identifyUser(idToken.payload.sub, { attributes: attr }).catch(() => { });

        let operation = {
          _id: { "$oid": idToken.payload.website },
          name: idToken.payload.name,
          part: Part.parse(parseInt(idToken.payload.part || 1))
        }
        try {
          if (idToken.payload.options) {
            operation.options = JSON.parse(idToken.payload.options);
          }
        } catch (e) {
          console.error(e);
        }
        dispatch(setOperation(operation));

        Api.get(window.jsRoutes.controllers.Api.getTools())
          .then(result => {
            if (result.tools) {
              dispatch(setTools(result.tools));
              dispatch(setRights(result.rights));
            }
          })

        Auth.currentCredentials().then(creds => {
          Api.post(GeneralRoutes.attachPolicy(), { "payload": { "identityId": creds.identityId } })
            .catch(Api.silentFail);

          try {
            const config = {
              sessionSampleRate: 1,
              guestRoleArn: "arn:aws:iam::245211809793:role/Cognito_LevelFlightUnauth_Role",
              identityPoolId: "us-west-2:d50be070-53f9-4cb7-96c5-93d6fe0c25f5",
              endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
              telemetries: [["performance"], ["errors"], ["http", {/*addXRayTraceIdHeader: true*/ }]],
              enableXRay: true,
              allowCookies: true,
            };

            const APPLICATION_ID = 'b811a398-52fa-4c58-9a7a-d88c802dd5b4';
            const APPLICATION_VERSION = '1.0.0';
            const APPLICATION_REGION = 'us-west-2';

            window.awsRum = new AwsRum(
              APPLICATION_ID,
              APPLICATION_VERSION,
              APPLICATION_REGION,
              config
            );
            window.awsRum.setAwsCredentials(creds);
          } catch (error) {
            // Ignore errors thrown during CloudWatch RUM web client initialization
          }
        });
      }
    }
  }, [route, dispatch, user?.signInUserSession?.idToken])

  return <ThemeProvider theme={theme}>
    <BrowserRouter>
      <div id="modalHolder" />
      <ErrorBoundary section={"Main"}>
        <Suspense fallback={<Loading active={true} />}>
          <Routes>
            <Route path={"/oauth"} element={<PRORedirect execute={(params, navigate) => {
              navigate("/");
            }} />} />
            <Route path={"/logout"} element={<PRORedirect execute={(params, navigate) => {
              signOut();
              navigate("/");
            }} />} />

            <Route path="/client/:id/accept" element={<ClientAccept />} />

            <Route path="/ara/:id" element={<ReviewARA />} />
            
            <Route path="/mx-vendor/:id/deferral" element={<MxVendorDeferral />} />
            <Route path="/mx-vendor/:id/corrective" element={<MxVendorCorrective />} />
            <Route path="/mx-vendor/:id/rii" element={<MxVendorRII />} />

            <Route path="/login" element={<Login />} />

            <Route path={"*"} element={
              <RequireAuth>
                <MainLayout />
              </RequireAuth>
            }>
              <Route index element={<DashboardComponent />} />
              <Route path="dashboard" element={<DashboardComponent />} />
              <Route path="calendars/*" element={<CalendarIndex />} />
              <Route path="records/*" element={<Compliance />} />
              <Route path="flights/*" element={<Flights />} />
              <Route path="administration" element={<Financials />} />
              <Route path="administration/financials/*" element={<Financials />} />
              <Route path="safety/*" element={<Safety />} />
              <Route path="fleet/*" element={<Fleet />} />
              <Route path="operations/*" element={<Operations />} />
              <Route path="administration/crm/*" element={<CRM />} />
              <Route path="administration/settings/*" element={<Operation />} />
              <Route path="administration/users" element={<Admin />} />
              <Route path="administration/users/:id/*" element={<User />} />
              <Route path="records/cabin-crew/:id/*" element={<User isCompliance />} />
              <Route path="records/mx/:id/*" element={<User isCompliance />} />
              <Route path="records/all/:id/*" element={<User isCompliance />} />

              <Route path="dispatches/:trip" element={<DispatchTrip />} />
              <Route path="quotes/:trip" element={<DispatchTrip />} />

              {/* legacy routing START */}
              <Route path="analytics/*" element={<PRORedirect execute={(params, navigate) => {
                navigate("/operations/analytics/" + params["*"]);
              }} />} />
              <Route path="reports" element={<PRORedirect execute={(params, navigate) => {
                navigate("/operations/reports/" + DataStore.Get.string("reports.category", "pilots"));
              }} />} />
              {/* <Route path="manuals/*" element={<PRORedirect execute={(params, navigate) => {
                console.info("params", params);
                navigate("/compliance/manuals/" + params["*"]);
              }} />} /> */}
              <Route path="dispatches" element={<PRORedirect execute={(params, navigate) => {
                navigate("/flights/dispatches");
              }} />} />
              <Route path="quotes" element={<PRORedirect execute={(params, navigate) => {
                navigate("/flights/quotes");
              }} />} />
              <Route path="quotes/:status/:sortBy" element={<PRORedirect execute={(params, navigate) => {
                navigate(`/flights/quotes/${params['status']}/${params['sortBy']}`);
              }} />} />

              <Route path="companies" element={<PRORedirect execute={(params, navigate) => {
                navigate("/financials/companies");
              }} />} />
              <Route path="customers" element={<PRORedirect execute={(params, navigate) => {
                navigate("/financials/customers");
              }} />} />
              <Route path="expenses" element={<PRORedirect execute={(params, navigate) => {
                navigate("/financials/expenses");
              }} />} />
              <Route path="sms/:id" element={<PRORedirect execute={(params, navigate) => {
                navigate("/safety/tickets/" + params["id"]);
              }} />} />
              <Route path="sms" element={<PRORedirect execute={(params, navigate) => {
                navigate("/safety/tickets");
              }} />} />
              <Route path="safetyMeetings" element={<PRORedirect execute={(params, navigate) => {
                navigate("/safety/meetings");
              }} />} />
              <Route path="safetyMeetings/:id" element={<PRORedirect execute={(params, navigate) => {
                navigate("/safety/meetings/" + params["id"]);
              }} />} />
              <Route path="iep" element={<PRORedirect execute={(params, navigate) => {
                navigate("/safety/iep");
              }} />} />
              <Route path="riskProfile" element={<PRORedirect execute={(params, navigate) => {
                navigate("/safety/riskProfile");
              }} />} />
              <Route path="users/:id/*" element={<PRORedirect execute={(params, navigate) => {
                navigate("/administration/users/" + params["id"] + "/" + params["*"]);
              }} />} />
              <Route path="users" element={<PRORedirect execute={(params, navigate) => {
                navigate("/administration/users");
              }} />} />
              <Route path="aircraft/:id/*" element={<PRORedirect execute={(params, navigate) => {
                navigate("/fleet/aircraft/" + params["id"] + "/" + params["*"]);
              }} />} />
              <Route path="aircraft" element={<PRORedirect execute={(params, navigate) => {
                navigate("/fleet/aircraft");
              }} />} />
              <Route path="components" element={<PRORedirect execute={(params, navigate) => {
                navigate("/fleet/components");
              }} />} />
              <Route path="workOrder" element={<PRORedirect execute={(params, navigate) => {
                navigate("/fleet/workOrder");
              }} />} />
              <Route path="mpl" element={<PRORedirect execute={(params, navigate) => {
                navigate("/fleet/mpl");
              }} />} />
              {/* legacy routing END */}

              <Route path="popout/*" element={<Popout />} />

              <Route path="api/dispatch/:id/review/tripSheet" element={<PRORedirect execute={(params) => {
                Api.changeUrl("/popout/api/dispatch/" + params["id"] + "/review/tripSheet");
              }} />} />

              <Route path="api/*" element={<PRORedirect execute={(params) => {
                Api.changeUrl("https://" + import.meta.env.VITE_REST_SERVER + "/api/" + params["*"]);
              }} />} />

              <Route path={"*"} element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  </ThemeProvider>
}
export default withInAppMessaging(Main);

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { route } = useAuthenticator(c => [c.route]);
  if (route !== "authenticated") {
    InAppMessaging.clearMessages();
    return <Navigate to={"/login"} state={{ from: location }} replace />
  }
  return children;
}