export const MaintenanceAction = {
	parse: function (id) {
		switch (id) {
			case 0:
				return this.Grounded;
			case 1:
				return this.AWR;
			case 2:
				return this.RTS;
			case 3:
				return this.Ferry;
			case 4:
				return this.InfoMX;
			default:
				return this.NA;
		}
	},
	list: function () {
		return [this.Grounded, this.AWR, this.RTS, this.Ferry, this.InfoMX];
	},

	NA: {id: -1, name: "NA"},
	Grounded: {id: 0, name: "Grounded"},
	AWR: {id: 1, name: "Airworthiness Release"},
	RTS: {id: 2, name: "Return to Service"},
	Ferry: {id: 3, name: "Ferry"},
	InfoMX: {id: 4, name: "Info to Maintenance"},
};