export const SMSStatus = {
	parse: function (id) {
		switch (id) {
			case 1:
				return this.Opened;
			case 2:
				return this.Processing;
			case 3:
				return this.Analysis;
			case 4:
				return this.Review;
			case 5:
				return this.Control;
			case 6:
				return this.Resolution;
			case 7:
				return this.Closed;
			case 8:
				return this.Deferred;
			case 9:
				return this.RII;
			default:
				return {id: 0, name: "Unknown"};
		}
	},
	list: function () {
		return [this.Opened, this.Processing, this.Analysis, this.Review, this.Control, this.Resolution, this.Closed];
	},
	fullList: function () {
		return [this.Opened, this.Processing, this.Deferred, this.Analysis, this.Review, this.Control, this.RII, this.Resolution, this.Closed];
	},

	compare: function (status, test) {
		let index = this.parse(status);
		return index.sort === test.sort ? 0 : index.sort > test.sort ? 1 : -1;
	},

	Opened: {id: 1, name: "Initial", sort: 1},
	Processing: {id: 2, name: "Processing", sort: 2},
	Analysis: {id: 3, name: "Analysis", sort: 4},
	Review: {id: 4, name: "Review", sort: 5},
	Control: {id: 5, name: "Corrective Action", sort: 6},
	Resolution: {id: 6, name: "Follow-Up", sort: 8},
	Closed: {id: 7, name: "Closed", sort: 9},
	Deferred: {id: 8, name: "Deferred", sort: 3},
	RII: {id: 9, name: "RII", sort: 7},
};