import React, { CSSProperties, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, FormControl, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import useMediaQuery from '@mui/material/useMediaQuery'

import { cloneDeep, compact, extend, find, get, isEmpty, isNumber, set, sortBy, unset } from 'lodash'

import AirportInput from '../../helpers/airportInput'
import Api from '../../helpers/api'
import { PRODatePicker } from '../../helpers/calendar'
import { DropdownInput } from '../../helpers/dropdown'
import { Currency } from '../../helpers/formatters'
import { FromTo } from '../../icons/fromTo'
import { addNewButtonStyle, styles } from './common'

import { Fragment } from 'react'
import { Checkbox } from '../../helpers/checkbox'
import Columns from '../../helpers/columns'
import Form from '../../helpers/form/form'
import PROLabel from '../../helpers/label/label'
import SnackBar from '../../helpers/snackBar'
import { minutesToHHMM } from '../../helpers/utils/formatters'

const localStyle: { [x: string]: CSSProperties | {} } = {
  legMiddleBody: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    width: '100%',
    gap: '10px',
  },
  legLabel: {
    marginRight: '5px',
    fontSize: 'var(--font-base)',
  },
  legMiddleBodyPart1: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  legMiddleBodyPart2: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  customModal: {
    // maxHeight: '70vh',
    // width: '800px !important',
    overflow: 'auto',
  },
}

type QuickQuoteModalProps = {
  open: boolean
  onCancel: Function
  onOk: Function
  type: string
  setSnackBarOpen: Function
  setSnackBarMessage: Function
  setSnackBarStatus: Function
}

export type KeyValueInterface = {
  [key: string | number]: any
}

const initialPairs = [
  {
    from: '',
    to: '',
    date: null,
    elt: null,
    pax: null,
  },
]

const QuickQuoteModal: React.FC<QuickQuoteModalProps> = ({ open, onCancel, onOk, setSnackBarOpen, setSnackBarMessage, setSnackBarStatus }) => {
  const navigate = useNavigate()
  const isMobileView = useMediaQuery('(max-width:470px)')
  const isTabView = useMediaQuery('(max-width:700px)')

  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true)
  const [dispatch, setDispatch] = useState<any>({
    legs: [{}]
  })
  const [aircraft, setAircraft] = useState([])
  const [price, setPrice] = useState({})
  const [rates, setRates] = useState([])
  const [autocompleteOffFleet, setAutocompleteOffFleet] = useState([])
  const [offFleetTerm, setOffFleetTerm] = useState<any>()

  const [localSnackBarOpen, setLocalSnackBarOpen] = useState(false);
  const [localSnackBarStatus, setLocalSnackBarStatus] = useState('');
  const [localSnackBarMessage, setLocalSnackBarMessage] = useState('');


  const aircraftId = get(dispatch, 'aircraft._id.$oid')

  useEffect(() => {
    if (!open) {
      setPrice({})
      setDispatch({legs: []})
    } if (!dispatch.legs.length) {
      addLeg()
    }
  }, [open])

  const changeAircraft = (ev: any) => {
    let state = cloneDeep(dispatch)
    set(state, 'aircraft', ev)
    setDispatch(state)
  }

  const changeOffFleetAircraft = (ev: any) => {
    let state = cloneDeep(dispatch)
    set(state, 'offFleet', ev)
    setDispatch(state)
  }

  const changeRate = (ev: any) => {
    let state = cloneDeep(dispatch)
    set(state, 'rate', ev)
    setDispatch(state)
  }

  const changeDate = (index: number, date: any) => {
    let state = cloneDeep(dispatch)
    set(state, `legs.${index}.departure.time`, date.startOfDay())
    state.legs = sortBy(state.legs, 'departure.time')
    setDispatch(state)
  }

  const changeAirport = (side: any, index: number, airport: any) => {
    if (get(dispatch, `legs.${index}.${side}.airport`) === airport.icao) return
    setDispatch((p: any) => {
      let state = cloneDeep(p)
      set(state, `legs.${index}.${side}.airport`, airport.icao)
      if (state['legs'][index + 1] && side === 'arrival') {
        set(state, `legs.${index + 1}.departure.airport`, airport.icao)
      }
      return state
    })
  }

  const addLeg = () => {
    let state = cloneDeep(dispatch)
    let newIndex = state.legs.length
    set(state, `legs.${newIndex}.departure.airport`, get(state, `legs.${newIndex - 1}.arrival.airport`))
    set(state, `legs.${newIndex}.departure.time`, get(state, `legs.${newIndex - 1}.departure.time`))
    state.legs = sortBy(state.legs, 'departure.time')
    setDispatch(state)
  }

  const removeLeg = (index: number) => {
    let state = cloneDeep(dispatch)
    unset(state, `legs.${index}`)
    state.legs = compact(state.legs)
    state.legs = sortBy(state.legs, 'departure.time')
    setDispatch(state)
  }

  const save = () => {
    return Api.post((window as any).jsRoutes.controllers.Quotes.quickQuote(), dispatch)
  }

  useEffect(() => {
    let call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.AircraftController.list(), call)
      .then((response) => {
        let crafts = response.aircraft.concat([{ _id: { $oid: 'off' }, value: 'Off Fleet Aircraft' }])
        setAircraft(crafts)

        setDispatch((prop: any) => {
          if (isEmpty(prop.aircraft)) {
            let obj = cloneDeep(prop)
            set(obj, 'aircraft', crafts[0])
            prop = obj
          }
          let selectedCraftId = get(prop, 'aircraft._id.$oid') || crafts[0].id
          let craft =
            find(response.aircraft, function (c) {
              return c._id.$oid === selectedCraftId
            }) || response.aircraft[0]
          if (get(prop, 'legs.0.departure.airport') === '') {
            let state = cloneDeep(prop)
            set(state, 'legs.0.departure.airport', get(craft, 'airport'))
            set(state, 'legs.0.paxCount', get(craft, 'paxSeats') || 0)
            prop = state
          }
          return prop
        })
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (isEmpty(aircraftId) || aircraftId === 'off') return
    let call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.AircraftController.listAircraftRates(aircraftId), call)
      .then((result) => {
        setRates(result.rates)
        setDispatch((prop: any) => {
          if (!result.rates.map((r: any) => r._id.$oid).includes(get(prop, 'rate._id.$oid'))) {
            let state = cloneDeep(prop)
            let rate = result.rates.find((r: any) => get(r, `default.${aircraftId}`) === true) || result.rates[0]
            set(state, 'rate', rate)
            return state
          }
          return prop
        })
      })
      .catch((ev) => {
        setRates([])
        Api.silentFail()
      })
    return () => {
      call.abort()
    }
  }, [aircraftId])

  useEffect(() => {
    if (isEmpty(offFleetTerm)) return
    let call = new AbortController()
    Api.post((window as any).jsRoutes.controllers.Operations.searchOffFleet(), { term: offFleetTerm }, call)
      .then((result) => {
        setAutocompleteOffFleet(
          result.aircraft.map((i: any) => {
            return isNumber(i.type) ? i : extend(i, { name: i.tailNumber + ' - ' + i.type.name })
          })
        )
      })
      .catch(Api.flagFail)
    return () => {
      call.abort()
    }
  }, [offFleetTerm])

  useEffect(() => {
    if (!open) {
      return;
    }
    let payload = cloneDeep(dispatch)
    payload.purpose = 7
    if (get(payload, 'aircraft._id.$oid') === 'off') {
      payload.aircraft = payload.offFleet
    }
    payload.legs.forEach((l: any) => {
      l.passengerCount = l.hasPAX ? get(dispatch, 'aircraft.paxSeats') || 1 : 0
    })
    delete payload.offFleet
    let call = new AbortController()
    Api.post((window as any).jsRoutes.controllers.Quotes.calculateTemporaryPrice(), payload, call)
      .then((result) => {
        setPrice(result)
        setButtonDisabled(!result.success)
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [dispatch])

  const handleSaveAndClose = () => {
    save().then((result) => {
      setSnackBarMessage && setSnackBarMessage(result.message)
      setSnackBarStatus && setSnackBarStatus('success')
      setSnackBarOpen && setSnackBarOpen(true)
      onOk()
    }).catch((err: Error) => {
      setLocalSnackBarMessage(err.message)
      setLocalSnackBarStatus('error')
      setLocalSnackBarOpen(true)
    })
  }

  const handleSaveAndContinue = () => {
    save().then((result) => {
      navigate('/flights/quotes/edit/' + result.dispatch.$oid)
      onOk()
    }).catch((err: Error) => {
      setLocalSnackBarMessage(err.message)
      setLocalSnackBarStatus('error')
      setLocalSnackBarOpen(true)
    })
  }

  const handleSaveAndSend = () => {
    save().then((result) => {
      setSnackBarMessage && setSnackBarMessage(result.message)
      setSnackBarStatus && setSnackBarStatus('success')
      setSnackBarOpen && setSnackBarOpen(true)
      onOk(true, result.dispatch.$oid)
    }).catch((err: Error) => {
      setLocalSnackBarMessage(err.message)
      setLocalSnackBarStatus('error')
      setLocalSnackBarOpen(true)
    })
  }

  return (
    <Modal open={open} onClose={() => onCancel()}>
      <Box sx={[styles.modal, localStyle.customModal, isTabView ? styles.mobile : styles.desktop]}>
      <SnackBar
        open={localSnackBarOpen}
        message={localSnackBarMessage}
        status={localSnackBarStatus}
        handleClose={() => setLocalSnackBarOpen(false)}
      />
        <Box sx={styles.header}>
          <Box>Quick Quote</Box>
          <FontAwesomeIcon icon={faTimes} onClick={() => onCancel()} />
        </Box>
        <Box
          sx={{
            ...styles.body,
            paddingLeft: isMobileView ? '1px' : '15px',
            pt: '1rem',
          }}
        >
          <Columns template={'1fr'}>
            <Form className={undefined} style={undefined} bottom={undefined}>
              <DropdownInput
                key={get(dispatch, 'aircraft._id.$oid')}
                name="Aircraft"
                value={dispatch.aircraft}
                options={aircraft}
                onChange={changeAircraft}
                labelNode={function (){
                  if (this._id.$oid === 'off') return 'Off-Fleet Aircraft'
                  return (
                    this.tailNumber +
                    ' - ' +
                    (get(this, 'type.name') || '') +
                    ' (' +
                    (this.paxSeats || 'N/A') +
                    ' seats)' +
                    (this.is91Only ? ' (91 Only)' : '')
                  )
                }}
                valueNode={'_id.$oid'}
                valueOnly={false}
              />
              {get(dispatch, 'aircraft._id.$oid') === 'off' ? (
                <PROLabel
                  name="Off Fleet"
                  value={<Autocomplete node="name" selected={get(dispatch, 'offFleet.name')} options={autocompleteOffFleet} onInputChange={setOffFleetTerm} onChange={changeOffFleetAircraft} />}
                  style={undefined}
                  error={undefined}
                  addendum={undefined}
                  className={undefined}
                  grid={undefined}
                  labelClass={undefined}
                  hideLabel={undefined}
                  noBox={undefined}
                />
              ) : undefined}

              {rates.length > 0 ? (
                <DropdownInput
                  key={get(dispatch, 'rate._id.$oid')}
                  name="Rate"
                  value={dispatch.rate}
                  options={rates}
                  onChange={changeRate}
                  labelNode={'name'}
                  valueNode={'_id.$oid'}
                  valueOnly={false}
                />
              ) : undefined}
            </Form>

            <Columns template={'auto auto 1fr 30px 1fr auto'} className={'quickquote-legs'}>
              <div className={'header'}>Pax</div>
              <div className={'header text-center'}>Date</div>
              <div className={'header text-right'}>From</div>
              <div className={'header'} />
              <div className={'header'}>To</div>
              <div className={'header'} />
              {(dispatch.legs || []).map((leg: any, index: number, list: any) => (
                <Fragment key={'leg_' + index}>
                  <Checkbox
                    className={'centered'}
                    value={true}
                    unvalue={false}
                    checked={get(leg, 'hasPAX')}
                    name={''}
                    onChange={(ev: any) => {
                      let state = cloneDeep(dispatch)
                      set(state, `legs.${index}.hasPAX`, ev)
                      setDispatch(state)
                    }}
                    pill={undefined}
                  />
                  <PRODatePicker className="depart" selected={get(leg, 'departure.time')} onChange={(d) => changeDate(index, d)} debug={undefined} style={undefined} />
                  {index === 0 ? (
                    <AirportInput
                      className="from"
                      icao={get(leg, 'departure.airport')}
                      onChange={(v: any) => changeAirport('departure', index, v)}
                      style={{ width: '100%' }}
                      onClear={() => setButtonDisabled(true)}
                    />
                  ) : (
                    <input key={'from_' + index + get(leg, 'departure.airport')} className="from readOnly" readOnly={true} type="text" value={get(leg, 'departure.airport')} />
                  )}
                  <FromTo className="centered" type={undefined} />
                  <AirportInput
                    className="to"
                    icao={get(leg, 'arrival.airport')}
                    onChange={(v: any) => changeAirport('arrival', index, v)}
                    style={{ width: '100%' }}
                    onClear={() => setButtonDisabled(true)}
                  />
                  {index > 0 ? <FontAwesomeIcon style={{ marginTop: '10px', cursor: 'pointer' }} icon={faTrash} onClick={() => removeLeg(index)} /> : <Box></Box>}
                </Fragment>
              ))}
            </Columns>

            <Button variant='contained' sx={addNewButtonStyle} onClick={addLeg}>
              Add Leg
            </Button>

            <Box sx={{ ...styles.control, '& > p': {minWidth: '80px'}, alignItems: "center" }}>
            <Typography>Time</Typography>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <Typography sx={{fontSize: 'var(--font-base)'}}>{get(price, "breakdown.flightMins") ? `${minutesToHHMM(get(price, "breakdown.flightMins"))?.split(":")[0] || 0}HR + ${minutesToHHMM(get(price, "breakdown.flightMins"))?.split(':')[1] || 0}MIN` : '0HR + 0MIN'}</Typography>
            </FormControl>
          </Box>

          <Box sx={{ ...styles.control, '& > p': {minWidth: '80px'}, alignItems: "center" }}>
            <Typography>Price</Typography>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <Typography sx={{fontSize: 'var(--font-base)'}}>{Currency(price.total || 0)}</Typography>
            </FormControl>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column', paddingLeft: '80px', gap: '5px' }}>
              <Checkbox
                value={undefined}
                unvalue={0}
                checked={get(price, 'breakdown.fet', 0) > 0}
                name={<span>FET {get(price, 'breakdown.fet', 0) > 0 ? <span className={'mono text-small'}>{`(${Currency(get(price, 'breakdown.fet'))})`}</span> : ''}</span>}
                onChange={(ev: any) => {
                  let state = cloneDeep(dispatch)
                  set(state, 'fet', ev)
                  setDispatch(state)
                }}
                className={undefined}
                pill={undefined}
                disabled={get(price, 'breakdown.fet', 0) <= 0}
              />
              {get(dispatch, 'rate.taxi') > 0 ? (
                <Checkbox
                  value={undefined}
                  unvalue={0}
                  checked={get(price, 'breakdown.taxi', 0) > 0}
                  name={<span>Taxi {get(price, 'breakdown.taxi', 0) > 0 ? <span className={'mono text-small'}>{`(${Currency(get(price, 'breakdown.taxi'))})`}</span> : ''}</span>}
                  onChange={(ev: any) => {
                    let state = cloneDeep(dispatch)
                    set(state, 'taxi', ev)
                    setDispatch(state)
                  }}
                  className={undefined}
                  pill={undefined}
                  disabled={get(price, 'breakdown.taxi', 0) <= 0}
                />
              ) : undefined}
              {get(dispatch, 'rate.landing') > 0 ? (
                <Checkbox
                  value={undefined}
                  unvalue={0}
                  checked={get(price, 'breakdown.landings', 0) > 0}
                  name={<span>Landings {get(price, 'breakdown.landings', 0) > 0 ? <span className={'mono text-small'}>{`(${Currency(get(price, 'breakdown.landings'))})`}</span> : ''}</span>}
                  onChange={(ev: any) => {
                    let state = cloneDeep(dispatch)
                    set(state, 'landings', ev)
                    setDispatch(state)
                  }}
                  className={undefined}
                  pill={undefined}
                  disabled={get(price, 'breakdown.landings', 0) <= 0}
                />
              ) : undefined}
              {get(dispatch, 'rate.additionalCrew') > 0 || get(dispatch, 'rate.flightAttendant') > 0 || get(dispatch, 'rate.overnight') > 0 ? (
                <Checkbox
                  value={undefined}
                  unvalue={0}
                  checked={get(price, 'breakdown.additionalCrew', 0) > 0 || get(price, 'breakdown.flightAttendant', 0) > 0 || get(price, 'breakdown.overnight', 0) > 0}
                  name={
                    <span>
                      Crew{' '}
                      {get(price, 'breakdown.additionalCrew', 0) > 0 || get(price, 'breakdown.flightAttendant', 0) > 0 || get(price, 'breakdown.overnight', 0) > 0 ? (
                        <span className={'mono text-small'}>{`(${Currency(
                          (get(price, 'breakdown.additionalCrew') || 0) + (get(price, 'breakdown.flightAttendant') || 0) + (get(price, 'breakdown.overnight') || 0)
                        )})`}</span>
                      ) : (
                        ''
                      )}
                    </span>
                  }
                  onChange={(ev: any) => {
                    let state = cloneDeep(dispatch)
                    set(state, 'crewDays', ev)
                    set(state, 'faDays', ev)
                    set(state, 'overnightsAmount', ev)
                    setDispatch(state)
                  }}
                  className={undefined}
                  pill={undefined}
                  disabled={get(price, 'breakdown.additionalCrew', 0) <= 0 && get(price, 'breakdown.flightAttendant', 0) <= 0 && get(price, 'breakdown.overnight', 0) <= 0}
                />
              ) : undefined}
              {get(dispatch, 'rate.fuelSurcharge') > 0 ? (
                <Checkbox
                  value={undefined}
                  unvalue={0}
                  checked={get(price, 'breakdown.fuelSurcharge', 0) > 0}
                  name={<span>Fuel {get(price, 'breakdown.fuelSurcharge', 0) > 0 ? <span className={'mono text-small'}>{`(${Currency(get(price, 'breakdown.fuelSurcharge'))})`}</span> : ''}</span>}
                  onChange={(ev: any) => {
                    let state = cloneDeep(dispatch)
                    set(state, 'fuelSurcharge', ev)
                    setDispatch(state)
                  }}
                  className={undefined}
                  pill={undefined}
                  disabled={get(price, 'breakdown.fuelSurcharge', 0) <= 0}
                />
              ) : undefined}
              {get(dispatch, 'rate.minHours') > 0 ? (
                <Checkbox
                  value={undefined}
                  unvalue={0}
                  checked={get(price, 'breakdown.min', 0) > 0}
                  name={<span>Mins {get(price, 'breakdown.min', 0) > 0 ? <span className={'mono text-small'}>{`(${Currency(get(price, 'breakdown.min'))})`}</span> : ''}</span>}
                  onChange={(ev: any) => {
                    let state = cloneDeep(dispatch)
                    set(state, 'mins', ev)
                    setDispatch(state)
                  }}
                  className={undefined}
                  pill={undefined}
                  disabled={get(price, 'breakdown.min', 0) <= 0}
                />
              ) : undefined}
              {get(dispatch, 'rate.shortLegAmount') > 0 ? (
                <Checkbox
                  value={undefined}
                  unvalue={0}
                  checked={get(price, 'breakdown.shortLeg', 0) > 0}
                  name={<span>Short Leg {get(price, 'breakdown.shortLeg', 0) > 0 ? <span className={'mono text-small'}>{`(${Currency(get(price, 'breakdown.shortLeg'))})`}</span> : ''}</span>}
                  onChange={(ev: any) => {
                    let state = cloneDeep(dispatch)
                    set(state, 'shortLeg', ev)
                    setDispatch(state)
                  }}
                  className={undefined}
                  pill={undefined}
                  disabled={get(price, 'breakdown.shortLeg', 0) <= 0}
                />
              ) : undefined}
            </Box>
          </Columns>

          <Box
            sx={[
              styles.actionButtons,
              {
                mt: '2rem',
                justifyContent: isMobileView ? 'center' : 'end',
                flexWrap: 'wrap',
                gap: '5px',
                alignItems: 'center',
              },
            ]}
          >
            <Button disabled={isButtonDisabled} variant="contained" sx={{ ...styles.action, marginTop: '8px' }} onClick={handleSaveAndClose}>
              Save & Close
            </Button>

            <Button disabled={isButtonDisabled} variant="contained" sx={{ ...styles.action, marginTop: '8px' }} onClick={handleSaveAndContinue}>
              Save & Edit
            </Button>

            <Button disabled={isButtonDisabled} variant="contained" sx={{ ...styles.action, marginTop: '8px' }} onClick={handleSaveAndSend}>
              Save & Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default QuickQuoteModal

