import {createSlice} from "@reduxjs/toolkit";
import {setAircraft} from "./viewingAircraftReducer";
import get from "lodash/get";
import {setUser} from "./viewingUserReducer";

function sanitizeTitle(inbound) {
	let title = inbound;
	if (title.props) {
		title = title.props.children[0]
	}
	return title;
}

export const menuSlice = createSlice({
	name: "menu",
	initialState: {
		title: "Welcome to LevelFlight",
		breadcrumb: null,
		subtitle: null,
	},
	reducers: {
		setTitle: (state, action) => {
			state.title = action.payload;
			if (action.payload) {
				document.title = `LevelFlight // ${sanitizeTitle(state.title)}`;
			}
		},
		setBreadcrumb: (state, action) => {
			state.breadcrumb = action.payload;
			if (action.payload) {
				document.title = `LevelFlight // ${state.breadcrumb.join(" / ")} / ${sanitizeTitle(state.title)}`;
			}
		},
		setSubtitle: (state, action) => {
			state.subtitle = action.payload;
			if (action.payload) {
				document.title = `LevelFlight // ${state.breadcrumb.join(" / ")} / ${sanitizeTitle(state.title)} - ${state.subtitle}`;
			}
		}
	},
	extraReducers: (builder)=> {
		builder
			.addCase(setAircraft, (state, action) => {
				let camp = action.payload.camp ? <img alt={"atp"} src={"/images/logos/logo-camp.png"} style={{
					position: "relative",
					top: -6,
					height: 32,
					marginLeft: "var(--spacing)"
				}}/> : undefined
				let atp = action.payload.flightDocs ?
					<img className={"atp-logo-gray"} alt={"atp"} src={"/images/logos/logo-atp-white.svg"} style={{
						padding: 2,
						backgroundColor: "var(--gray)",
						height: 24,
						marginLeft: "var(--spacing)"
					}}/> : undefined
				state.title = `${action.payload.tailNumber || ""}`;
				state.subtitle = <div style={{display: "flex"}}>{get(action, "payload.type.name") || ""}{action?.payload?.active ? "" : " - Retired"}{camp} {atp}</div>;
				state.breadcrumb = ["Fleet", "Aircraft"];
				document.title = `LevelFlight // ${state.breadcrumb.join(" / ")} / ${state.title}`;
				if (state.subtitle) {
					document.title += ` - ${sanitizeTitle(state.subtitle)}`;
				}
			})
			.addCase(setUser, (state, action) => {
				state.title = `${action.payload.firstName || ""} ${action.payload.lastName || ""}`;
				state.subtitle = (action.payload.active ? undefined : "Inactive");
				state.breadcrumb = ["Manage", "Users"];
				document.title = `LevelFlight // ${state.breadcrumb.join(" / ")} / ${state.title}`;
				if (state.subtitle) {
					document.title += ` - ${sanitizeTitle(state.subtitle)}`;
				}
			})
	}
})

export const {setTitle, setBreadcrumb, setSubtitle} = menuSlice.actions;

export const selectMenu = state => state.menu;

export default menuSlice.reducer;