import ContactModal from "../../pages/modal/contact";
import { Fragment, useState } from "react";
import CustomerAutocomplete from "./autocomplete";
import CustomerDropdown from "./dropdown";

const CustomerSelector = props => {
  const [openContact, setOpenContact] = useState(false);
  const [editContact, setEditContact] = useState(undefined)

  const toggleCreate = () => {
    setEditContact(undefined);
    setOpenContact(true);
  }
  
  const toggleEdit = (customer) => {
    setEditContact(customer);
    setOpenContact(true);
  }

	const change = (customer) => {
		props.onChange(customer);
	}

	const options = {
		grid: props.grid,
    label: props.label,
    hideLabel: props.hideLabel,
		company: props.company,
		customer: props.customer,
		onChange: change,
    create: toggleCreate,
    edit: toggleEdit,
		contactOnly: props.contactOnly,
		style: props.style,
		labelClass: props.labelClass
  };
  
  const onClose = () => {
    setOpenContact(false);
  }

  const onSave = (customer) => {
    setOpenContact(false);
    change(customer);
  };

	return <Fragment>
    <ContactModal open={openContact} onCancel={onClose} onOk={onSave} editContact={editContact} company={[props.company]} isCustomer={true}/>
		{(props.autocomplete) ?
			<CustomerAutocomplete {...options} />
			:
			<CustomerDropdown {...options} />
		}
	</Fragment>;
}
export default CustomerSelector;