import {createSlice} from "@reduxjs/toolkit";
import DataStore from "@helpers/dataStore";
import isObject from "lodash/isObject";

export const Options = {
	Trip: {
		ShowActuals: "trip.actuals",
		ShowCalendar: "trip.calendar",
		ShowMap: "trip.map",
		ShowAirportsAround: "trip.airportsAround",
		ShowConflicts: "trip.conflicts",
		ShowPricing: "trip.pricing",
		ShowLeg: "trip.leg",
		UseNewFeatures: "trip.useNew",
		DutyPaddingStart: "trip.duty.padding.start",
		DutyPaddingEnd: "trip.duty.padding.end",
	},
	Calendar: {
		View: "calendar.view",
		UseNewFeatures: "calendar.useNew",
    UseGregorian: "calendar.gregorian",
    ShowCalendar: "calendar.showCalendar",
    ShowM2M: "calendar.showM2M"
	},
	Memo: {
		Top: "memo.top",
		Left: "memo.left",
	},
	DepartingList: "dashboard.departing.list",
	InternationalList: "dashboard.international.list",
};

const optionsSettings = {
	"trip.pricing": {type: "boolean", default: false},
	"trip.leg": {type: "string", default: null},
	"trip.airportsAround": {type: "boolean", default: false},
	"trip.duty.padding.start": {type: "int", default: 60},
	"trip.duty.padding.end": {type: "int", default: 30},

	"calendar.view": {type: "string", default: "aircraft"},
	"calendar.useNew": {type: "boolean", default: false},
  "calendar.gregorian": { type: "boolean", default: false },
  "calendar.showCalendar": { type: "boolean", default: false },
  "calendar.showM2M": { type: "boolean", default: false },

	"memo.top": {type: "int", default: 50},
	"memo.left": {type: "int", default: "75vw"},
};

function processInitial([, value]){
	if (isObject(value)) {
		return Object.assign({}, ...Object.entries(value).map(processInitial))
	} else {
		const opts = optionsSettings[value] ?? {};
		return ({[value]:DataStore.Get[opts.type || "boolean"](value, opts.default ?? true)})
	}
}

export const optionsSlice = createSlice({
	name: "options",
	initialState: processInitial([null, Options]),
	reducers: {
		setOption: (state, action) => {
			DataStore.set(action.payload.option, action.payload.value);
			state[action.payload.option] = action.payload.value;
		},
		toggle: (state, action) => {
			const n = !state[action.payload];
			DataStore.set(action.payload, n);
			state[action.payload] = n;
		}
	}
});

export const {toggle, setOption} = optionsSlice.actions;

export const selectOption = id => state => state?.options[id];

export default optionsSlice.reducer;