import { Fragment, useEffect, useState } from 'react'
import Api from '../../helpers/api'
import Columns from '../../helpers/columns'
import { Dropdown } from '../../helpers/dropdown'
import PROLabel from '../../helpers/label/label'
import { usePopover } from '../../hooks/usePopover'
import { PlusCircle } from '../../icons/circle/plus'
import { Pencil } from '../../icons/pencil'
import { CustomerRoutes } from '../../routes/customer'

const CustomerDropdown = ({ company, customer, contactOnly, onChange, label, grid, labelClass, style, create, edit, hideLabel }) => {
  const [popover] = usePopover()
  const [customers, setCustomers] = useState([])

  const selectCustomer = (value) => {
    onChange(value)
  }

  useEffect(() => {
    const companyId = company?._id?.$oid
    if (companyId) {
      Api.post(CustomerRoutes.getAllForCompany(), {
        _id: { $oid: companyId },
        contactOnly: contactOnly,
      })
        .then((result) => {
          let change = result.customers || []
          change.unshift({ _id: { $oid: null }, lastName: 'Select a ' + label })
          setCustomers(change)
        })
        .catch(Api.silentFail)
    }
  }, [company, contactOnly, customer, label])

  return (
    <Fragment>
      {popover}
      <PROLabel
        grid={grid}
        name={label}
        labelClass={labelClass}
        hideLabel={hideLabel}
        value={
          <Columns template="1fr auto auto">
            <Dropdown key={customer} value={customer} options={customers} onChange={selectCustomer} valueNode={'_id.$oid'} labelNode={Dropdown.Formatter.UserName} allowNull={true} />
            {customer?._id?.$oid ? (
              <button className="text-cancel" onClick={() => edit(customer)}>
                <Pencil />
              </button>
            ) : undefined}
            <button className="text-primary" onClick={create}>
              <PlusCircle />
            </button>
          </Columns>
        }
        style={style}
      />
    </Fragment>
  )
}
export default CustomerDropdown
