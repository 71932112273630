import {createSelector, createSlice} from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import {UserType} from "../helpers/utils/userType";

export const viewingUserSlice = createSlice({
	name: "viewingUser",
	initialState: {},
	reducers: {
		setUser: (state, action) => action.payload
	}
})

export const {setUser} = viewingUserSlice.actions;

export const selectViewingUser = createSelector(
	[state => state.viewingUser],
	user => {
		let n = cloneDeep(user);
		if (n.userTypes) {
			n.userTypes = n.userTypes.map(t => UserType.parse(t));
		}
		return n;
	}
)

export default viewingUserSlice.reducer;