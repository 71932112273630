// import {faArrowAltRight} from "@fortawesome/pro-solid-svg-icons/faArrowAltRight";
// import {faArrowAltRight as falArrowAltRight} from "@fortawesome/pro-light-svg-icons/faArrowAltRight";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// export const FromTo = ({type, ...props})=> <FontAwesomeIcon icon={type === "light" ? falArrowAltRight: faArrowAltRight} {...props} />

import Box from "@mui/material/Box";

{/* divider between the 2 leg sections */}
export const FromTo = ({type, ...props}) => <Box sx={{
    width: 'var(--space-xl)',
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center',
    opacity: (type === 'light' ? 0.5 : 1)
    }}>
<img style={{width: props.width || 20, height: props.height || 20}} src="/images/icons/plane.svg" />
</Box>