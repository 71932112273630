import { selectTicket } from "@data/ticketReducer.jsx";
import Api from "@helpers/api";
import { DateTimeInput } from "@helpers/dateInput";
import { Dropdown, DropdownInput } from "@helpers/dropdown";
import Form from "@helpers/form/form";
import Label from "@helpers/label/label";
import PRODate from "@helpers/proDate";
import { YesOrNo } from "@helpers/radioInput/radioInput";
import { TextAreaLabel } from "@helpers/textArea";
import { TextInput } from "@helpers/textInput";
import { SMSTicketType } from "@helpers/utils/SMSTicketType";
import { History } from "@icons/history.jsx";
import { Box, Button, IconButton } from "@mui/material";
import { get } from "lodash";
import includes from "lodash/includes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChangeLogModal from "../../../pages/modal/change-log";
import { styles } from "../../../pages/modal/common";
import VendorOptionsModal from "../../../pages/modal/vendor-options";
import WorkOrderAutocomplete from "../../../pages/workOrder/autocomplete";

export const Initial = ({ id, onSave, onClose }) => {
	const item = useSelector(selectTicket(id));
	const [aircraft, setAircraft] = useState([]);
	const [phases, setPhases] = useState([]);
	const [types, setTypes] = useState([]);
	const [ataCodes, setAtaCodes] = useState([]);
	const [actions, setActions] = useState([]);
	const [errors, setErrors] = useState([]);

	const [ticketType, setTicketType] = useState(item.ticketType ?? SMSTicketType.Maintenance.id);
	const [asapEvent, setAsapEvent] = useState(item.asapEvent ?? false);
	const [description, setDescription] = useState(item.description);
	const [eventDate, setEventDate] = useState(item.eventDate ?? PRODate.now());
	const [reaction, setReaction] = useState(item.reaction);
	const [flightTime, setFlightTime] = useState(item.flightTime);
	const [delayHours, setDelayHours] = useState(item.delayHours);
	const [phase, setPhase] = useState(item.phase ?? phases?.[0]?.id);
	const [type, setType] = useState(item.type ?? types?.[0]?.id);
	const [action, setAction] = useState(item.action ?? actions?.[0]?.id);
	const [ataCode, setAtaCode] = useState(item.ataCode ?? ataCodes?.[0]?.id);
	const [craft, setCraft] = useState(item.aircraft ?? {"_id": null});
  const [title, setTitle] = useState(item.title);
  const [workOrder, setWorkOrder] = useState()
  const [openVendorOption, setOpenVendorOption] = useState(false)
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false)

  useEffect(() => {
    setTicketType(item.ticketType ?? SMSTicketType.Maintenance.id)
    setAsapEvent(item.asapEvent ?? false)
    setDescription(item.description)
    setEventDate(item.eventDate ?? PRODate.now())
    setReaction(item.reaction)
    setFlightTime(item.flightTime)
    setDelayHours(item.delayHours)
    setPhase(item.phase ?? phases?.[0]?.id)
    setType(item.type ?? types?.[0]?.id)
    setAction(item.action ?? actions?.[0]?.id)
    setAtaCode(item.ataCode ?? ataCodes?.[0]?.id)
    setCraft(item.aircraft ?? { "_id": null })
    setTitle(item.title)
  }, [item])

	const save = (btn)=>{
		Api.post(window.jsRoutes.controllers.SMS.saveOpening(item._id.$oid),
			{
				"ticketType": ticketType,
				"asapEvent": asapEvent,
				"description": description,
				"eventDate": eventDate,
				"reaction": reaction,
				"flightTime": flightTime,
				"delayHours": delayHours,
				"phase": phase,
				"type": type,
				"action": action,
				"ataCode": ataCode,
				"aircraft": craft,
				"title": title,
			}
		)
			.then(Api.flagSuccess)
			.then(() => {
				setErrors([]);
				onSave();
			})
			.catch(e => {
				Api.flagFail(e);
				setErrors(e.json.errors || []);
			})
			.then(() => {
				btn.deactivate();
			});
	}

	useEffect(()=>{
		const call = new AbortController();
		Api.get(window.jsRoutes.controllers.AircraftController.list(), call)
			.then(result => {
				result.aircraft = result.aircraft || [];
				result.aircraft.unshift({"_id": null, "tailNumber": "Not Applicable"});
				setAircraft(result.aircraft);
			})
			.catch(Api.silentFail);
		return ()=>{
			call.abort();
		}
	}, [])

	useEffect(()=>{
		const call = new AbortController();
		Api.get(window.jsRoutes.controllers.Api.getSMSPhases(), call).then(response => {
			setPhases(response.phases);
		}).catch(Api.silentFail);
		return ()=>{
			call.abort();
		}
	}, [])

	useEffect(()=>{
		const call = new AbortController();
		Api.get(window.jsRoutes.controllers.Api.getSMSTypes(), call).then(response => {
			setTypes(response.types);
		}).catch(Api.silentFail);
		return ()=>{
			call.abort();
		}
	}, [])

	useEffect(()=>{
		const call = new AbortController();
		Api.get(window.jsRoutes.controllers.Api.getSMSATACodes(), call).then(response => {
			setAtaCodes(response.ataCodes)
		}).catch(Api.silentFail);
		return ()=>{
			call.abort();
		}
	}, [])

	useEffect(()=>{
		const call = new AbortController();
		Api.get(window.jsRoutes.controllers.Api.getSMSActions(), call).then(response => {
			setActions(response.actions);
		}).catch(Api.silentFail);
		return ()=>{
			call.abort();
		}
	}, [])

	return <Form bottom={<>
		{item?.logs?.opened?.user?._id ?
			<div className="text-center">Last edited
				by {item?.logs?.opened?.user?.firstName} {item?.logs?.opened?.user?.lastName} on {(item?.logs?.opened?.timestamp || PRODate.now()).format("MMM dd, yyyy")}
				<IconButton onClick={() => setOpenChangeLogModal(true)}><History/></IconButton>
			</div> : undefined}
    <Box sx={{ width: '100%', textAlign: 'end' }}>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={onClose}>Cancel</Button>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={save}>Save</Button>
    </Box>
	</>}>
    <ChangeLogModal open={openChangeLogModal} id={item?._id?.$oid} node="logs.opened" onClosed={() => setOpenChangeLogModal(false)}/>
    <VendorOptionsModal
      id={id}
      open={openVendorOption}
      onClose={() => setOpenVendorOption(false)} onSave={() => {
      setOpenVendorOption(false)
      onSave()
    }} />
    <Label name="Reported By" value={get(item, "createdBy.firstName") + " " + get(item, "createdBy.lastName")} />
    <WorkOrderAutocomplete key={workOrder?._id?.$oid} label={"Work Order"} workOrder={workOrder} onChange={v=> {
      Api.post(window.jsRoutes.controllers.SMS.setWorkOrder(item._id.$oid), { "workOrder": v?._id }).then(() => {
          setWorkOrder(v)
        })
    }} />
    <Box></Box>  
    <Box>
      <Button variant={"contained"} style={{ width: '49%', marginRight: '10px'}} className="bg-info" onClick={() => setOpenVendorOption(true)}>Vendor</Button>
      <Button variant={"contained"} style={{ width: '49%'}} onClick={() => Api.popout(window.jsRoutes.controllers.SMS.review(item._id.$oid), "ticket")}>View Report</Button>
    </Box>
		<DropdownInput name="Type of Event" value={ticketType} options={SMSTicketType.list()}
					   labelNode="name" onChange={v => setTicketType(v)}
					   error={includes(errors, "obj.ticketType")}/>
		<TextInput name="Title" value={title}
				   onChange={v => setTitle(v)}
				   error={includes(errors, "obj.title")}/>
		<YesOrNo name="ASAP Event" value={asapEvent}
				 onChange={v => setAsapEvent(v)}
				 error={includes(errors, "obj.asapEvent")}/>
		<TextAreaLabel key={"desc" + description} name="Description"
					   value={description || ""} onChange={v => setDescription(v)}
					   error={includes(errors, "obj.description")}/>
		<TextAreaLabel key={"react" + reaction} name="Reaction"
					   value={reaction || ""} onChange={v => setReaction(v)}
					   error={includes(errors, "obj.reaction")}/>
		<DropdownInput name="Type" value={type} options={types} onChange={v => setType(v)} error={includes(errors, "obj.type")}/>
		{item.leg
			?
			<>
				<TextInput name="Flight Time" value={flightTime || 0}
						   onChange={v => setFlightTime(v)}
						   error={includes(errors, "obj.flightTime")}/>
				<TextInput name="Delay Hours" value={delayHours || 0}
						   onChange={v => setDelayHours(v)}
						   error={includes(errors, "obj.delayHours")}/>
				<DropdownInput name="Phase" value={phase} options={phases}
							   onChange={v => setPhase(v)}
							   error={includes(errors, "obj.phase")}/>
				<DropdownInput name="Action" value={action} options={actions}
							   onChange={v => setAction(v)}
							   error={includes(errors, "obj.action")}/>
				<DropdownInput name="ATA Code" value={ataCode} options={ataCodes}
							   onChange={v => setAtaCode("ataCode", v)}
							   error={includes(errors, "obj.ataCode")}/>
			</>
			:
			<>
				<DropdownInput name="Tail Number" value={craft} allowNull={true}
							   options={aircraft} valueNode="_id.$oid" labelNode={Dropdown.Formatter.Aircraft}
							   valueOnly={false} onChange={v => setCraft(v)}
							   error={includes(errors, "obj.aircraft")}/>
				<DropdownInput name="ATA Code" value={ataCode} options={ataCodes}
							   onChange={v => setAtaCode(v)}
							   error={includes(errors, "obj.ataCode")}/>
			</>
		}
		<DateTimeInput name="Event Date" value={eventDate}
					   onChange={v => setEventDate(v)}
					   error={includes(errors, "obj.eventDate")}/>
	</Form>
}