import { selectTicket } from "@data/ticketReducer.jsx";
import Api from "@helpers/api";
import { DateInput } from "@helpers/dateInput";
import { Dropdown, DropdownInput } from "@helpers/dropdown";
import Form from "@helpers/form/form";
import { YesOrNo } from "@helpers/radioInput/radioInput";
import { SignatureInput } from "@helpers/signature";
import { TextAreaLabel } from "@helpers/textArea";
import { TextInput } from "@helpers/textInput";
import { MaintenanceAction } from "@helpers/utils/MaintenanceAction";
import { SMSTicketType } from "@helpers/utils/SMSTicketType";
import { History } from "@icons/history.jsx";
import { Box, Button, IconButton } from "@mui/material";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChangeLogModal from "../../../pages/modal/change-log";
import { styles } from "../../../pages/modal/common";

export const CorrectiveAction = ({id, call, safetyTeam, onSave, onClose}) => {
  const item = useSelector(selectTicket(id));
	const [errors, setErrors] = useState([]);

	const [control, setControl] = useState(item.control);
	const [maintenanceAction, setMaintenanceAction] = useState(item.maintenanceAction ?? MaintenanceAction.Grounded.id);
	const [complianceAction, setComplianceAction] = useState(item.complianceAction);
	const [revisionToManuals, setRevisionToManuals] = useState(item.revisionToManuals);
	const [faa, setFaa] = useState(item.faa);
	const [correctedBy, setCorrectedBy] = useState(item.correctedBy);
	const [user, setUser] = useState(item?.logs?.followedUp?.assigned?._id ?? safetyTeam?.[0]?._id);
  const [due, setDue] = useState(item?.logs?.followedUp?.due);
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false)

  useEffect(() => {
    setControl(item.control)
    setMaintenanceAction(item.maintenanceAction ?? MaintenanceAction.Grounded.id)
    setComplianceAction(item.complianceAction)
    setRevisionToManuals(item.revisionToManuals)
    setFaa(item.faa)
    setCorrectedBy(item.correctedBy)
    setUser(item?.logs?.followedUp?.assigned?._id ?? safetyTeam?.[0]?._id)
    setDue(item?.logs?.followedUp?.due)
  }, [item, safetyTeam])

	const save = ()=> {
		let out = {
			control: control,
			maintenanceAction: maintenanceAction,
			complianceAction: complianceAction,
			revisionToManuals: revisionToManuals,
			faa: faa,
			correctedBy: correctedBy,
			user: user,
			due: due?.timestamp,
		};
		Api.post(call || window.jsRoutes.controllers.SMS.saveControl(item._id.$oid), out)
			.then(Api.flagSuccess)
			.then(() => {
				setErrors([]);
				onSave();
			})
			.catch(e => {
				Api.flagFail(e);
				setErrors(e.json.errors || []);
			})
	}

	return <Form bottom={<>
		{item?.logs?.corrected?.user?._id ?
			<div className="text-center">
				Last edited by {item?.logs?.corrected?.user?.firstName} {item?.logs?.corrected?.user?.lastName} on {item?.logs?.corrected?.timestamp?.format?.("MMM dd, yyyy")}
				<IconButton onClick={() => setOpenChangeLogModal(true)}><History/></IconButton>
			</div>
			: undefined}
    <Box sx={{ width: '100%', textAlign: 'end' }}>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={onClose}>Cancel</Button>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={save}>Save</Button>
    </Box>
	</>}>
    <ChangeLogModal open={openChangeLogModal} id={item?._id?.$oid} node="logs.corrected" onClosed={() => setOpenChangeLogModal(false)}/>
		<TextAreaLabel key={control}
					   name={(item.ticketType === SMSTicketType.Change.id) ? "Needed Actions / Revisions" : "Corrective Action"}
					   value={control || ""} onChange={v => setControl(v)}
					   error={includes(errors, "obj.control")}/>
		{(item.ticketType === SMSTicketType.Maintenance.id) ?
			<DropdownInput name="Maintenance Action" value={maintenanceAction ?? -1} labelNode={"name"} valueNode={"id"}
						   options={MaintenanceAction.list()} onChange={v => setMaintenanceAction(v)}
						   error={includes(errors, "obj.maintenanceAction")}/>
		: undefined}
		{(item.ticketType === SMSTicketType.Maintenance.id && [1, 2, 4].includes(maintenanceAction)) ?
			<>
				<TextInput name="Corrected By" value={correctedBy?.name || ""}
						   onChange={v=> setCorrectedBy(p=> {
								 let state = {...p};
								 state.name = v;
								 return state;
							 })}/>
				<TextInput name="Certificate #" value={correctedBy?.certificate || ""}
						   onChange={v=> setCorrectedBy(p=> {
								 let state = {...p};
								 state.certificate = v;
								 return state;
							 })}/>
				<DateInput name="Date" value={correctedBy?.date}
						   onChange={v=> setCorrectedBy(p=> {
								 let state = {...p};
								 state.date = v;
								 return state;
							 })}/>
				<SignatureInput
					fields={["control","maintenanceAction","correctedBy.name","correctedBy.certificate","correctedBy.date"]}
					object={item}
					request={window.jsRoutes.controllers.SMS.signatureRequest(item._id.$oid, "rts")}
					sign={window.jsRoutes.controllers.SMS.signatureSign(item._id.$oid, "rts")}
					item={item?.signatures?.rts} onSave={onSave}
					note={<div style={{marginTop: "var(--spacing)"}}>
						Note: Make sure all your data is saved BEFORE digitally signing the data.
					</div>}
				/>
			</>
		: undefined}
		{(item.ticketType === SMSTicketType.Safety.id) ?
			<YesOrNo name="Compliance Action" value={complianceAction ?? ""}
					 onChange={v => setComplianceAction(v)}
					 error={includes(errors, "obj.complianceAction")}/>
		: undefined}
		{(item.cassEvent) ?
			<TextAreaLabel key={revisionToManuals} name="Revision to Manuals"
						   value={revisionToManuals || ""}
						   onChange={v => setRevisionToManuals(v)}
						   error={includes(errors, "obj.revisionToManuals")}/>
		: undefined}

		{(item.ticketType === SMSTicketType.Safety.id && !isEmpty(item.regulatoryEvent)) ?
			<>
				<TextInput name="FAA Inspector Name" value={faa?.name || ""}
						   onChange={v=> setFaa(p=> {
								 let state = {...p};
								 state.name = v;
								 return state;
							 })}/>
				<TextAreaLabel key={faa?.contact} name="FAA Contact Information"
							   value={faa?.contact || ""}
							   onChange={v=> setFaa(p=> {
									 let state = {...p};
									 state.contact = v;
									 return state;
								 })}
							   errors={<small>Include Email</small>}/>
			</>
		: undefined}

		{call ? false : <>
			<DropdownInput key={user || ""} name="Follow-Up Assigned To"
						   value={{_id:user}}
						   options={safetyTeam}
						   onChange={v=> setUser(v._id)}
						   valueNode="_id.$oid"
						   valueOnly={false}
						   labelNode={Dropdown.Formatter.UserName}
						   error={includes(errors, "obj.user")} />
			<DateInput name="Follow-Up Due" value={due}
					   onChange={v=> setDue(v)}
					   error={includes(errors, "obj.due")}/>
		</>}
	</Form>
}