import {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import get from "lodash/get";
import styles from "./style.module.scss";

export const Tooltip = ({top, left, right, interactive, children, raw}) => {
	const dom = useRef();
	const [_left, setLeft] = useState(left);
	const [_right, setRight] = useState(right);

	useEffect(() => {
		if (get(dom, "current.children.0")) {
			const rect = dom.current.children[0].getBoundingClientRect();
			if (rect.left < 0) {
				setRight(null);
				setLeft(_left - rect.left)
			}
			if (rect.right < 0) {
				setRight(null);
				setLeft(_left + rect.right);
			}
			if (rect.right > window.screen.availWidth) {
				setLeft(null);
				setRight(-rect.width + 20);
			}
		}
	}, [_left])

	return createPortal(
		<div ref={dom} className={[styles.proTooltip, (interactive ? "interactive" : "")].join(" ")}
			 style={{top: top, left: _left, right: _right}}>
			{raw ? raw : <div className="pro-tooltip-content">
				{children}
			</div>}
		</div>,
		document.getElementById("popoverHolder")
	);
}

export const SimpleTooltip = ({tooltip, test, onClick, style, className, children, raw}) => {
	const dom = useRef();
	const [popover, setPopover] = useState(false);

	const rect = dom.current ? dom.current.getBoundingClientRect() : null;
	let tt = <Tooltip
		top={(dom.current) ? rect.bottom + window.pageYOffset : 0}
		left={(dom.current) ? rect.left + window.pageXOffset + (rect.width / 2) : 0} raw={raw}>
		{tooltip}
	</Tooltip>;

	return <div className={[styles.simpleTooltip, className].join(" ")} ref={dom} onMouseEnter={() => {
		setPopover(true)
	}} onMouseLeave={() => {
		setPopover(false)
	}} onClick={(onClick) ? onClick : undefined} style={style}>
		{(popover || test) ? tt : undefined}
		{children}
	</div>;
}