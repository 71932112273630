import { Box, Button } from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import PROLabel from "../../helpers/label/label";
import BasicTable from "../../layout/table/table";
import { addNewButtonStyle } from "../../pages/modal/common";
import ReadyForMettingModal from "../../pages/modal/ready-for-meeting";
import { SMSEvent } from "./readyForMeeting";

export const SelectedEvents = ({ grid, className, style, selected, onChange, openTicketModal }) => {
  const [openSMSEventModal, setOpenSMSEventModal] = useState(false)

  const openSMSModal = useCallback(() => setOpenSMSEventModal(true), [])
  const closeSMSModal = useCallback(() => setOpenSMSEventModal(false), [])

  return <PROLabel grid={grid} value={<Fragment>
    <ReadyForMettingModal open={openSMSEventModal} title="Available SMS Events" onChange={onChange} selected={selected} openTicketModal={openTicketModal} onClose={closeSMSModal} type="ticket"/>
    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
      <Button variant="contained" sx={addNewButtonStyle} onClick={openSMSModal}>Add New</Button>
    </Box>
    <BasicTable className={className} template={"1fr auto auto"} style={style}
      headers={["Description", "Date", <div key={"toolbar"} className={"toolbar"}></div>]}>
      {(selected || []).map((item) =>
        <SMSEvent key={item._id.$oid} {...item} selected={false} onClick={openSMSModal} openTicketModal={openTicketModal} />
      )}
    </BasicTable>
  </Fragment>} />
}