import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import Api from '../../helpers/api';
import Form from '../../helpers/form/form';
import CompanySelector from '../../pages/company/selector';
import CustomerSelector from '../../pages/customers/selector';
import { TabPanel, styles } from './common';
import QuoteUtils from '../../pages/quote/utils';

type QuoteSendModalProps = {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  id: string | number;
  company: any;
  setSnackBarOpen: Function;
  setSnackBarMessage: Function;
  setSnackBarStatus: Function;
};

const initFormData = {
  contactBy: 'email',
  email: '',
  phone: '',
  message: '',
};

const TABS = ['Company', 'Customer', 'Send Quote']

const QuoteSend: React.FC<QuoteSendModalProps> = ({
  open,
  onCancel,
  onOk,
  setSnackBarOpen,
  setSnackBarMessage,
  setSnackBarStatus,
  id,
  company
}) => {
  const [isButtonDisabled, setButtonDisabled] = useState<any>(true);
  const [formData, setFormData] = useState<any>({});
  const isMobileView = useMediaQuery('(max-width:470px)');
  const isTabView = useMediaQuery('(max-width:700px)');
  const [tab, setTab] = useState<number>(0);
  const [localCompany, setLocalCompany] = useState<any>({});
  const [customer, setCustomer] = useState<any>({});

  const handleFormDataChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onTabChange = (_: React.SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const initializeData = () => {
    setButtonDisabled(true);
    let initFormDataCloned = cloneDeep(initFormData);
    setFormData({ ...initFormDataCloned });
  };

  const handleSend = () => {
      const payload = {
        subject: 'Quote For You',
        to: formData.email,
        message: formData.message,
        sendFile: false,
        sendFromTeam: false,
      };
      Api.post((window as any).jsRoutes.controllers.Quotes.emailQuote(id), payload)
        .then((result) => {
          setSnackBarMessage && setSnackBarMessage(result?.message);
          setSnackBarStatus && setSnackBarStatus(result.success ? 'success' : 'error');
          setSnackBarOpen && setSnackBarOpen(true);
          onOk();
        })
        .catch(Api.flagFail);
  };

  React.useEffect(() => {
    initializeData();
  }, [open]);

  React.useEffect(() => {
    setButtonDisabled(!QuoteUtils.isEmail(formData.email))
  }, [formData]);

  React.useEffect(() => {
    if (company) {
      setLocalCompany(company);
    }
  }, [company])

  const updateCompanyOrCustomer = (value: any, path: string) => {
    const payload = {
      '_id': id,
      path,
      value
    };
    return Api.post((window as any).jsRoutes.controllers.Dispatches.saveDispatchItem(), payload);
  }

  return (
    <Modal open={open} onClose={onCancel}>
      <Box sx={[styles.modal, isTabView ? styles.mobile : styles.desktop]}>
        <Box sx={styles.header}>
          <Box>Send Quote</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onCancel} />
        </Box>
        <Box
          sx={{ ...styles.body, paddingLeft: isMobileView ? '1px' : '15px' }}
        >
          <Tabs value={tab} onChange={onTabChange} sx={{ mb: '10px' }}>
            {TABS.map(tab => (
              <Tab key={tab} label={tab} id={`tab-${tab}`} sx={styles.tab} />
            ))}
          </Tabs>

          <TabPanel value={tab} index={0}>
            <Form className={'label-grid'} style={{}} bottom={undefined}>
              <CompanySelector labelClass={'control-label'} company={localCompany} onChange={(item: any) => {
                setLocalCompany(item);
                updateCompanyOrCustomer(item, 'client.company');
              }} />
            </Form>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Form className={'label-grid'} style={{}} bottom={undefined}>
              <CustomerSelector labelClass={'control-label'} label='Contact' customer={customer} company={localCompany} onChange={(item: any) => {
                setCustomer(item);
                updateCompanyOrCustomer(item, 'client.customer');
                const cloneFormData = cloneDeep(formData);
                cloneFormData.email = item.email;
                setFormData(cloneFormData);
              }} />
            </Form>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            {/* <Box sx={{ ...styles.control, alignItems: 'center' }}>
              <Typography sx={{ width: '150px !important' }}>
                Contact By{' '}
              </Typography>
              <RadioGroup
                row
                name='contactBy'
                onChange={(e) =>
                  handleFormDataChange(e.target.name, e.target.value)
                }
                value={formData.closed}
              >
                <FormControlLabel
                  value={'email'}
                  control={<Radio defaultChecked />}
                  label='Email'
                />
                <FormControlLabel value={'sms'} control={<Radio />} label='SMS' />
              </RadioGroup>
            </Box> */}

            {formData.contactBy === 'sms' && (
              <Box sx={{ ...styles.control, alignItems: 'center' }}>
                <Typography>Phone No</Typography>
                <FormControl variant='standard' sx={{ width: '100%' }}>
                  <TextField
                    size='small'
                    fullWidth
                    name='phone'
                    value={formData.phone}
                    onChange={(e) =>
                      handleFormDataChange(e.target.name, e.target.value)
                    }
                  />
                </FormControl>
              </Box>
            )}

            {formData.contactBy === 'email' && (
              <Box sx={{ ...styles.control, alignItems: 'center' }}>
                <Typography>Email</Typography>
                <FormControl variant='standard' sx={{ width: '100%' }}>
                  <TextField
                    size='small'
                    fullWidth
                    value={formData.email}
                    onChange={(e) =>
                      handleFormDataChange('email', e.target.value)
                    }
                  />
                </FormControl>
              </Box>
            )}

            <Box sx={{ ...styles.control, alignItems: 'center' }}>
              <Typography>Message</Typography>
              <FormControl variant='standard' sx={{ width: '100%' }}>
                <TextareaAutosize
                  minRows={3}
                  fullWidth
                  style={{
                    width: '100%',
                    border: '1px solid rgb(133, 133, 133)',
                    borderRadius: '5px',
                    padding: '10px',
                  }}
                  value={formData.message}
                  onChange={(e) =>
                    handleFormDataChange('message', e.target.value)
                  }
                />
              </FormControl>
            </Box>
          </TabPanel>

          <Box
            sx={[
              styles.actionButtons,
              {
                mt: '2rem',
                justifyContent: 'end',
                flexDirection: 'row',
                gap: '2px',
                alignItems: 'center',
              },
            ]}
          >
            <Button
              disabled={isButtonDisabled}
              variant='contained'
              sx={styles.action}
              onClick={handleSend}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default QuoteSend;
