/* eslint-disable react/display-name */
import { Button } from "@aws-amplify/ui-react";
import get from "lodash/get";
import { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from "react";
import Api from "../../helpers/api";
import Autocomplete from "../../helpers/autocomplete/autocomplete";
import Columns from "../../helpers/columns";
import PROLabel from "../../helpers/label/label";
import { usePopover } from "../../hooks/usePopover";
import { AddressBook } from "../../icons/addressBook";
import { PlusCircle } from "../../icons/circle/plus";
import { NoteIcon } from "../../icons/note";
import { Pencil } from "../../icons/pencil";
import CompanyModal from "../../pages/modal/company";
import CompanySelectorModal from "../../pages/modal/company-selector";
import CompanyNote from "./note";

const CompanySelector = forwardRef((props, ref) => {
	const autocompleteRef = useRef();
	const [popover, setPopover, clearPopover] = usePopover();
	const [query, setQuery] = useState(get(props, "company.name") || "");
	const [autocomplete, setAutocomplete] = useState([]);
	const [data, setData] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [editCompany, setEditCompany] = useState('');
  const [addCompanyModal, setAddCompanyModal] = useState(false);

	const company = Object.assign({name: ""}, props.company);

	const onChange = (company) => {
		if (query === "") {
			company = null
		}
		if (company && company.name && company.name !== "") {
			setQuery(company.name)
		} else {
			setQuery(null);
		}
		props.onChange(company);
	}

	const onInputChange = (value) => {
		if (value === "") {
			props.onChange(null);
		}
		setQuery(value);
	}

	const listSelect = (item) => {
		props.onChange(item);
		setQuery(item.name);
	}

	const toggleCreate = () => {
    setEditCompany(undefined);
    setModalOpen(true);
	}

	const showNote = () => {
		setPopover(<CompanyNote _id={company._id} onClosed={clearPopover} />);
	}

  const toggleInfo = () => {
    setEditCompany(get(company, "_id.$oid"));
    setModalOpen(true);
	}

	const fallback = useRef();
	ref = ref || fallback;

	useEffect(()=>{
		const call = new AbortController();
		if (query) {
			Api.post(window.jsRoutes.controllers.Companies.search(), {term: query, active: true}, call)
				.then(results => {
					setAutocomplete(results.companies || []);
				})
				.catch(Api.flagFail);
		}
		return ()=>{
			call.abort()
		}
	}, [query])

	useEffect(() => {
		let call = new AbortController();
		if (!company?._id?.$oid) return;
		Api.get(window.jsRoutes.controllers.Companies.get(company?._id?.$oid), call)
			.then(r => {
				setData(r.company);
			})
			.catch(Api.silentFail)
		return () => {
			call.abort()
		}
	}, [company._id])

	useImperativeHandle(ref, () => ({
		focus: () => {
			autocompleteRef.current.focus();
		}
	}), [autocompleteRef]);

	return <Fragment>
    {popover}
    <CompanySelectorModal listSelect={listSelect} onClose={() => setAddCompanyModal(false) } open={addCompanyModal} />
    <CompanyModal open={isModalOpen} onCancel={() => setModalOpen(false)} onOk={() => setModalOpen(false)} editCompanyId={editCompany} />
		<PROLabel labelClass={props.labelClass} grid={props.grid} name={"Company"} hideLabel={props.hideLabel} value={
			<Columns template="1fr auto auto auto">
				<Autocomplete
					ref={autocompleteRef}
					canClear={true}
					style={{width: "100%"}}
					selected={query}
					options={autocomplete}
					node="name"
					onInputChange={onInputChange}
					onChange={onChange}
					append={get(company, "_id.$oid") ?
						<Button className="text-cancel" onClick={toggleInfo}>
							<Pencil />
						</Button>
					: false}
				/>
				{data.notes ? <button onClick={showNote}><NoteIcon /></button> : null}
				<button onClick={() => setAddCompanyModal(true)}><AddressBook /></button>
				<button className="text-primary" onClick={toggleCreate}><PlusCircle /></button>
			</Columns>
		}/>
	</Fragment>
})
export default CompanySelector;