/* eslint-disable react/display-name */
import {forwardRef} from "react";
import styles from "./style.module.scss";

export const Panel = forwardRef(({title, prepend, append, additional, footer, passthru, noPadding, headerClass, bodyStyle, footerStyle, className, style, children, headerClick}, ref)=> {
	return <div className={[styles.proPanel, (className || "") + (passthru ? "passthru" : "")].join(" ")} style={style}>
		{(title) ? <div className={"header " + (headerClass || "") + (passthru ? " full-width" : "")} onClick={headerClick}>
			{prepend ? prepend : undefined}
			<h3 className="title">{title}</h3>
			{append ? append : undefined}
		</div> : undefined}
		<div ref={ref} className={"body" + (passthru ? " passthru" : "") + (noPadding ? " no-padding" : "")} style={bodyStyle}>
			{children}
		</div>
		{(additional) ? additional : undefined}
		{(footer) ? <div className="footer" style={footerStyle}>
			{footer}
		</div> : undefined}
	</div>
});