import {SetStateAction, useState} from "react";

export function usePopover() {
	const [popover, setPopover] = useState(null);
	const [counter, setCounter] = useState(0);

	const unsetPopover = () => {
		setCounter(p => p+1);
		setPopover(null)
	}

	return [popover, (e: SetStateAction<null>)=>{
		setCounter(p => p+1);
		setPopover(e);
	}, unsetPopover, counter];
}