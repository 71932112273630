import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CSSProperties } from 'react';
import { VendorOptions } from '../../pages/sms/components/vendor-options';
import { styles } from './common';

type VendorOptionslProps = {
  open: boolean
  id: string
  onClose: () => void
  onSave: () => void
}

const localStyles: { [x: string]: CSSProperties | {} } = {
  customModal: {
    width: '80% !important'
  }
}

const VendorOptionsModal: React.FC<VendorOptionslProps> = ({ id, open, onClose, onSave }) => {
  const isMobileView = useMediaQuery('(max-width:700px)');

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={[styles.modal, localStyles.customModal, isMobileView ? styles.mobile : styles.desktop]}>
        <Box sx={styles.header}>
          <Box></Box>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </Box>
        <Box sx={styles.body}>
          <VendorOptions id={id} onClosed={onClose} loadData={onSave} />
        </Box>
      </Box>
    </Modal>
  )
}

export default VendorOptionsModal;