import React, { CSSProperties, useEffect, useRef, useState } from 'react'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import useMediaQuery from '@mui/material/useMediaQuery'

import { get, has, isPlainObject } from 'lodash'
import { useDispatch } from 'react-redux'
import { setTicket } from '../../../data/ticketReducer'
import Api from '../../../helpers/api'
import IoTSocket from '../../../helpers/iotSocket'
import Loading from '../../../helpers/loading/loading'
import PRODate from '../../../helpers/proDate'
import SnackBar from '../../../helpers/snackBar'
import { AttachmentsView } from '../../../helpers/upload'
import { SMSStatus } from '../../../helpers/utils/SMSStatus'
import { SMSTicketType } from '../../../helpers/utils/SMSTicketType'
import { useMenu } from '../../../hooks/useMenu'
import { usePopover } from '../../../hooks/usePopover'
import { CheckCircle } from '../../../icons/circle/check'
import { Confirm } from '../../../layout/confirmModal'
import { Analysis } from '../../../pages/sms/components/analysis'
import { CorrectiveAction } from '../../../pages/sms/components/correctiveAction'
import { Deferred } from '../../../pages/sms/components/deferred'
import { FollowUp } from '../../../pages/sms/components/followUp'
import { Initial } from '../../../pages/sms/components/initial'
import { Processing } from '../../../pages/sms/components/processing'
import { Review } from '../../../pages/sms/components/review'
import { RII } from '../../../pages/sms/components/rii'
import Create from '../../../pages/sms/create'
import { styles } from '../common'

type TicketModalProps = {
  open: boolean
  onCancel: () => void
  onSave: () => void
  editTicketId: string
  leg?: any
}

const localStyles: { [x: string]: CSSProperties | {} } = {
  customModal: {
    width: '70% !important',
    overflow: 'hidden'
  },
}

const TicketModal: React.FC<TicketModalProps> = ({ open, onCancel, onSave, editTicketId, leg }) => {
  const isMobileView = useMediaQuery('(max-width:700px)')
  const menu = useMenu()
  const dispatch = useDispatch()
  const [popover, setPopover, clearPopover] = usePopover()
  const [socketReady, setSocketReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [forceUpdate, setForceUpdate] = useState(0)
  const [selected, setSelected] = useState<any>(1)
  const [item, setItem] = useState<any>({})
  const [severities, setSeverities] = useState([])
  const [likelihoods, setLikelihoods] = useState([])
  const [matrix, setMatrix] = useState([])
  const [safetyTeam, setSafetyTeam] = useState([])
  const [phases, setPhases] = useState([])
  const [types, setTypes] = useState([])
  const [actions, setActions] = useState([])
  const [ataCodes, setAtaCodes] = useState([])
  const [spis, setSpis] = useState<any>([])
  const [ticketType, setTicketType] = useState<any>(SMSTicketType.Safety.id)
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const snackBarRef = useRef({ message: '', status: '' });
  
  useEffect(() => {
    menu.setTitle('SMS Ticket')
    menu.setBreadcrumb(['Safety Management', 'Tickets'])
    menu.setSubtitle('ID ' + (item.id || ''))
  }, [menu, item.id])

  useEffect(() => {
    if (!open) {
      return
    }
    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.Api.getSMSPhases(), call)
      .then((response) => {
        setPhases(response.phases)
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      return
    }
    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.Api.getSMSTypes(), call)
      .then((response) => {
        setTypes(response.types)
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      return
    }
    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.Api.getSMSActions(), call)
      .then((response) => {
        setActions(response.actions)
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      return
    }
    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.Api.getSMSATACodes(), call)
      .then((response) => {
        setAtaCodes(response.ataCodes)
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      return
    }
    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.Api.getSeverities(), call)
      .then((response) => {
        setSeverities(response.severities)
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      return
    }
    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.Api.getLikelihoods(), call)
      .then((response) => {
        setLikelihoods(response.likelihoods)
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      return
    }
    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.Api.getRiskMatrix(), call)
      .then((response) => {
        setMatrix(response.matrix)
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      return
    }
    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.Api.getSafetyTeam(), call)
      .then((response) => {
        setSafetyTeam(response.users)
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      return
    }
    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.Operations.getSPIs(true), call)
      .then((response) => {
        setSpis([{ name: 'None', _id: { $oid: 0 } }].concat(response.spis))
      })
      .catch(Api.silentFail)
    return () => {
      call.abort()
    }
  }, [open])

  useEffect(() => {
    if (!socketReady || !open || editTicketId === 'new') return
    setIsLoading(true)
    Api.get((window as any).jsRoutes.controllers.SMS.get(editTicketId))
      .then((response) => {
        response.sms.eventDate = new PRODate(response.sms.eventDate)
        response.sms.reviewDate = new PRODate(response.sms.reviewDate)
        response.sms.resolutionDate = new PRODate(response.sms.resolutionDate)
        if (get(response, 'sms.createdOn')) {
          response.sms.createdOn = new PRODate(response.sms.createdOn)
        }
        if (get(response, 'sms.certified.date')) {
          response.sms.certified.date = new PRODate(response.sms.certified.date)
        }
        let reviewDue = get(response.sms, 'logs.corrected.due')
        if (reviewDue) {
          response.sms.logs.corrected.due = new PRODate(reviewDue)
        }
        let followUpDue = get(response.sms, 'logs.followedUp.due')
        if (followUpDue) {
          response.sms.logs.followedUp.due = new PRODate(followUpDue)
        }
        let correctedBy = get(response.sms, 'correctedBy.date')
        if (correctedBy) {
          response.sms.correctedBy.date = new PRODate(correctedBy)
        }
        let deferredBy = get(response.sms, 'deferredBy.date')
        if (deferredBy) {
          response.sms.deferredBy.date = new PRODate(deferredBy)
        }
        let riiBy = get(response.sms, 'riiBy.date')
        if (riiBy) {
          response.sms.riiBy.date = new PRODate(riiBy)
        }
        let deferredExpire = get(response.sms, 'deferred.expire')
        if (deferredExpire) {
          response.sms.deferred.expire = new PRODate(deferredExpire)
        }
        if (response?.sms?.mis?.due) {
          response.sms.mis.due = new PRODate(response.sms.mis.due)
        }
        if (has(response.sms, 'logs.opened.timestamp')) {
          response.sms.logs.opened.timestamp = new PRODate(get(response.sms, 'logs.opened.timestamp'))
        }
        if (has(response.sms, 'logs.processed.timestamp')) {
          response.sms.logs.processed.timestamp = new PRODate(get(response.sms, 'logs.processed.timestamp'))
        }
        if (has(response.sms, 'logs.deferred.timestamp')) {
          response.sms.logs.deferred.timestamp = new PRODate(get(response.sms, 'logs.deferred.timestamp'))
        }
        if (has(response.sms, 'logs.analyzed.timestamp')) {
          response.sms.logs.analyzed.timestamp = new PRODate(get(response.sms, 'logs.analyzed.timestamp'))
        }
        if (has(response.sms, 'logs.reviewed.timestamp')) {
          response.sms.logs.reviewed.timestamp = new PRODate(get(response.sms, 'logs.reviewed.timestamp'))
        }
        if (has(response.sms, 'logs.corrected.timestamp')) {
          response.sms.logs.corrected.timestamp = new PRODate(get(response.sms, 'logs.corrected.timestamp'))
        }
        if (has(response.sms, 'logs.corrected.due')) {
          response.sms.logs.corrected.due = new PRODate(get(response.sms, 'logs.corrected.due'))
        }
        if (has(response.sms, 'logs.followedUp.timestamp')) {
          response.sms.logs.followedUp.timestamp = new PRODate(get(response.sms, 'logs.followedUp.timestamp'))
        }
        if (has(response.sms, 'deferred.extension')) {
          response.sms.deferred.extension = new PRODate(get(response.sms, 'deferred.extension'))
        }
        dispatch(setTicket({key: editTicketId, ...response.sms}))
        setItem(response.sms)
        setTicketType(SMSTicketType.parse(response.sms))
      })
      .catch((e) => {
        alert(e)
        Api.changeUrl('/safety')
      })
      .then(() => {
        setIsLoading(false)
      })
  }, [open, forceUpdate, editTicketId, socketReady, dispatch])

  useEffect(() => {
    if (!open || editTicketId === 'new') return

    const socket = IoTSocket.subscribe(editTicketId, (data: any) => {
      if (data.value.loading) {
        setLoadingMessage(data.value.loading)
      }
    })

    setSocketReady(true)

    return () => {
      socket.unsubscribe()
      setSocketReady(false)
    }
  }, [editTicketId, open])

  const loadData = () => {
    if (editTicketId !== 'new') {
      snackBarRef.current = {
        message: 'Ticket Saved!',
        status: 'success'
      }
      setOpenSnackbar(true);
    }
    onSave()
    setForceUpdate((p) => p + 1)
  }

  const saveImage = (modal: any, image: any) => {
    Api.post((window as any).jsRoutes.controllers.SMS.uploadImage(item._id.$oid), image)
      .then(Api.flagSuccess)
      .then(() => {
        setForceUpdate((p) => p + 1)
      })
      .catch(Api.flagFail)
  }

  const deleteImage = (image: any) => {
    setPopover(
      <Confirm
        title={'Delete Attachment'}
        message={'Are you sure you want to delete this attachment?'}
        onClosed={clearPopover}
        onConfirm={(modal: any) => {
          Api.delete((window as any).jsRoutes.controllers.SMS.removeImage(item._id.$oid, image.id))
            .then(Api.flagSuccess)
            .catch(Api.flagFail)
            .then(() => {
              setForceUpdate((p) => p + 1)
            })
            .then(() => {
              modal.close()
            })
        }}
      />
    )
  }

  const initial = () => ({
    value: SMSStatus.Opened.id,
    label: (
      <div key={SMSStatus.Opened.id} className="no-wrap">
        <span>{SMSStatus.Opened.name}</span>
        <span>{item?.logs?.opened?.user?._id ? <CheckCircle className="text-success white-badge-bg" style={{ marginLeft: 'var(--spacing)' }} /> : undefined}</span>
      </div>
    ),
    object: <Initial id={editTicketId} key={forceUpdate} onClose={onCancel} onSave={loadData} />,
  })

  const processing = () => {
    return {
      value: SMSStatus.Processing.id,
      label: (
        <div key={SMSStatus.Processing.id} className="no-wrap">
          <span>{SMSStatus.Processing.name}</span>
          <span>{item?.logs?.processed?.user?._id ? <CheckCircle className="text-success white-badge-bg" style={{ marginLeft: 'var(--spacing)' }} /> : undefined}</span>
        </div>
      ),
      object: <Processing id={editTicketId} key={forceUpdate} safetyTeam={safetyTeam} onClose={onCancel} onSave={loadData} />,
    }
  }

  const deferred = () => {
    return {
      value: SMSStatus.Deferred.id,
      label: (
        <div key={SMSStatus.Deferred.id} className="no-wrap">
          <span>{SMSStatus.Deferred.name}</span>
          <span>{item?.logs?.deferred?.user?._id ? <CheckCircle className="text-success white-badge-bg" style={{ marginLeft: 'var(--spacing)' }} /> : undefined}</span>
        </div>
      ),
      object: <Deferred key={forceUpdate} item={item} onClose={onCancel} onSave={loadData} call={undefined} />,
    }
  }

  const analysis = () => {
    return {
      value: SMSStatus.Analysis.id,
      label: (
        <div key={SMSStatus.Analysis.id} className="no-wrap">
          <span>{SMSStatus.Analysis.name}</span>
          <span>{item?.logs?.analyzed?.user?._id ? <CheckCircle className="text-success white-badge-bg" style={{ marginLeft: 'var(--spacing)' }} /> : undefined}</span>
        </div>
      ),
      object: <Analysis key={forceUpdate} item={item} severities={severities} likelihoods={likelihoods} spis={spis} matrix={matrix} onClose={onCancel} onSave={loadData} />,
    }
  }

  const review = () => {
    return {
      value: SMSStatus.Review.id,
      label: (
        <div key={SMSStatus.Review.id} className="no-wrap">
          <span>{item.asapEligible ? 'ERC' : SMSStatus.Review.name}</span>
          <span>{item?.logs?.reviewed?.user?._id ? <CheckCircle className="text-success white-badge-bg" style={{ marginLeft: 'var(--spacing)' }} /> : undefined}</span>
        </div>
      ),
      object: <Review id={editTicketId} key={forceUpdate} safetyTeam={safetyTeam} onClose={onCancel} onSave={loadData} />,
    }
  }

  const corrective = () => {
    return {
      value: SMSStatus.Control.id,
      label: (
        <div key={SMSStatus.Control.id} className="no-wrap">
          <span>{SMSStatus.Control.name}</span>
          <span>{item?.logs?.corrected?.user?._id ? <CheckCircle className="text-success white-badge-bg" style={{ marginLeft: 'var(--spacing)' }} /> : undefined}</span>
        </div>
      ),
      object: <CorrectiveAction id={editTicketId} key={forceUpdate} safetyTeam={safetyTeam} onClose={onCancel} onSave={loadData} call={undefined} />,
    }
  }

  const rii = () => {
    return {
      value: SMSStatus.RII.id,
      label: (
        <div key={SMSStatus.RII.id} className="no-wrap">
          <span>{SMSStatus.RII.name}</span>
          <span>{item?.logs?.rii?.user?._id ? <CheckCircle className="text-success white-badge-bg" style={{ marginLeft: 'var(--spacing)' }} /> : undefined}</span>
        </div>
      ),
      object: <RII id={editTicketId} key={forceUpdate} onClose={onCancel} onSave={loadData} call={undefined} />,
    }
  }

  const followUp = () => {
    return {
      value: SMSStatus.Resolution.id,
      label: (
        <div key={SMSStatus.Resolution.id} className="no-wrap">
          <span>{SMSStatus.Resolution.name}</span>
          <span>{item?.logs?.followedUp?.user?._id ? <CheckCircle className="text-success white-badge-bg" style={{ marginLeft: 'var(--spacing)' }} /> : undefined}</span>
        </div>
      ),
      object: <FollowUp id={editTicketId} key={forceUpdate} onClose={onCancel} onSave={loadData} />,
    }
  }

  const closed = () => {
    return {
      value: SMSStatus.Closed.id,
      label: (
        <div key={SMSStatus.Closed.id}>
          <span>{SMSStatus.Closed.name}</span>
          <span>{SMSStatus.compare(item.status, SMSStatus.Closed) > 0 ? <CheckCircle className="text-success white-badge-bg" /> : undefined}</span>
        </div>
      ),
      object: <h3 className="text-center">This ticket has been closed.</h3>,
    }
  }

  let _items: any[] = ticketType === SMSTicketType.Maintenance && !item.cassEvent ? [initial, processing, corrective, closed] : [initial, processing, analysis, review, corrective, followUp, closed]
  if (item.rii) {
    _items.splice(!item.cassEvent ? 3 : 5, 0, rii)
  }
  if (isPlainObject(item.deferred)) {
    _items.splice(2, 0, deferred)
  }
	let items = _items.map((item, ind) => item(ind + 1));

	if (item.status !== selected && !items.map(i => i.value).includes(selected)) {
		setSelected(item.status ?? null);
	}

  return (
    <Modal open={open} onClose={onCancel}>
      <Box sx={[styles.modal, isMobileView ? styles.mobile : { ...styles.desktop, ...localStyles.customModal, height: editTicketId !== 'new' ? '80%' : 'auto' }]}>
        <SnackBar handleClose={() => setOpenSnackbar(false)} message={snackBarRef.current.message} open={openSnackbar} status={snackBarRef.current.status} />
        <Loading active={isLoading} message={loadingMessage} style={undefined} />
        {popover}
        <Box sx={styles.header}>
          <Box>{editTicketId !== 'new' ? 'Edit Ticket' : 'Add New Ticket'}</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onCancel} />
        </Box>
        <Box sx={styles.body}>
          {editTicketId === 'new' ? (
            <Create ticketType={ticketType?.id} onClosed={onCancel} onSave={() => {
              onCancel()
              loadData()
            }} />
          ) : (
            <>
              <Tabs
                value={selected}
                onChange={(_, p) => {
                  if (p === SMSStatus.Closed.id && item.status !== SMSStatus.Closed.id) {
                    return
                  }
                  setSelected(p)
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                {items.map((i) => (
                  <Tab key={i.value} label={i.label} value={i.value} disabled={i.value === SMSStatus.Closed.id && item.status !== SMSStatus.Closed.id} />
                ))}
              </Tabs>
                {selected === 1 && <Initial id={editTicketId} key={forceUpdate} item={item} phases={phases} types={types} ataCodes={ataCodes} actions={actions} onClose={onCancel} onSave={loadData} items={items} saveImage={saveImage} deleteImage={deleteImage}/>}
                {items.find((i) => i.value === selected && selected !== 1)?.object}
                <AttachmentsView items={item.images} onSave={saveImage} onDelete={deleteImage} label={undefined} placeholder={undefined} allowEdit={undefined} meta={undefined} className={undefined} style={undefined} grid={undefined} newModal />
            </>
          )}
        </Box>
        <Box sx={styles.footer}></Box>
      </Box>
    </Modal>
  )
}

export default TicketModal
