import { selectTicket } from "@data/ticketReducer.jsx";
import Api from "@helpers/api";
import { DateInput } from "@helpers/dateInput";
import Form from "@helpers/form/form";
import Label from "@helpers/label/label";
import { SignatureInput, SignatureView } from "@helpers/signature";
import { TextInput } from "@helpers/textInput";
import { MaintenanceAction } from "@helpers/utils/MaintenanceAction";
import { SMSTicketType } from "@helpers/utils/SMSTicketType";
import { History } from "@icons/history.jsx";
import { Box, Button, IconButton } from "@mui/material";
import ChangeLogModal from "../../../pages/modal/change-log";
import { useState } from "react";
import { useSelector } from "react-redux";
import { styles } from "../../../pages/modal/common";

export const RII = ({id, call, onSave, onClose}) => {
  const item = useSelector(selectTicket(id));
	const [riiBy, setRiiBy] = useState(item.riiBy);
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false)

	const save = (btn)=>{
		let out = {
			riiBy: riiBy
		};
		Api.post(call || window.jsRoutes.controllers.SMS.saveRII(item._id.$oid), out)
			.then(Api.flagSuccess)
			.then(() => {
				onSave();
			})
			.catch(e => {
				Api.flagFail(e);
			})
			.then(() => {
				btn.deactivate();
			});
	}

	return <Form bottom={<>
		{item?.logs?.rii?.user?._id ?
			<div className="text-center">
				Last edited by {item?.logs?.rii?.user?.firstName} {item?.logs?.rii?.user?.lastName} on {item?.logs?.rii?.timestamp?.format?.("MMM dd, yyyy")}
				<IconButton onClick={() => setOpenChangeLogModal(true)}><History/></IconButton>
			</div>
			: undefined}
    <Box sx={{ width: '100%', textAlign: 'end' }}>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={onClose}>Cancel</Button>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={save}>Save</Button>
    </Box>
	</>}>
    <ChangeLogModal open={openChangeLogModal} id={item?._id?.$oid} node="logs.rii" onClosed={() => setOpenChangeLogModal(false)}/>
		<Label name={(item.ticketType === SMSTicketType.Change.id) ? "Needed Actions / Revisions" : "Corrective Action"} value={item.control || ""} />
		{(item.ticketType === SMSTicketType.Maintenance.id) ?
			<Label name="Maintenance Action" value={MaintenanceAction.parse(item.maintenanceAction)?.name} />
		: undefined}
		{(item.ticketType === SMSTicketType.Maintenance.id && [1, 2, 4].includes(item.maintenanceAction)) ?
			<>
				<Label name="Corrected By" value={item?.correctedBy?.name || ""} />
				<Label name="Certificate #" value={item?.correctedBy?.certificate || ""} />
				<Label name="Date" value={item?.correctedBy?.date?.format?.()} />
				<SignatureView item={item?.signatures?.rts} onSave={onSave} />
			</>
		: undefined}
		{(item.ticketType === SMSTicketType.Maintenance.id && item.rii) ?
			<>
				<TextInput name="RII Inspector Name" value={riiBy?.name || ""}
						   onChange={v=> setRiiBy(p=> {
								 let state = {...p};
								 state.name = v;
								 return state;
							 })}/>
				<TextInput name="Certificate #" value={riiBy?.certificate || ""}
						   onChange={v=> setRiiBy(p=> {
								 let state = {...p};
								 state.certificate = v;
								 return state;
							 })}/>
				<DateInput name="Date" value={riiBy?.date}
						   onChange={v=> setRiiBy(p=> {
								 let state = {...p};
								 state.date = v;
								 return state;
							 })}/>
				<SignatureInput
					fields={["control","maintenanceAction","riiBy.name","riiBy.certificate","riiBy.date"]}
					object={item}
					request={window.jsRoutes.controllers.SMS.signatureRequest(item._id.$oid, "rii")}
					sign={window.jsRoutes.controllers.SMS.signatureSign(item._id.$oid, "rii")}
					item={item?.signatures?.rii} onSave={onSave}
					note={<div style={{marginTop: "var(--spacing)"}}>
						Note: Make sure all your data is saved BEFORE digitally signing the data.
					</div>}
				/>
			</>
			: undefined}
	</Form>
}