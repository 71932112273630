import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@mui/material';
import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";
import extend from "lodash/extend";
import find from "lodash/find";
import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isUndefined from "lodash/isUndefined";
import ulast from "lodash/last";
import map from "lodash/map";
import set from "lodash/set";
import unset from "lodash/unset";
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AirportInput from "../../../helpers/airportInput";
import Api from "../../../helpers/api";
import Autocomplete from "../../../helpers/autocomplete/autocomplete";
import { PRODateTimePicker } from '../../../helpers/calendar';
import Columns from "../../../helpers/columns";
import { DropdownInput } from "../../../helpers/dropdown";
import Form from "../../../helpers/form/form";
import PROLabel from "../../../helpers/label/label";
import PRODate from '../../../helpers/proDate';
import SimpleInput from "../../../helpers/simpleInput";
import { TextInput } from "../../../helpers/textInput";
import { toTenths } from "../../../helpers/utils/formatters";
import Promises from "../../../helpers/utils/promises";
// import { FromTo } from "../../../icons/fromTo";
import { styles as commonStyles } from '../../../pages/modal/common';
import CompanySelector from "../../company/selector";
import CustomerSelector from "../../customers/selector";
import styles from "./style.module.scss";

const NewQuote = ({date, craftId, initialOffFleet, from, type, onClosed, onSave}) => {
	const startDate = date || PRODate.now().withHours(8).withMinutes(0).withSeconds(0, 0);

	const navigate = useNavigate();
	const companySelector = useRef();
	const [craft, setCraft] = useState(craftId);
	const [offFleet, setOffFleet] = useState(initialOffFleet);
	const [offFleetTerm, setOffFleetTerm] = useState(offFleet ? (offFleet.tailNumber + " - " + get(offFleet, "type.name")) : undefined);
	const [aircraft, setAircraft] = useState([]);
	const [autocompleteOffFleet, setAutocompleteOffFleet] = useState([]);
	const [forceUpdate, setForceUpdate] = useState(0);
	const [client, setClient] = useState({});
	const [showNextLegButton, setShowNextLegButton] = useState(false);
	const [pairs, setPairs] = useState([
		{
			from: from || "",
			to: "",
			date: new PRODate(startDate.timestamp, 0, "L"),
			elt: null,
			pax: null
		}
	]);

	useEffect(()=>{
		window.awsRum?.recordEvent?.("modal.view", {type:type, action:"open"});
	}, [type])

	const changedAirport = (key, index, obj)=>{
		if (!obj) return;
		setPairs(p => {
			if (p[index][key] === (obj.icao ?? obj.iata ?? obj.faa)) return p;
			let state = cloneDeep(p);
			set(state, index + "." + key, (obj.icao ?? obj.iata ?? obj.faa));
			delete(state[index]["calcs"]);

			if (key === "to") {
				if (state[index + 1]) {
					set(state, (index + 1) + ".from", (obj.icao ?? obj.iata ?? obj.faa));
					delete(state[index+1]["calcs"]);
				}
			}

			let setNewLegButtonActive = showNextLegButton;
			if (index + 1 >= pairs.length && !setNewLegButtonActive) {
				setNewLegButtonActive = true;
			}
			setShowNextLegButton(setNewLegButtonActive);

			return state;
		});
	}

	const save = (edit)=>{
		window.awsRum?.recordEvent?.("modal.view", {type:type, action:"save"});
		let error = false;
		for (let pair of pairs) {
			if (isEmpty(pair.from) || isEmpty(pair.to)) {
				error = true;
			}
		}
		if (error) {
			alert("You are missing an airport pair.");
			return;
		}
		let payload = {
			"aircraft": (craft === "off") ? get(offFleet, "_id.$oid") || get(offFleet, "type._id.$oid") : craft,
			"client": client,
			"pairs": pairs.map(item=> ({
				from: item.from,
				to: item.to,
				date: item.date.timestamp,
				pax: item.pax
			}) )
		};
		if (type === "Quote") {
			Api.post(window.jsRoutes.controllers.Quotes.create(), payload)
				.then(Api.flagSuccess)
        .then(response => {
          if (edit) {
            navigate(`/flights/quotes/edit/${response.dispatch.$oid}`);
          }
          onSave();
				})
				.catch(Api.flagFail)
		} else {
			Api.post(window.jsRoutes.controllers.Dispatches.createDispatch(), payload)
				.then(Api.flagSuccess)
        .then(response => {
          if (edit) {
            navigate(`/flights/dispatches/edit/${response.dispatch.$oid}`);
          }
          onSave();
				})
				.catch(Api.flagFail)
		}
	}

	const changeDate = (index, key, date)=>{
		if (!(date instanceof PRODate)) {
			setForceUpdate(p => p+1);
			return
		}
		if (has(pairs[index].calcs, "from")) {
			let fDate, tDate;
			let promises = new Promises();
			if (key === "from") {
				fDate = date;
				if (has(pairs[index].calcs, "minutes")) {
					tDate = fDate.plus(pairs[index].calcs.minutes);
				}
			} else {
				if (has(pairs[index].calcs, "minutes")) {
					fDate = date.minus(pairs[index].calcs.minutes);
				}
				tDate = date;
			}
			promises.push(fDate.withZoneId(pairs[index].calcs.from.timezone));
			promises.push(tDate.withZoneId(pairs[index].calcs.to.timezone));
			Promise.all(promises).then(values => {
				let state = cloneDeep(pairs);
				set(state, index + ".date", values[0] || null);
				set(state, index + ".elt", values[1] || null);
				setPairs(state);
			});
		} else {
			let state = cloneDeep(pairs);
			set(state, index + ".date", date);
			setPairs(state);
		}
	}

	const addNewPair = ()=>{
		let last = ulast(pairs);
		let arrival = last.date.clone();
		if (has(last.calcs, "minutes")) {
			arrival = arrival.plus(last.calcs.minutes + 60);
		}
		let moveFocus = () => {
			setTimeout(()=>{
				let dom = document.getElementById("lastFocus");
				if (dom) dom.focus();
			}, 300)
		};
		if (has(last.calcs, "to")) {
			arrival.withZoneId(last.calcs.to.timezone)
				.then(r => {
					let newCraft = aircraft.find(item => item.id === craft);
					let realPax = (pairs.findIndex(i => i === last) === 0 && last.pax === 0) ? get(newCraft, "paxSeats") || 0 : last.pax;
					setPairs(p => {
						let state = cloneDeep(p);
						state.push({from: last.to, to: "", date: r, pax: realPax});
						return state
					})
					moveFocus()
				});
		} else {
			setPairs(p => {
				let state = cloneDeep(p);
				state.push({from: last.to, to: "", date: arrival, pax: last.pax});
				return state
			})
			moveFocus()
		}
	}

	const changeAircraft = (isOffFleet, value)=>{
		const oldId = craft;
		if (isOffFleet) {
			if (isNumber(value.type)) {
				setOffFleet({"type": value});
			} else {
				setOffFleet(value);
			}
			setOffFleetTerm(value.name);
			setAutocompleteOffFleet([]);
		} else {
			setCraft(value);
		}
		const oldCraft = aircraft.find(item=> item.id === oldId );
		const newCraft = aircraft.find(item=> item.id === value );
		const oldCraftMax = oldCraft?.paxSeats || -1;
		const newCraftMax = newCraft?.paxSeats || 0;
		let state = cloneDeep(pairs);
		state.forEach((pair, i) => {
			if (i === 0 && (pair.from === "" || pair.from === (oldCraft?.airport || ""))) {
				pair.from = newCraft?.airport || "";
			}
			if (pair.pax === oldCraftMax) {
				pair.pax = newCraftMax;
			}
			delete(pair.calcs);
		})
		setPairs(state);
	}

	const removeLeg = (index)=>{
		let state = cloneDeep(pairs);
		unset(state, index);
		setPairs(compact(state));
	}

	useEffect(()=>{
		const call = new AbortController();
		Api.get(window.jsRoutes.controllers.AircraftController.list(), call)
			.then((response) => {
				const allCraft = response.aircraft.map((_craft)=>
					({
						id: _craft._id.$oid,
						value: _craft.tailNumber + " - " + _craft.type.name + " (" + (_craft.paxSeats || "N/A") + " seats)" + (_craft.is91Only ? " (91 Only)" : ""),
						paxSeats: _craft.paxSeats,
						airport: _craft.airport
					})
				).concat([{id: "off", value: "Off Fleet Aircraft"}]);
				setAircraft(allCraft);

				let newCraft = find(response.aircraft, c=> c._id.$oid === craft ) || response.aircraft[0];
				setPairs(p => {
					let state = cloneDeep(p);
					if (!p[0].pax) {
						set(state, "0.pax", get(newCraft, "paxSeats") || 0);
					}
					if (!p[0].from || p[0].from === "") {
						set(state, "0.from", get(newCraft, "airport"));
						set(state, "0.pax", get(newCraft, "paxSeats") || 0);
					}
					return state;
				});
				if (isEmpty(craft)) {
					setCraft(allCraft[0].id);
				}
				if (companySelector.current) {
					companySelector.current.focus();
				}
			})
			.catch(Api.silentFail);
		return ()=>{
			call.abort()
		}
		// eslint-disable-next-line
	}, [forceUpdate])

	useEffect(()=>{
		const updateFlightTimeAndDistance = (index)=>{
			const pair = pairs[index];
			return Api.post(window.jsRoutes.controllers.Dispatches.getFlightTimeAndDistance(), {
				"aircraft": (craft === "off") ? get(offFleet, "type._id.$oid") : craft,
				"from": pair.from,
				"to": pair.to
			})
				.then((results) => {
					let out = {};
					out[index] = {};
					out[index] = {"calcs": results};
					let d = ((pairs[index].date instanceof PRODate) ? pairs[index].date : new PRODate(pairs[index].date));
					let first;
					if (index === 0 && d.timezoneLabel === "L") {
						first = d.pushingZoneId;
					} else {
						first = d.withZoneId;
					}
					return first.call(d, results.from.timezone)
						.then((r) => {
							out[index].date = r;
							return r.plus(results.minutes).withZoneId(results.to.timezone)
								.then(a => {
									out[index].elt = a;
									if (pairs[index + 1]) {
										let d = ((pairs[index + 1].date instanceof PRODate) ? pairs[index + 1].date : new PRODate(pairs[index + 1].date));
										return d.withZoneId(results.to.timezone)
											.then((r) => {
												out[index + 1] = {date: r};
												return out;
											});
									} else {
										return out;
									}
								});
						});
				});
		}

		let call = new AbortController();

		let out = cloneDeep(pairs);
		let promises = new Promises();
		out.forEach((item, index) => {
			let arrival = item.date.plus(get(item, "calcs.minutes") || 0);
			let next = out[index + 1];
			if (next) {
				if (next.from !== item.to) {
					if (isUndefined(out[index + 1])) {
						out[index + 1] = {}
					}
					out[index + 1].from = item.to;
					out[index + 1].calcs = null;
				}
			}
			if (has(item.calcs, "to")) {
				promises.push(
					arrival.withZoneId(item.calcs.to.timezone)
						.then(result => {
							out[index].elt = result.clone();
							if (next) {
								if (result.plus(30).isSameOrAfter(next.date)) {
									if (isUndefined(out[index + 1])) {
										out[index + 1] = {}
									}
									out[index + 1].date = result.plus(30);
								}
							}
						})
				);
			}
			if (!isEmpty(item.from) && !isEmpty(item.to) && isEmpty(item.calcs)) {
				promises.push(
					updateFlightTimeAndDistance(index)
						.then(result => {
							out[index] = extend(out[index], result[index]);
						})
				);
			}
		});
		Promise.all(promises)
			.then(() => {
				if (!call.signal.aborted) {
					setPairs(p => JSON.stringify(p) !== JSON.stringify(out) ? out : p);
				}
			});
		return ()=>{
			call.abort();
		}
	}, [craft, offFleet, pairs])

	useEffect(()=>{
		if (isEmpty(offFleetTerm)) return;
		const autocompleteCall = new AbortController();
		Api.post(window.jsRoutes.controllers.Operations.searchOffFleet(), {"term": offFleetTerm}, autocompleteCall)
			.then(result => {
				setAutocompleteOffFleet(result.aircraft.map(i=> isNumber(i.type) ? i : extend(i, {name: i.tailNumber + " - " + i.type.name}) ));
			})
			.catch(Api.flagFail);
		return ()=>{
			autocompleteCall.abort();
		}
	}, [offFleetTerm])

	// let label = (type === "Quote") ? "Create a new Quote" : "Create a new Trip";

	return <Box>
		<Form className={"label-grid"}>
			<DropdownInput labelClass={"control-label"} name="Aircraft" value={craft} options={aircraft} onChange={v=> changeAircraft(false, v)} />
			{craft === "off" ? <PROLabel name="Off Fleet" value={<Autocomplete
				node="name"
				selected={offFleetTerm}
				options={autocompleteOffFleet}
				onInputChange={(v)=>{ setOffFleetTerm(v) }}
				onChange={v=> changeAircraft(true, v)}
			/>} /> : undefined}
			<CompanySelector labelClass={"control-label"} key={get(client, "company._id.$oid")} ref={companySelector} company={get(client, "company")} onChange={item => {
				setClient(p => {
					let state = cloneDeep(p);
					set(state, "company", item);
					return state;
				});
				let dom = document.getElementById("lastFocus");
				if (dom) {
					dom.focus();
				}
			}}/>
			<CustomerSelector labelClass={"control-label"} key={get(client, "customer._id.$oid") + get(client, "company._id.$oid")}
				label="Contact" customer={client.customer} company={client.company} onChange={item => {
				let state = cloneDeep(client);
				set(state, "customer", item);
				setClient(state);
			}}/>
		</Form>

		<div className={styles.createLegsGrid}>
			{/* <div className="depart grid-label">Depart</div>
			<div className="from grid-label">From</div>
			<div className="to grid-label">To</div>
			<div className="arrive grid-label">Arrival</div>
			<div className="ete grid-label">ETE</div>
			<div className="distance grid-label">NM</div>
			<div className="pax grid-label">PAX</div> */}
			{map(pairs, (row, index, list) => {
				let warning;
				let last = pairs[index - 1];
				if (last && last.elt) {
					if (last.elt.isSameOrAfter(row.date)) {
						warning = <div key={"warning_" + index} className="warning">Overlaps past trip!</div>
					}
				}
				return <div className={styles.quoteFieldGroup} key={index}>
					<div className={styles.quoteFieldGroupRow}>
						<div className={styles.quoteFieldWrapper}>
							<div className={[styles.quoteFieldLabel, styles.quoteFieldLabelFixed].join(' ')}>From</div>
							<div className={styles.quoteFieldValue}>
								<PRODateTimePicker className="depart" selected={row.date} onChange={(v)=> changeDate(index, "from", v)} />
								<div className="departureCity">{get(row, "calcs.from.name") || ""}</div>
								{(index === 0) ?
									<AirportInput className="from" icao={row.from} onChange={v=> changedAirport("from", index, v)}/>
									:
									<input className="from readOnly" readOnly={true} type="text" value={row.from} style={{ textAlign: 'start'}}/>
								}
							</div>
						</div>
					</div>
					{/* <div className={styles.quoteFieldGroupRow}>
						<FromTo className="arrow" />
					</div> */}
					<div className={styles.quoteFieldGroupRow}>
						<div className={styles.quoteFieldWrapper}>
							<div className={[styles.quoteFieldLabel, styles.quoteFieldLabelFixed].join(' ')}>To</div>
							<div className={styles.quoteFieldValue}>
								{(row.elt instanceof PRODate) ?
									<PRODateTimePicker className="arrive" selected={row.elt} onChange={v=> changeDate(index, "to", v)} />
									:
									false
								}
								<div className="arrivalCity">{get(row, "calcs.to.name") || ""}</div>
								<AirportInput
									className="to"
									id={(index === list.length - 1) ? "lastFocus" : ""}
									icao={row.to}
									onChange={v=> changedAirport("to", index, v)} />
							</div>
						</div>
					</div>
					<div className={styles.quoteFieldGroupRowSpaced}>
						<div className={styles.quoteFieldWrapper}>
							<div className={styles.quoteFieldLabel}>ETE</div>
							<div className="quoteFieldValue ete mono">{toTenths((get(row, "calcs.minutes") || 0) / 60)}</div>
						</div> 
						<div className={styles.quoteFieldWrapper}>
							<div className={styles.quoteFieldLabel}>NM</div>
							<div className="quoteFieldValue distance mono">{get(row, "calcs.distance.value")}</div>
						</div>
						<div className={styles.quoteFieldWrapper}>
							<div className={styles.quoteFieldLabel}>PAX</div>
							<div className="quoteFieldValue pax">
								<SimpleInput key={row.pax} value={row.pax} preprocess={TextInput.Whole.In} postprocess={TextInput.Whole.Out} onChange={(value) => {
									let state = cloneDeep(pairs);
									set(state, index + ".pax", value);
									setPairs(state);
								}} />
							</div>
						</div>
						<div className={styles.quoteFieldWrapper}>
							{index > 0 ?
								<FontAwesomeIcon icon={faTrash} onClick={() => removeLeg(index)} style={{ marginLeft: '10px', cursor: 'pointer'}}/>
								:
								<div className={"remove"}/>
							}
							{warning}
						</div>
					</div>
				</div>
			})}
		</div>

		{has(ulast(pairs), "calcs.minutes") ?
		<Columns template={"1fr auto 1fr"} style={{marginTop:"var(--spacing)"}}>
			<div className="stretch"/>
        {showNextLegButton && <Button variant='contained' sx={[commonStyles.action, {mt: '10px'}]} onClick={addNewPair}> New Leg </Button>}
		</Columns>
		:
      undefined}
    
    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 'var(--space-lg)' }}>
        <Button variant='contained' sx={commonStyles.action} onClick={(ev) => {
          window.awsRum?.recordEvent?.("modal.view", { type: type, action: "closed" });
          onClosed(ev)
        }}>Close</Button>
      <Button variant='contained' sx={commonStyles.action} onClick={() => save()}>Save & Close</Button>
      <Button variant='contained' sx={commonStyles.action} onClick={() => save(true)}>Save & Edit</Button>
    </Box>
    
	</Box>
}
export default NewQuote;