import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../data/userReducer'
import Api from '../../helpers/api'
import LegSelector from '../../helpers/legSelector'
import PRODate from '../../helpers/proDate'
import { TextInput } from '../../helpers/textInput'
import { AttachmentsView } from '../../helpers/upload'
import { MinusCircle } from '../../icons/circle/minus'
import { FromTo } from '../../icons/fromTo'
import { styles } from './common'

const localStyles: { [x: string]: CSSProperties | {} } = {
  legMiddleBody: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '5px',
    width: '100%',
  },
  legLabel: {
    marginRight: '5px',
    fontSize: '14px',
  },
  legMiddleBodyPart1: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginRight: '10px',
  },
  legMiddleBodyPart2: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
}

type Image = {
  id: string,
  etag: string,
  note: string,
  timestamp: number
}

type Code = {
  name: string
  code: string
  _id: {
    $oid: string
  }
  leaseback: boolean
  intuit: {
    id: string
    name: string
    desc: string
    sync: string
  }
  quickbooks: {
    ListID: string
    Name: string
  }
}

type Method = {
  name: string
  _id: {
    $oid: string
  }
  intuit: {
    id: string
    name: string
    type: string
    sync: string
  }
  quickbooks: {
    ListID: string
    Name: string
  }
}

type ExpenseItem = {
  _id?: {
    $oid: string
  }
  amount: number
  closed: boolean
  code: Code
  date: PRODate | null
  description: string
  method: Method
  operation?: {
    $oid: string
  }
  user: {
    _id: {
      $oid: string
    }
    firstName: string
    lastName: string
  },
  images: Image[],
  note: string,
  leg: {
    _id: {
      $oid: string
    },
    departure: {
      time: number,
      airport: string
    },
    arrival: {
      airport: string,
      time: number
    }
  },
  quantity: number,
  billable: boolean
  billableAmount: number
}

export const defaultExpense: ExpenseItem = {
  amount: 0,
  closed: false,
  code: {
    name: '',
    code: '',
    _id: {
      $oid: '',
    },
    leaseback: false,
    intuit: {
      id: '',
      name: '',
      desc: '',
      sync: '',
    },
    quickbooks: {
      ListID: '',
      Name: '',
    },
  },
  date: new PRODate(),
  description: '',
  method: {
    name: '',
    _id: {
      $oid: '',
    },
    intuit: {
      id: '',
      name: '',
      type: '',
      sync: '',
    },
    quickbooks: {
      ListID: '',
      Name: '',
    },
  },
  user: {
    _id: {
      $oid: '',
    },
    firstName: '',
    lastName: '',
  },
  images: [],
  note: '',
  leg: {
    _id: {
      $oid: ''
    },
    departure: {
      time: 0,
      airport: ''
    },
    arrival: {
      airport: '',
      time: 0
    }
  },
  quantity: 0,
  billable: false,
  billableAmount: 0
}

type ExpenseModalProps = {
  open: boolean
  onCancel: () => void
  onOk: () => void
  editExpense?: ExpenseItem
}


const ExpenseModal: React.FC<ExpenseModalProps> = ({ open, onCancel, onOk, editExpense }) => {
  const user = useSelector(selectUser);
  const [actionCodes, setActionCodes] = useState<Code[]>([])
  const [paymentMethods, setPaymentMethods] = useState<Method[]>([])
  const [localExpense, setLocalExpense] = useState<ExpenseItem>(defaultExpense)
  const isMobileView = useMediaQuery('(max-width:700px)')

  const handleChange = (path: string, value: any) => {
    const state = cloneDeep(localExpense);
    set(state, path, value);
    setLocalExpense(state);
  }

  const handleSaveExpense = () => {
    if (user._id.$oid) {
      localExpense.user._id.$oid = user._id.$oid;
    }
    Api.post((window as any).jsRoutes.controllers.Expenses.edit(), localExpense).then((result) => {
      const promises = (localExpense?.images || []).map((image: Image) => 
        Api.post((window as any).jsRoutes.controllers.Expenses.addImage(result.expense._id.$oid), image)
      )
      Promise.all(promises)
        .then(onOk)
        .catch(onCancel)
    }).catch(onCancel);
  }

  useEffect(() => {
    if (!editExpense) {
      return;
    }

    const call = new AbortController()
    Api.get((window as any).jsRoutes.controllers.AccountingCodes.all(), call)
      .then((result) => {
        if (result.accountingCodes) {
          setActionCodes([...result.accountingCodes])
        }
      })

    Api.get((window as any).jsRoutes.controllers.PaymentMethods.all(), call)
      .then((result) => {
        if (result.paymentMethods) {
          setPaymentMethods([...result.paymentMethods]);
        }
      })
    setLocalExpense(editExpense)
  }, [editExpense]);

  useEffect(() => {
    if (!open) {
      setLocalExpense(defaultExpense);
    }
  }, [open]);

  const onClose = () => {
    setLocalExpense(defaultExpense);
    onCancel()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={[styles.modal, isMobileView ? styles.mobile : styles.desktop]}>
        <Box sx={styles.header}>
          <Box>{localExpense._id ? 'Edit Expense' : 'Add New Expense'}</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </Box>
        <Box sx={styles.body}>
          <Box
            sx={{
              ...styles.control,
              justifyContent: isMobileView ? 'start' : 'end',
              alignItems: 'center',
            }}
          >
            {localExpense.leg?._id.$oid ? (
              <Box sx={localStyles.legMiddleBody}>
                <Typography sx={localStyles.legLabel}>Leg:</Typography>
                <Box sx={localStyles.legMiddleBodyPart1}>
                  <p className={'mono no-wrap text-small'}>
                    {new PRODate(localExpense.leg.departure.time).format('MM/dd/yyyy HH:mm ZZ')}
                  </p>
                  <p className={'bold'}>{localExpense.leg.departure.airport}</p>
                  <Box className={'centered'}>
                    <FromTo type={undefined} />
                  </Box>
                </Box>

                <Box sx={localStyles.legMiddleBodyPart2}>
                  <p className={'bold'}>{localExpense.leg.arrival.airport}</p>
                  <p className={'mono no-wrap text-small'}>
                    {new PRODate(localExpense.leg.arrival.time).format('MM/dd/yyyy HH:mm ZZ')}
                  </p>
                  <button
                    onClick={() => {
                      handleChange('leg', defaultExpense.leg)
                    }}
                  >
                    <MinusCircle className={'text-danger'} />
                  </button>
                </Box>
              </Box>
            ) : (
              <>
                <Typography>Leg: </Typography>
                <LegSelector
                  onChange={(val: any) => handleChange('leg', val)}
                />
              </>
            )}
          </Box>
          <Box sx={{ ...styles.control, alignItems: 'center' }}>
            <Typography>Code</Typography>
            <FormControl>
              <Select
                size='small'
                fullWidth
                value={localExpense?.code?._id?.$oid || ''}
                onChange={(e) => {
                  const code = actionCodes.find(code => code?._id?.$oid === e.target.value);
                  if (code) {
                    handleChange('code', code)
                  }
                }}
              >
                <MenuItem value=''>- Select Code -</MenuItem>
                {actionCodes.map((actionCode, idx) => (
                  <MenuItem key={idx} value={actionCode._id.$oid}>{actionCode.code} - {actionCode.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ ...styles.control, alignItems: 'center' }}>
            <Typography>Description </Typography>
            <FormControl variant='standard'>
              <TextField
                size='small'
                fullWidth
                type='text'
                value={localExpense.description}
                onChange={(e) => handleChange('description', e.target.value)}
              />
            </FormControl>
          </Box>
          <Box sx={{ ...styles.control, alignItems: 'center' }}>
            <Typography>Qty </Typography>
            <FormControl variant='standard'>
              <TextField
                size='small'
                fullWidth
                type='number'
                value={localExpense.quantity}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>Gal</InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange('quantity', e.target.value)}
              />
            </FormControl>
          </Box>
          <Box sx={{ ...styles.control, alignItems: 'center' }}>
            <Typography>Pmt. Type</Typography>
            <FormControl>
              <Select
                value={localExpense.method?._id.$oid || ''}
                fullWidth
                size='small'
                onChange={(e) => {
                  const method = paymentMethods.find(item => item._id.$oid === e.target.value);
                  if (method) {
                    handleChange('method', method)
                  }
                }}
              >
                <MenuItem value=''>- Select Code -</MenuItem>
                {paymentMethods.map((paymentMethod, idx) => (
                  <MenuItem key={idx} value={paymentMethod._id.$oid}>
                    {paymentMethod.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ ...styles.control, alignItems: 'center' }}>
            <Typography>Amount </Typography>
            <FormControl variant='standard'>
              <TextField
                size='small'
                type='number'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                }}
                value={localExpense.amount}
                onChange={(e) => handleChange('amount', parseFloat(e.target.value))}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              ...styles.control,
              alignItems: 'center',
              width: isMobileView ? '100%' : '50%',
            }}
          >
            <Typography>Date </Typography>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  format='MM/dd/yyyy'
                  value={localExpense.date.valueOf() || null}
                  onChange={(value) => handleChange('date', value)}
                  slotProps={{
                    textField: { 
                      size: 'small',
                      variant: 'standard',
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={{ ...styles.control, alignItems: 'center' }}>
            <Typography>Note </Typography>
            <FormControl>
              <TextareaAutosize
                minRows={3}
                name={'note'}
                style={{
                  width: '100%',
                  border: '1px solid rgb(133, 133, 133)',
                  borderRadius: '5px',
                  padding: '10px',
                }}
                onChange={(e) => handleChange('note', e.target.value)}
                value={localExpense.note}
              />
            </FormControl>
          </Box>
          {localExpense.leg && <Box sx={{ ...styles.control, alignItems: 'center' }}>
            <Typography>Billable</Typography>
            <ToggleButtonGroup
              color='primary'
              value={localExpense.billable}
              exclusive
              onChange={(_: React.MouseEvent<HTMLElement>, value: boolean) => {
                handleChange("billable", value)
              }}
              sx={{ height: '40px' }}
            >
              <ToggleButton value={true}>YES</ToggleButton>
              <ToggleButton value={false}>NO</ToggleButton>
            </ToggleButtonGroup>
          </Box>}
          {localExpense.billable && <Box sx={{ ...styles.control, alignItems: 'center' }}>
            <Typography>Billable Amount</Typography>
            <TextInput hideLabel prepend={"$"}
              value={localExpense.billableAmount || localExpense.amount}
              onChange={(v: any) => handleChange("billableAmount", v)}
              process={TextInput.Currency}/>
          </Box>}
          
          <Box sx={{ ...styles.control, alignItems: 'center' }}>
            <Typography>Closed </Typography>
            <ToggleButtonGroup
              color='primary'
              value={localExpense.closed}
              exclusive
              onChange={(_: React.MouseEvent<HTMLElement>, value: string) => {
                handleChange("closed", value)
              }}
              sx={{ height: '40px' }}
            >
              <ToggleButton value={true}>YES</ToggleButton>
              <ToggleButton value={false}>NO</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <AttachmentsView
            allowEdit={false}
            showAsPopover={true}
            items={localExpense.images}
            onSave={(modal: any, item: any) => {
              if (!localExpense.images) {
                localExpense.images = [];
              }
              localExpense.images.push({ ...item, id: item.file } as Image)
              handleChange('images', localExpense.images);
              modal?.close()
            }}
            onDelete={(item: { id: string }) => {
              const newImages = localExpense.images.filter(img => img.id !== item.id);
              handleChange('images', newImages);
            }}
            label={undefined}
            placeholder={undefined}
            meta={undefined}
            className={undefined}
            style={undefined}
            grid={undefined}
            newModal
          />
          <Box sx={[styles.control, { justifyContent: 'space-between', marginTop: '2rem' }]}>
            <Box>
              {/* {localExpense._id && <Button variant='contained' sx={[styles.action, styles.error]} onClick={() => setConfirmDelete(true)}>Delete</Button>} */}
            </Box>
            <>
              <Button onClick={onCancel} variant='contained' sx={styles.action}>Close</Button>
              <Button onClick={handleSaveExpense} variant='contained' sx={styles.action}>Save</Button>
            </>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ExpenseModal
