import { Checkbox, Chip, InputLabel, ListItemText } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import React from 'react'

export type Options = {
  id: string | number | string[]
  label: string
  name?: string
  hideCheckbox?: boolean
}

type CustomSelectProp = {
  label?: string
  options: Options[]
  value: any
  width?: string
  onChange: (value: any) => void
  borderBelowItem?: number
  multiple?: boolean
}

const CustomSelect: React.FC<CustomSelectProp> = ({ label, options, value, onChange, width = '100px', borderBelowItem = -1, multiple }) => {
  const onSelectChange = (event: SelectChangeEvent) => {
    if (multiple && Array.isArray(event.target.value) && event.target.value.some((item) => Array.isArray(item))) {
      onChange([])
    } else {
      onChange(event.target.value)
    }
  }

  return (
    <Box sx={{ backgroundColor: 'var(--white)' }}>
      <FormControl variant="outlined" sx={{ minWidth: width }} size="small">
        <InputLabel size="small" sx={{ fontSize: '14px' }}>{label}</InputLabel>
        <Select
          value={value}
          onChange={onSelectChange}
          size="small"
          label={label}
          multiple={multiple}
          sx={{ width: 'max-content', minWidth: width, fontSize: '14px' }}
          renderValue={(value: any) => {
            if (Array.isArray(value)) {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {value.map((v) => (
                    <Chip key={v} label={options.find((o) => o.id === v)?.label} />
                  ))}
                </Box>
              )
              // return value.map(id => options.find(o => o.id === id)?.label).join(', ');
            }
            return options.find((o) => o.id === value)?.label
          }}
        >
          {options.map((item, idx) => (
            <MenuItem key={idx} value={item.id} sx={{ fontSize: '14px', borderBottom: borderBelowItem === idx ? '1px solid black' : '', pl: item.hideCheckbox ? '22px' : '10px' }}>
              {multiple && !item.hideCheckbox && <Checkbox size="small" checked={value?.indexOf(item.id) > -1} />}
              {item.label || item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomSelect
