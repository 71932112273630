import {useEffect, useState} from "react";
import Api from "@helpers/api";
import Autocomplete from "@helpers/autocomplete/autocomplete";
import PROLabel from "@helpers/label/label";
import Columns from "@helpers/columns";
import Edit from "./edit";
import {Pencil} from "@icons/pencil";
import {usePopover} from "@hooks/usePopover";

export const WorkOrderAutocomplete = ({workOrder, onChange, grid, label, labelClass, style})=>{
	const [popover, setPopover, clearPopover] = usePopover();
	const [query, setQuery] = useState(workOrder?.name ?? "");
	const [autocomplete, setAutocomplete] = useState([]);

	useEffect(() => {
		if (query.trim() === "") return;
		const abort = new AbortController();
		Api.post(window.jsRoutes.controllers.WorkOrders.search(), {term: query}, abort)
			.then(results => {
				setAutocomplete(results.workOrders);
			})
			.catch(() => {
				setAutocomplete([]);
			})
		return ()=>{
			abort.abort();
		}
	}, [query]);

	const showWorkOrder = ()=>{
		setPopover(<Edit key={workOrder?._id?.$oid} id={workOrder?._id} onClosed={clearPopover} />);
	}

	return <PROLabel grid={grid} name={label} labelClass={labelClass} style={style} value={
		<Columns template="1fr auto">
			<Autocomplete style={{width: "100%"}} selected={query} canClear={true} node={"name"}
						  options={autocomplete} onInputChange={v => setQuery(v)} onChange={(item)=>{
				onChange?.(item);
			}} />
			{workOrder?._id?.$oid ? <button onClick={showWorkOrder}><Pencil/></button> : false}
			{popover}
		</Columns>
	} />;
}
export default WorkOrderAutocomplete;