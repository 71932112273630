import {RouteBuilder} from "./base";

export const CustomerRoutes = {
	"list": function(letter, page) {
		return RouteBuilder.builder({
			method: "POST",
			url: "/api/customer/list/" + encodeURIComponent((function (k, v) {
				return v
			})("letter", letter)) + "/" + encodeURIComponent((function (k, v) {
				return v
			})("page", page))
		})
	},
	"getAllForCompany": function() {
		return RouteBuilder.builder({
			method: "POST",
			url: "/api/customer/company"
		})
	},
	"get":  function(id) {
		return RouteBuilder.builder({
			method: "GET",
			url: "/api/customer/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id))
		})
	},
	"edit":  function() {
		return RouteBuilder.builder({
			method: "POST",
			url: "/api/customer/edit"
		})
	},
	"merge":  function() {
		return RouteBuilder.builder({
			method: "POST",
			url: "/api/customer/merge"
		})
	},
	"delete":  function(id) {
		return RouteBuilder.builder({
			method: "DELETE",
			url: "/api/customer/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id))
		})
	},
	"search":  function() {
		return RouteBuilder.builder({
			method: "POST",
			url: "/api/customer/search"
		})
	},
	"images": {
		"add": function(id) {
			return RouteBuilder.builder({
				method: "POST",
				url: "/api/customer/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id)) + "/image"
			})
		},
		"remove": function(id, image) {
			return RouteBuilder.builder({
				method: "DELETE",
				url: "/api/customer/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id)) + "/image/" + encodeURIComponent((function (k, v) {
					return v
				})("image", image))
			})
		},
	}
};