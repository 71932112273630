import React from "react";
import get from "lodash/get";
import "./style.scss";

const Well = (props) => {
	return <div className={"well " + (props.className || "")} style={props.style}>
		{React.Children.map(props.children, i => {
			let options = {};
			if (!["div", "span", "a", "label"].includes(get(i, "type"))) {
				options.grid = props.grid;
			}
			if ((get(i, "$$typeof") || "").toString().includes("react.fragment")) {
				return React.cloneElement(i, null, React.Children.map(i.props.children, j => j ? React.cloneElement(j, options) : j));
			} else {
				return i ? React.cloneElement(i, options) : i;
			}
		})}
	</div>
};
export default Well;