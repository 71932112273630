export type MenuItemType = {
  label: string;
  value: string;
  menuItems: { label: string; value: string; action?: boolean }[];
};

export const MenuItems: MenuItemType[] = [
  {
    label: "Flights",
    value: "flights",
    menuItems: [
      { label: "Dispatches", value: "flights/dispatches", action: true },
      { label: "Quotes", value: "flights/quotes", action: true },
      { label: "Avinode RFQs", value: "flights/rfqs" },
    ],
  },
  {
    label: "Operations",
    value: "operations",
    menuItems: [
      { label: "Library", value: "operations/library" },
      { label: "Calendar", value: "operations/calendar" },
      { label: "Analytics", value: "operations/analytics" },
      { label: "Reports", value: "operations/reports" },
    ],
  },
  {
    label: "Records",
    value: "records",
    menuItems: [
      { label: "Aircraft", value: "records/aircraft" },
      { label: "Pilots", value: "records/pilots" },
      { label: "Cabin Crew", value: "records/cabin-crew" },
      { label: "MX", value: "records/mx" },
      { label: "All Users", value: "records/all" },
      // { label: 'ERK', value: 'records/erk' },
    ],
  },
  {
    label: "Safety",
    value: "safety",
    menuItems: [
      { label: "Tickets", value: "safety/tickets", action: true },
      { label: "IEP", value: "safety/iep" },
      { label: "Meetings", value: "safety/meetings" },
      { label: "Risk Profile Register", value: "safety/riskProfile" },
    ],
  },
  {
    label: "Fleet",
    value: "fleet",
    menuItems: [
      { label: "Aircraft", value: "fleet/aircraft/?active=true" },
      { label: "MX Providers", value: "fleet/mpl" },
      { label: "Work Orders", value: "fleet/workOrder", action: true },
      { label: "Components", value: "fleet/components" },
      { label: "Parts Inventory", value: "fleet/parts-inventory" },
    ],
  },
  {
    label: "Administration",
    value: "administration",
    menuItems: [
      { label: "Ops. Settings", value: "administration/settings" },
      {
        label: "User Accounts",
        value: "administration/users/?type=15&active=true",
        action: true,
      },
      {
        label: "Companies",
        value: "administration/crm/companies",
        action: true,
      },
      {
        label: "Contacts",
        value: "administration/crm/customers",
        action: true,
      },
      {
        label: "Expenses",
        value: "administration/financials/expenses",
        action: true,
      },
      { label: "Financials", value: "administration/financials/trips" },
    ],
  },
];

export const CreateItems: MenuItemType = {
  label: "",
  value: "",
  menuItems: [
    { label: "Company", value: "company" },
    { label: "Contact", value: "contact" },
    { label: "Dispatch", value: "trip" },
    { label: "Quote", value: "quote" },
    { label: "Quick Quote", value: "quickQuote" },
    { label: "Expense", value: "expense" },
    { label: "Ticket", value: "ticket" },
    { label: "User Account", value: "userAccount" },
    { label: "Work Order", value: "workOrder" },
  ],
};

export const MOBILE_BREAKPOINT = 1024;
