import React from "react";
import Api from "../helpers/api";

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError(error) {
		console.error("derived error", error);
		return {hasError: true};
	}

	componentDidCatch(error, errorInfo) {
		console.error("boundary catch", error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <div>
				<h1 className={"text-center bg-danger"}>Something went wrong.</h1>
				<div className={"text-center"}>A simple refresh will fix this issue most of the time, however if the issue persists, a clearing of your cache might be necessary.</div>
				<div className={"text-center"}>
					<button className={"pro-btn btn-success"} onClick={()=>{ Api.reload() }}>Refresh</button>
					<button className={"pro-btn btn-info"} onClick={()=>{ Api.changeUrl("/") }}>Return to Dashboard</button>
					<button className={"pro-btn btn-danger"} onClick={()=>{
						window.localStorage.clear();
						Api.reload();
					}}>Clear Cache</button>
				</div>
			</div>
		}

		return this.props.children;
	}
}