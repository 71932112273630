import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import useMediaQuery from '@mui/material/useMediaQuery'
import PRODate from 'helpers/proDate'
import React, { CSSProperties } from 'react'
import NewQuote from '../../pages/quote/create'
import { styles } from './common'

type NewQuoteModalProps = {
  open: boolean
  onClose: () => void
  onSave: () => void
  date?: PRODate
  craftId?: string
  initialOffFleet?: any
  from?: string
  type: 'Trip' | 'Quote'
}

const localStyles: { [x: string]: CSSProperties | {} } = {
  customModal: {
    width: '80% !important'
  }
}

const NewQuoteModal: React.FC<NewQuoteModalProps> = ({ open, onClose, onSave, date, craftId, initialOffFleet, from, type }) => {
  const isMobileView = useMediaQuery('(max-width:700px)')
  
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={[styles.modal, localStyles.customModal,isMobileView ? styles.mobile : styles.desktop]}>
        <Box sx={styles.header}>
          <Box>{type === 'Quote' ? 'Add a new Quote' : 'Create a new Trip'}</Box>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} />
        </Box>
        <Box sx={styles.body}>
          <NewQuote type={type} date={date} craftId={craftId} initialOffFleet={initialOffFleet} from={from} onClosed={onClose} onSave={onSave} />
        </Box>
      </Box>
    </Modal>
  )
}

export default NewQuoteModal
