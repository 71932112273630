import { Fragment, useEffect, useRef, useState } from "react";
import extend from "lodash/extend";
import has from "lodash/has";
import pick from "lodash/pick";
import get from "lodash/get";
import includes from "lodash/includes";
import map from "lodash/map";
import cloneDeep from "lodash/cloneDeep";
import PROLabel from "./label/label";
import Autocomplete from "./autocomplete/autocomplete";
import { Box, FormControl, Typography } from "@mui/material";
import { styles } from "../pages/modal/common";
import useMediaQuery from "@mui/material/useMediaQuery";

const PROAddress = ({
  address = {},
  onChange,
  topLine = true,
  bottomLine = true,
  title,
  grid,
  disabled,
  error,
  hideLabel
}) => {
  const temp = useRef();
  const isMobileView = useMediaQuery("(max-width:600px)");
  const [autocomplete, setAutocomplete] = useState();
  const [options, setOptions] = useState({});

  const [streetOptions, setStreetOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [postalCodeOptions, setPostalCodeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const hasGoogleServices = has(window, "google");

  useEffect(() => {
    if (hasGoogleServices) {
      setAutocomplete(new window.google.maps.places.AutocompleteService());
    }
  }, [hasGoogleServices]);

  useEffect(() => {
    if (options.type || options.types) {
      autocomplete.getPlacePredictions(options, (results) => {
        if (!results) return;
        if (options.type.includes("(cities)")) {
          setCityOptions(results);
        } else if (options.type.includes("(sublocality)")) {
          setStateOptions(results);
        } else if (options.type.includes("(postal_code)")) {
          setPostalCodeOptions(results);
        } else if (options.type.includes("(country)")) {
          setCountryOptions(results);
        } else if (options.type.includes("placeId")) {
          setStreetOptions(results);
        }
      });
    }
  }, [options, autocomplete]);

  const changeObject = (item) => {
    let obj = cloneDeep(address);
    if (get(item, "place_id")) {
      let places = new window.google.maps.places.PlacesService(temp.current);
      places.getDetails({ placeId: item.place_id }, (info) => {
        let formatted = extend.apply(
          {},
          map(info.address_components, function (i) {
            if (includes(i.types, "street_number")) {
              return { streetNumber: i.long_name };
            }
            if (includes(i.types, "route")) {
              return { streetName: i.long_name };
            }
            if (includes(i.types, "locality")) {
              return { city: i.long_name };
            }
            if (includes(i.types, "administrative_area_level_1")) {
              return { state: i.long_name };
            }
            if (includes(i.types, "country")) {
              return { country: i.short_name };
            }
            if (includes(i.types, "postal_code")) {
              return { postalCode: i.long_name };
            }
            return {};
          })
        );
        if (has(formatted, "streetNumber")) {
          formatted.street =
            formatted.streetNumber + " " + formatted.streetName;
          delete formatted.streetNumber;
          delete formatted.streetName;
        }
        Object.assign(obj, formatted);
        onChange(
          pick(obj, ["street", "city", "state", "postalCode", "country"])
        );
      });
    } else {
      const merged = Object.assign({}, obj, item);
      if (JSON.stringify(merged) !== JSON.stringify(obj)) {
        onChange(
          pick(merged, ["street", "city", "state", "postalCode", "country"])
        );
      }
    }
  };

  return (
    <Fragment>
      <div ref={temp} />
      {topLine ? <hr className="full" /> : undefined}
      {title ? <PROLabel grid={grid} value={title} /> : undefined}
      <PROLabel
        error={error}
        grid={grid}
        name={hideLabel? "" : "Street"}
        value={
          hideLabel? 
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>Street </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <Autocomplete
                  node="description"
                  selected={address?.street}
                  options={streetOptions}
                  onInputChange={(value) => {
                    setOptions({
                      input: value,
                      type: ["placeId"],
                      componentRestrictions: { country: address?.country },
                    });
                  }}
                  onChange={(v) => {
                    changeObject(v);
                  }}
                  onBlur={(value) => {
                    changeObject({ street: value });
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Box>
            :
            <Autocomplete
              node="description"
              selected={address?.street}
              options={streetOptions}
              onInputChange={(value) => {
                setOptions({
                  input: value,
                  type: ["placeId"],
                  componentRestrictions: { country: address?.country },
                });
              }}
              onChange={(v) => {
                changeObject(v);
              }}
              onBlur={(value) => {
                changeObject({ street: value });
              }}
              disabled={disabled}
            />
        }
      />
      <PROLabel
        error={error}
        grid={grid}
        name={hideLabel? "" : "City"}
        value={
          hideLabel? 
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>City </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <Autocomplete
                  node="description"
                  selected={address?.city}
                  options={cityOptions}
                  onInputChange={(value) => {
                    setOptions({
                      input: value,
                      type: ["placeId", "(cities)"],
                      componentRestrictions: { country: address?.country },
                    });
                  }}
                  onChange={changeObject}
                  onBlur={(value) => {
                    changeObject({ city: value });
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Box>
          :
          <Autocomplete
            node="description"
            selected={address?.city}
            options={cityOptions}
            onInputChange={(value) => {
              setOptions({
                input: value,
                type: ["placeId", "(cities)"],
                componentRestrictions: { country: address?.country },
              });
            }}
            onChange={changeObject}
            onBlur={(value) => {
              changeObject({ city: value });
            }}
            disabled={disabled}
          />
        }
      />
      <PROLabel
        error={error}
        grid={grid}
        name={hideLabel? "" : "State"}
        value={
          hideLabel?
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>State </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <Autocomplete
                  node="description"
                  selected={address?.state}
                  options={stateOptions}
                  onInputChange={(value) => {
                    setOptions({
                      input: value,
                      type: ["placeId", "(sublocality)"],
                      componentRestrictions: { country: address?.country },
                    });
                  }}
                  onChange={changeObject}
                  onBlur={(value) => {
                    changeObject({ state: value });
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Box>
          : 
          <Autocomplete
            node="description"
            selected={address?.state}
            options={stateOptions}
            onInputChange={(value) => {
              setOptions({
                input: value,
                type: ["placeId", "(sublocality)"],
                componentRestrictions: { country: address?.country },
              });
            }}
            onChange={changeObject}
            onBlur={(value) => {
              changeObject({ state: value });
            }}
            disabled={disabled}
          />
        }
      />
      <PROLabel
        error={error}
        grid={grid}
        name={hideLabel? "" : "Postal Code"}
        value={
          hideLabel ?
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>Postal Code </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <Autocomplete
                  node="description"
                  selected={address?.postalCode}
                  options={postalCodeOptions}
                  inputStyle={{ width: isMobileView? '100%' : '50%' }}
                  onInputChange={(value) => {
                    setOptions({
                      input: value,
                      type: ["placeId", "(postal_code)"],
                      types: ["(regions)"],
                      componentRestrictions: { country: address?.country },
                    });
                  }}
                  onChange={changeObject}
                  onBlur={(value) => {
                    changeObject({ postalCode: value });
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Box>
          : 
          <Autocomplete
            node="description"
            selected={address?.postalCode}
            options={postalCodeOptions}
            onInputChange={(value) => {
              setOptions({
                input: value,
                type: ["placeId", "(postal_code)"],
                types: ["(regions)"],
                componentRestrictions: { country: address?.country },
              });
            }}
            onChange={changeObject}
            onBlur={(value) => {
              changeObject({ postalCode: value });
            }}
            disabled={disabled}
          />
        }
      />
      <PROLabel
        error={error}
        grid={grid}
        name={hideLabel? "" : "Country"}
        value={
          hideLabel?  
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>Country </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <Autocomplete
                  node="description"
                  selected={address?.country}
                  options={countryOptions}
                  onInputChange={(value) => {
                    setOptions({ input: value, type: ["placeId", "(country)"] });
                  }}
                  onChange={changeObject}
                  onBlur={(value) => {
                    changeObject({ country: value });
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Box>
          : 
          <Autocomplete
            node="description"
            selected={address?.country}
            options={countryOptions}
            onInputChange={(value) => {
              setOptions({ input: value, type: ["placeId", "(country)"] });
            }}
            onChange={changeObject}
            onBlur={(value) => {
              changeObject({ country: value });
            }}
            disabled={disabled}
          />
        }
      />
      {bottomLine ? <hr className="full" /> : undefined}
    </Fragment>
  );
};
export default PROAddress;
