export const RouteBuilder = {
    builder: function (r) {
        let base = import.meta.env.VITE_REST_SERVER;
        return {
            method: r.method,
            url: r.url,
            absoluteURL: function () {
              return 'https://' + (r.root ?? base) + r.url
            }
        }
    }
}