import {Fragment} from "react";
import Columns from "./columns";

export const Section = function (props) {
	return <Fragment>
		<hr className={"full " + (props.className || "")}/>
		<Columns template={props.append ? "1fr auto" : "1fr"} className={"full"}>
			<h3 className={(props.className || "") + (props.error ? " text-danger" : "")}>{props.name}</h3>
			{props.append ? props.append : undefined}
		</Columns>
	</Fragment>
};