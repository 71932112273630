export const DocumentCode = {
	parse: function (id) {
		switch (id) {
			case 1:
				return this.PRC;
			case 2:
				return this.RAC;
			case 3:
				return this.MMC;
			case 4:
				return this.Military;
			case 5:
				return this.ReEntry;
			case 6:
				return this.Facilitation;
			case 7:
				return this.Nexus;
			case 8:
				return this.SENTRI;
			case 9:
				return this.Visa;
			case 13:
				return this.License;
			default:
				return this.Passport;
		}
	},
	list: function () {
		return [this.Passport, this.PRC, this.RAC, this.MMC, this.Military, this.ReEntry, this.Facilitation, this.Nexus, this.SENTRI, this.Visa, this.License];
	},

	Passport: {id: 0, name: "Passport"},
	PRC: {id: 1, name: "Permanent Resident Card"},
	RAC: {id: 2, name: "Alien Registration Card"},
	MMC: {id: 3, name: "US Merchant Mariner"},
	Military: {id: 4, name: "US Military ID"},
	ReEntry: {id: 5, name: "Re-Entry or Refugee Permit"},
	Facilitation: {id: 6, name: "Facilitation Document"},
	Nexus: {id: 7, name: "NEXUS"},
	SENTRI: {id: 8, name: "SENTRI Card"},
	Visa: {id: 9, name: "US non-immigrant Visa"},
	License: {id: 13, name: "Drivers License"},
};