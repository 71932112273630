import React, { Fragment } from "react";
import Api from "../../helpers/api";
import Autocomplete from "../../helpers/autocomplete/autocomplete";
import Columns from "../../helpers/columns";
import { Dropdown } from "../../helpers/dropdown";
import PROLabel from "../../helpers/label/label";
import { AddressBook } from "../../icons/addressBook";
import { PlusCircle } from "../../icons/circle/plus";
import CustomerSelectorModal from "../../pages/modal/customer-selector";
import { CustomerRoutes } from "../../routes/customer";

export default class CustomerAutocomplete extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			query: (((props.customer?.firstName || "") + " " + (props.customer?.middleName || "")).trim() + " " + (props.customer?.lastName || "")).trim(),
			customer: props.customer,
      autocomplete: [],
      companyId: '',
      openModal: false
		};
	}

	onInputChange(value) {
		this.setState({query: value}, () => {
			if (this.cancel) this.cancel.abort();
			this.cancel = new AbortController();
			Api.post(CustomerRoutes.search(), {
				term: this.state.query,
				contactOnly: this.props.contactOnly
			}, this.cancel).then(results => {
				this.setState({autocomplete: results.customers});
			}).catch(() => {
				this.setState({autocomplete: false});
			});
		});
	}

	onChange(item) {
		this.setState({customer: item, query: (((item?.firstName || "") + " " + (item?.middleName || "")).trim() + " " + (item?.lastName || "")).trim()});
		this.props.onChange(item);
	}

	showFullList() {
    this.setState({
      companyId: this.props.company?._id?.$oid,
      openModal: true
		});
	}

  listSelect(user) {
    this.onInputChange(user.firstName + ' ' + user.lastName);
		this.props.onChange(user);
	}

	create() {
		this.props.create(this.state.query);
	}

	render() {
		return (
			<Fragment>
        {this.state.popover}
        <CustomerSelectorModal open={this.state.openModal} companyId={this.state.companyId} onSelect={(user) => this.listSelect(user)} onClose={() => this.setState({openModal: false})}/>
				<PROLabel grid={this.props.grid} name={this.props.label} labelClass={this.props.labelClass} value={
					<Columns template="1fr auto auto">
						<Autocomplete style={{width: "100%"}} selected={this.state.query} canClear={true}
									  options={this.state.autocomplete} node={Dropdown.Formatter.UserName} onInputChange={this.onInputChange.bind(this)} onChange={this.onChange.bind(this)}/>
						<button className="text-cancel" onClick={this.showFullList.bind(this)}><AddressBook/></button>
						<button className="text-primary" onClick={this.create.bind(this)}><PlusCircle /></button>
					</Columns>
				} style={this.props.style}/>
			</Fragment>
		);
	}
}