import { faTimes, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextareaAutosize, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { merge } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";
import get from "lodash/get";
import isArray from "lodash/isArray";
import set from "lodash/set";
import unset from "lodash/unset";
import React, { CSSProperties, Fragment, useEffect, useState } from "react";
import PROAddress from "../../helpers/address";
import Api from "../../helpers/api";
import Autocomplete from "../../helpers/autocomplete/autocomplete";
import ButtonSave from "../../helpers/buttonSave/buttonSave";
import Columns from "../../helpers/columns";
import { DateInput } from "../../helpers/dateInput";
import { DropdownInput } from "../../helpers/dropdown";
import PROLabel from "../../helpers/label/label";
import PRODate from "../../helpers/proDate";
import PRORepeat from "../../helpers/repeat";
import SnackBar from "../../helpers/snackBar";
import { TextInput } from "../../helpers/textInput";
import { Country } from "../../helpers/utils/country";
import { DocumentCode } from "../../helpers/utils/documentCode";
import { MinusCircle } from "../../icons/circle/minus";
import { PlusCircle } from "../../icons/circle/plus";
import CompanySelector from "../../pages/company/selector";
import { TabPanel, isValidEmail, styles } from "./common";
import { useSelector } from "react-redux";
import { selectOperation } from "../../data/operationReducer";
import { QuickBooksCustomer } from "../../pages/customers/edit";
import { usePopover } from "../../hooks/usePopover";

type ContactModalProps = {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  editContact?: any;
  company: any[]
  isCustomer?: boolean
};

const TABS = [
  "Basic Info",
  "TSA",
  "Company",
  "Address",
  "Travel Info",
  "Notes",
  "Finance",
];

const initFormData = {
  company: [] as any[],
  name: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  citizenship: Country.US.short,
  passportCountry: Country.US.short,
  phones: [],
  contact: true,
  locked: false,
  address: {},
  travelAddress: {},
  notes: "",
};

const localStyles: { [x: string]: CSSProperties | {} } = {
  customModal: {
    maxHeight: "650px !important",
  },
  control: {
    '& > p': {
      width: '140px',
    },
    alignItems: 'center'
  }
};

const ContactModal: React.FC<ContactModalProps> = ({
  open,
  onCancel,
  onOk,
  editContact,
  company = [],
  isCustomer = false
}) => {
  const [popover, setPopover, clearPopover] = usePopover();
  const [value, setValue] = useState<number>(0);
  // const isSmallerMobileView = useMediaQuery("(max-width:370px)");
  const isMobileView = useMediaQuery("(max-width:500px)");
  const isTabView = useMediaQuery("(max-width:700px)");
  const operation = useSelector(selectOperation);
  const [formData, setFormData] = useState<any>({});
  const [customers, setCustomers] = useState<any>([]);
  const [query, setQuery] = useState("");
  const [isQuerying, setIsQuerying] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState<any>(true);

  const [customer, setCustomer] = useState<any>({});
  const [snackBarError, setSnackBarError] = useState('');
  const [validateEmailMsg, setValidateEmailMsg] = useState<string>('');

  const initializeData = () => {
    setValue(0);
    let initFormDataCloned = cloneDeep(initFormData);
    initFormDataCloned.company = company;
    setFormData(initFormDataCloned);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const saveContact = () => {
    return new Promise((resolve, reject) => {
      const url = isCustomer ? (window as any).jsRoutes.controllers.Customers.edit() : (window as any).jsRoutes.controllers.Users.saveBasicInfo(editContact?._id?.$oid)
      Api.post(url, formData)
        .then(Api.flagSuccess)
        .then((result) => {
          if (result.decision) {
            reject(result.message);
          } else {
            resolve(result);
          }
        })
        .catch((response) => {
          console.log(response);
          reject(response?.message);
        });
    });
  };

  const removeCompany = (index: any) => {
    let state = cloneDeep(formData);
    unset(state, "company." + index);
    set(state, "company", compact(state.company));
    setFormData(state);
  };

  const addCompany = (item: any) => {
    let state = cloneDeep(formData);
    if (!isArray(state.company)) {
      state.company = [];
    }
    state.company.push(item);
    setFormData(state);
  };

  const handleSave = () => {
    saveContact().then(onOk, setSnackBarError);
  };

  const handleFormDataChange = (name: string, value: any) => {
    let state = cloneDeep(formData);
    set(state, name, value);
    setFormData(state);
    merge(customer.documents, state.documents);
  };

  useEffect(() => {
    initializeData();
  }, [open]);

  useEffect(() => {
    if (customer) {
      setFormData(cloneDeep(customer));
    }
  }, [customer]);

  useEffect(() => {
    let msg = '';
    if (formData?.email?.length) {
      msg = isValidEmail(formData.email) ? '' : 'Please enter a valid email';
    }
    setButtonDisabled(
      !formData.firstName ||
        !formData.lastName ||
        // !formData.email ||
        // !formData.phones.length ||
        msg
    );
    setValidateEmailMsg(msg);
  }, [formData]);

  useEffect(() => {
    let call = new AbortController();
    if (query.length > 1) {
      setIsQuerying(true);
      Api.post(
        (window as any).jsRoutes.controllers.IntuitController.getCustomers(),
        { query: query },
        call
      )
        .then((r) => {
          setIsQuerying(false);
          setCustomers(r.customers);
        })
        .catch(Api.silentFail);
    }
    return () => {
      call.abort();
    };
  }, [query]);

  useEffect(() => {
    let call = new AbortController();
    if (editContact && editContact._id) {
      const url = isCustomer ? (window as any).jsRoutes.controllers.Customers.get(editContact._id.$oid) : (window as any).jsRoutes.controllers.Users.getBasicInfo(editContact._id.$oid)
      const key = isCustomer ? 'customer' : 'user'
      Api.get(url,call)
        .then((result) => {
          if (result[key].birthday) {
            result[key].birthday = new PRODate(result[key].birthday);
          }
          (result[key].documents || []).forEach((doc: any) => {
            doc.expiry = new PRODate(doc.expiry);
          });
          console.info(result[key].birthday);
          setCustomer((p: any) => {
            let state = cloneDeep(p);
            merge(state, result[key]);
            return state;
          });
        })
        .catch(Api.silentFail);
    }
    return () => {
      call.abort();
    };
  }, [editContact]);

  const handleCloseSnackBar = () => setSnackBarError('');

  return (
    <Modal open={open} onClose={onCancel}>
      <Box
        sx={[
          styles.modal,
          localStyles.customModal,
          isTabView ? styles.mobile : styles.desktop,
        ]}
      >
        {popover}
        <SnackBar
          open={!!snackBarError}
          handleClose={handleCloseSnackBar}
          message={snackBarError}
          status="error"
        />
        <Box sx={styles.header}>
          <Box>
            {editContact && editContact._id
              ? "Edit Contact"
              : "Add New Contact"}
          </Box>
          <FontAwesomeIcon icon={faTimes} onClick={onCancel} />
        </Box>
        <Box sx={styles.body}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            scrollButtons={"auto"}
            variant="scrollable"
          >
            {TABS.map((tab) => (
              <Tab key={tab} label={tab} id={`tab-${tab}`} sx={styles.tab} />
            ))}
          </Tabs>

          <TabPanel value={value} index={0}>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>First Name  <FontAwesomeIcon icon={faAsterisk} style={{ color: 'red', marginBottom: '5px'}} size='2xs'/></Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.firstName}
                  onChange={(e) =>
                    handleFormDataChange("firstName", e.target.value)
                  }
                />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '120px'}}>
                <Typography>Middle </Typography>
                {/* <Typography sx={{fontSize: '12px'}}>(not required)</Typography> */}
              </Box>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.middleName}
                  onChange={(e) =>
                    handleFormDataChange("middleName", e.target.value)
                  }
                />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>Last Name  <FontAwesomeIcon icon={faAsterisk} style={{ color: 'red', marginBottom: '5px'}} size='2xs'/></Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.lastName}
                  onChange={(e) =>
                    handleFormDataChange("lastName", e.target.value)
                  }
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                ...styles.control,
                alignItems: "center",
                gap: isMobileView ? "4px" : "0px",
              }}
            >
              <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '120px'}}>
                <Typography>Phone </Typography>
                {/* <Typography sx={{ fontSize: '12px' }}>(not required)</Typography> */}
                </Box>
              <PRORepeat
                key={(formData.phones || []).join("-")}
                values={formData.phones}
                onChange={(value: any) => handleFormDataChange("phones", value)}
              />
            </Box>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '120px'}}>
                <Typography>Email </Typography>
                {/* <Typography sx={{ fontSize: '12px' }}>(not required)</Typography> */}
              </Box>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.email}
                  placeholder="johndoe@example.com"
                  onChange={(e) =>
                    handleFormDataChange("email", e.target.value)
                  }
                  error={!!validateEmailMsg}
                  helperText={validateEmailMsg}
                />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography sx={{ width: "150px !important" }}>
                Primary Contact?{" "}
              </Typography>
              <ToggleButtonGroup
                color='primary'
                value={formData.contact}
                exclusive
                onChange={(_: React.MouseEvent<HTMLElement>, value: boolean) => {
                  handleFormDataChange("contact", value)
                }}
                sx={{ height: '40px' }}
              >
                <ToggleButton value={true}>YES</ToggleButton>
                <ToggleButton value={false}>NO</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography sx={{ width: "150px !important" }}>
                Lock this customer to me?{" "}
              </Typography>
              <ToggleButtonGroup
                color='primary'
                value={formData.locked}
                exclusive
                onChange={(_: React.MouseEvent<HTMLElement>, value: boolean) => {
                  handleFormDataChange("locked", value)
                }}
                sx={{ height: '40px' }}
              >
                <ToggleButton value={true}>YES</ToggleButton>
                <ToggleButton value={false}>NO</ToggleButton>
              </ToggleButtonGroup>

            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ ...styles.control, ...localStyles.control, '& > div': { alignItems: 'start', ml: '-4px' } }}>
              <Typography>Birthday </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <DateInput
                  value={formData.birthday}
                  onChange={(v: any) => handleFormDataChange("birthday", v)}
                  format="MM/DD/yyyy"
                />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, ...localStyles.control }}>
              <Typography>Gender </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <ToggleButtonGroup
                  color='primary'
                  value={formData.gender}
                  exclusive
                  onChange={(_: React.MouseEvent<HTMLElement>, value: string) => {
                    handleFormDataChange("gender", value)
                  }}
                  sx={{ height: '40px' }}
                >
                  <ToggleButton value='Male'>MALE</ToggleButton>
                  <ToggleButton value='Female'>FEMALE</ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, ...localStyles.control }}>
              <Typography>Weight </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.weight}
                  onChange={(e) =>
                    handleFormDataChange("weight", e.target.value)
                  }
                />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, ...localStyles.control }}>
              <Typography>Citizenship </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <DropdownInput value={formData.citizenship}
							   onChange={(v: any) => handleFormDataChange("citizenship", v)} options={Country.byCountryName()}
                  labelNode="name" valueNode="short" />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, ...localStyles.control }}>
              <Typography>Redress </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.redress}
                  onChange={(e) =>
                    handleFormDataChange("redress", e.target.value)
                  }
                />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, ...localStyles.control }}>
              <Typography>Known Traveler Number </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.travelerNumber}
                  onChange={(e) =>
                    handleFormDataChange("travelerNumber", e.target.value)
                  }
                />
              </FormControl>
            </Box>
            <Box sx={{ ...styles.control, ...localStyles.control }}>
              <Typography>Verified </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <ToggleButtonGroup
                  color='primary'
                  value={formData.tsaInfo?.verified}
                  exclusive
                  onChange={(_: React.MouseEvent<HTMLElement>, value: boolean) => {
                    handleFormDataChange("tsaInfo.verified", value)
                  }}
                  sx={{ height: '40px' }}
                >
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box
              sx={{
                ...styles.control,
                alignItems: "start",
                gap: isMobileView ? "8px" : "0px",
              }}
            >
              <Typography sx={{ mt: "0.75rem" }}>Company </Typography>
              <FormControl>
                <CompanySelector onChange={addCompany} hideLabel={true} />
                <Typography sx={{ mt: "0.75rem", ml: "4px" }}>
                  Selected Company(ies){" "}
                </Typography>
                <PROLabel
                  name=""
                  value={
                    <Columns template={"1fr auto"}>
                      {(get(formData, "company") || []).map(
                        (item: any, index: number) => {
                          if (get(item, "_id.$oid")) {
                            return (
                              <div
                                key={"company_" + get(item, "_id.$oid")}
                                className={"row"}
                              >
                                <div>{item.name}</div>
                                <button
                                  className="btn-danger"
                                  onClick={() => removeCompany(index)}
                                >
                                  <MinusCircle />
                                </button>
                              </div>
                            );
                          } else {
                            removeCompany(index);
                            return false;
                          }
                        }
                      )}
                    </Columns>
                  }
                />
              </FormControl>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PROAddress
              topLine={false}
              bottomLine={false}
              key={"Address"}
              address={formData.address}
              onChange={(v: any) => handleFormDataChange("address", v)}
              hideLabel={true}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            {(customer.documents || []).map((doc: any, index: number) => {
              let country = Country.parse(doc.country || "") || Country.US;
              return (
                <Box key={doc._id.$oid} sx={{ mb: "10px" }}>
                  {index > 0 && <hr className="full" />}
                  <DropdownInput
                    name="Document Type"
                    value={doc.type}
                    onChange={(v: any) =>
                      handleFormDataChange("documents." + index + ".type", v)
                    }
                    options={DocumentCode.list()}
                    labelNode="name"
                    valueNode="id"
                    append={
                      <button
                        className="control-content btn-danger"
                        onClick={() => {
                          let state = cloneDeep(customer);
                          unset(state, "documents." + index);
                          set(state, "documents", compact(state.documents));
                          setCustomer(state);
                        }}
                      >
                        <MinusCircle />
                      </button>
                    }
                  />
                  <TextInput
                    name="Number"
                    value={doc.number}
                    onChange={(v: any) =>
                      handleFormDataChange("documents." + index + ".number", v)
                    }
                  />
                  <DateInput
                    name="Expiration"
                    value={doc.expiry}
                    onChange={(v: any) =>
                      handleFormDataChange("documents." + index + ".expiry", v)
                    }
                    format="MM/DD/yyyy"
                  />
                  {doc.type === DocumentCode.License.id ? null : (
                    <DropdownInput
                      key="country"
                      name="Country"
                      value={country.short}
                      onChange={(v: any) =>
                        handleFormDataChange(
                          "documents." + index + ".country",
                          v
                        )
                      }
                      options={Country.byCountryName()}
                      labelNode="name"
                      valueNode="short"
                    />
                  )}
                  {doc.type === DocumentCode.License.id ? (
                    <PROAddress
                      key={index + "addr"}
                      address={doc.address}
                      onChange={(v: any) =>
                        handleFormDataChange(
                          "documents." + index + ".address",
                          v
                        )
                      }
                      topLine={false}
                      bottomLine={false}
                    />
                  ) : undefined}
                </Box>
              );
            })}
            <ButtonSave
              style={{ marginTop: "10px" }}
              className="control-content btn-primary btn-block"
              label={
                <span>
                  <PlusCircle /> Travel Document
                </span>
              }
              onClick={(btn: any) => {
                btn.deactivate();
                Api.get(
                  (window as any).jsRoutes.controllers.Application.generateId()
                ).then((result) => {
                  if (!customer.documents) {
                    customer.documents = [];
                  }
                  customer.documents.push({
                    _id: result._id,
                    country: Country.US.short,
                    type: DocumentCode.list()[0].id,
                  });
                  setCustomer((p: any) => {
                    let state = cloneDeep(p);
                    merge(state, customer);
                    return state;
                  });
                });
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Box sx={{ ...styles.control, alignItems: "center" }}>
              <Typography>Notes </Typography>
              <FormControl variant="standard" sx={{ ml: 0.5 }}>
                <TextareaAutosize
                  minRows={3}
                  value={formData.notes}
                  style={{
                    width: "100%",
                    border: "1px solid rgb(133, 133, 133)",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                  onChange={(e) =>
                    handleFormDataChange("notes", e.target.value)
                  }
                />
              </FormControl>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Box sx={{ ...styles.control, alignItems: "end" }}>
              <Typography sx={{minWidth: '150px !important'}}>QuickBooks Online</Typography>
              {operation.tools?.intuit ?
                <Fragment>
                  <PROLabel value={<Autocomplete key={get(formData, "intuit.name")} options={customers} selected={get(customer, "intuit.name")} node={"name"} onInputChange={(temp: any) => {
                    setQuery(temp);
                  }} onChange={(value: any) => {
                    handleFormDataChange("intuit", value);
                  }} isLoading={isQuerying} />} />
                </Fragment>
                :
                <Fragment>
                  <TextInput value={get(formData, "intuit.code")} onChange={(v: any)=> handleFormDataChange("intuit.code", v)}/>
                </Fragment>
              }
            </Box>
            <Box sx={{ ...styles.control, alignItems: "end", '& > div': { width: '10px' } }}>
              <Typography sx={{minWidth: '150px !important'}}>QuickBooks Desktop</Typography>
              {get(operation, "tools.quickbooks") && <Fragment>
                {get(customer, "quickbooks.FullName")}
                <button style={{ width: '100%'}} className={"full pro-btn"} onClick={() => {
                  setPopover(<QuickBooksCustomer
                    query={get(customer, "quickbooks.FullName") || get(customer, "firstName")}
                    onSave={(v: any) => handleFormDataChange("quickbooks", v)} onClosed={clearPopover}/>)
                }}>Map to QuickBooks Desktop
                </button>
              </Fragment>}
            </Box>
          </TabPanel>

          <Box
            sx={[
              styles.actionButtons,
              {
                mt: "2rem",
                justifyContent: "end",
                flexDirection: "row",
                alignItems: "center",
              },
            ]}
          >
            <Button variant="contained" sx={styles.action} onClick={onCancel}>
              Close
            </Button>

            <Button
                disabled={isButtonDisabled}
                variant="contained"
                sx={styles.action}
                onClick={handleSave}
              >
                Save
              </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactModal;
