import { Box, Button } from "@mui/material";
import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";
import isNull from "lodash/isNull";
import { useEffect, useRef, useState } from "react";
import isPlainObject from "react-redux/lib/utils/isPlainObject";
import Api from "../../helpers/api";
import ButtonSave from "../../helpers/buttonSave/buttonSave";
import Loading from "../../helpers/loading/loading";
import PRODate from '../../helpers/proDate';
import { Arrow } from "../../icons/circle/arrow";
import BasicTable from "../../layout/table/table";
import styles from "./style.module.scss";

const ChangeLog = ({id, node, mod, onClosed, showKey, newValueClass, byTimestamp, keyFormatter = function (i) {
	i = i.replace("_internal.", "");
	i = i.replace("locked.", "");
	i = i.replace("breakdown.", "");
	i = i.replace("price.", "");
	return i;
},
preprocess, hide=["_id", "operation"]}) => {
	const btn = useRef();
	const [logs, setLogs] = useState([]);
	const [last, setLast] = useState();
	const [start, setStart] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const process = function (i) {
		i = preprocess?.(i) ?? i;
		if (isNull(i)) {
			return "";
		}
		if (isBoolean(i)) {
			return i ? "Yes" : "No"
		}
		if (PRODate.isDate(i)) {
			return new PRODate(i).format();
		}
		if (isFinite(i)) {
			return i
		}
		if (isArray(i)) {
			return i.map((x, ind) => <div key={ind}>{process(x)}</div>)
		}
		if (isPlainObject(i)) {
			return <div className={styles.jsonObject}>{Object.entries(i).map((e, ind) => {
				if (hide.includes(e[0])) return null;
				return <div key={e[0] + ind}>
					<span className={"bold"}>{e[0]}</span>&nbsp;:&nbsp;<span>{process(e[1])}</span>
				</div>
			})}</div>
		}
		try {
			let json = JSON.parse(i);
			return process(json);
		} catch {}
		return i.toString()
	}

	useEffect(() => {
		let call = new AbortController();
		let url = byTimestamp ? window.jsRoutes.controllers.Api.getChangeLogByTimestamp() : window.jsRoutes.controllers.Api.getChangeLog();
		if (btn.current) {
			btn.current.activate();
		}
		setIsLoading(true);
		const local = -(new Date()).getTimezoneOffset() / 60;
		Api.post(url, {
			"_id": {"$oid": id},
			"key": node,
			"mod": mod,
			"last": start
		}, call).then((result) => {
			setLast(result?.last?.id ? result.last : null);
			setLogs(result.logs.filter((l) => !l.key.includes(".fbo.services.")).map(l => {
				let out = l;
				out.timestamp = new PRODate(l.timestamp).withOffset(local);
				return out;
			}));
			setIsLoading(false);
		}).catch(() => {
			setIsLoading(false);
		}).then(() => {
			if (btn.current) {
				btn.current.deactivate();
			}
		})
		return () => {
			call.abort()
		}
	}, [id, node, mod, start, byTimestamp])

	return <Box>
		<Loading active={isLoading}/>
		<BasicTable template={"1fr auto auto auto 35px 1fr"} style={{maxHeight: "100%"}} headers={[<div key={"date"}
			className="text-right">Date</div>, "User", !node || showKey ? "Key" : null,
			<div key={"old"} className="text-right">Old Value</div>, "", <div key={"new"} className="text-left">New Value</div>]}>
			{logs.map(log => {
				const key = !node || showKey ? keyFormatter(log.key) : null;
				return <div key={log._id} className={"row" + (key === "status" ? " bg-warning" : "")}>
					<div
						className="cell text-right mono text-small">{log.timestamp.format("MM/DD/yyyy @ HH:mmZZ")}</div>
					<div className="cell">{log.userName}</div>
					<div className="cell">{key}</div>
					<div className="cell text-right text-small"><i>{process(log.oldValue)}</i></div>
					<div className="cell text-center"><Arrow direction={"right"}/></div>
					<div className={"cell text-left "+(newValueClass||"")}>{process(log.newValue)}</div>
				</div>
			})}
			{last ? <div key={"more"} className={"row"}>
				<div className={"cell text-center full-width"}><ButtonSave ref={btn} onClick={() => {
					setStart(last)
				}} label="Load More"/></div>
			</div> : undefined}
    </BasicTable>
    <Box sx={{ display: 'flex', justifyContent: 'end', mt: '50px' }}>
      <Button variant='contained' sx={styles.action} onClick={onClosed}>Close</Button>
    </Box>
	</Box>
}
export default ChangeLog;