import Api from "@helpers/api";
import ButtonSave from "@helpers/buttonSave/buttonSave";
import Form from "@helpers/form/form";
import PROLabel from "@helpers/label/label";
import notify from "@helpers/notify";
import { CopyIcon } from "@icons/copy";
import { Box, Button } from "@mui/material";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { selectTicket } from "../../../data/ticketReducer";
import { styles } from "../../../pages/modal/common";

export const VendorOptions = ({id, onClosed, loadData})=>{
	const item = useSelector(selectTicket(id))

	return <Box>
		<Form>
			<PROLabel name={"Deferral Link"} className={"no-wrap"} value={get(item, "external.$oid") ? window.jsRoutes.controllers.SMS.vendorLinkDeferral(item.external.$oid).absoluteURL(true) : "N/A"} addendum={<button onClick={()=>{
				navigator.clipboard.writeText(window.jsRoutes.controllers.SMS.vendorLinkDeferral(item.external.$oid).absoluteURL(true));
				notify.info("External Vendor URL", "Copied to Clipboard!");
			}}>
				<CopyIcon/>
			</button>} />

			<PROLabel name={"Corrective Action Link"} className={"no-wrap"} value={get(item, "external.$oid") ? window.jsRoutes.controllers.SMS.vendorLinkCorrective(item.external.$oid).absoluteURL(true) : "N/A"} addendum={<button onClick={()=>{
				navigator.clipboard.writeText(window.jsRoutes.controllers.SMS.vendorLinkCorrective(item.external.$oid).absoluteURL(true));
				notify.info("External Vendor URL", "Copied to Clipboard!");
			}}>
				<CopyIcon/>
			</button>} />

			{item.rii ?
			<PROLabel name={"RII Link"} className={"no-wrap"} value={get(item, "external.$oid") ? window.jsRoutes.controllers.SMS.vendorLinkRII(item.external.$oid).absoluteURL(true) : "N/A"} addendum={<button onClick={()=>{
				navigator.clipboard.writeText(window.jsRoutes.controllers.SMS.vendorLinkRII(item.external.$oid).absoluteURL(true));
				notify.info("External Vendor URL", "Copied to Clipboard!");
			}}>
				<CopyIcon/>
			</button>} /> : false}
		</Form>
		{get(item, "external.$oid") ?
			<ButtonSave className={"bg-danger"} label={"Remove Link"} onClick={(btn)=>{
				Api.delete(window.jsRoutes.controllers.SMS.removeExternalId(item._id.$oid))
					.then(() => {
						loadData()
					})
					.catch(Api.flagFail)
					.then(()=>{
						btn.deactivate();
					})
			}} />
			:
			<ButtonSave label={"Generate"} onClick={(btn)=>{
				Api.get(window.jsRoutes.controllers.SMS.generateExternalId(item._id.$oid))
					.then(() => {
						loadData()
					})
					.catch(Api.flagFail)
					.then(()=>{
						btn.deactivate();
					})
			}} />
    }
    <Box sx={{ width: '100%', textAlign: 'end'}}>
      <Button variant='contained' sx={styles.action} onClick={onClosed}>Close</Button>
    </Box>
	</Box>
}