import Autocomplete from "../autocomplete/autocomplete";
import isString from "lodash/isString";
import Api from "../api";
import PRODate from "../proDate";
import {useEffect, useState} from "react";
import Columns from "../columns";
import {FromTo} from "../../icons/fromTo";

const LegSelector = ({onChange})=>{
	const [term, setTerm] = useState();
	const [dispatches, setDispatches] = useState([]);

	useEffect(() => {
		const call = new AbortController();
		if (!isString(term) || term === "" || term.length < 2) {
			return
		}
		Api.post(window.jsRoutes.controllers.Dispatches.search(), {term: term, showLegs:true}, call)
			.then((result) => {
				result.dispatches.forEach(function (item) {
					item.departure = item.departure || {};
					item.departure.time = new PRODate(item.departure?.time);
					item.arrival = item.arrival || {};
					item.arrival.time = new PRODate(item.arrival?.time);
				});
				setDispatches(result.dispatches);
			})
			.catch(Api.flagFail);
		return ()=>{
			call.abort()
		}
	}, [term])

	return <Autocomplete selected={term} onInputChange={v => setTerm(v) } onChange={onChange} placeholder={"Search"} options={dispatches} node={function(){
		return <Columns template={"1fr"} style={{gap:0}}>
			<div>Trip: <b>{this.dispatch?.tripId}</b> {this.dispatch?.flightId ? <span className={"text-small"}>Flight {this.dispatch?.flightId}</span> : null}</div>
			<Columns template={"auto auto 20px auto 1fr"} className={"center"}>
				<div className={"mono no-wrap text-small"}>{this.departure.time.format("MM/dd/yyyy HH:mm ZZ")}</div>
				<div className={"bold"}>{this.departure?.airport}</div>
				<div className={"centered"}>
					<FromTo />
				</div>
				<div className={"bold"}>{this.arrival?.airport}</div>
				<div className={"mono no-wrap text-small"}>{this.arrival.time.format("MM/dd/yyyy HH:mm ZZ")}</div>
			</Columns>
			{this.dispatch?.client?.company?.name ? <div className={"text-small bold"}>{this.dispatch?.client?.company?.name}</div> : null}
		</Columns>
	}} />
}
export default LegSelector;