export const buildCSV = function (headers, data) {
	const h = headers.map(i => `"${(i instanceof Object) ? i.props.children : i}"`);
	let csv = [h.join(",")];
	data.forEach(function (row) {
		return csv.push(row.map(i => {
			if (["=", "+", "-", "@"].includes(i[0])) {
				i = "'" + i;
			}
			return `"${i}"`;
		}).join(","));
	})
	return "data:text/csv;charset=utf-8," + encodeURI(csv.join("\r\n"));
}
export const chunk = function (array, size) {
	let R = [];
	for (let i = 0; i < array.length; i += size)
		R.push(array.slice(i, i + size));
	return R;
};