/* eslint-disable react/display-name */
import {MapView} from "@aws-amplify/ui-react-geo";
import styles from "./style.module.scss";
import {createContext, forwardRef, lazy, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {LngLatBounds} from "maplibre-gl";
import {Layer, Marker, Popup, Source} from "react-map-gl";
import {along, bearing, length, point} from "@turf/turf";
import {Purpose} from "@helpers/utils/purpose";
import Columns from "@helpers/columns";
import {useNavigate} from "react-router-dom";
import DataStore from "@helpers/dataStore";
import '@aws-amplify/ui-react-geo/styles.css';
import {Badge, Button, Typography} from "@mui/material";
import {Arrow} from "@icons/circle/arrow";
import Form from "@helpers/form/form";
import PROLabel from "@helpers/label/label";
import {SimpleTooltip} from "@helpers/tooltip";
import {usePopover} from "@hooks/usePopover";
import {PopoverFull} from "@layout/popover";

const AirportInformation = lazy(()=> import("src/pages/airportInformation").then(r=> ({default: r.AirportInformation})));
const AddLeg = lazy(()=> import("src/pages/dispatches/trip/addLeg"));

const CHART_POPUP_CONTEXT = createContext(null);

const Chart = forwardRef(({legs, pins, style, animated, lineWidth=2}, ref) => {
	const map = useRef();
	const pinMarkers = useRef([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [showToolbar, setShowToolbar] = useState(false);
	const [markers, setMarkers] = useState([]);
	const [routes, setRoutes] = useState({});
	const [icons, setIcons] = useState(null);
	const steps = 100;
	const staticColor = "#F2BC57";

	const getColor = (color)=>{
		const test = document.querySelector("." + color);
		if (test) {
			return getComputedStyle(test).backgroundColor;
		}
		return "#666666";
	};

  useEffect(()=>{
		if ((pins ?? []).length > 0) return;
		setMarkers(legs || []);
		if (isLoaded && map.current) {
			const bounds = (legs || [])
				.map(l => [l.from, l.to])
				.flat()
				.filter(({lat, lng}) => lat && lng)
				.map(({lat,lng})=>[lng,lat])
				.reduce((prev, loc) => prev.extend(loc), new LngLatBounds());
			if (!bounds.isEmpty()) {
				try {
					map.current.fitBounds(bounds.toArray(), {padding: 80, maxZoom:13});
				} catch (e) {
					console.error("fitBounds failed. 1", bounds, e);
				}
			}
		}
	}, [pins, legs, isLoaded])

	useEffect(()=>{
		if (isLoaded && map.current && (pins||[]).length > 0) {
			const bounds = (pins||[])
				.filter(({lat, lng}) => lat && lng)
				.map(({lat,lng})=>[lng,lat])
				.reduce((prev, loc) => prev.extend(loc), new LngLatBounds());
			if (!bounds.isEmpty()) {
				try {
					map.current.fitBounds(bounds.toArray(), {padding: 80, maxZoom:12});
				} catch (e) {
					console.error("fitBounds failed. 2", e);
				}
			}
		}
	}, [pins, isLoaded])

	useEffect(()=>{
		setRoutes({
			"type": "FeatureCollection",
			"features": (legs || []).map(leg => {
				const dLng = leg?.from?.lng;
				const dLat = leg?.from?.lat;
				const aLng = leg?.to?.lng;
				const aLat = leg?.to?.lat;
				if (!dLng || !dLat || !aLng || !aLat) {
					return null
				}
				let out = {
					"type": "Feature",
					"geometry": {
						"type": "LineString",
						"coordinates": [[dLng, dLat], [aLng, aLat]]
					},
					"properties": {
						"color": leg.color ?? getColor(leg.purpose?.background)
					}
				};
				const dist = length(out);
				let arc = [];
				for (let i = 0; i < dist; i += dist / steps) {
					const seg = along(out, i);
					arc.push(seg.geometry.coordinates);
				}
				out.geometry.coordinates = arc;
				return out;
			})
		});
	}, [legs])

	useEffect(()=>{
		if (animated === false || (legs || []).length <= 0) return;
		setIcons({
			"type": "FeatureCollection",
			"features": (legs || []).map(leg => {
				const dLng = leg?.from?.lng;
				const dLat = leg?.from?.lat;
				if (!dLng || !dLat) {
					return null
				}
				return ({
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": [dLng, dLat]
					}
				})
			})
		})
	}, [animated, legs])

	useEffect(() => {
		let counter = 0;
		let forceStop = false;
		if (animated === false) return;
		const animate = () => {
			if (!map.current || forceStop || !routes || !icons || !icons?.features || !isLoaded) return;
			routes.features.forEach((feature, index) => {
				const icon = icons?.features?.[index] ?? {};
				if (!feature?.geometry?.coordinates?.[counter]) return;
				icon.geometry = icon.geometry ?? {};
				icon.geometry.coordinates = feature?.geometry?.coordinates?.[counter];
				const start = feature?.geometry?.coordinates?.[counter >= steps ? counter - 1 : counter];
				const stop = feature?.geometry?.coordinates?.[counter >= steps ? counter : counter + 1];
				if (start && stop) {
					icon.properties = icon.properties ?? {};
					icon.properties.bearing = bearing(point(start), point(stop));
				}
			});

			map.current.getSource?.('icons')?.setData?.(icons);

			counter++;
			if (counter >= steps) counter = 0;

			requestAnimationFrame(animate);
		};

		if (map.current && isLoaded) {
			animate();
		}

		return () => {
			forceStop = true;
		}
	}, [animated, isLoaded, routes, icons]);

	const mapIsLoaded = ()=>{
		const icon = new Image(20, 20);
		icon.src = "data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20focusable%3D%22false%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%0A%09%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%20512%20576%22%20style%3D%22enable-background%3Anew%200%200%20512%20576%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23337AB7%3B%7D%0A%3C%2Fstyle%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M192%2C96v114.3L8.1%2C315.4c-5%2C2.9-8.1%2C8.2-8.1%2C13.9v65.5c0%2C10.6%2C10.2%2C18.3%2C20.4%2C15.4l171.6-49V464l-57.6%2C43.2%0A%09c-4%2C3-6.4%2C7.8-6.4%2C12.8v40c0%2C10.4%2C9.8%2C18%2C19.9%2C15.5L256%2C544l108.1%2C31.5c10.1%2C2.5%2C19.9-5.1%2C19.9-15.5v-40c0-5-2.4-9.8-6.4-12.8%0A%09L320%2C464V361.1l171.6%2C49c10.2%2C2.9%2C20.4-4.8%2C20.4-15.4v-65.5c0-5.7-3.1-11-8.1-13.9L320%2C210.3V96c0-35.3-28.6-96-64-96%0A%09S192%2C60.7%2C192%2C96z%22%2F%3E%0A%3C%2Fsvg%3E";
		icon.onload = ()=>{
			map.current.addImage('airplane', icon);
			setIsLoaded(true);
		}
	}

	const toggleInteractive = () => {
		setIsLoaded(false);
		setShowToolbar(p => {
			return !p;
		});
	}

	pinMarkers.current = [];
	const _pins = (pins||[]).filter(({lat, lng}) => lat && lng).map(({lat,lng,label,_id,data,scale}, index, list) =>
		<PopUpMarker ref={dom => {
			if (dom) pinMarkers.current.push(dom);
		}} key={_id?.$oid || label} id={_id?.$oid} lat={lat} lng={lng} color={staticColor} label={label} data={data} near={list.filter(i => i?.data?.last?.arrival?.airport ? i?.data?.last?.arrival?.airport === data?.last?.arrival?.airport : false)} scale={scale} />
	);

	useImperativeHandle(ref, ()=>({
		getMap: ()=>{
			return map.current;
		},
		getMarker: (id)=>{
			pinMarkers.current?.forEach(i => i?.hide?.());
			return pinMarkers.current?.find?.(i => i.id === id);
		}
	}), [map, pinMarkers])

	return <div className={styles.map} style={style}>
		<nav className={styles.toolbar}>
			<Button variant={"contained"} onClick={toggleInteractive}>{showToolbar ? "Back to Static" : "Enter Interactive Mode"}</Button>
		</nav>
		<CHART_POPUP_CONTEXT.Provider value={{close:()=>{
			pinMarkers.current.forEach(p => p?.hide?.())
		}}}>
		<MapView key={showToolbar} ref={map} style={{height:300, width:"100%"}} interactive={showToolbar} onLoad={mapIsLoaded} attributionControl={false} onError={(err)=>{
			console.error("map error", err, routes, icons);
			throw err;
		}} mapStyle={window.matchMedia('(prefers-color-scheme: dark)').matches ? "levelflightDark-production" : "levelflightLight-production"}>
			{markers.filter(i => i.purpose !== Purpose.Positioning).map(({_id,from,purpose}) =>
				from?.lat ? <Marker key={_id?.$oid+"-from"} latitude={from.lat} longitude={from.lng} color={getColor(purpose?.background)} /> : null
			)}
			{markers.filter(i => i.purpose !== Purpose.Positioning).map(({_id,to,purpose}) =>
				to?.lat ? <Marker key={_id?.$oid+"-to"} latitude={to.lat} longitude={to.lng} color={getColor(purpose?.background)} /> : null
			)}
			{markers.filter(i => i.purpose === Purpose.Positioning).map(({_id,from,purpose}) =>
				from?.lat ? <Marker key={_id?.$oid+"-from"} latitude={from.lat} longitude={from.lng} color={getColor(purpose?.background)} /> : null
			)}
			{markers.filter(i => i.purpose === Purpose.Positioning).map(({_id,to,purpose}) =>
				to?.lat ? <Marker key={_id?.$oid+"-to"} latitude={to.lat} longitude={to.lng} color={getColor(purpose?.background)} /> : null
			)}
			{_pins}
			<Source id={"routes"} type={"geojson"} data={routes}>
				<Layer id={"routes"} type={"line"} paint={
					{
						"line-width": lineWidth,
						"line-color": ["get", "color"]
					}
				} />
			</Source>
			{isLoaded ?
			<Source id={"icons"} type={"geojson"} data={icons}>
				<Layer id={"icons"} type={"symbol"} layout={
					{
						"icon-image": "airplane",
						"icon-rotate": ["get", "bearing"],
						"icon-rotation-alignment": "map",
						"icon-allow-overlap": true,
						"icon-ignore-placement": true
					}
				} />
			</Source> : null}
		</MapView>
		</CHART_POPUP_CONTEXT.Provider>
		<div style={{display: "none"}}>
			<div className="background-unknown"/>
			<div className="background-positioning"/>
			<div className="background-maintenance"/>
			<div className="background-training"/>
			<div className="background-charter"/>
			<div className="background-owner"/>
			<div className="background-owner"/>
			<div className="background-medical"/>
		</div>
	</div>
})
export default Chart;

const PopUpMarker = forwardRef(({lat, lng, label, color, id, data, near, scale}, ref)=>{
	const navigate = useNavigate();
	const [popover, setPopover, clearPopover] = usePopover();
	const [showPopup, setShowPopup] = useState(false);
	const context = useContext(CHART_POPUP_CONTEXT);

	useImperativeHandle(ref, ()=>({
		id: id,
		airport: data?.last?.arrival?.airport,
		togglePopup: ()=>{
			setShowPopup(p => !p);
		},
		hide: ()=>{
			setShowPopup(false);
		},
	}), [data?.last?.arrival?.airport, id])

	return (!lat || !lng) ? false : <>
		{popover}
		<Marker latitude={lat} longitude={lng} color={color} scale={scale ?? 1} onClick={!data && !id ? null : (ev)=> {
			ev.originalEvent.stopPropagation();
			context.close?.();
			setShowPopup(p => !p)
		}} />
		{showPopup ? <Popup latitude={lat} longitude={lng} onClose={()=> setShowPopup(false)} closeButton={false} closeOnClick={true}>
			<Columns template={"1fr"} style={{gridGap:0}}>
				<Badge component={"div"} slotProps={{root:{style:{width:"100%"}}}} badgeContent={near.length > 1 ?
					<SimpleTooltip tooltip={<>{near.map(i=><div key={i?._id?.$oid}>{i.label}</div>)}</>}>
						{near.length - 1}
					</SimpleTooltip>
					: null} color={"primary"}>
					<Typography variant={"h6"} className={"text-center text-dark"} style={{margin:"0 auto"}}>{label}</Typography>
				</Badge>

				<Form style={{gridGap:"0 6px"}}>
					{data?.last ? <PROLabel name={"Last"} value={<div className={"mono"}>{data?.last?.departure?.airport} <Arrow direction={"right"} /> {data?.last?.arrival?.airport}</div>} /> : false}
					{data?.next ? <PROLabel name={"Next"} value={<div className={"mono"}>{data?.next?.departure?.airport} <Arrow direction={"right"}/> {data?.next?.arrival?.airport}</div>} /> : false}
					{data?.name ? <PROLabel name={"Name"} value={data?.name} /> : false}
					{data?.longestRunway ? <PROLabel name={"Runway"} value={data?.longestRunway} /> : false}
				</Form>

				{id ? <Button variant={"contained"} onClick={()=>{
					DataStore.Set.array("AircraftCalendar.solo", [id])
					navigate('/calendars/aircraft');
				}}>View Calendar</Button> : false}

				{data?.longestRunway ? <>
					<Button variant={"contained"} className={"background-gray text-black"} onClick={() => {
						setPopover(<PopoverFull large={true} onClosed={clearPopover} saveBtn={false} title={(data?.icao ?? data?.iata ?? data?.faa)}><AirportInformation icao={(data?.icao ?? data?.iata ?? data?.faa)} /></PopoverFull>)
					}}>More Info</Button>
					<Button variant={"contained"} onClick={() => {
						setPopover(<AddLeg trip={data?.trip} loadData={data?.refresh} onClosed={clearPopover}
										   defaultAirport={(data?.icao ?? data?.iata ?? data?.faa)}/>)
					}}>Add Leg</Button>
				</> : false}
			</Columns>
		</Popup> : null}
	</>
});
