import PaginationUI from '@mui/material/Pagination';
import { useEffect, useState } from 'react';

export const Pagination = ({ page, onChange, pageItems, total }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (total) {
      setCount(page < total ? page + 1 : page)
    } else if (pageItems) {
      setCount(pageItems < 25 ? page : page + 1)
    }
  }, [page, pageItems, total]);


  return <PaginationUI sx={{ display: 'flex', justifyContent: 'center' }} count={count} page={page} onChange={(_, value) => onChange(value)} />
}