import { faBars, faCog, faPlane, faQuestionCircle, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { Notifications } from 'aws-amplify'
import { noop } from 'lodash'
import React, { CSSProperties, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toggleDashboard, toggleDashboardState } from '../../data/dashboardStateReducer'
import { selectOperation } from '../../data/operationReducer'
import { selectUser } from '../../data/userReducer'
import Api from '../../helpers/api'
import ZuluClock from '../../helpers/clock'
import DataStore from '../../helpers/dataStore'
import SnackBar from '../../helpers/snackBar'
import { UserType } from '../../helpers/utils/userType'
import NotificationsCenter from '../../layout/notificationCenter'
import AddUserModal from '../../pages/modal/add-user'
import AirportBrowserModal from '../../pages/modal/airport-browser'
import ChangeLogModal from '../../pages/modal/change-log'
import ChangeOpsModal from '../../pages/modal/change-ops'
import CompanyModal from '../../pages/modal/company'
import ConfirmModal from '../../pages/modal/confirm'
import ContactModal from '../../pages/modal/contact'
import EditWorkOrderModal from '../../pages/modal/edit-workorder'
import ExpenseModal, { defaultExpense } from '../../pages/modal/expense'
import NewQuoteModal from '../../pages/modal/new-quote'
import QuickQuoteModal from '../../pages/modal/quick-quote'
import QuoteSend from '../../pages/modal/quote-send'
import TicketModal from '../../pages/modal/ticket'
import { GeneralRoutes } from '../../routes/general'
import Accordions from './accordion'
import { CreateItems, MOBILE_BREAKPOINT, MenuItems } from './constants'
import MenuButton from './menu'
import Settings from './settings'
import { onCreateNewSMS } from '../../data/appBarStateReducer'

const { InAppMessaging } = Notifications;

const styles: { [x: string]: CSSProperties | {} } = {
  root: {
    color: '#000',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    justifyContent: 'center',
    minHeight: 60,
    boxShadow: 'unset'
  },
  desktop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'var(--white)',
    color: 'var(--trueBlack)',
    width: '100%',
    padding: '0 calc(var(--space-sm) + 2px)',
    paddingLeft: 'var(--space-md)',
    paddingRight: 'var(--space-md)',
    '& > div': {
      '&:nth-of-type(1)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '60%',
        maxWidth: '700px'
      },
      '&:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        '& > picture': {
          display: 'flex',
          width: '60px',
          margin: '0 calc(var(--space-sm) + 2px)',
          '& > img': {
            maxHeight: '60px'
          }
        },
        '& > div': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0 calc(var(--space-xs) + 1px)',
          '& > svg': {
            opacity: 0.5,
            cursor: 'pointer',
          },
        }
      },
    },
  },
  mobile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'var(--white)',
    color: 'var(--trueBlack)',
    paddingLeft: 'calc(var(--space-sm) + 2px)',
    paddingRight: 'calc(var(--space-sm) + 2px)',
    '& > svg': {
      cursor: 'pointer',
    },
  },
  menuLabel: {
    color: '#000',
    cursor: 'pointer !important',
    fontWeight: 'var(--font-weight-bold)',
    fontSize: 'var(--font-md)',
  },
}

const DesktopAppBar = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
    display: 'none !important',
  },
}))

const MobileAppBar = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    display: 'none !important',
  },
}))
const NavBar: React.FC = () => {
  const user = useSelector(selectUser)
  const operation = useSelector(selectOperation);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const dashboardState = useSelector(toggleDashboardState);
  const [openQuickQuote, setOpenQuickQuote] = useState<boolean>(false)
  const [newlyCreatedQuoteDispatch, setNewlyCreatedQuoteDispatch] = useState<string | number>('')
  const [openDispatch, setOpenDispatch] = useState<boolean>(false)
  const [openQuote, setOpenQuote] = useState<boolean>(false)
  const [openCompany, setOpenCompany] = useState<boolean>(false)
  const [openContact, setOpenContact] = useState<boolean>(false)
  const [openExpense, setOpenExpense] = useState<boolean>(false)
  const [isAddNewTicket, setAddNewTicket] = useState<boolean>(false)
  const [openAirport, setOpenAirport] = useState<boolean>(false)
  const [openQuoteSend, setOpenQuoteSend] = useState<boolean>(false);
  const [openChangeOps, setOpenChangeOps] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const [menuEl, setMenuEl] = useState<SVGSVGElement | null>(null);
  const [settingEl, setSettingEl] = useState<SVGSVGElement | null>(null);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarStatus, setSnackBarStatus] = useState<string>('');
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');
  const [confirmCreateOps, setOpenConfirmCreateOps] = useState<boolean>(false);
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const [openWorkOrderModal, setOpenWorkOrderModal] = useState<boolean>(false);
  const [openChangeLog, setOpenChangeLog] = useState<boolean>(false);

  const open = Boolean(anchorEl)
  const showNavBar = !window.location.pathname.startsWith('/popout/');

  useEffect(() => {
    window.addEventListener('resize', () => {
      closeAccountMenu();
      onCloseSetting();
    })
  }, []);

  const openAccountMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAccountMenu = () => {
    setAnchorEl(null);
  };

  const onNavigate = (value: string) => {
    navigate(value)
  }
  const onCreateSelect = (value: string) => {
    openModal(value)
  }

  const openAccordion = (e: MouseEvent<SVGSVGElement>) => {
    setMenuEl(e.currentTarget)
  }
  const closeAccordion = () => {
    setMenuEl(null)
  }
  const openSetting = (e: MouseEvent<SVGSVGElement>) => {
    closeAccordion()
    setSettingEl(e.currentTarget)
  }
  const onCloseSetting = () => {
    setSettingEl(null)
  }

  const openModal = (type: string) => {
    if (type === 'company') {
      setOpenCompany(true)
    } else if (type === 'quickQuote') {
      setOpenQuickQuote(true)
    } else if (type === 'trip') {
      setOpenDispatch(true)
    } else if (type === 'quote') {
      setOpenQuote(true)
    } else if (type === 'contact') {
      setOpenContact(true)
    } else if (type === 'expense') {
      setOpenExpense(true)
    } else if (type === 'ticket') {
      setAddNewTicket(true)
    } else if (type === 'userAccount') {
      setOpenUserModal(true)
    } else if (type === 'workOrder') {
      setOpenWorkOrderModal(true);
    }
  }

  const onOk = () => { 
    setOpenContact(false)
    dispatch(toggleDashboard({ reloadData: !dashboardState.needReload }))
  }

  const quoteOnOk = (sendQuote = false, id: string | number) => {
    setOpenQuickQuote(false);
    dispatch(toggleDashboard({ reloadData: !dashboardState.needReload }))
    if (sendQuote) {
      setNewlyCreatedQuoteDispatch(id);
      setOpenQuoteSend(true);
    }
  }

  const onMyAccountClicked = () => {
    DataStore.set("admin.section", UserType.Accounting.id);
    onNavigate(`/administration/users/${user._id.$oid}`);
    closeAccountMenu();
    onCloseSetting();
  }

  const openAirportModal = () => {
    onCloseSetting();
    setOpenAirport(true)
  };

  const onTripQuoteClicked = () => {
    onCloseSetting();
  }

  const onOpenLog = () => {
    closeAccountMenu();
    onCloseSetting();
    setOpenChangeLog(true);
  }

  const onLogout = () => {
    onCloseSetting();
    onNavigate('/logout');
  };

  const createNewOps = () => {
    Api.get((window as any).jsRoutes.controllers.Operations.create())
      .then(() => {
        Api.reload()
      })
    setOpenConfirmCreateOps(false);
  }

  const handleCloseSnackBar = () => setSnackBarOpen(false);

  const openNewDashboard = () => {
    dispatch(toggleDashboard({ isLegacy: false }))
  }

  const onChangeNewOperation = useCallback(() => {
    closeAccountMenu()
    setOpenChangeOps(true)
  }, [])

  const createNewOperation = useCallback(() => {
    closeAccountMenu()
    setOpenConfirmCreateOps(true)
  }, [])

  return showNavBar ? (
    <AppBar position="absolute" sx={styles.root}>
      <SnackBar
        open={snackBarOpen}
        handleClose={handleCloseSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
      <AddUserModal
        open={openUserModal}
        onOk={() => {
          dispatch(toggleDashboard({ reloadData: !dashboardState.needReload }))
          setOpenUserModal(false)
        }}
        onCancel={() => setOpenUserModal(false)}
      />
      <ChangeLogModal open={openChangeLog} id={operation?._id?.$oid} node="deleted" mod={1} byTimestamp={true} onClose={() => setOpenChangeLog(false)}/>
      <EditWorkOrderModal
        open={openWorkOrderModal}
        onCancel={() => { setOpenWorkOrderModal(false) } }
        onOk={() => { () => { setOpenWorkOrderModal(false) } }}
        onError={function (err: Error): void {
          throw new Error('Function not implemented.')
        } }      />
      <ConfirmModal
        open={confirmCreateOps}
        onCancel={() => setOpenConfirmCreateOps(false)}
        onOk={createNewOps}
      >
        <p>Are you sure you want to create a new operation?</p>
      </ConfirmModal>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeAccountMenu}
      >
        <MenuItem selected={location.pathname === '/'} onClick={() => { onNavigate('/'); closeAccountMenu(); }}>Dashboard</MenuItem>
        <MenuItem onClick={onMyAccountClicked}>My Account</MenuItem>
        {UserType.SuperAdmin.check(user.userTypes) && <MenuItem onClick={onChangeNewOperation}>Change Operation</MenuItem>}
        {UserType.SuperAdmin.check(user.userTypes) && <MenuItem onClick={createNewOperation}>Create New Operation</MenuItem>}
        {UserType.SuperAdmin.check(user.userTypes) && <MenuItem onClick={onOpenLog}>Trip/Quote Log of Deletes</MenuItem>}
        <MenuItem onClick={onLogout}>Logout {user.fullName}</MenuItem>
      </Menu>
      <ChangeOpsModal open={openChangeOps} onClose={() => setOpenChangeOps(false)} />
      <QuoteSend
        open={openQuoteSend}
        onCancel={() => setOpenQuoteSend(false)}
        onOk={onOk}
        id={newlyCreatedQuoteDispatch}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setSnackBarOpen={setSnackBarOpen}
        company={undefined}
      />
      <QuickQuoteModal
        open={openQuickQuote}
        onCancel={() => setOpenQuickQuote(false)}
        onOk={quoteOnOk}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setSnackBarOpen={setSnackBarOpen}
        type={''}
      />
      <NewQuoteModal
        open={openDispatch}
        type='Trip'
        onClose={() => setOpenDispatch(false)}
        onSave={() => setOpenDispatch(false)}
      />
      <NewQuoteModal
        open={openQuote}
        type='Quote'
        onClose={() => setOpenQuote(false)}
        onSave={() => setOpenQuote(false)}
      />
      <AirportBrowserModal open={openAirport} onCancel={() => setOpenAirport(false)} onOk={onOk} />
      <CompanyModal
        open={openCompany}
        onCancel={() => setOpenCompany(false)}
        onOk={onOk}
      />
      <ContactModal
        open={openContact}
        onCancel={() => setOpenContact(false)}
        onOk={onOk}
        editContact={undefined} company={[]}
        isCustomer={true}
      />
      <ExpenseModal
        open={openExpense}
        onCancel={() => setOpenExpense(false)}
        onOk={() => setOpenExpense(false)}
        editExpense={defaultExpense}
      />
      {/* <SMSEventModal open={isAddNewTicket} onCancel={() => setAddNewTicket(false)} onOk={() => {
        setAddNewTicket(false);
        dispatch(onCreateNewSMS({ createdSMS: true }))
      }} /> */}

      <TicketModal
        open={isAddNewTicket}
        onCancel={() => {
          setAddNewTicket(false);
        }}
        onSave={() => {
          setAddNewTicket(false);
          dispatch(onCreateNewSMS({ createdSMS: true }))
        }}
        editTicketId={'new'}
      />

      <DesktopAppBar sx={styles.desktop}>
        <Box>
          <img onClick={() => {
            onNavigate('/');
            openNewDashboard();
          }} src="/images/logos/logo.svg" alt="Logo" style={{ width: 50, cursor: 'pointer' }} />
          {MenuItems.map((menuItem) => (
            <MenuButton
              key={menuItem.label}
              label={menuItem.label}
              value={menuItem.value}
              menuItems={menuItem.menuItems}
              onSelect={onNavigate}
            />
          ))}
        </Box>
        <Box sx={{ height: '60px' }}>
          <ZuluClock />
          <picture className={"row-one"} onClick={UserType.SuperAdmin.check(user.userTypes) ? () => { InAppMessaging.dispatchEvent({ name: 'test_event' }) } : noop}>
            <source srcSet={GeneralRoutes.logo.dark(operation?._id?.$oid).absoluteURL()} media={"(prefers-color-scheme: dark)"} />
            <img style={{ maxWidth: '70px' }} alt={operation.name} src={GeneralRoutes.logo.default(operation?._id?.$oid).absoluteURL()} />
          </picture>
          <MenuButton
            label="Create"
            endIcon={<KeyboardArrowDownIcon />}
            menuItems={CreateItems.menuItems}
            onSelect={onCreateSelect}
            variant="contained"
          />
          <Box sx={{ minWidth: '120px', ml: '10px !important' }}>
            <FontAwesomeIcon icon={faPlane} onClick={openAirportModal} style={{ rotate: '-90deg' }} />
            <FontAwesomeIcon icon={faQuestionCircle} onClick={() => window.open('https://levelflight.atlassian.net/servicedesk/customer/portals')} />
            <NotificationsCenter />
            <FontAwesomeIcon icon={faUserAlt} onClick={openAccountMenu} />
          </Box>
        </Box>
      </DesktopAppBar>
      <MobileAppBar sx={styles.mobile}>
        <FontAwesomeIcon icon={faBars} size="xl" onClick={openAccordion} />
        <img onClick={() => onNavigate('/')} src="/images/logos/logo.png" alt="Logo" style={{ width: 50, cursor: 'pointer' }} />
        <FontAwesomeIcon icon={faCog} size="xl" onClick={openSetting} />
        <Accordions
          anchorEl={menuEl}
          onSelect={onNavigate}
          onClose={closeAccordion}
        />
        <Settings
          anchorEl={settingEl}
          onSelect={onCreateSelect}
          onClose={onCloseSetting}
          onOpenAirportClicked={openAirportModal}
          onMyAccountClicked={onMyAccountClicked}
          onTripQuoteClicked={onTripQuoteClicked}
          onChangeNewOperation={onChangeNewOperation}
          createNewOperation={createNewOperation}
          onOpenLog={onOpenLog}
          onLogout={onLogout}
        />
      </MobileAppBar>
    </AppBar>
  ) : null
}

export default NavBar
