import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import includes from "lodash/includes";
import pick from "lodash/pick";
import set from "lodash/set";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../../helpers/api";
import { DropdownInput } from "../../../helpers/dropdown";
import Form from "../../../helpers/form/form";
import Label from "../../../helpers/label/label";
import PRODate from "../../../helpers/proDate";
import { RadioInput, YesOrNo } from "../../../helpers/radioInput/radioInput";
import { TextAreaLabel } from "../../../helpers/textArea";
import { SMSTicketType } from "../../../helpers/utils/SMSTicketType";
import { styles } from "../../../pages/modal/common";
import { History } from "@icons/history";
import ChangeLogModal from "../../../pages/modal/change-log";

export const Analysis = ({item, severities, likelihoods, spis, matrix, onClose, onSave}) => {
	const [errors, setErrors] = useState([]);
	const [temp, setTemp] = useState(Object.assign({severity: severities[0].id, likelihood: likelihoods[0].id}, item))
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false)
	// const _item = useMemo(()=>{
	// 	setTemp(Object.assign({severity: severities[0].id, likelihood: likelihoods[0].id}, item));
	// 	return item
  // }, [item])
  
  useEffect(() => {
    setTemp(item)
  }, [item])

	const save = ()=>{
    Api.post(window.jsRoutes.controllers.SMS.saveAnalysis(item._id.$oid), pick(temp, ["rootCauseAnalysis", "severity", "likelihood", "spi", "isCulturalIssue", "cultureAnalysis"]))
      .then(Api.flagSuccess)
      .then(() => {
        setErrors([]);
        onSave();
      })
      .catch(e => {
        Api.flagFail(e);
        setErrors(e.json.errors || []);
      });
	}

	const onChange = (key, value)=>{
		setTemp(p => {
			let state = cloneDeep(p);
			set(state, key, value);
			return state;
		})
	}

	return (
		<Form bottom={<>
			{get(item, "logs.analyzed.user._id") ? <div className="text-center">Last edited
        by {get(temp, "logs.analyzed.user.firstName")} {get(temp, "logs.analyzed.user.lastName")} on {(get(temp, "logs.analyzed.timestamp") || new PRODate()).format("MMM dd, yyyy")}
        <IconButton onClick={() => setOpenChangeLogModal(true)}><History/></IconButton>
      </div> : undefined}
      <Box sx={{ width: '100%', textAlign: 'end' }}>
        <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={onClose}>Cancel</Button>
        <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={save}>Save</Button>
      </Box>
    </>}>
      <ChangeLogModal open={openChangeLogModal} id={item?._id?.$oid} node="logs.analyzed" onClosed={() => setOpenChangeLogModal(false)}/>
			<TextAreaLabel key={temp.rootCauseAnalysis}
						   name={(temp.ticketType === SMSTicketType.Change.id) ? "Analysis of Change" : "Root Cause Analysis"}
						   value={temp.rootCauseAnalysis || ""}
						   onChange={v => onChange("rootCauseAnalysis", v)}
						   error={includes(errors, "obj.rootCauseAnalysis")}/>
			<RadioInput name="Severity" value={temp.severity}
						options={severities.map(function (item) {
							return {label: item.value, value: item.id}
						})} onChange={v => onChange("severity", v)}
						error={includes(errors, "obj.severity")}/>
			<RadioInput name="Likelihood" value={temp.likelihood}
						options={likelihoods.map(function (item) {
							return {label: item.value, value: item.id}
						})} onChange={v => onChange("likelihood", v)}
						error={includes(errors, "obj.likelihood")}/>
			<Label name="Risk Matrix" value={
				<SMSRiskMatrix key={temp.severity + "-" + temp.likelihood} severity={temp.severity} likelihood={temp.likelihood} severities={severities} matrix={matrix} likelihoods={likelihoods} onChange={(sev, like)=>{
					onChange("severity", sev);
					onChange("likelihood", like);
				}}/>
			}/>
			{(temp.ticketType === SMSTicketType.Change.id) ? undefined :
				<DropdownInput name="SPI" value={{_id: temp.spi}} options={spis}
							   valueNode="_id.$oid" labelNode="name" valueOnly={false} onChange={(obj) => {
					if (obj._id.$oid === 0) {
						setTemp(p=>{
							let state = cloneDeep(p);
							delete state.spi;
							return state;
						})
					} else {
						onChange("spi", obj._id);
					}
				}} error={includes(errors, "obj.spi")} append={
					<Form>
						<Link to={"/administration/settings/spis"}>Add a new SPI.</Link>
					</Form>
				} />
			}
			<YesOrNo name="Is Systemic Cultural Issue" value={temp.isCulturalIssue}
					 onChange={v => onChange("isCulturalIssue", v)}
					 error={includes(errors, "obj.isCulturalIssue")}/>
			{temp.isCulturalIssue ?
				<TextAreaLabel key={temp.cultureAnalysis} name={"Culture Analysis"}
							   value={temp.cultureAnalysis || ""}
							   onChange={v => onChange("cultureAnalysis", v)}
							   error={includes(errors, "obj.cultureAnalysis")}/> : undefined}
		</Form>
	);
}

export const SMSRiskMatrix = function ({severity, likelihood, severities, matrix, likelihoods, onChange}) {
	return (
		<table className='table table-bordered' style={{width: "intrinsic", borderTop: "none", marginTop: 60}}>
			<thead>
			<tr>
				<th/>
				{severities?.map((item) => <th key={"head_" + item.id} style={{width: 50, position: "relative"}}>
					<div style={{position: "absolute", transform: "rotate(-34deg)"}} ref={(node) => {
						if (!node) return null;
						node.style.top = (node.getBoundingClientRect().width / -2) + "px";
					}}>{item.value}</div>
				</th>)}
			</tr>
			</thead>
			<tbody>
			{matrix?.map((row, r) => {
				return (
					<tr key={"row_" + r} style={{height: 40}}>
						<th>{likelihoods?.find(item => item.id === r)?.value}</th>
						{row?.map((col, c) => {
							return <td key={"cell_" + c} className="text-center" onClick={()=>{
								onChange?.(c, r);
							}}
									   style={{backgroundColor: col, verticalAlign: "middle"}}>
								{((severity === c) && (likelihood === r)) ?
									<FontAwesomeIcon icon={faCheck} className={((col === "yellow") ? "text-dark" : "text-white")} style={{fontSize: 17}}/>
								: false}
							</td>
						})}
					</tr>
				);
			})}
			</tbody>
		</table>
	);
};