import clamp from "lodash/clamp";
import initial from "lodash/initial";
import isNaN from "lodash/isNaN";
import noop from "lodash/noop";
import nth from "lodash/nth";
import range from "lodash/range";
import union from "lodash/union";
import { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Arrow } from "../../icons/circle/arrow";
import Columns from "../columns";
import { Panel } from "../panel";
import PRODate from '../proDate';
import SimpleInput from "../simpleInput";
import { SimpleTooltip } from "../tooltip";
import { chunk } from "../utils/functions";
import "./style.scss";

export const PRODatePicker = ({ type = "date", selected, showing, onChange = noop, debug, className, style, warning = false }) => {
  let hideTimeout, justClickedTimeout;
	const yearInput = useRef();
	const monthInput = useRef();
	const dayInput = useRef();
	const calDom = useRef();
	const justClicked = useRef(false);
	const [yearFormat, setYearFormat] = useState("yyyy");
	const [showCal, setShowCal] = useState(false);
	const _selected = (selected instanceof PRODate) ? selected : (showing instanceof PRODate) ? showing : PRODate.now();
	const today = PRODate.now().withOffset(_selected.offset);
	const useNative = useMemo(()=>
		(function (a) {
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substring(0, 4))) {
				try {
					let test = document.createElement("input");
					test.type = "date";
					return test.type === "date";
				} catch (e) {
					return false;
				}
			}
		})(navigator.userAgent || navigator.vendor || window.opera)
	, []);

	if (debug) {
		console.info("PRODatePicker::", selected);
	}

	useLayoutEffect(() => {
		if (yearInput.current) {
			let width = yearInput.current.width();
			if (yearFormat === "yyyy" && width < 40) {
				setYearFormat("yy")
			}
			if (yearFormat === "yy" && width > 40) {
				setYearFormat("yyyy")
			}
		}
	}, [yearInput, yearFormat]);

	const dateClicked = (date) => {
		clearTimeout(hideTimeout);
		if (!PRODate.equals(selected, date)) {
			if (date instanceof PRODate) {
				onChange(date);
			} else {
				onChange(null);
			}
		}
		setShowCal(false);
	}

	const showCalendar = () => {
		clearTimeout(hideTimeout);
		setShowCal(true);
	}

	const hideCalendar = (count) => {
		hideTimeout = setTimeout(() => {
			const active = document.activeElement;
			if ((justClicked.current) ||
				(dayInput.current && active === dayInput.current.getInput()) ||
				(monthInput.current && active === monthInput.current.getInput()) ||
				(yearInput.current && active === yearInput.current.getInput())
			) {
				if (count < 3) {
					hideCalendar(count+1);
				}
				return;
			}
			setShowCal(false);
		}, 200);
	}

	const onNativeChange = (ev) => {
		let [year, month, day] = ev.target.value.split("-");
		year = parseInt(year) || new Date().getFullYear();
		month = (parseInt(month) || 1) - 1;
		day = parseInt(day) || 1;
		let out;
		if (selected) {
			try {
				let date = selected.withYear(year).withMonth(month).withDay(day);
				out = date || selected;
			} catch (e) {
				console.warn("date error", e)
			}
		} else {
			try {
				out = new PRODate().withYear(year).withMonth(month).withDay(day);
			} catch (e) {
				console.warn("date error", e)
			}
		}
		onChange(out);
	}

	const onFieldChange = (ev, key) => {
		clearTimeout(hideTimeout);

		let val = parseInt((ev && ev.target) ? ev.target.value : ev);
		if (isNaN(val) && selected) {
			onChange(undefined);
			setShowCal(false);
			return;
		}

		// TODO: Why was I doing a timezone offset? Maybe for when the picker is in local?
		// let offset = new Date().getTimezoneOffset() / -60;
		// const now = PRODate.now().withOffset(offset);
		let date = (_selected instanceof PRODate) ? _selected : PRODate.now();

		let _year;
		if (yearInput.current && yearInput.current.value()) {
			let out = parseInt(yearInput.current.value());
			if (out > 1000) {
				_year = out;
			} else if (out < 100) {
				_year = 2000+out;
			} else {
				_year = date.year;
			}
		} else {
			_year = date.year;
		}

		let parts = [
			(dayInput.current && dayInput.current.value() ? parseInt(dayInput.current.value()) : date.day),
			(monthInput.current && monthInput.current.value() ? parseInt(monthInput.current.value()) - 1 : date.month),
			_year
		];
		if (key === "day") {
			parts[0] = val;
		}
		if (key === "month") {
			parts[1] = val - 1;
		}
		if (key === "year") {
			parts[2] = _year;
		}

		try {
			date = date.withDay(1).withYear(parts[2]).withMonth(parts[1]).withDay(parts[0]);
		} catch (e) {
			// Do nothing
		}

		if (selected instanceof PRODate) {
			if (selected.isSameDay(date)) {
				hideTimeout = setTimeout(() => {
					setShowCal(false);
				}, 200)
				return
			}
		}
		if (!selected && !ev) return;
		onChange(date);
		setShowCal(false);
		if (key === "month") {
			setTimeout(() => {
				dayInput.current.focus();
			}, 0);
		}
	}

	let day, month, year;
	try {
		day = selected.format("dd");
		month = selected.format("MM");
    year = selected.format(yearFormat);
	} catch (e) {
		// Do nothing
	}

	const nativeValue = selected?.format ? selected.format(type === "month" ? "yyyy-MM" : "yyyy-MM-dd") : null;

  return <div className={"date-chooser " + (className || "")}>
    {useNative ?
      <Columns template={"1fr auto"}>
        <input type={type} value={nativeValue} onChange={onNativeChange} placeholder={nativeValue ? null : "Select Date"} style={{ ...style }} />
      </Columns>
      :
      <Fragment>
        <Columns template={"1fr 10px 1fr 10px 1fr"} className="baseline" style={{ gridGap: 0, textAlign: "center", color: warning ? 'red' : '', ...style }}>
          <SimpleInput key={"month_" + month} style={{ minWidth: 25, margin: 0 }}
            className="text-center" ref={monthInput} value={month} onFocus={showCalendar}
            onChange={(v) => onFieldChange(v, "month")} onBlur={hideCalendar} inputStyle={{ color: warning ? 'red' : 'var(--trueBlack)' }} />
          <div>/</div>
          <SimpleInput key={"day_" + day} style={{ minWidth: 25, margin: 0 }}
            className="text-center" ref={dayInput} value={day} onFocus={showCalendar}
            onChange={(v) => onFieldChange(v, "day")} onBlur={hideCalendar} inputStyle={{ color: warning ? 'red' : 'var(--trueBlack)' }}/>
          <div>/</div>
          <SimpleInput key={"year_" + year + yearFormat} style={{ minWidth: 25, margin: 0 }}
            className="text-center" ref={yearInput} value={year} onFocus={showCalendar}
            onChange={(v) => onFieldChange(v, "year")} onBlur={hideCalendar} inputStyle={{ color: warning ? 'red' : 'var(--trueBlack)' }} />
        </Columns>
        <div ref={calDom} style={{ position: "relative" }}>
          {showCal ?
            <PROCalendar
              dom={calDom.current}
              key={_selected.timestamp}
              selected={_selected}
              today={today}
              changed={dateClicked}
              debug={debug}
              headerClick={() => {
                justClicked.current = true;
                clearTimeout(justClickedTimeout);
                dayInput.current.focus();
                justClickedTimeout = setTimeout(() => {
                  justClicked.current = false;
                }, 800);
              }}
            />
            : undefined}
        </div>
      </Fragment>}
  </div>
}

export const PROCalendar = ({ dom, selected, today, changed, headerClick, debug }) => {
  const [top, setTop] = useState(0);
  const [show, setShow] = useState(false);
  const offset = new Date().getTimezoneOffset() / -60;
  const [showing, setShowing] = useState(selected || PRODate.now().withOffset(offset));

  const monthLength = showing.daysInMonth;
  const fill = range(-showing.firstOfMonth.dayOfWeek, 0);
  let days = range(1, monthLength + 1);
  if (fill.length > 0) {
    days = union(fill, days);
  }
  const breakdown = chunk(days, 7);
  const shownHasSelected = (selected instanceof PRODate && showing.year === selected.year && showing.month === selected.month) ? selected.day : false;
  const shownHasToday = (showing.year === today.year && showing.month === today.month) ? today.day : false;

  const lastFocus = document.activeElement;

  const changeShowing = (showNext) => {
    const next = (showNext) ? showing.nextMonth() : showing.lastMonth();
    if (debug) {
      console.info("show next", next);
    }
    setShowing(next);
    lastFocus.focus();
  }

  if (debug) {
    console.info("PROCalendar", showing, selected);
  }

  useEffect(() => {
    let domParent = dom;
    while (domParent && domParent.scrollTop === 0) {
      domParent = domParent.parentNode;
    }
    setTop(domParent.scrollTop);
    setShow(true);

    const step = () => {
      setTop(domParent.scrollTop);

      let _domParent = dom;
      while (_domParent && _domParent.scrollTop === 0) {
        _domParent = _domParent.parentNode;
      }
      domParent = _domParent;
      ani = window.requestAnimationFrame(step);
    }

    let ani = window.requestAnimationFrame(step);

    return () => {
      window.cancelAnimationFrame(ani);
    }
  }, [dom, debug])

  return <Panel className={"calendar"} style={{ visibility: show ? "visible" : "hidden", transform: `translate(0, -${top || 0}px)` }}
    title={<span>{PRODate.monthLabels()[showing.month]} {showing.year}</span>}
    prepend={<Arrow direction={"left"} onClick={() => {
      changeShowing(false)
    }} />}
    append={<Arrow direction={"right"} style={{ justifySelf: "end" }} onClick={() => {
      changeShowing(true)
    }} />} headerClick={headerClick}>
    <Columns template="repeat(7, 1fr)" className="text-center">
      {PRODate.dayLabels().map(function (day) {
        return <div key={day} className="day-label">{day}</div>;
      })}
      {breakdown.map(row =>
        row.map(day =>
          <div key={"day_" + day} onClick={() => {
            let date = (selected || showing).withDay(1).withYear(showing.year).withMonth(showing.month).withDay(day);
            changed(date);
          }}
            className={`day ${(day === shownHasSelected) ? "selected" : (day === shownHasToday) ? "today" : ""}`}>
            {(day > 0) ? <span>{day}</span> : false}
          </div>
        )
      )}
    </Columns>
  </Panel>
}

export const PROTimePicker = (props) => {
  const input = useRef();
  const selected = props.selected;
  const onChange = props.onChange || noop;
  const warning = props.warning

  return <Columns className="time-chooser baseline" template={"1fr"} style={{ gridGap: 0 }}>
    <SimpleInput
      key={(selected instanceof PRODate) ? selected.format("HH:mmZ") : "NotSet"}
      ref={input}
      className="text-center"
      style={{ minWidth: 50, maxWidth: 100 }}
      inputStyle={{color: warning ? 'red' : 'var(--trueBlack)'}}
      value={(selected instanceof PRODate) ? selected.format("HH:mm") : ""}
      postprocess={Calendar.ProcessHours}
      onFocus={field => {
        field.current.setValue(selected instanceof PRODate ? selected.format("HHmm") : "");
      }}
      onChange={(value) => {
        onChange((selected instanceof PRODate) ? selected.startOfDay().plus(value) : undefined)
      }}
      append={
        <Columns template={"1fr"} style={{ gridGap: 0, lineHeight: 1, color: warning ? 'red' : '' }}>
          {(selected instanceof PRODate) ? <div style={{color: warning ? 'red' : '', fontSize: '12px'}}>{selected.format("WWW")}</div> : null}
          {props.allowLocal ?
            <SimpleTooltip className={"centered"} tooltip={"Click to view this date in your computer's timezone"}>
              <button className={"text-primary"} onClick={() => {
                const d = new Date();
                if (selected) {
                  onChange(selected.withOffset(-d.getTimezoneOffset() / 60))
                }
              }} style={{ fontSize: "7pt" }}>
                <div className={"text-white bg-primary text-center"} style={{ borderRadius: 4, padding: "0 2px", color: warning ? 'red' : '', fontSize: '12px' }}>{(selected instanceof PRODate) ? selected.timezone : "Z"}</div>
              </button>
            </SimpleTooltip>
            :
            props.allowZulu ?
              <SimpleTooltip className={"centered"} tooltip={"Click to view this date in Zulu"}>
                <button className={"text-primary"} onClick={() => {
                  if (selected) {
                    onChange(selected.withOffset(0))
                  }
                }} style={{ fontSize: "7pt" }}>
                  <div className={"text-white bg-primary text-center"} style={{ borderRadius: 4, padding: "0 2px", color: warning ? 'red' : '', fontSize: '12px' }}>{(selected instanceof PRODate) ? selected.timezone : "Z"}</div>
                </button>
              </SimpleTooltip>
              :
              <div style={{ fontSize: '12px'}}>{ (selected instanceof PRODate) ? selected.timezone : "Z" }</div>
          }
        </Columns>
      }
    />
  </Columns>
}

export const PRODateTimePicker = ({ className, showing, selected, style, append, allowLocal, allowZulu, onChange = noop, debug, warning = false }) => {

  const change = (date) => {
    if (debug) {
      console.info("PRODateTimePicker::change", date);
    }
    if (!PRODate.equals(selected, date)) {
      if (date instanceof PRODate) {
        onChange(date);
      } else {
        onChange(null);
      }
    }
  }

  return <Columns className={`calendar-chooser ${className || ""}`} style={{ ...style, display: 'flex', flexWrap: 'wrap' }} gridGap={0}>
    <PRODatePicker selected={selected} onChange={change} showing={showing} debug={debug} warning={warning} />
    <div />
    <PROTimePicker selected={selected} onChange={change} debug={debug} allowLocal={allowLocal} allowZulu={allowZulu} warning={warning} />
    <div>{append}</div>
  </Columns>
}

const Calendar = {
  ProcessHours: function (val) {
    let ones = parseInt(nth(val, -1)) || 0;
    let tens = (parseInt(nth(val, -2)) || 0) * 10;
    let hParts = initial(initial(val));
    let hours = (parseInt(hParts.join("")) || 0) * 60;
    return clamp(hours + tens + ones, 0, 1439);
  }
};
export default Calendar;
