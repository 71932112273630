import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import operationReducer from "./operationReducer";
import menuReducer from "./menuReducer";
import viewingUserReducer from "./viewingUserReducer";
import viewingAircraftReducer from "./viewingAircraftReducer";
import tripReducer from "./tripReducer";
import optionsReducer from "./optionsReducer";
import memoReducer from "./memoReducer";
import ticketReducer from "./ticketReducer";
import dashboardStateReducer from "./dashboardStateReducer";
import appBarStateReducer from "./appBarStateReducer";

export default configureStore({
  reducer: {
    user: userReducer,
    operation: operationReducer,
    menu: menuReducer,
    viewingUser: viewingUserReducer,
    viewingAircraft: viewingAircraftReducer,
    trips: tripReducer,
    options: optionsReducer,
    memo: memoReducer,
    ticket: ticketReducer,
    dashboardAction: dashboardStateReducer,
    appBarAction: appBarStateReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
})