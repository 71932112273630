import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

export const dashboardAction = createSlice({
  name: "dashboardAction",
  initialState: {
    isLegacy: false,
    needReload: false
  },
  reducers: {
    toggleDashboard: (state, action) => action.payload,
    reloadData: (state, action) => action.payload,
  }
})

export const { toggleDashboard, reloadData } = dashboardAction.actions;

export const toggleDashboardState = (state: { dashboardAction: { isLegacy: boolean, needReload: boolean } }) => state.dashboardAction

export default dashboardAction.reducer;