import { Box, Button } from "@mui/material";
import isFunction from "lodash/isFunction";
import { useEffect, useRef, useState } from "react";
import Api from "../../helpers/api";
import Columns from "../../helpers/columns";
import DataStore from "../../helpers/dataStore";
import Loading from "../../helpers/loading/loading";
import { Pagination } from "../../helpers/pagination";
import BasicTable from "../../layout/table/table";
import { styles } from "../../pages/modal/common";
import CustomSelect from "../../pages/utils/CustomSelect";

const CompanyRolladex = ({onSelect, onClosed})=>{
	const modal = useRef();
	const [rolladex, setRolladex] = useState(DataStore.Get.string("companyRolladex") || "A");
	const [companies, setCompanies] = useState([]);
	const [page, setPage] = useState(DataStore.Get.int("companyIndexPage", 1));
	const [totalPages, setTotalPages] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "#"].map(l => ({"id":l.toUpperCase(), "label": l.toUpperCase()}));

	const changePage = (page)=>{
		DataStore.set("companyIndexPage", page);
		setPage(page);
	}

	useEffect(()=>{
		const call = new AbortController();
		setIsLoading(true);
		Api.get(window.jsRoutes.controllers.Companies.index(rolladex, page), call)
			.then(result => {
				setCompanies(result.companies || []);
				setPage(result.page || 1);
				setTotalPages(result.totalPages || 0);
			})
			.catch(Api.silentFail)
			.then(()=>{
				setIsLoading(false);
			})
		return ()=> {
			call.abort();
		}
	}, [rolladex, page])

	const container = modal.current ? modal.current.body() : null;

	return <Box>
		<Columns template={"1fr"}>
      <CustomSelect label="Alphabetically" options={alphabet} value={rolladex} onChange={l => {
        DataStore.set("companyRolladex", l);
				DataStore.set("companyIndexPage", 1);
				setRolladex(l);
				setPage(1);
      }}/>

			<Loading active={isLoading}/>

			<BasicTable headers={["Name"]} container={container}>
				{companies.map(company =>
					<div key={company?._id?.$oid} className={"row"} onClick={()=>{
						if (isFunction(onSelect)) {
							onSelect(company);
						}
					}}>
						<div className={"cell"}>{company.name}</div>
					</div>
				)}
			</BasicTable>

			<Pagination page={page} total={totalPages} onChange={changePage} />
    </Columns>
    <Box sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}>
      <Button variant='contained' sx={styles.action} onClick={onClosed}>Close</Button>
    </Box>
	</Box>
}
export default CompanyRolladex;