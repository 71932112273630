import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef} from "react";

export const PRORedirect = ({execute}) => {
	const params = useParams();
  const navigate = useNavigate();
  const count = useRef(0);

  useEffect(() => {
    if (count.current > 0) {
      return;
    }
    count.current += 1;
		execute(params, navigate);
	}, [execute, navigate, params])

	return <div>Loading...</div>;
}