import {createSlice} from "@reduxjs/toolkit";

export const viewingAircraftSlice = createSlice({
	name: "viewingAircraft",
	initialState: {},
	reducers: {
		setAircraft: (state, action) => action.payload
	}
})

export const {setAircraft} = viewingAircraftSlice.actions;

export const selectViewingAircraft = state => state.viewingAircraft;

export default viewingAircraftSlice.reducer;