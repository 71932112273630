import { useCallback, useEffect, useRef, useState } from "react";
import Api from "./api";
import Autocomplete from "./autocomplete/autocomplete";

const AirportInput = ({id, icao="", sideLabel, onChange=function () {
	console.error("No onChange function assigned to the AirportInput.")
}, className, style, inputStyle}) => {
	const autocomplete = useRef();
	const latestCall = useRef();
	const cancellable = useRef();
	const [_icao, setIcao] = useState(icao);
	const [airports, setAirports] = useState([]);

	const change = (value) => {
		setIcao(value);
	}

	const changeObject = useCallback((item) => {
		clearTimeout(cancellable.current);
		if (item?.icao ?? item?.iata ?? item?.faa) {
			setIcao(item.icao ?? item.iata ?? item.faa);
			setAirports([]);
			onChange(item);
		}
	}, [onChange, setAirports, setIcao])

	useEffect(() => {
		if ((_icao || "").length < 2 || _icao === icao) return
		let call = new AbortController();
		let api = Api.get(window.jsRoutes.controllers.Api.autocompleteAirports(_icao), call);
		latestCall.current = api;
		api.then(results => {
			let airports = results.airports || [];
			if (results.query === _icao) {
				setAirports(airports);
			}
			return airports;
		})
		.catch(Api.silentFail)
		return ()=>{
			call.abort();
		}
	}, [_icao, icao, onChange, changeObject])

	return <Autocomplete
		key={icao}
		ref={autocomplete}
		className={className}
		style={style}
		inputStyle={inputStyle}
		id={id}
		node={function () {
			return (this.icao ?? this.iata ?? this.faa) + " - " + this.name + (this.longestRunway ? " - " + this.longestRunway + "ft" : "") + ((this.distance) ? " (" + this.distance.value.toPrecision(3) + " " + this.distance.unit + ")" : "")
		}}
		placeholder={"..."}
		selected={_icao}
		options={airports}
		onInputChange={change}
		onChange={changeObject}
		onBlur={()=>{
			if (latestCall.current) {
				cancellable.current = setTimeout(()=> {
					latestCall.current.then(result => {
						let first = (result.airports || [])[0];
						if (first) {
							changeObject(first);
						} else {
							setIcao(_icao);
						}
					})
				}, 200)
			} else {
				if (airports[0]) {
					changeObject(airports[0]);
				}
			}
		}}
		sideLabel={sideLabel}
	/>
}
export default AirportInput;