import { Box, Button } from "@mui/material";
import concat from "lodash/concat";
import isFunction from "lodash/isFunction";
import { styles } from "../../pages/modal/common";
import React from "react";
import Api from "../../helpers/api";
import Columns from "../../helpers/columns";
import DataStore from "../../helpers/dataStore";
import { Pagination } from "../../helpers/pagination";
import { Star } from "../../icons/star";
import BasicTable from "../../layout/table/table";
import CustomSelect from "../../pages/utils/CustomSelect";
import { CustomerRoutes } from "../../routes/customer";

export default class CustomerRollabex extends React.Component {
	constructor(props) {
		super(props);
		this.getUsers = this.getUsers.bind(this);
		this.getByCompany = this.getByCompany.bind(this);
		let rolladex = props.rolladex || DataStore.Get.string("customerRolladex") || "A";
		let page = parseInt(props.page) || DataStore.Get.int("customerIndexPage") || 1;
		const alphabet = concat([{
			"id": "*",
			"label": <Star />,
			"action": this.getByCompany
		}], ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "#"].map(l => {
			return {"id":l.toUpperCase(), "label": l.toUpperCase(), "action": this.changeRolladex.bind(this, l)}
		}));
    this.state = { users: [], alphabet: alphabet, rolladex: rolladex, page: page, totalPages: 0, selectedUser: '' };

	}

	componentDidMount() {
		if (this.props.companyId) {
			this.getByCompany();
		} else {
			this.getUsers();
		}
	}

	getUsers(i) {
		Api.get(CustomerRoutes.list(this.state.rolladex, this.state.page))
			.then(results => {
				this.setState({
					"users": results.customers || [],
					page: results.page,
					totalPages: results.totalPages,
					rolladex: results.rolladex
				});
			})
			.catch(e => {
				if (i >= 3) return;
				this.setState({
					users: e.json.customers,
					page: e.json.page,
					totalPages: e.json.totalPages,
					rolladex: e.json.rolladex
				}, this.getUsers.bind(this, i ? i++ : 0));
			});
	}

	getByCompany() {
		Api.post(CustomerRoutes.getAllForCompany(), {
			_id: this.props.companyId,
			contactOnly: false
		}).then(results => {
			this.setState({"users": results.customers, page: 1, totalPages: 1, rolladex: "*"});
		}).catch(Api.flagFail);
	}

  userSelected(user) {
    this.setState({ selectedUser: user._id.$oid })
		if (isFunction(this.props.onSelect)) {
			this.props.onSelect(user);
		}
	}

	changePage(page) {
		DataStore.set("customerIndexPage", page);
		this.setState({"page": page}, this.getUsers);
	}

	changeRolladex(letter) {
		DataStore.set("customerRolladex", letter);
		this.setState({rolladex: letter, page: 1}, this.getUsers);
	}

	render() {
		return (
			<Box>
				<Columns template={"1fr"}>
          <CustomSelect label="Alphabetically" options={this.state.alphabet} value={this.state.rolladex} onChange={l => {
            DataStore.set("customerRolladex", l);
            DataStore.set("customerIndexPage", 1);
            this.changeRolladex(l);
          }} />
          
					<BasicTable headers={["Name"]}>
						{(this.state.users || []).map((user) => {
							return <div key={user._id.$oid} className={"row"}>
								<button key={user._id.$oid} className={`cell text-left ${this.state.selectedUser === user._id.$oid ? 'highlight' : ''}`}
										onClick={this.userSelected.bind(this, user)}>
									{user.lastName}, {user.firstName} {user.middleName}
								</button>
							</div>
						})}
					</BasicTable>
					<Pagination page={this.state.page} total={this.state.totalPages} onChange={this.changePage.bind(this)} />
        </Columns>
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}>
          <Button variant='contained' sx={styles.action} onClick={this.props.onClosed}>Close</Button>
        </Box>
			</Box>
		);
	}
}
CustomerRollabex.defaultProps = {
	label: "Customers"
};