import PRODate from "./proDate";
import isUndefined from "lodash/isUndefined";

/**
 * @return {string}
 */
export const Date = function (timestamp, format) {
	if (isUndefined(format)) {
		format = "MM/dd/yyyy";
	}
	let date = (timestamp instanceof PRODate) ? timestamp : new PRODate(timestamp);
	return date.format(format);
};

/**
 * @return {string}
 */
export const Currency = (amount, currency="USD") => {
	let o;
	try {
		o = Number(Math.abs(amount)).toLocaleString('en-US', {style: 'currency', currency: currency});
	} catch (e) {
		return <span className="text-danger">NA</span>;
	}
	// let [dollars, cents] = (parseFloat(amount)||0).toFixed(2).toString().split(".");
	// dollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return amount < 0 ? <span className="text-danger">({o})</span> : o;
};

export const CurrencySymbol = (currency) => {
	try {
		return (0).toLocaleString('en-US', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();
	} catch(e) {
		return "-";
	}
}
