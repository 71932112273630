import {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from "react";
import isFunction from "lodash/isFunction";
import omit from "lodash/omit";
import styles from "./style.module.scss";
import {Circle} from "../../icons/circle/circle";

const SimpleInput = (props, ref) => {
	const input = useRef();
	const {value, onChange} = props;
	const preprocess = useMemo(() => (props.preprocess || (v => v)), [props.preprocess]);
	const postprocess = useMemo(() => (props.postprocess || (v => v)), [props.postprocess]);
	const [_internal, setInternal] = useState(preprocess(value));
	const [lostFocus, setLostFocus] = useState(false);

	if (input.current) {
		if (input.current.value !== _internal) {
			input.current.value = _internal === undefined ? "" : _internal;
		}
	}

	const focus = () => {
		window.onbeforeunload = (ev)=>{
			ev.preventDefault();
			const message = "Are you sure you want to leave this page before saving?";
			ev.returnValue = message;
			return message;
		};
		input.current.select();
		if (isFunction(props.onFocus)) {
			props.onFocus(ref);
		}
		setLostFocus(false);
	}

	const change = (ev) => {
		setInternal(ev.target.value);
		if (isFunction(props.onCharacter)) {
			const out = postprocess(ev.target.value);
			props.onCharacter(out);
		}
	}

	const blur = (ev) => {
		window.onbeforeunload = null;
		if (isFunction(props.onBlur)) {
			props.onBlur(ev);
		}
		setLostFocus(true);
	}

	const keyPressHandler = (ev) => {
		if (ev.key === 'Enter') {
			window.document.activeElement.blur();
			if (isFunction(props.onEnter)) {
				props.onEnter(postprocess(_internal))
			}
		}
	}

	useEffect(() => {
		let id;
		if (lostFocus) {
			const original = postprocess(value);
			const out = postprocess(_internal);
			if (original !== out) {
				onChange(out);
			}

			id = setTimeout(() => {
				setInternal(_internal => {
					const test = preprocess(value);
					if (test !== _internal) {
						return test;
					}
					return _internal;
				});
			}, 200);
		}
		return () => {
			clearTimeout(id);
		}
	}, [lostFocus, preprocess, postprocess, value, onChange, _internal]);

	useImperativeHandle(ref, () => ({
		value: () => postprocess(_internal),
		width: () => {
			if (!input.current) {
				return -1
			}
			return input.current.getBoundingClientRect().width;
		},
		setValue: (value) => {
			setInternal(value);
			setTimeout(() => {
				input.current.select();
			}, 100)
		},
		focus: () => {
			input.current.focus();
		},
		hasFocus: ()=>{
			if (document.activeElement === document.body) return false;
			return input.current === document.activeElement;
		},
		select: () => {
			input.current.select();
		},
		getBoundingClientRect: () => {
			return input.current.getBoundingClientRect();
		},
		getInput: () => {
			return input.current;
		}
	}), [_internal, input, postprocess])

	useLayoutEffect(() => {
		if (isFunction(props.onSize)) {
			props.onSize(input.current);
		}
	})

	return <div className={styles.proInput + " " + (props.groupClass || "")} style={props.style} onBlur={props.onBlur}>
		<div className="grid-input-group" style={{ backgroundColor: 'transparent'}}>
			{props.prepend ? props.prepend : undefined}

			<input {...omit(props, ["onCharacter", "onSize", "onEnter", "preprocess", "postprocess", "groupClass", "prepend", "append", "style", "inputStyle"])}
				   ref={input} value={_internal} className="input" type={props.type || "text"} onChange={change}
				   onFocus={focus} onBlur={blur} onKeyPress={keyPressHandler} style={props.inputStyle} />

			{(preprocess(props.value) === _internal) ? undefined : <Circle className="input-check text-warning"/>}

			{props.append ? <div className="input-side-label">{props.append}</div> : undefined}
		</div>
	</div>;
}
export default forwardRef(SimpleInput);