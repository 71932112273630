import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CSSProperties } from 'react';
import { styles } from './common';

type ConfirmModalProps = {
  open: boolean
  children?: React.ReactElement
  onCancel: () => void
  onOk: () => void
}

const localStyles: { [x: string]: CSSProperties | {} } = {
  customModal: {
    width: '500px !important',
    padding: '20px',
  },
  customLabel: {
    minWidth: '100px !important',
  }
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ open, onCancel, onOk, children }) => {
  const isMobileView = useMediaQuery('(max-width:700px)');

  return (
    <Modal
      open={open}
      onClose={onCancel}
      sx={{ 
        "& > .MuiBackdrop-root" : {
                backgroundColor: "rgb(0, 0, 0, 0.1)"
              }
        }}
    >
      <Box sx={[styles.modal, localStyles.customModal, isMobileView ? styles.mobile : styles.desktop]}>
        {children}
        <Box sx={[styles.body, { textAlign: 'end' }]}>
          <Button variant='contained' color='inherit' sx={{ width: 80, mr: '5px' }} onClick={onCancel}>Cancel</Button>
          <Button variant='contained' color='primary' sx={{ width: 80 }} onClick={onOk}>OK</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmModal;