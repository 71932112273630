import {RouteBuilder} from "./base";

export const GeneralRoutes = {
	"image": (op0)=> RouteBuilder.builder({
		method: "GET",
		url: "/api/image/" + encodeURIComponent((function (k, v) {
			return v
		})("op", op0)) + "?bucket=levelflight-images"
	}),
	"manual": (op0)=> RouteBuilder.builder({
		method: "GET",
		url: "/api/image/" + encodeURIComponent((function (k, v) {
			return v
		})("op", op0)) + "?bucket=levelflight-manuals"
	}),
	"attachment": (op0)=> RouteBuilder.builder({
		method: "GET",
		url: "/api/image/" + encodeURIComponent((function (k, v) {
			return v
		})("op", op0)) + "?bucket=levelflight-attachments"
	}),
	"iep": (op0, name1)=> RouteBuilder.builder({
		method: "GET",
		url: "/api/image/" + encodeURIComponent((function (k, v) {
			return v
		})("op", op0)) + "?bucket=levelflight-ieps&name=" + encodeURIComponent((function (k, v) {
			return v
		})("name", name1))
	}),
	"thumb": (op0)=> RouteBuilder.builder({
		method: "GET",
		url: "/api/image/" + encodeURIComponent((function (k, v) {
			return v
		})("op", op0)) + "/thumb"
	}),
	"attachPolicy": ()=> RouteBuilder.builder({
		method: "POST",
		url: "/api/user/attachPolicy"
	}),
	"logo": {
		"default": function(op0) {
			return RouteBuilder.builder({
				method: "GET",
				url: "/api/image/logo_" + encodeURIComponent((function (k, v) {
					return v
				})("op", op0)) + "/thumb"
			})
		},
		"dark": function(op0) {
			return RouteBuilder.builder({
				method: "GET",
				url: "/api/image/logo_" + encodeURIComponent((function (k, v) {
					return v
				})("op", op0)) + "/thumb?dark=true"
			})
		},
	},
}