import React from "react";
import get from "lodash/get";
import styles from "./style.module.scss";
import { useMediaQuery } from "@mui/material";

const Form = ({ className, style, children, bottom }) => {
  const isMobileView = useMediaQuery("(max-width:500px)");

	const check = (i) => {
		const type = (get(i, "$$typeof") || "").toString();
		const typeCheck = typeof (i || {}).type;
		return type.includes("react.") && !typeCheck.includes("string");
	}

  return <div className={[isMobileView ? {} : styles.labelGrid, (className || "")].join(" ")} style={style}>
		{React.Children.map(children, i => {
			let options = {};
			const c = check(i);
			const typeCheck = typeof (i || {}).type;
			if (c) {
				options.grid = 'true';
			}
			if (typeCheck.includes("symbol")) {
				return React.cloneElement(i, null, React.Children.map(i.props.children, j => j ? React.cloneElement(j, check(j) ? options : {}) : j));
			}
			return i ? React.cloneElement(i, options) : i;
		})}
		{bottom ? <div className={styles.bottomBar}>{bottom}</div> : undefined}
	</div>
};
export default Form;