import {setBreadcrumb, setSubtitle, setTitle} from "../data/menuReducer";
import {useDispatch} from "react-redux";
import {useMemo} from "react";

export function useMenu() {
	const dispatch = useDispatch();

	return useMemo(() => ({
		setTitle: (obj) => {
			dispatch(setTitle(obj));
		}, setBreadcrumb: (obj) => {
			dispatch(setBreadcrumb(obj));
		}, setSubtitle: (obj) => {
			dispatch(setSubtitle(obj));
		}
	}), [dispatch]);
}