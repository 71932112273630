import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { MenuItems } from './constants';


type AccordionProps = {
  anchorEl: SVGSVGElement | null;
  onSelect: (value: string) => void;
  onClose: () => void
}

const Accordions: React.FC<AccordionProps> = ({ anchorEl, onSelect, onClose }) => {
  const open = Boolean(anchorEl);

  const onSelectItem = (value: string) => {
    onClose();
    setTimeout(() => {
      onSelect(value);
    }, 50);
  }

  return <Menu
    PaperProps={{
      style: {
        width: 300,
      },
    }}
    sx={{
      '& .MuiMenu-list': {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
      },
    }}
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    TransitionComponent={Fade}
  >
    {MenuItems.map((menuItem, idx) => (
      <Accordion key={idx}
        sx={{
          margin: '0px !important',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Box>{menuItem.label}</Box>
        </AccordionSummary>
        <AccordionDetails>
          {menuItem.menuItems.map(item => (
            <MenuItem key={item.label} onClick={() => onSelectItem(item.value)}>
              {item.label}
            </MenuItem>
          ))}
        </AccordionDetails>
      </Accordion>
    ))}
  </Menu>
}

export default Accordions;
