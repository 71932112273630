import {createSlice} from "@reduxjs/toolkit";
import DataStore from "../helpers/dataStore";

export const memoSlice = createSlice({
	name: "memo",
	initialState: { text: DataStore.Get.string("memo", null) },
	reducers: {
		setMemo: (state, action) => {
			if (!action.payload) {
				DataStore.remove("memo");
				state.text = null;
			} else {
				DataStore.set("memo", action.payload);
				state.text = action.payload;
			}
		}
	}
})

export const {setMemo} = memoSlice.actions;

export const selectMemo = state => state.memo;

export default memoSlice.reducer;