import {createSelector, createSlice} from "@reduxjs/toolkit";
import set from "lodash/set";

export const tripSlice = createSlice({
	name: "trips",
	initialState: {},
	reducers: {
		setTrip: (state, action) => {
			try {
				const id = action.payload._id.$oid;
				state[id] = action.payload;
			} catch (e) {
				state = {};
			}
		},
		updateTrip: (state, action) => {
			set(state, `${action.payload.trip}.${action.payload.label}`, action.payload.value);
			// Need to set all the embedded dispatch in each leg too!
			Object.values(state).forEach(trip => {
				(trip.legs || []).forEach(leg => {
					if (leg.dispatch._id.$oid === action.payload.trip) {
						set(leg.dispatch, action.payload.label, action.payload.value);
					}
				})
			})
		},
		updateLeg: (state, action) => {
			let _trip = state[action.payload.trip] ? action.payload.trip : Object.entries(state).find(k => (k[1].legs || []).some(l => l._id.$oid === action.payload.leg))[0];
			let index = state[_trip].legs.findIndex(i => i._id.$oid === action.payload.leg);
			set(state, `${_trip}.legs.${index}.${action.payload.label}`, action.payload.value);
    },
    updateLegs: (state, action) => {
			let _trip = state[action.payload.trip] ? action.payload.trip : Object.entries(state).find(k => (k[1].legs || []))[0];
			set(state, `${_trip}.legs`, action.payload.legs);
    },
	}
})

export const {setTrip, updateTrip, updateLeg, updateLegs} = tripSlice.actions;

export const selectTrip = id => createSelector(
	[state => state.trips[id]],
	trip => trip || {}
);
export const selectLeg = (tripId, legId) => createSelector(
	[state => state.trips[tripId]],
	trip => {
		return trip?.legs?.find?.(l => l._id && l._id.$oid && l._id.$oid === legId) || {};
	}
)

export default tripSlice.reducer;