import isObject from "lodash/isObject";
import isBoolean from "lodash/isBoolean";

export const Part = {
	parse: function (id) {
		try {
			if (id instanceof Part) return id;
		} catch {}
		switch (id) {
			case 1:
				return this.Part91;
			case 2:
				return this.Part121;
			case 3:
				return this.Part125;
			case 4:
				return this.Part135;
			default:
				return this.Part91;
		}
	},
	byPurpose: function (purpose, hasPax, part) {
		if ([7, 10].includes(isObject(purpose) ? purpose.id : purpose) && hasPax) return part;
		return this.Part91;
	},
	byLeg: function (leg, part) {
		return this.byPurpose(leg.dispatch.purpose, isBoolean(leg.isPositioning) ? !leg.isPositioning : (leg.passengers || []).length > 0 || leg.passengerCount > 0, part)
	},
	list: function() {
		return [this.Part91, this.Part135, this.Part125, this.Part121];
	},
	Part91: {id: 1, name: "Part 91"},
	Part121: {id: 2, name: "Part 121"},
	Part125: {id: 3, name: "Part 125"},
	Part135: {id: 4, name: "Part 135"}
};