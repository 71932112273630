import { CSSProperties } from "react";
import Box from '@mui/material/Box';
import { SxProps } from "@mui/material";

export const styles: { [x: string]: CSSProperties | {} } = {
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    maxHeight: '95%',
    bgcolor: 'var(--white)',
    boxShadow: 0,
    padding: 'var(--space-md)',
    borderRadius: 'var(--radius-sm)',
    // '&::-webkit-scrollbar': {
    //   width: '0.4em'
    // },
    // '&::-webkit-scrollbar-track': {
    //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    //   webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#7c809c',
    // }
  },
  desktop: {
    width: 700,
  },
  mobile: {
    width: '95%',
    maxHeight: '95%'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > svg': {
      cursor: 'pointer',
      fontSize: '25px', 
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 'var(--space-md)'
    },
    '& > div': {
      backgroundColor: 'transparent',
      padding: 'var(--space-sm)',
      fontSize: 'var(--font-lg)',
      fontWeight: 'var(--font-weight-bold)',
      lineHeight: 'initial'
    }
  },
  body: {
    padding: 'var(--space-sm)', 
    overflowY: 'auto', 
    maxHeight: '70vh'
  },
  footer: {
    paddingLeft: '25px'
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'var(--font-weight-normal)',
    fontSize: 'var(--font-base)',
    color: 'var(--black)', 
    minWidth: 'unset',
    maxWidth: 'none', 
    paddingTop: 'var(--space-xs)',
    paddingBottom: 'var(--space-xs)',
    paddingLeft: 'var(--space-md)',
    paddingRight: 'var(--space-md)',
    borderRadius: 'var(--radius-xs)',
    minHeight: 'unset',
    height: '40px', /* match mui button base height */
    border: '1px solid var(--lightGray)',
    marginRight: 'var(--space-xs)',
    '&.Mui-selected': { 
      background: 'var(--primaryBlue)',
      color: 'var(--white) !important', 
      border: '1px solid var(--primaryBlue)'
    }
  },
  control: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: 'var(--space-xs)',
    paddingBottom: 'var(--space-xs)',
    marginTop: 0,
    marginLeft: 0,
    '& > .control-label': {
      display: 'none'
    },
    '& > p': {
      minWidth: '120px',
      fontSize: '15px'
    },
    '& > div': {
      width: 'calc(100% - 120px)',
      '& > div': { 
        width: '100%'
      },
      '& > label > span': {
        fontSize: '14px'
      }
    },
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  action: {
    textTransform: 'none',
    marginLeft: 1,
    marginTop: -1,
    fontWeight: 600,
    fontSize: 'var(--font-base)',
    backgroundColor: 'var(--primaryBlue)',
    color: 'var(--white)',
    height: '40px'
  },
  error: {
    backgroundColor: '#D32F2F !important'
  },
  divider: {
    width: '2px',
    backgroundColor: 'gray',
    height: '30px',
    m: '0 4px 0 4px'
  },
  pointer: {
    cursor: 'pointer',
    pointerEvents: 'all'
  }
}

export const sectionTabStyles = {
  activeTab: {
    backgroundColor: 'var(--primaryBlue)',
    color: 'var(--white)',
    fontFamily: 'var(--sans)'
  }
}

export const searchInputStyles = {
  backgroundColor: 'var(--lightestGray)',
  color: 'var(--trueBlack)',
  height: '40px',
  padding: '0 0 0 10px',
  gap: '5px',
  borderRadius: 'var(--radius-xs)',
  border: '1px solid var(--lightGray)'

}

export const addNewButtonStyle = {
  backgroundColor: 'var(--primaryBlue)',
  height: '36px',
  color: '#fff',
  minWidth: 'max-content',
  margin: '10px 0 10px 0'
}

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: SxProps
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </Box>
  );
}

export const isValidEmail = (email: string) => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};