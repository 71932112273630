import { useEffect, useState } from "react";
import Api from "../../helpers/api";
import PRODate from "../../helpers/proDate";
import get from "lodash/get";
import includes from "lodash/includes";
import union from "lodash/union";
import uniqBy from "lodash/uniqBy";
import BasicTable from "../../layout/table/table";
import { Try } from "../../helpers/utils/try";

const ReadyForMeeting = ({ onChange, className, style, ...props }) => {
  const [selected, setSelected] = useState(props.selected);
  const [ieps, setIeps] = useState(selected || []);

  const toggleEvent = (value) => {
    let index = selected.findIndex((item) => get(item, "_id.$oid") === get(value, "_id.$oid"));
    let state = [...selected];
    if (index >= 0) {
      state.splice(index, 1);
    } else {
      state.push(value);
    }
    onChange(state);
    setSelected(state);
  }

  useEffect(() => {
    const call = new AbortController();
    Api.post(window.jsRoutes.controllers.IEPs.list(), { active: true }, call)
      .then((response) => {
        response.ieps.forEach(function (item) {
          item.due = new PRODate(item.due);
          item.assignedDate = new PRODate(item.assignedDate);
        });
        setIeps(ieps => uniqBy(union(ieps, response.ieps), (item) => item._id.$oid));
      })
      .catch(Api.flagFail);
    return () => {
      call.abort();
    }
  }, [])

  let selectedIds = selected.map((item) => get(item, "_id.$oid"));

  return <BasicTable className={className} style={style} template={"1fr auto auto auto auto"}
    headers={["Description", "Assigned", "Due", "To", ""]}>
    {ieps.map((item) =>
      <SafetyMeetingIEP key={item._id.$oid} {...item} selected={includes(selectedIds, item._id.$oid)}
        onClick={() => toggleEvent(item)} />
    )}
  </BasicTable>
}
export default ReadyForMeeting;

export const SafetyMeetingIEP = props => {
  const truncate = (str = '', count) => {
    if (str.length < count) {
      return str;
    }
    let sub = `${str}`.substring(0, count);
    return sub.substring(0, sub.lastIndexOf(" ")) + "...";
  }

  return <button className={`row passthru ${props.selected ? "active " : ""}`} onClick={props.onClick}>
    <div className="cell text-overflow">{truncate(props.name, 70)}</div>
    <div className="cell mono">{props.assignedDate.format("MMM DD, yyyy")}</div>
    <div className="cell mono no-wrap">{props.due.format("MMM DD, yyyy")}</div>
    <div className="cell" style={{ verticalAlign: "top" }}>{Try(() => props.assigned.lastName)}, {Try(() => props.assigned.firstName)}</div>
    <div className={"cell"} />
  </button>
}