import { Box } from "@mui/material";
import isFunction from "lodash/isFunction";
import isPlainObject from "lodash/isPlainObject";
import React, { Fragment } from "react";
import styles from "./style.module.scss";
import { noop } from "lodash";

const PROLabel = ({
  name,
  value,
  style,
  error,
  addendum,
  className,
  grid,
  labelClass,
  hideLabel,
  noBox,
  onClick = noop,
  required
}) => {
  const WrapperComp = noBox ? Fragment : Box;
  let out;
  if (grid) {
    out = (
      <Fragment>
        <label
          className={[
            "control-label",
            styles.controlLabel,
            labelClass || "",
            error ? "has-error" : "",
          ].join(" ")}
          style={{ ...style, width: name ? "" : "4px" }}
          onClick={onClick}
        >
          {name}
          {addendum ? (
            <span className="text-small"> ({addendum})</span>
          ) : undefined}
          {required ? <span style={{marginLeft:2}}>*</span> : undefined}
        </label>
        <div
          className={
            "control-content " + (className || "") + (error ? " has-error" : "")
          }
          style={style}
        >
          {value}
        </div>
      </Fragment>
    );
  } else {
    let newValue = value;
    if (isPlainObject(newValue) || isFunction(newValue)) {
      let valueProps = Object.assign({}, value.props || {});
      valueProps.className = [valueProps.className || "", className || ""]
        .join(" ")
        .trim();
      valueProps.style = Object.assign(valueProps.style || {}, {
        width: "100%",
      });
      newValue = React.cloneElement(value, valueProps);
    }
    // out = <Columns className={[styles.inputGroup, (error ? "has-error" : ""), (groupClass ? groupClass : "")].join(" ").trim()} template="auto 1fr" style={style}></Columns>
    out = (
      <WrapperComp>
        {!hideLabel && <label
          className={[
            "control-label",
            styles.controlLabel,
            labelClass || "",
          ].join(" ")}
          style={{ width: name ? "" : "4px" }}
        >
          {name}
          {addendum ? (
            <span className="text-small"> ({addendum})</span>
          ) : undefined}
        </label>}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            ...style
          }}
        >
          {newValue}
        </Box>
      </WrapperComp>
    );
  }
  return out;
};
export default PROLabel;
