import { selectTicket } from "@data/ticketReducer.jsx";
import Api from "@helpers/api";
import { Dropdown, DropdownInput } from "@helpers/dropdown";
import Form from "@helpers/form/form";
import { default as Label, default as PROLabel } from "@helpers/label/label";
import PRODate from "@helpers/proDate";
import { YesOrNo } from "@helpers/radioInput/radioInput";
import { TextInput } from "@helpers/textInput";
import { SMSTicketType } from "@helpers/utils/SMSTicketType";
import { History } from "@icons/history.jsx";
import { Box, Button, IconButton } from "@mui/material";
import includes from "lodash/includes";
import isPlainObject from "lodash/isPlainObject";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChangeLogModal from "../../../pages/modal/change-log";
import { styles } from "../../../pages/modal/common";

export const Processing = ({id, safetyTeam, onSave, onClose}) => {
  const item = useSelector(selectTicket(id));
	const [errors, setErrors] = useState([]);

	const [rii, setRii] = useState(item.rii ?? false);
	const [cassEvent, setCassEvent] = useState(item.cassEvent ?? false);
	const [discrepancy, setDiscrepancy] = useState(item.discrepancy ?? false);
	const [asapEligible, setAsapEligible] = useState(item.asapEligible ?? false);
	const [deferred, setDeferred] = useState(item.deferred ?? false);
	const [riskProfileAnalysis, setRiskProfileAnalysis] = useState(item.riskProfileAnalysis ?? false);
	const [riskProfileAnalysisDescription, setRiskProfileAnalysisDescription] = useState(item.riskProfileAnalysisDescription);
	const [mis, setMis] = useState(item.mis ?? {});
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false)

  useEffect(() => {
    setRii(item.rii ?? false)
    setCassEvent(item.cassEvent ?? false)
    setDiscrepancy(item.discrepancy ?? false)
    setAsapEligible(item.asapEligible ?? false)
    setDeferred(item.deferred ?? false)
    setRiskProfileAnalysis(item.riskProfileAnalysis ?? false)
    setRiskProfileAnalysisDescription(item.riskProfileAnalysisDescription)
    setMis(item.mis ?? {})
  }, [item])

	const save = (btn)=>{
		let out = {
			"discrepancy": null,
			"rii": null,
			"deferred": null,
			"riskProfileAnalysis": null,
			"riskProfileAnalysisDescription": null,
			"asapEligible": null,
			"cassEvent": cassEvent,
			"mis": mis,
		};
		if (item.ticketType !== SMSTicketType.Change.id) {
			out.discrepancy = discrepancy;
		}
		if (item.ticketType !== SMSTicketType.Change.id && discrepancy) {
			out.deferred = deferred;
		}
		if ((item?.aircraft?.paxSeats ?? 0) > 10) {
			out.rii = rii;
		}
		if (item.ticketType !== SMSTicketType.Maintenance.id || cassEvent) {
			out.riskProfileAnalysis = riskProfileAnalysis;
		}
		if (item.riskProfileAnalysis) {
			out.riskProfileAnalysisDescription = riskProfileAnalysisDescription;
		}
		if (item.asapEvent) {
			out.asapEligible = asapEligible;
		}
		if (isPlainObject(out.deferred)) {
			if (!out?.deferred?.code) {
				out.deferred.code = "A";
			}
		}
		Api.post(window.jsRoutes.controllers.SMS.saveProcessing(item._id.$oid), out)
			.then(Api.flagSuccess)
			.then(() => {
				setErrors([]);
				onSave();
			})
			.catch(e => {
				Api.flagFail(e);
				setErrors(e.json.errors || []);
			})
			.then(() => {
				btn.deactivate();
			});
	}

	return <Form bottom={<>
		{item?.logs?.processed?.user?._id ?
			<div className="text-center">Last edited
				by {item?.logs?.processed?.user?.firstName} {item?.logs?.processed?.user?.lastName} on {(item?.logs?.processed?.timestamp || new PRODate()).format("MMM dd, yyyy")}
				<IconButton onClick={() => setOpenChangeLogModal(true)}><History/></IconButton>
			</div> : undefined}
    <Box sx={{ width: '100%', textAlign: 'end' }}>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={onClose}>Cancel</Button>
      <Button variant='contained' sx={[styles.action, {width: '120px'}]} onClick={save}>Save</Button>
    </Box>
	</>}>
    <ChangeLogModal open={openChangeLogModal} id={item?._id?.$oid} node={"logs.processed"} onClosed={() => setOpenChangeLogModal(false)}/>
		{(item?.aircraft?.paxSeats ?? 0) > 10 ? <YesOrNo name="RII" value={rii} onChange={v => setRii(v)} error={includes(errors, "obj.rii")}/> : undefined}

		<YesOrNo name="CASS Event" value={cassEvent}
				 onChange={v => {
					 setCassEvent(v);
					 if (!v) {
						 setRiskProfileAnalysis(false);
						 setRiskProfileAnalysisDescription(null);
					 }
				 }}
				 error={includes(errors, "obj.cassEvent")}/>

		{(item.ticketType !== SMSTicketType.Maintenance.id || cassEvent) ?
			<YesOrNo name="Risk Profile Analysis" value={riskProfileAnalysis}
							 onChange={v => setRiskProfileAnalysis(v)}
							 error={includes(errors, "obj.riskProfileAnalysis")}/>
			: undefined}
		{riskProfileAnalysis ?
			<TextInput name="Description" value={riskProfileAnalysisDescription}
								 onChange={v => setRiskProfileAnalysisDescription(v)}/>
			: undefined}

		{(item.ticketType !== SMSTicketType.Change.id) ?
			<YesOrNo name="Discrepancy" value={discrepancy}
					 onChange={() => {
						 if (discrepancy) {
							 setDiscrepancy(false);
							 setDeferred(null);
						 } else {
							 setDiscrepancy(true);
						 }
					 }}
					 error={includes(errors, "obj.discrepancy")} />
		: false}
		{(item.ticketType !== SMSTicketType.Change.id && discrepancy) ?
			<YesOrNo name="Deferred" value={isPlainObject(deferred)}
					 onChange={() => {
						 if (isPlainObject(deferred)) {
							 setDeferred(null);
						 } else {
							 setDeferred({});
						 }
					 }} />
		: false}

		{item.asapEvent ?
			<YesOrNo name="ASAP Eligible" value={asapEligible}
					 onChange={v => setAsapEligible(v)}
					 error={includes(errors, "obj.asapEligible")}/>
		: undefined}

		{/* This comes from the create function (iOS mostly), but there's no other place to set this certified fields... */}
		{item.certified ? <>
			<Label name="Processed By" value={item?.certified?.by} />
			<Label name="On" value={item?.certified?.date?.format?.()} />
			<Label name="" value={item?.certified?.signature ? <img alt="signature" src={item?.certified?.signature} className="img-responsive"/> : undefined}/>
		</> : undefined}

		<YesOrNo name="MIS Required" value={mis?.required ?? false} onChange={v => setMis(p => {
			let state = {...p};
			state.required = v;
			return v ? state : null;
		})} error={includes(errors, "obj.mis.required")}/>

		{mis?.required ? <>
			{mis?.due ? <PROLabel name={"MIS Due By"} value={mis?.due?.format?.()} /> : undefined}
			<DropdownInput key={mis.assigned || ""} name="MIS Assigned To"
						   value={mis.assigned || ""}
						   options={safetyTeam}
						   onChange={v=> setMis(p=> {
								 let state = {...p};
								 state.assigned = v;
								 return state;
							 })}
						   valueNode="_id.$oid"
						   valueOnly={false}
						   labelNode={Dropdown.Formatter.UserName}
						   error={includes(errors, "obj.mis.assigned")} />
			<YesOrNo name="MIS Filed" value={mis?.filed} onChange={v => setMis(p=> {
				let state = {...p};
				state.filed = v;
				return state;
			})} error={includes(errors, "obj.mis.filed")}/>
		</> : undefined}
	</Form>
}